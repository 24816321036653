<template>
  <div
    :id="id"
    class="modal"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
          >
            &times;
          </button>
          <h4 class="modal-title">
            Conversion from wavenumber
          </h4>
        </div>
        <div class="modal-body">
          Wavelength 
          <math-jax
            latex="\lambda = \frac{1}{k} \Longrightarrow \lambda[\mathrm{nm}] = \frac{10^7}{k[\mathrm{cm^{-1}}]}"
            :block="true"
          />
          Optical period 
          <math-jax
            latex="T = \frac{1}{ck} \Longrightarrow T[\mathrm{fs}] \approx \frac{3.336\cdot
          10^4}{k[\mathrm{cm^{-1}}]}"
            :block="true"
          />
          Angular frequency
          <math-jax
            latex="\omega = 2\pi c k \Longrightarrow \omega[\mathrm{fs^{-1}}]
          \approx \frac{k[\mathrm{cm^{-1}}]}{5308.837}"
            :block="true"
          />
          Energy 
          <math-jax
            latex="E = 2\pi c\hbar k \Longrightarrow E[\mathrm{eV}]
          \approx \frac{k[\mathrm{cm^{-1}}]}{8065.550} "
            :block="true"
          />
          Frequency
          <math-jax
            latex="f = ck \Longrightarrow f[\mathrm{THz}] \approx
          \frac{k[\mathrm{cm^{-1}}]}{33.356} "
            :block="true"
          />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-default"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: null,
  },
};
</script>
