import axios from 'axios';
import {authHeader} from './auth-header';

class DataService {

  //materials
  getMaterialsData(){
    return axios.get(process.env.VUE_APP_BASE_URL + "/api/materials", { headers: authHeader() });
  }
  async getMaterialData(material) {
    return axios.get(process.env.VUE_APP_BASE_URL + "/api/materials/" + material, { headers: authHeader() });
  }
  getN2MaterialsData(){
    return axios.get(process.env.VUE_APP_BASE_URL + "/api/materials/n2defined", { headers: authHeader() });
  }
  async updateMaterial(material){
    try {
      return await axios.put(process.env.VUE_APP_BASE_URL + "/api/materials/" + material.key, material, { headers: authHeader() });
    } catch (error) {
      return error.response;
    }
  }
  async saveAsMaterial(key,material){
    try {
      return await axios.post(process.env.VUE_APP_BASE_URL + "/api/materials/save/" + key, material, { headers: authHeader() });
    } catch (error) {
      return error.response;
    }
  }
  async deleteMaterial(key){
    try {
      return await axios.delete(process.env.VUE_APP_BASE_URL + "/api/materials/remove/" + key, { headers: authHeader() });
    } catch (error) {
      return error.response;
    }
  }

  //schemes
  getSchemesData(){
    return axios.get(process.env.VUE_APP_BASE_URL + "/api/schemes", { headers: authHeader() });
  }
  getSchemeData(name){
    return axios.get(process.env.VUE_APP_BASE_URL + "/api/schemes/name/" + name, { headers: authHeader() });
  }
  updateScheme(name, jsonScheme){
    return axios.put(process.env.VUE_APP_BASE_URL + "/api/schemes/" + name, jsonScheme, { headers: authHeader() });
  }
  registerScheme(jsonScheme){
    return axios.post(process.env.VUE_APP_BASE_URL + "/api/schemes", jsonScheme, { headers: authHeader() });
  }


  //products
  getProductsData(){
    return axios.get(process.env.VUE_APP_BASE_URL + "/api/products", { headers: authHeader() });
  }
  getCategorizedProductsData(){
    return axios.get(process.env.VUE_APP_BASE_URL + "/api/products/categorized", { headers: authHeader() });
  }
  getProductSpecsData(){
    return axios.get(process.env.VUE_APP_BASE_URL + "/api/products/specs", { headers: authHeader() });
  }
  getHarmonicOptimizationSpec(){
    return axios.get(process.env.VUE_APP_BASE_URL + "/api/products/harmonicOptimizationSpec", { headers: authHeader() });
  }


  getProductData(productId){
    return axios.get(process.env.VUE_APP_BASE_URL + "/api/products/" + productId,  { headers: authHeader() });
  }
  registerProduct(product){
    return axios.post(process.env.VUE_APP_BASE_URL + "/api/products", product, { headers: authHeader() });
  }
  updateProduct(id, product){
    return axios.put(process.env.VUE_APP_BASE_URL + "/api/products/" + id, product,  { headers: authHeader() });
  }
  deleteProduct(productId){
    return axios.delete(process.env.VUE_APP_BASE_URL + "/api/products/" + productId,  { headers: authHeader() });
  }

  //images
  postSvgImage(id, image){
    var formData = new FormData();
    formData.append("file", image);
    return axios.post(process.env.VUE_APP_BASE_URL + "/api/images/svg/"+ id, formData, { headers: authHeader() } )
  }
  postDescriptionImage(id, image){
    var formData = new FormData();
    formData.append("file", image);
    return axios.post(process.env.VUE_APP_BASE_URL + "/api/images/description/"+ id, formData, { headers: authHeader() } )
  }
  deleteDescriptionImage(id){
    return axios.delete(process.env.VUE_APP_BASE_URL + "/api/images/description/"+ id, { headers: authHeader() } )
  }
  
  //images
  postSchemeImage(id, image){
    var formData = new FormData();
    formData.append("file", image);
    return axios.post(process.env.VUE_APP_BASE_URL + "/api/images/scheme/"+ id, formData, { headers: authHeader() } )
  }

  //presets
  getPresetsData(){
    return axios.get(process.env.VUE_APP_BASE_URL + "/api/presets", { headers: authHeader() });
  }
  getPresetData(presetId){
    return axios.get(process.env.VUE_APP_BASE_URL + "/api/presets/" + presetId, { headers: authHeader() });
  }
  registerPreset(presetInfo){
    return axios.post(process.env.VUE_APP_BASE_URL + "/api/presets", presetInfo, { headers: authHeader() });
  }
  updatePreset(name, presetInfo){
    return axios.put(process.env.VUE_APP_BASE_URL + "/api/presets/" + name, presetInfo, { headers: authHeader() });
  }
  deletePreset(presetId){
    return axios.delete(process.env.VUE_APP_BASE_URL + "/api/presets/" + presetId, {headers: authHeader()});
  }
  uploadPresetFromScheme(jsonName){
    return axios.post(process.env.VUE_APP_BASE_URL + "/api/presets/fromScheme", jsonName, { headers: authHeader() });
  }

  //system 
  getApiVersion(){
    return axios.get(process.env.VUE_APP_BASE_URL + "/api/system/version", { headers: authHeader() });
  }

  getOldSchemeData(name){
    return axios.get(process.env.VUE_APP_OLD_SCHEMES + name + ".json");
  }
}

export default new DataService();
