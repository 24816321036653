<template lang="">
  <div
    v-if="pdfData"
    style="width: 900px"
  >
    <div id="content">
      <table
        width="100%"
        border="0"
      >
        <tr>
          <td align="left">
            <a :href="link">http://toolbox.lightcon.com/planner/{{ pdfData.name }}</a>
          </td>
          <td align="right">
            <button @click="print()">
              Print
            </button>
          </td>
        </tr>
      </table>
    </div>

    <table
      width="100%"
      border="0"
    >
      <tr>
        <td align="left">
          <img
            style="width: 200px"
            src="http://lightcon.com/images/LCN-logo.png"
            alt="Light Conversion logo"
          >
        </td>
        <td align="right" />
      </tr>
    </table>

    <table
      width="100%"
      border="0"
    >
      <tr>
        <td>
          <h1><strong>Optical table layout</strong></h1>
          <h2>
            <strong>{{ pdfData.layoutSettings.title }}</strong>
          </h2>
          <p>{{ pdfData.layoutSettings.comments }}</p>
          <table class="table table-striped">
            <thead />
            <tr>
              <td><strong>Author</strong></td>
              <td>{{ pdfData.layoutSettings.author }}</td>
            </tr>
            <tr>
              <td><strong>Email</strong></td>
              <td>{{ pdfData.layoutSettings.email }}</td>
            </tr>
            <tr>
              <td><strong>Date</strong></td>
              <td>{{ today }}</td>
            </tr>
            <tr>
              <td><strong>Layout code</strong></td>
              <td>{{ pdfData.name }}</td>
            </tr>
            <tr>
              <td><strong>Table size</strong></td>
              <td>{{ tableSizeFromParameters(pdfData.tableParameters) }}</td>
            </tr>
            <tr>
              <td><strong>Table type</strong></td>
              <td>{{ pdfData.tableParameters.type }}</td>
            </tr>
          </table>
        </td>
        <td
          valign="bottom"
          align="right"
        >
          <img
            :src="qrsrc"
            alt=""
            title=""
          >&nbsp;&nbsp;&nbsp;
        </td>
      </tr>
    </table>

    <div style="margin-top: 10px">
      <img
        style="width: 100%"
        :src="image"
      >
    </div>

    <div id="kitchenWrap">
      <div>
        <h2>Items</h2>
        <table class="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Item name</th>
              <th>Item code</th>
              <th>Full size</th>
              <th>Housing size</th>
              <th>Dynamics NAV code</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(items, index) in pdfData.products"
              :key="index"
            >
              <td
                v-for="item in items"
                :key="item"
              >
                {{ item }}
              </td>
            </tr>
          </tbody>
        </table>
        <hr>
        <table
          width="880"
          border="0"
        >
          <tr>
            <td align="left">
              © Light Conversion, UAB
            </td>
            <td align="right">
              Phone: +370 5 249 18 30,&nbsp; e-mail:&nbsp;<a href="mailto:sales@lightcon.com">sales@lightcon.com</a>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import DataService from "@/services/data.service";

export default {
  props: ["id"],
  data() {
    return {
       pdfData: null,    
    }
  },
  computed: {
    link() {
      if (this.pdfData != null) {
        return "/planner/" + this.pdfData.name;
      } else {
        return "/planner/";
      }
    },
    qrsrc() {
      if (this.pdfData != null) {
        return (
          "https://api.qrserver.com/v1/create-qr-code/?data=http://toolbox.lightcon.com/planner/" +
          this.pdfData.name +
          "&size=150x150"
        );
      } else {
        return "";
      }
    },
    today() {
      var today = new Date();
      var hh = today.getHours();
      var min = today.getMinutes();
      var dd = today.getDate();
      var mm = today.getMonth() + 1; //January is 0!
      var yyyy = today.getFullYear();

      if (dd < 10) {
        dd = "0" + dd;
      }

      if (mm < 10) {
        mm = "0" + mm;
      }

      if (min < 10) {
        min = "0" + min;
      }

      return yyyy + "-" + mm + "-" + dd + " " + hh + ":" + min;
    },
    image() {
      return process.env.VUE_APP_SCHEMES_PATH + this.pdfData.name + ".png" + "?" + Date.now();
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    print() {
      window.print();
    },
    getData() {
      DataService.getSchemeData(this.id).then((response) => {
        this.pdfData = response.data;
      });
    },
    tableSizeFromParameters(tableParameters){
      if (tableParameters.type === "imperial") {
          return tableParameters.X / 30.48 / 10 + " ft x " + tableParameters.Y / 30.48 / 10 + " ft";
        } else {
          return tableParameters.X / 10 + " cm x " + tableParameters.Y / 10 + " cm";
        }
    }
  },
};
</script>
<style lang="css" scoped>
h1,
h2 {
  color: black;
}
</style>
