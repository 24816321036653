export const pi = 3.14159265359;
export const inverseGammas = [
  Infinity,
  1.0,
  1.7724538509055159,
  2.678938534707748,
  3.6256099082219087,
  4.5908437119988035,
  5.566316001780236,
  6.548062940247824,
  7.533941598797611,
  8.522688139219477,
  9.513507698668732,
  10.505874856078682,
  11.499428186073992,
  12.493910817096538,
  13.489135130274068,
  14.484960871964619,
  15.481281081592398,
  16.478012759093506,
  17.475090507152988,
  18.47246209704247,
  19.47008531125551,
  20.467925653438638,
  21.46595465971433,
  22.46414863479381,
  23.46248769318332,
  24.460955022856115,
  25.459536313392714,
  26.458219307256787,
  27.456993444337222,
  28.455849577892057,
  29.454779745699692,
  30.453776984289362,
  31.452835177076054,
  32.45194892939254,
  33.45111346501913,
  34.450324540016226,
  35.44957837057517,
  36.448871572296255,
  37.44820110883671,
  38.447564248283804,
  39.44695852593014,
  40.446381712381395,
  41.44583178612555,
  42.445306909852484,
  43.44480540993861,
  44.44432575861387,
  45.443866558410704,
  46.44342652856116,
  47.44300449306353,
  48.44259937018392,
];
