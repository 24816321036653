<template lang="">
  <div
    :id="id"
    class="modal"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
          >
            &times;
          </button>
          <h4 class="modal-title">
            Littrow angle
          </h4>
        </div>
        <div class="modal-body">
          <p>
            If angle of incidence <math-jax latex="\vartheta_0" /> is equal to the Littrow angle
            <math-jax latex="\vartheta_\mathrm{L}" />, <math-jax latex="m=-1" /> reflection angle <math-jax latex="\vartheta_{-1}" /> is equal to <math-jax latex="\vartheta_0" />:
            <math-jax
              latex="\vartheta_\mathrm{L}=\arcsin\left(\frac{\lambda}{2d}\right)."
              :block="true"
            />
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-default"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
  export default {
    props: {
      id: null,
    },
  };
  </script>
  