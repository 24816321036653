<template lang="">
  <div
    id="comparematerials"
    class="row"
    ng-controller="materialslistCtrl"
  >
    <!-- TITLE -->
    <div class="col-lg-12 col-md-12 col-xs-12 settings">
      <h3>Comparison of materials</h3>
    </div>
    <!-- OUTPUT -->
    <div class="col-lg-6 col-md-6 col-xs-12 settings">
      <div class="row">
        <div id="comparematerials_n_plot" />
        <div id="comparematerials_gvd_plot" />
        <div id="comparematerials_tod_plot" />
      </div>
    </div>
    <!-- INPUT -->
    <div class="col-lg-6 col-md-6 col-xs-12">
      <h5>Wavelength range: {{ wlrangeMin }} - {{ wlrangeMax }}  nm</h5>
      <div class="padding-top-sm">
        <Slider
          v-model="slider.value"
          v-bind="slider"
          @update="onSliderChange()"
        />
      </div>

      <div class="row padding-top-sm">
        <div class="col-lg-10 col-md-10 col-xs-10 no-right-padding">
          <h5>Material</h5>
        </div>

        <div class="col-lg-2 col-md-2 col-xs-2 no-left-padding">
          <button
            type="button"
            class="btn btn-info btn-add-remove"
            style="width: 100%"
            href="#"
            @click="addMaterial($store.state.defaultMaterial.value)"
          >
            <span class="glyphicon glyphicon-plus" />
          </button>
        </div>
      </div>

      <MaterialSelect
        v-for="(material, index) in materialList"
        :key="material.id"
        :n="index"
        :default-material-name="material.material.alias[0]"
        @delete="onDelete(index)"
        @material="onSelect"
      />

      <div
        class="btn-group"
        style="width: 100%"
        data-toggle="btns"
      >
        <a
          :class="`${extraordinary ? 'btn btn-default' : 'btn btn-default active'}`"
          data-toggle="tab"
          style="width: 50%"
          @click="
            extraordinary = !extraordinary;
            drawPlots();
          "
        >ordinary</a>
        <a
          :class="`${extraordinary ? 'btn btn-default active' : 'btn btn-default'}`"
          data-toggle="tab"
          style="width: 50%"
          @click="
            extraordinary = !extraordinary;
            drawPlots();
          "
        >extraordinary</a>
      </div>

      <!-- invisible selectpicker -->
      <div style="display: none">
        <!-- WAVELENGTH SELECT -->
        <label ng-bind="wlrangeLabel" />
        <div class="row">
          <div class="col-md-6 col-xs-7">
            <input
              id="wlslider"
              ng-model="wlsliderValue"
              ng-change="Update()"
              type="number"
            >
          </div>
          <div class="col-md-6 col-xs-5">
            <div class="input-group">
              <input
                id="wltext"
                ng-init="Update('plot');"
                ng-model="wlsliderValue"
                ng-change="Update()"
                onClick="this.select();"
                step="1"
                type="number"
                class="form-control indicator"
              >
              <span class="input-group-addon">nm</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Slider from "@vueform/slider";
import MaterialSelect from "@/components/MaterialSelect.vue";
import { RefractiveIndexMaterial, GVDMaterial, TODMaterial } from "@/tools/MaterialDispersion.js";
import { drawPlotD3 } from "@/tools/Plot.js";
import DataService from "@/services/data.service";

export default {
  components: {
    MaterialSelect,
    Slider,
  },
  data() {
    return {
      materialList: [],
      inputValue: 1030,
      slider: {
        value: [500, 1030],
        max: 2000,
        lazy: false,
        showTooltip: "drag",
        step: -1,
      },
      wlrangeMin: null,
      wlrangeMax: null,
      extraordinary: false,
      error: false,
    };
  },
  mounted() {
    this.get5DefaultValuesAsync();
  },
  methods: {
    onSelect(material, n) {
      if (n <= this.materialList.length) {
        this.materialList[n].material = material;
        this.updateRange();
        this.drawPlots();
      }
    },
    onSliderChange() {
      this.drawPlots();
    },
    onDelete(n) {
      this.materialList.splice(n, 1);
      this.updateRange();
      this.drawPlots();
    },
    addMaterial(materialToAdd) {
      if (this.materialList.length > 0) {
        var newMaterialForList = {
          id: this.materialList[this.materialList.length - 1].id + 1,
          material: materialToAdd,
        };
      } else {
        newMaterialForList = { id: 1, material: materialToAdd };
      }
      this.materialList.push(newMaterialForList);
      this.updateRange();
      this.drawPlots();
    },
    updateRange() {
      var wlMin = this.materialList[0].material.parameters[0].wl_n_range[0];
      var wlMax = this.materialList[0].material.parameters[0].wl_n_range[1];

      for (var i = 0; i < this.materialList.length; i += 1) {
        if (wlMin > this.materialList[i].material.parameters[0].wl_n_range[0]) {
          wlMin = this.materialList[i].material.parameters[0].wl_n_range[0];
        }

        if (wlMax < this.materialList[i].material.parameters[0].wl_n_range[1]) {
          wlMax = this.materialList[i].material.parameters[0].wl_n_range[1];
        }
      }

      wlMin *= 1.0e3;
      wlMax *= 1.0e3;

      if (wlMin < 20) wlMin = 20;
      if (wlMax > 5000) wlMax = 5000;

      this.wlrangeMin = wlMin;
      this.wlrangeMax = wlMax;

      this.slider.min = wlMin;
      this.slider.max = wlMax;

      if (this.slider.value[0] < this.wlrangeMin) this.slider.value[0] = this.wlrangeMin;
      if (this.slider.value[1] > this.wlrangeMax) this.slider.value[1] = this.wlrangeMax;

      this.drawPlots();

      //if wavelength ranges do not overlap
      if (this.wlrangeMin > this.wlrangeMax) {
        this.no_wl_overlap = true;
      } else {
        this.no_wl_overlap = false;
      }
    },
    async getMaterial(name){
      var materialForReturn;
      await DataService.getMaterialData(name)
          .then((response) => {
            materialForReturn = response.data.value;
          });
      return materialForReturn;
    },
    async get5DefaultValuesAsync(){
     this.addMaterial(await this.getMaterial("ZnSe"));
     this.addMaterial(await this.getMaterial("CaF2"));
     var material3 = await this.getMaterial("fused_silica");
     material3.alias[0]="fused_silica";
     this.addMaterial(material3);
     this.addMaterial(await this.getMaterial("Al2O3"));
     this.addMaterial(await this.getMaterial("BK7"));
    },
    drawPlots() {
      var ray = 0;
      if (this.extraordinary) {
        var sumOfExtraordinaryPlots = 0;
        for (var k = 0; k < this.materialList.length; k += 1) {
          if (this.materialList[k].material.NumberOfAxis !== 0) sumOfExtraordinaryPlots++;
        }

        if (sumOfExtraordinaryPlots === 0) {
          this.extraordinary = false;
          ray = 0;
          this.error = true;
        } else {
          ray = 1;
          this.error = false;
        }
      }
      var x = [];
      var y_n = [];
      var y_gvd = [];
      var y_tod = [];

      for (var j = this.slider.value[0]; j <= this.slider.value[1]; j += 10) {
        x.push(j);
      }

      var funcs_n = [];
      var funcs_gvd = [];
      var funcs_tod = [];

      var fun_n = function (_material, ray) {
        return function (wl) {
          return RefractiveIndexMaterial(wl / 1000.0, _material, ray);
        };
      };
      var fun_gvd = function (_material, ray) {
        return function (wl) {
          return GVDMaterial(wl / 1000.0, _material, ray) * 1.0e27; /* in [fs^2/mm] */
        };
      };
      var fun_tod = function (_material, ray) {
        return function (wl) {
          return TODMaterial(wl / 1000.0, _material, ray) * 1.0e42; /* in [fs^3/mm] */
        };
      };

      // creation of list of functions
      for (var i = 0; i < this.materialList.length; i += 1) {
        if (typeof this.materialList[i].material !== "undefined") {
          if ((this.materialList[i].material.NumberOfAxis !== 0 && ray === 1) || ray === 0) {
            funcs_n.push(null);
            funcs_n[i] = fun_n(this.materialList[i].material, ray);

            funcs_gvd.push(null);
            funcs_gvd[i] = fun_gvd(this.materialList[i].material, ray);

            funcs_tod.push(null);
            funcs_tod[i] = fun_tod(this.materialList[i].material, ray);

            y_n.push({ title: this.materialList[i].material.name, fun: funcs_n[i] });

            y_gvd.push({ title: this.materialList[i].material.name, fun: funcs_gvd[i] });

            y_tod.push({ title: this.materialList[i].material.name, fun: funcs_tod[i] });
          }
        }
      }

      setTimeout(function () {
        drawPlotD3(
          x,
          y_n,
          "comparematerials_n_plot",
          { title: "Wavelength", units: "nm" },
          { title: "Refractive index" }
        );
        drawPlotD3(
          x,
          y_gvd,
          "comparematerials_gvd_plot",
          { title: "Wavelength", units: "nm" },
          { title: "GVD (fs\u00B2/mm)" }
        );
        drawPlotD3(
          x,
          y_tod,
          "comparematerials_tod_plot",
          { title: "Wavelength", units: "nm" },
          { title: "TOD (fs\u00B3/mm)" }
        );
      }, 1);
    },
  },
};
</script>
<style lang="css">
.padding-top-sm {
  padding-top: 1em;
}
</style>
