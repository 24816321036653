const c = 299792458;
const pi = 3.14159265359;

//UNIT CONVERTER
//converts frequency in fs-1 to anything
export function uc_omega_to(omega, units) {
  if (!omega) {
    return;
  }

  switch (units) {
    case "fs-1":
      return omega;
    case "nm":
      return (2.0 * pi * c) / 1.0e6 / omega;
    case "cm-1":
      return (1.0e13 / (2.0 * pi * c)) * omega;
    case "um":
      return uc_omega_to(omega, "nm") / 1.0e3;
  }
  return;
}

//UNIT CONVERTER
//converts anything to frequency in fs-1
export function uc_to_omega(value, units) {
  if (!value) {
    return;
  }

  switch (units) {
    case "fs-1":
      return value;
    case "nm":
      return (2.0 * pi * c) / 1.0e6 / value;
    case "cm-1":
      return ((2.0 * pi * c) / 1.0e13) * value;
    case "um":
      return uc_to_omega(value * 1.0e3, units);
  }
  return;
}
