<template>
  <div class="row col-lg-5 col-md-6 col-xs-12">
    <p>
      Conversion of peak bandwidth between different units and notations:
      <abbr title="Full width at half maximum">FWHM</abbr>, standard deviation
      <math-jax latex="\sigma" />, diameter at <math-jax latex="1/\mathrm{e}^2" /> and
      <math-jax latex="1/\mathrm{e}" />
      level.
    </p>
    <img
      class="img-center"
      src="@/assets/static/media/svg/bandwidthconverter.svg"
    >
  </div>
</template>

 