<template lang="">
  <div
    v-if="$store.state.dataReady"
    class="row"
  >
    <!-- TITLE -->
    <div class="col-lg-12 col-md-12 col-xs-12 settings">
      <h3>
        B-integral
        <small><span
          data-toggle="collapse"
          data-target="#info_Bintegral"
          class="glyphicon glyphicon-info-sign info-glyph"
        /></small>
      </h3>
    </div>
    <!-- OUTPUT -->
    <div class="col-lg-6 col-md-6 col-xs-12">
      <div class="row">
        <div
          id="info_Bintegral"
          class="col-lg-12 col-md-12 col-xs-12 collapse"
        >
          <p>
            Value of B-integral for material of given thickness <math-jax latex="z" />, wavelength of radiation and
            position-independent (maximal) intensity <math-jax latex="I_\mathrm{max}" />:
            <math-jax
              latex="B=\frac{2\pi}{\lambda}\intop_0^{z}n_2I(z)\mathrm{d}z'=\frac{2\pi n_2 I_\mathrm{max} z}{\lambda}."
              :block="true"
            />
            Reported values of nonlinear refractive index <math-jax latex="n_2" /> at the corresponding wavelenghts
            given below. For wavelenghts in the given range, value of <math-jax latex="n_2" /> is interpolated. For
            wavelenghts outside the region, <math-jax latex="n_2" /> at the closest wavelength is used.
          </p>
        </div>

        <div class="col-xs-6">
          <h5><math-jax latex="B_\mathrm{o}" /></h5>
          <div class="input-group">
            <input
              id="wltext"
              v-model="Bintegral_o"
              type="number"
              class="form-control"
              readonly
            >
          </div>
        </div>
        <div class="col-xs-6">
          <h5><math-jax latex="B_\mathrm{e}" /></h5>
          <div class="input-group">
            <input
              v-model="Bintegral_e"
              type="number"
              class="form-control"
              readonly
            >
          </div>
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-xs-12">
          <div class="form-group">
            <textarea
              v-model="textarea_text"
              class="form-control"
              rows="8"
              readonly
            />
          </div>
        </div>
      </div>
    </div>
    <!-- INPUT -->
    <div class="col-lg-6 col-md-6 col-xs-12 settings">
      <!-- MATERIAL SELECT -->
      <Selector
        :list="materials"
        :list-name="'Materials'"
        :default-selection="'fused_silica'"
        @selectedMaterial="onSelection"
      />

      <div class="row bottom_align_row">
        <div class="col-xs-4">
          <h5>Wavelength <small>[nm]</small></h5>
          <input
            id="wltext"
            v-model="wlsliderValue"
            step="1"
            type="number"
            class="form-control indicator"
          >
        </div>
        <div class="col-xs-4">
          <h5>Intensity <math-jax latex="I_\mathrm{max}" /><small>[GW/cm&sup2;]</small></h5>
          <input
            v-model="intensity"
            type="number"
            min="0"
            class="form-control"
          >
        </div>
        <div class="col-xs-4">
          <h5>Distance <math-jax latex="z" /> <small>[mm]</small></h5>
          <input
            v-model="distance"
            type="number"
            min="0"
            class="form-control"
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataService from "@/services/data.service";
import Selector from "@/components/Selector.vue";
import { NLRefractiveIndex } from "@/tools/MaterialDispersion.js";

export default {
  components: {
    Selector,
  },
  data() {
    return {
      material: null,
      intensity: 17,
      distance: 33,
      wlsliderValue: 1030,
    };
  },
  computed: {
    materials(){
      return this.$store.getters.n2materials;
    },
    Bintegral_e() {
      if (this.material === null) return "";
      if (this.material.NumberOfAxis == 0) return "";
      return Math.round10(
        ((2 *
          Math.PI *
          NLRefractiveIndex(this.wlsliderValue / 1000.0, 1, this.material) *
          this.intensity *
          this.distance) /
          this.wlsliderValue) *
          1.0e15,
        -3
      );
    },
    Bintegral_o() {
      if (this.material === null) return "";
      return Math.round10(
        ((2 *
          Math.PI *
          NLRefractiveIndex(this.wlsliderValue / 1000.0, 0, this.material) *
          this.intensity *
          this.distance) /
          this.wlsliderValue) *
          1.0e15,
        -3
      );
    },
    textarea_text() {
      if (this.material === null) return "";
      var text;
      var material = this.material;
      if (material.NumberOfAxis >= 1) {
        text = "Wavelength [nm]\tn_2(o) [cm^2/W]\tn_2(e) [cm^2/W]\n";

        for (var i in material.n2_o) {
          text += material.n2_wl[i] * 1000.0 + "\t" + material.n2_o[i] + "\t" + material.n2_e[i] + "\n";
        }
        text += "---\nSource:\n" + material.n2_source;
      } else {
        text = "Wavelength [nm]\tn_2 [cm^2/W]\n";

        for (i in material.n2_o) {
          text += material.n2_wl[i] * 1000.0 + "\t" + material.n2_o[i] + "\n";
        }
        text += "---\nSource:\n" + material.n2_source;
      }
      return text;
    },
  },
  methods: {
    onSelection(material) {
      DataService.getMaterialData(material).then((response) => {
        this.material = response.data.value;
      });
    },
  },
};
</script>
<style lang=""></style>
