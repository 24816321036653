<template lang="">
  <div
    :id="id"
    class="modal"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
          >
            &times;
          </button>
          <h4 class="modal-title">
            Beam parameter product
          </h4>
        </div>
        <div class="modal-body">
          <p>
            Beam parameter product (BPP) is product of divergence half-angle <math-jax latex=" \vartheta/2 " /> 
            and radius at waist <math-jax latex="w_0" />,
            <math-jax
              latex="\mathrm{BPP} = M^2 \frac{\lambda}{\pi},"
              :block="true"
            />
            usually measured in mm<math-jax latex="\cdot" />mrad.
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-default"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: null,
  },
};
</script>
