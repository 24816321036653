<template>
  <div
    :id="id"
    class="modal"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
          >
            &times;
          </button>
          <h4 class="modal-title">
            Conversion from angular frequency
          </h4>
        </div>
        <div class="modal-body">
          Wavelength 
          <math-jax
            latex="\lambda = \frac{2\pi c}{\omega} \Longrightarrow \lambda[\mathrm{nm}] \approx \frac{1883.652}{\omega[\mathrm{fs^{-1}}]} "
            :block="true"
          />
          Wavenumber
          <math-jax
            latex=" k = \frac{\omega}{2\pi c} \Longrightarrow k[\mathrm{cm^{-1}}] \approx 5308.837 \cdot \omega[\mathrm{fs^{-1}}]"
            :block="true"
          />
          Optical period
          <math-jax
            latex="T = \frac{2\pi}{\omega} \Longrightarrow T[\mathrm{fs}] \approx \frac{6.283}{\omega[\mathrm{fs^{-1}}]}"
            :block="true"
          />
          Energy
          <math-jax
            latex="E = \hbar\omega \Longrightarrow E[\mathrm{eV}] \approx \frac{\omega[\mathrm{fs^{-1}}]}{1.519}"
            :block="true"
          />
          Frequency
          <math-jax
            latex="f = \frac{\omega}{2\pi} \Longrightarrow f[\mathrm{THz}] \approx 159.160 \cdot \omega[\mathrm{fs^{-1}}]"
            :block="true"
          />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-default"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: null,
  },
};
</script>
