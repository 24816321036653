<template lang="">
  <div
    id="gratingpair"
    ng-controller="diffractionCtrl"
    class="row"
  >
    <!-- TITLE -->
    <div class="col-lg-12 col-md-12 col-xs-12 settings">
      <h3>
        Grating pair compressor
        <small><span
          data-toggle="collapse"
          data-target="#info_gratingpair"
          class="glyphicon glyphicon-info-sign info-glyph"
        /></small>
      </h3>
    </div>
    <!-- INFO/PLOT -->
    <div class="col-lg-6 col-md-6 col-xs-12 settings">
      <div
        id="info_gratingpair"
        class="collapse"
      >
        <p>
          Dispersion parameters of grating pair compressor/stretcher for given distance between gratings <math-jax latex="L" />, angle of
          incidence <math-jax latex="\vartheta_0" /> and grooves density <math-jax latex="d^{-1}" />. Formulas for the double-pass GDD and TOD are,
          respectively:
          <math-jax
            latex="\mathrm{GDD}(\lambda)=-\frac{\lambda^{3}L}{\pi
          c^{2}d^{2}}\left[1-\left(\frac{\lambda}{d}-\sin\vartheta_0\right)^{2}\right]^{-\frac{3}{2}},"
            :block="true"
          />
          <math-jax
            latex="\mathrm{TOD}(\lambda)=-\frac{3\lambda}{2\pi
          c}\frac{\partial^{2}\phi_{g}}{\partial\omega^{2}}\frac{1+\frac{\lambda}{d}\sin\vartheta_0-\sin^{2}\vartheta_0}{1-\left(\frac{\lambda}{d}-\sin\vartheta_0\right)^{2}}."
            :block="true"
          />
        </p>
        <img
          class="img-center"
          src="@/assets/static/media/svg/gratingpair.svg"
        >
      </div>

      <div id="gratingpair_GDD_plot" />
    </div>
    <!-- INPUT/OUTPUT -->
    <div class="col-lg-6 col-md-6 col-xs-12 settings">
      <div class="row">
        <div class="col-xs-12">
          <h5>Wavelength range</h5>
          <Slider
            v-model="slider.value"
            v-bind="slider"
            @update="update"
          />
        </div>
      </div>

      <div class="row">
        <!-- DISTANCE SELECT -->
        <div class="col-lg-4 col-xs-4 no-right-padding">
          <h5 for="L_text">
            Distance <math-jax latex="L" />
          </h5>
          <div class="input-group">
            <input
              id="L_text"
              v-model="L"
              min="0"
              step="1"
              type="number"
              class="form-control indicator"
              tabindex="1"
            >
            <span class="input-group-addon">mm</span>
          </div>
        </div>
        <!-- AOI SELECT -->
        <div class="col-lg-4 col-xs-4 no-right-padding">
          <h5>AOI <math-jax latex="\vartheta_0" /></h5>
          <div class="input-group">
            <input
              id="theta0_text"
              v-model="theta0"
              min="0"
              max="90"
              step="1"
              type="number"
              class="form-control indicator"
              tabindex="2"
            >
            <span class="input-group-addon">deg</span>
          </div>
        </div>
        <!-- GRATING SELECT -->
        <div class="col-lg-4 col-xs-4">
          <h5>Grooves <math-jax latex="d^{ - 1 }" /></h5>
          <div class="input-group">
            <input
              id="gpmm_text"
              v-model="gpmm"
              min="300"
              max="9999"
              step="1"
              type="number"
              class="form-control indicator"
              tabindex="3"
            >
            <span class="input-group-addon">/mm</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Slider from "@vueform/slider";
import { drawPlotD3 } from "@/tools/Plot.js";
import { grating_pair_GDD, deg_to_rad, grating_pair_TOD } from "@/tools/Utils.js";

export default {
  components: {
    Slider,
  },
  data() {
    return {
      theta0: 25,
      gpmm: 800,
      L: 10,
      slider: {
        value: [400, 1500],
        min: 100,
        max: 1773,
        lazy: false,
        showTooltip: "drag",
        step: -1,
      },
    };
  },
  watch: {
    theta0() {
      this.update();
    },
    gpmm() {
      this.update();
    },
    L() {
      this.update();
    },
  },
  mounted(){
    this.update();
  },
  methods: {
    update() {
      var L = this.L;
      var theta0 = this.theta0;
      var gpmm = this.gpmm;

      var fun_GDD = function (wl) {
        return grating_pair_GDD(wl * 1.0e-9, L * 1.0e-3, deg_to_rad(theta0), gpmm * 1.0e3);
      };

      var fun_TOD = function (wl) {
        return grating_pair_TOD(wl * 1.0e-9, L * 1.0e-3, deg_to_rad(theta0), gpmm * 1.0e3);
      };

      var y = [
        { title: "GDD", val: [] },
        { title: "TOD", val: [] },
      ];

      var x = [];
      var j;
      var wl_min = this.slider.value[0];
      var wl_max = this.slider.value[1];
      var wl_limit = Math.floor(((1 + Math.sin(deg_to_rad(theta0))) / gpmm) * 1.0e6) - 5.0;
      if (isNaN(wl_limit)) {
        return;
      }

      this.slider.max = wl_limit;

      if (wl_max > wl_limit) {
        wl_max = wl_limit;
      }

      this.slider.value = [wl_min, wl_max];

      for (j = wl_min; j <= wl_max; j += 5.0) {
        x.push(j);
        y[0].val.push(fun_GDD(j));
        y[1].val.push(fun_TOD(j));
      }
      var x_axis = { title: "Wavelength", units: "nm" };
      var y_axis = { title: "GDD (fs\u00B2), TOD (fs\u00B3)" };
      var target = "gratingpair_GDD_plot";
      var margin = { top: 15, right: 15, bottom: 50, left: 80 };

      drawPlotD3(x, y, target, x_axis, y_axis, {
        title: parseFloat(theta0).toFixed(0) + " deg, " + parseFloat(gpmm).toFixed(0) + " grooves/mm",
        explorer: { axis: "horizontal" },
      }, margin);
    },
  },
};
</script>
<style lang=""></style>
