<template lang="">
  <div
    :id="id"
    class="modal"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
          >
            &times;
          </button>
          <h4 class="modal-title">
            Deviation angle
          </h4>
        </div>
        <div class="modal-body">
          <p>
            <math-jax
              latex=" \delta = \vartheta_0 + \arcsin \left[ n \sin \left( \alpha - \arcsin \frac{\vartheta_0}{n} \right)
            \right] - \alpha"
              :block="true"
            />
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-default"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: null,
  },
};
</script>
