<template lang="">
  <div
    id="fresnelreflection"
    class="row"
    ng-controller="materialsdbCtrl"
  >
    <!-- TITLE -->
    <div class="col-xs-12">
      <h3>
        Fresnel reflection
        <small><span
          data-toggle="collapse"
          data-target="#info_fresnelreflection"
          class="glyphicon glyphicon-info-sign info-glyph"
        /></small>
      </h3>
    </div>
    <!-- INPUT/OUTPUT -->
    <div class="col-lg-5 col-md-6 col-xs-12">
      <div
        id="info_fresnelreflection"
        class="collapse"
      >
        <p>Reflection from a surface. For anisotropic crystals, all beams are treated as ordinary.</p>
        <img
          class="img-center"
          src="@/assets/static/media/svg/fresnelreflection.svg"
        >
      </div>

      <div class="row bottom_align_row">
        <div class="col-xs-6">
          <h5>
            Reflectance (s-polarization):
            <span
              class="glyphicon glyphicon-info-sign info-glyph"
              data-toggle="modal"
              data-target="#description_Rs"
            />
          </h5>
          <div class="input-group">
            <input
              id="R_stext"
              v-model="R_s"
              type="number"
              class="form-control"
              readonly
            >
            <span class="input-group-addon">%</span>
          </div>
        </div>

        <div class="col-xs-6">
          <h5>
            Reflectance (p-polarization):
            <span
              class="glyphicon glyphicon-info-sign info-glyph"
              data-toggle="modal"
              data-target="#description_Rp"
            />
          </h5>
          <div class="input-group">
            <input
              id="R_ptext"
              v-model="R_p"
              type="number"
              class="form-control"
              readonly
            >
            <span class="input-group-addon">%</span>
          </div>
        </div>
        <div class="col-xs-6">
          <h5>
            Brewster's angle:
            <span
              class="glyphicon glyphicon-info-sign info-glyph"
              data-toggle="modal"
              data-target="#description_thetabr"
            />
          </h5>
          <div class="input-group">
            <input
              id="theta_brtext"
              v-model="theta_br"
              type="number"
              class="form-control"
              readonly
            >
            <span class="input-group-addon">deg</span>
          </div>
        </div>
        <div class="col-xs-6">
          <h5>Angle of incidence <math-jax latex="\vartheta_0" />:</h5>
          <div class="input-group">
            <input
              id="thetaitext"
              v-model="thetai"
              step="1.0"
              min="0.0"
              max="90.0"
              type="number"
              class="form-control indicator"
            >
            <span class="input-group-addon">deg</span>
          </div>
        </div>
      </div>
      <br>
      
      <!-- MATERIAL SELECT -->
      <div v-if="$store.state.dataReady">
        <Selector
          :list="materials"
          :list-name="'Materials'"
          :default-selection="'BeO'"
          @selectedMaterial="onSelection"
        />
      </div>

      <!-- WAVELENGTH INPUT -->
      <h5>
        Wavelength range: <span>{{ wlrangeMin }}</span>-<span>{{ wlrangeMax }}</span> nm
      </h5>
      <div class="row">
        <div class="col-md-8 col-xs-6 top-padding">
          <Slider
            v-model="slider.value"
            v-bind="slider"
            @update="onSliderChange()"
          />
        </div>
        <div class="col-md-4 col-xs-6">
          <div class="input-group">
            <input
              id="wltext"
              v-model="inputValue"
              step="1"
              type="number"
              class="form-control indicator"
              tabindex="1"
              @change="onInputChange()"
            >
            <span class="input-group-addon">nm</span>
          </div>
        </div>
      </div>
    </div>
    <!-- PLOT -->
    <div class="col-lg-7 col-md-6 col-xs-12">
      <div id="fresnelreflection_r_plot" />
    </div>
  </div>

  <ReflectanceSDescriptionModal id="description_Rs" />
  <ReflectancePDescriptionModal id="description_Rp" />
  <BrewstersAngleDescriptionModal id="description_thetabr" />
</template>

<script>
import Slider from "@vueform/slider";
import DataService from "@/services/data.service";
import Selector from "@/components/Selector.vue";
import { fresnel_reflection, deg_to_rad, rad_to_deg } from "@/tools/Utils.js";
import { RefractiveIndex } from "@/tools/MaterialDispersion.js";
import { drawPlotD3 } from "@/tools/Plot.js";
import BrewstersAngleDescriptionModal from "@/components/descriptions/geometricalOptics/BrewstersAngle.vue";
import ReflectancePDescriptionModal from "@/components/descriptions/geometricalOptics/ReflectanceP.vue";
import ReflectanceSDescriptionModal from "@/components/descriptions/geometricalOptics/ReflectanceS.vue";

export default {
  components: {
    Slider,
    Selector,
    BrewstersAngleDescriptionModal,
    ReflectancePDescriptionModal,
    ReflectanceSDescriptionModal,
  },
  data() {
    return {
      material: null,
      inputValue: 1030,
      slider: {
        value: 1030,
        max: 2000,
        lazy: false,
        showTooltip: "drag",
        step: -1,
      },
      wlrangeMin: null,
      wlrangeMax: null,
      thetai: 60,
    };
  },
  computed: {
    materials() {
      return this.$store.getters.materials;
    },
    ri() {
      if (this.material !== null) return RefractiveIndex(this.inputValue / 1.0e3, 0, this.material);
      return null;
    },
    R_s() {
      var result = fresnel_reflection(this.ri, deg_to_rad(this.thetai), 0);
      return (result[0] * 100).toFixed(3);
    },
    R_p() {
      var result = fresnel_reflection(this.ri, deg_to_rad(this.thetai), 0);
      return (result[1] * 100).toFixed(3);
    },
    theta_br() {
      return rad_to_deg(Math.atan(this.ri)).toFixed(3);
    },
  },
  watch: {
    material(newValue) {
      this.wlrangeMin = parseFloat(newValue.parameters[0].wl_n_range[0]) * 1.0e3;
      this.slider.min = this.wlrangeMin;
      this.wlrangeMax = parseFloat(newValue.parameters[0].wl_n_range[1]) * 1.0e3;
      this.slider.max = this.wlrangeMax;
      if (this.slider.value > this.wlrangeMax) this.inputValue = this.wlrangeMax;
      if (this.slider.value < this.wlrangeMin) this.inputValue = this.wlrangeMin;
      this.drawPlot();
    },
    inputValue() {
      this.drawPlot();
    },
  },

  methods: {
    onInputChange() {
      this.slider.value = this.inputValue;
    },
    onSliderChange() {
      this.inputValue = this.slider.value;
    },
    onSelection(material) {
      DataService.getMaterialData(material).then((response) => {
        this.material = response.data.value;
      });
    },
    drawPlot() {
      //plotting reflectance dependence on AOI
      var x = [];
      var y_s_val = [];
      var y_p_val = [];
      var y_s_tooltip = [];
      var y_p_tooltip = [];
      var xval;
      var yval;

      var x_min = 0.0;
      var x_max = 90.0;
      var x_step = 1.0;

      var ri = RefractiveIndex(this.inputValue / 1.0e3, 0, this.material);

      var fun = function (n) {
        return function (thetai) {
          return fresnel_reflection(ri, deg_to_rad(thetai), 0)[n] * 100.0;
        };
      };

      var fun_s = fun(0);
      var fun_p = fun(1);

      var tooltipfun = function (x, y) {
        return x.toFixed(1) + " deg, " + y.toFixed(2) + " %";
      };

      for (xval = x_min; xval <= x_max; xval += x_step) {
        x.push(xval);
        yval = fun_s(xval);

        y_s_val.push(yval);
        y_s_tooltip.push(tooltipfun(xval, yval));

        yval = fun_p(xval);

        y_p_val.push(yval);
        y_p_tooltip.push(tooltipfun(xval, yval));
      }

      var y = [
        { title: "s-polarization", val: y_s_val, tooltip: y_s_tooltip },
        { title: "p-polarization", val: y_p_val, tooltip: y_p_tooltip },
      ];

      var x_axis = { title: "Angle of incidence", units: "deg" };
      var y_axis = { title: "Reflectance, %" };
      var target = "fresnelreflection_r_plot";
      var options = {
        vAxis: { viewWindow: { min: 0, max: 100 }, baselineColor: "#CCC" },
        hAxis: { baselineColor: "#CCC" },
      };

      drawPlotD3(x, y, target, x_axis, y_axis, options);
    },
  },
};
</script>
<style lang=""></style>
