<template lang="">
  <div
    :id="id"
    class="modal"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
          >
            &times;
          </button>
          <h4 class="modal-title">
            Phase matching angle <small>o-e-o interaction</small>
          </h4>
        </div>
        <div class="modal-body">
          <p>
            Phase matching condition:
            <math-jax
              latex="\frac{n_\mathrm{o}(\lambda_3)}{\lambda_3} = \left(
            \frac{n_\mathrm{e}(\vartheta,\lambda_1)}{\lambda_1} + \frac{n_\mathrm{o}(\lambda_2)}{\lambda_2}
            \right)\cos\vartheta_0."
              :block="true"
            />
            Here <math-jax latex="\vartheta_0" /> is the angle of incidence. Phase matching angle:
            <math-jax
              latex="\vartheta
            =\arcsin\sqrt{\frac{\frac{\lambda_{1}^{2}\cos^2\vartheta_0}{\left(n_\mathrm{o}(\lambda_3)\lambda_3-n_\mathrm{o}(\lambda_{1})
            \lambda_2\cos\vartheta_0\right)^{2}\cos^{2}\vartheta_{0}}-\frac{1}{n^2_\mathrm{o}(\lambda_{2})}}{\frac{1}{n_\mathrm{e}^{2}(\lambda_2})}-\frac{1}{n_\mathrm{o}^{2}(\lambda_{2})}}}"
              :block="true"
            />
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-default"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: null,
  },
};
</script>