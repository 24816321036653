<template>
  <div
    class="base-image-input"
    :style="{ 'background-image': `url(${imageData})` }"
    @click="chooseImage"
  >
    <span
      v-if="!imageData"
      class="placeholder"
    > Choose an Image </span>
    <input
      ref="fileInput"
      class="file-input"
      type="file"
      :accept="accept"
      @input="onSelectFile"
    >
  </div>
</template>
<script>
export default {
  props: {
    accept: {
      default() {
        return "";
      },
    },
  },
  emits: ["input"],
  data() {
    return {
      imageData: "",
    };
  },
  methods: {
    chooseImage() {
      this.$refs.fileInput.click();
    },
    onSelectFile() {
      const input = this.$refs.fileInput;
      const files = input.files;
      if (files && files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageData = e.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.$emit("input", files[0]);
      }
    },
    reset() {
      this.imageData = "";
    },
  },
};
</script>
<style scoped>
.base-image-input {
  display: block;
  width: 100%;
  height: 200px;
  cursor: pointer;
  background-size: cover;
  object-fit: contain;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}
.placeholder:hover {
  background: #e0e0e0;
}
.file-input {
  display: none;
}
</style>
