<template>
  <div
    :id="id"
    class="modal"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
          >
            &times;
          </button>
          <h4 class="modal-title">
            Bandwidth in wavenumber
          </h4>
        </div>
        <div class="modal-body">
          <p>
            Exact and approximate relations between the bandwidth in wavenumber and wavelength units is given by:
            <math-jax
              latex="\Delta k = \frac{\Delta\lambda}{\lambda_0^2 - \frac{\Delta\lambda^2}{4}} \approx
            \frac{\Delta\lambda}{\lambda_0^2}."
              :block="true"
            />
            If bandwidth <math-jax latex="\Delta \lambda" /> is given in nanometers, bandwidth in inverse centimeters is
            approximately
            <math-jax
              latex="\Delta k\mathrm{[cm^{-1}]} \approx 10^7 \cdot
            \frac{\Delta\lambda\mathrm{[nm]}}{(\lambda_0\mathrm{[nm]})^2}."
              :block="true"
            />
          </p>
          <p>
            Peak width relations:
            <math-jax
              latex="\mathrm{FWHM} = 2\sqrt{2\ln2}\sigma,$$ $$D_{1/\mathrm{e}^2} = 4\sigma =
            \sqrt{\frac{2}{\ln2}}\mathrm{FWHM},$$ $$D_{1/\mathrm{e}} = 2\sqrt{2}\sigma = \frac{\mathrm{FWHM}}{\sqrt{\ln
            2}}."
              :block="true"
            />
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-default"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    id: null,
  }
};
</script>
