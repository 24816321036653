<template lang="">
  <div
    class="panel panel-default"
    style="margin-bottom: 5px"
  >
    <div
      id="headingOne"
      class="panel-heading"
      role="tab"
    >
      <h4 class="panel-title">
        <a
          data-toggle="collapse"
          :data-target="titleid"
          aria-expanded="false"
          aria-controls="collapseOne"
        >
          <span :class="icon">&nbsp;</span>{{ Title }} ray
        </a>
      </h4>
    </div>
    <div
      :id="Title"
      class="panel-collapse collapse in"
      role="tabpanel"
      aria-labelledby="headingOne"
      aria-expanded="false"
    >
      <div class="row">
        <div class="col-xs-6">
          <h5>
            <abbr title="Group velocity dispersion">GVD</abbr>:
            <span
              class="glyphicon glyphicon-info-sign info-glyph"
              data-toggle="modal"
              data-target="#description_material_GVD"
            />
          </h5>
          <div class="input-group">
            <input
              id="GVDtext"
              v-model="GVD"
              onClick="this.select();"
              type="text"
              class="form-control"
              readonly
            >
            <span class="input-group-addon">fs&sup2;/mm</span>
          </div>
        </div>

        <div class="col-xs-6">
          <h5>
            <abbr title="Third-order dispersion">TOD</abbr>:
            <span
              class="glyphicon glyphicon-info-sign info-glyph"
              data-toggle="modal"
              data-target="#description_material_TOD"
            />
          </h5>
          <div class="input-group">
            <input
              id="TODtext"
              v-model="TOD"
              onClick="this.select();"
              type="text"
              class="form-control"
              readonly
            >
            <span class="input-group-addon">fs&sup3;/mm</span>
          </div>
        </div>
      </div>

      <div class="row bottom_align_row">
        <div class="col-xs-4">
          <h5>GVD=0 at:</h5>
        </div>

        <div class="col-xs-4">
          <h5>Refractive index:</h5>
        </div>

        <div class="col-xs-4">
          <h5>
            Group refractive index:
            <span
              class="glyphicon glyphicon-info-sign info-glyph"
              data-toggle="modal"
              data-target="#description_GVindex"
            />
          </h5>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-4">
          <div class="input-group">
            <input
              id="gvdzerotext"
              v-model="wl_gvdzero"
              type="text"
              class="form-control"
              readonly
            >
            <span class="input-group-addon">nm</span>
          </div>
        </div>

        <div class="col-xs-4">
          <input
            id=""
            v-model="n"
            type="text"
            class="form-control"
            readonly
          >
        </div>

        <div class="col-xs-4">
          <input
            id="ngtext"
            v-model="ng"
            type="text"
            class="form-control"
            readonly
          >
        </div>
      </div>
    </div>
  </div>

  <!-- DESCRIPTION: GVD in material  -->
  <div
    id="description_material_GVD"
    class="modal"
    role="dialog"
  >
    <GroupVelocityDispersionDescription />
  </div>

  <!-- DESCRIPTION: TOD in material  -->
  <div
    id="description_material_TOD"
    class="modal"
    role="dialog"
  >
    <ThirdOrderDispersionDescription />
  </div>

  <!-- DESCRIPTION: group refractive index in material -->
  <div
    id="description_GVindex"
    class="modal"
    role="dialog"
  >
    <GroupRefractiveIndexDescription />
  </div>
</template>

<script>
import GroupVelocityDispersionDescription from "@/components/descriptions/dispersion/GroupVelocityDispersion.vue";
import ThirdOrderDispersionDescription from "@/components/descriptions/dispersion/ThirdOrderDispersion.vue";
import GroupRefractiveIndexDescription from "@/components/descriptions/dispersion/GroupRefractiveIndex.vue";
import { GVDMaterial, TODMaterial, RefractiveIndex, GroupVelocity, UpdateGVDZero, c } from "@/tools/MaterialDispersion";

export default {
  name: "RayOutput",
  components: {
    GroupVelocityDispersionDescription,
    ThirdOrderDispersionDescription,
    GroupRefractiveIndexDescription,
  },
  props: {
    Title: String,
    icon: String,
    material: null,
    extraordinayRay: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isMaterialBiaxial: {
      type: Boolean,
      default() {
        return false;
      },
    },
    wl: null,
  },
  data() {
    return {
      titleid: "#" + this.Title,
    };
  },
  computed: {
    GVD() {
      if (this.isMaterialBiaxial) {
        return (GVDMaterial(this.wl / 1.0e3, this.material, 1) * 1.0e27).toFixed(3);
      } else {
        return (GVDMaterial(this.wl / 1.0e3, this.material, 0) * 1.0e27).toFixed(3);
      }
    },
    TOD() {
      var todr;

      if (this.isMaterialBiaxial) {
        todr = TODMaterial(this.wl / 1.0e3, this.material, 1) * 1.0e42;
      }else {
        todr = TODMaterial(this.wl / 1.0e3, this.material, 0) * 1.0e42;
      }
      
      return typeof todr !== "undefined" && !isNaN(todr) ? todr.toFixed(3) : null;
    },
    n() {
      var n;
      
      if (this.isMaterialBiaxial) {
        n = RefractiveIndex(this.wl / 1.0e3, 1, this.material);
      }else {
        n = RefractiveIndex(this.wl / 1.0e3, 0, this.material);
      }

      if (n != null) {
        return n.toFixed(3);
      } else {
        return null;
      }
    },
    GV() {
      if (this.isMaterialBiaxial) {
        return GroupVelocity(this.material, this.wl / 1.0e3, 1);
      } else {
        return GroupVelocity(this.material, this.wl / 1.0e3, 0);
      }
    },
    ng() {
      return (c / this.GV).toFixed(3);
    },
    wl_gvdzero() {
      var test = UpdateGVDZero(this.wl, this.material, this.isMaterialBiaxial);
      return test;
    },
  },
};
</script>
