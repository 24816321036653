<template lang="">
  <!-- DESCRIPTION theta0 -->
  <div
    :id="id"
    class="modal"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
          >
            &times;
          </button>
          <h4 class="modal-title">
            Angle of incidence
          </h4>
        </div>
        <div class="modal-body">
          <p>
            If deviation angle <math-jax latex="\vartheta_\mathrm{d}" />" is given, AOI is obtained from equation
            <math-jax
              latex="\sin^2\vartheta_0\left(1+\cos\vartheta_\mathrm{d}\right)-
          \frac{\lambda}{d}\sin\vartheta_0\left(1+\cos\vartheta_\mathrm{d}\right)+\frac{\lambda^2}{2d^2}-\frac{\sin^2\vartheta_\mathrm{d}}{2}= 0"
              :block="true"
            />
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-default"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: null,
  },
};
</script>