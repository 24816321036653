<template lang="">
  <div
    :id="id"
    class="modal"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
          >
            &times;
          </button>
          <h4 class="modal-title">
            Distance between prism apices
          </h4>
        </div>
        <div class="modal-body">
          <p>
            Vertical/horizontal distances between prism apices (<math-jax latex="h" />/<math-jax latex="w" />) is calculated from given parameters of the
            reference ray (<math-jax latex="\lambda_0" />, <math-jax latex="L" />, <math-jax latex="l_1" /> and <math-jax latex="l_2" />):
            <math-jax
              latex="h = (l_{1}^{\prime}+l_{2})\cos\frac{\alpha}{2}-\frac{L-(l_{1}^{\prime}+l_{2})\sin\alpha}{\sin(\vartheta_1-\alpha)}\sin\left(\vartheta_1-\frac{\alpha}{2}\right),"
              :block="true"
            />
            <math-jax
              latex="w = (l_{1}^{\prime}+l_{2})\sin\frac{\alpha}{2}-\frac{L-(l_{1}^{\prime}+l_{2})\sin\alpha}{\sin(\vartheta_1-\alpha)}\cos\left(\vartheta_1-\frac{\alpha}{2}\right)."
              :block="true"
            />
            Here <math-jax latex="\vartheta_1" /> is angle of reflection,
            <math-jax
              latex="\vartheta_1 = \arcsin\left(n\sin(\alpha-\psi_1)\right),"
              :block="true"
            />
            and <math-jax latex="l_1^\prime" /> is the distance between the ray's exit point and the apex of the first prism,
            <math-jax
              latex="l_1^\prime = l_1\frac{\cos\psi_1}{\cos\psi_2}."
              :block="true"
            />
            <math-jax latex="\psi_1" /> and <math-jax latex="\psi_2" /> are the internal reflection angles of the first prism:
            <math-jax
              latex="\psi_1 = \arcsin\frac{\sin\vartheta_0}{n},$$ $$\psi_2 = \alpha-\psi_1."
              :block="true"
            />
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-default"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: null,
  },
};
</script>
