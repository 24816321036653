import { fabric } from "fabric";
import $ from "jquery";
export var KITCHEN = {};
import store from "@/store/index.js";


KITCHEN.shiftPressed = false;
KITCHEN.ctrlPressed = false;
KITCHEN.copiedObject = null;
KITCHEN.tableDefined = false;
KITCHEN.holeRadius = 0.5;
KITCHEN.terminalMarkWidth = 6;
KITCHEN.terminalMarkFill = "#c0392b";
KITCHEN.terminalsVisible = false;
KITCHEN.lastTarget;
KITCHEN.layoutSettings = {
  notificationSent: false,
};
//const bus = new Vue()
var ft_to_cm = 30.48; // foot to centimeter conversion

var decimalAdjust = function (type, value, exp) {
  // If the exp is undefined or zero...
  if (typeof exp === "undefined" || +exp === 0) {
    return Math[type](value);
  }
  value = +value;
  exp = +exp;
  // If the value is not a number or the exp is not an integer...
  if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) {
    return NaN;
  }
  // Shift
  value = value.toString().split("e");
  value = Math[type](+(value[0] + "e" + (value[1] ? +value[1] - exp : -exp)));
  // Shift back
  value = value.toString().split("e");
  return +(value[0] + "e" + (value[1] ? +value[1] + exp : exp));
};

if (!Math.round10) {
  Math.round10 = function (value, exp) {
    return decimalAdjust("round", value, exp);
  };
}

var isBetween = function (x, a, b) {
  if ((a < x && x < b) || (b < x && x < a)) {
    return true;
  } else {
    return false;
  }
};

var deg_to_rad = function (deg) {
  return (deg / 180.0) * Math.PI;
};

KITCHEN.pngImageHD = "";

KITCHEN.defaultTableParams = {
  X: 2000,
  Y: 1200,
  offsetX: 25,
  offsetY: 25,
  type: "metric",
};

KITCHEN.drawLine = {
  active: false,
  isDown: false,
  lineColor: "#c0392b",
  lineWidth: 4,
  toggle: function () {
    $("#drawline-btn").toggleClass("active");
  },
  activate: function () {
    KITCHEN.drawLine.active = true;
    KITCHEN.canvas.selection = false;
    KITCHEN.canvas.hoverCursor = "default";
  },
  deactivate: function () {
    KITCHEN.drawLine.active = false;
    KITCHEN.drawLine.isDown = false;
    delete KITCHEN.drawLine.line;
    KITCHEN.canvas.selection = true;
    KITCHEN.canvas.hoverCursor = "move";
  },
  mouseDown: function (options) {
    var pointer;
    var points;
    //var width;

    KITCHEN.drawLine.activate();
    KITCHEN.drawLine.isDown = true;
    //KITCHEN.canvas.deactivateAll();

    if (Object.prototype.hasOwnProperty.call(KITCHEN.drawLine, "line")) {
      //not to drag away new segment
      pointer = { x: KITCHEN.drawLine.line.x2, y: KITCHEN.drawLine.line.y2 };
    } else {
      pointer = KITCHEN.canvas.getPointer(options.e);
    }

    points = [pointer.x, pointer.y, pointer.x, pointer.y];

    KITCHEN.drawLine.line = new fabric.Line(points, {
      hasControls: false,
      name: "line",
      strokeWidth: KITCHEN.drawLine.lineWidth,
      fill: KITCHEN.drawLine.lineColor,
      stroke: KITCHEN.drawLine.lineColor,
      originX: "center",
      originY: "top",
      selectable: false,
      targetFindTolerance: true,
    });

    KITCHEN.drawLine.line.setControlsVisibility({
      tr: false,
      bl: false,
      ml: false,
      mt: false,
      mr: false,
      mb: false,
      mtr: false,
      tl: false,
      br: false,
    });

    KITCHEN.canvas.add(KITCHEN.drawLine.line);

    KITCHEN.canvasHistory.saveState("addLine");
  },
  mouseMove: function (options) {
    var pointer;

    if (!KITCHEN.drawLine.isDown) {
      return;
    }

    pointer = KITCHEN.canvas.getPointer(options.e);

    var x2 = pointer.x;
    var y2 = pointer.y;

    if (KITCHEN.shiftPressed) {
      if (Math.abs(KITCHEN.drawLine.line.x1 - x2) < Math.abs(KITCHEN.drawLine.line.y1 - y2)) {
        x2 = KITCHEN.drawLine.line.x1;
      } else {
        y2 = KITCHEN.drawLine.line.y1;
      }
    }

    KITCHEN.drawLine.line
      .set({
        x2: x2,
        y2: y2,
      })
      .setCoords();

    KITCHEN.canvas.renderAll();
  },
  terminate: function () {
    if (KITCHEN.drawLine.isDown) {
      KITCHEN.drawLine.isDown = false;
      KITCHEN.canvas.remove(KITCHEN.drawLine.line);

      KITCHEN.canvas.forEachObject(function (obj) {
        if (Object.prototype.hasOwnProperty.call(obj, "name")) {
          if (obj.name === "line") {
            obj.set({ selectable: true, hasControls: true }).setCoords();
          }
        }
      });

      KITCHEN.canvas.renderAll();

      //deactivate drawing
      KITCHEN.drawLine.active = false;
      KITCHEN.canvas.selection = true;
      /*
      $("#drawline-btn").removeClass("active").removeClass("focus");
      $("[id^=drawline-color-]").hide();*/
    }
    KITCHEN.drawLine.active = false;
  },
};

KITCHEN.measure = {
  active: false,
  isDown: false,
  stage: 0,
  lineColor: "#000",
  lineWidth: 1.5,
  fontSize: 15,
  fontFamily: "Helvetica",
  fontColor: "#000",
  activate: function () {
    KITCHEN.measure.active = true;
    KITCHEN.canvas.selection = false;
    KITCHEN.canvas.hoverCursor = "default";
    KITCHEN.canvas.forEachObject(function (obj) {
      obj.selectable = false;
    });
  },
  deactivate: function () {
    KITCHEN.measure.active = false;
    KITCHEN.canvas.selection = true;
    KITCHEN.canvas.forEachObject(function (obj) {
      if (Object.prototype.hasOwnProperty.call(obj, "name")) {
        if (obj.name !== "terminal") {
          obj.selectable = true;
        }
      } else {
        obj.selectable = true;
      }
    });
    KITCHEN.canvas.hoverCursor = "move";
  },
  mouseDown: function (options) {
    //KITCHEN.canvas.deactivateAll();

    var pointer = KITCHEN.canvas.getPointer(options.e);
    var points = [pointer.x, pointer.y, pointer.x, pointer.y];

    switch (KITCHEN.measure.stage) {
      case 0:
        KITCHEN.measure.line0 = new fabric.Line(points, {
          hasControls: false,
          name: "measure_" + KITCHEN.measure.dimension + KITCHEN.measure.stage,
          strokeWidth: KITCHEN.measure.lineWidth,
          fill: KITCHEN.measure.lineColor,
          stroke: KITCHEN.measure.lineColor,
          originX: "center",
          originY: "center",
          selectable: true,
          noScaleCache: true,
          // strokeDashArray: [5, 5],
        });
        KITCHEN.canvas.add(KITCHEN.measure.line0);

        KITCHEN.measure.widthText0 = new fabric.Text("0", {
          fontSize: KITCHEN.measure.fontSize,
          fontFamily: KITCHEN.measure.fontFamily,
          fill: KITCHEN.measure.fontColor,
          left: (KITCHEN.measure.line0.x1 + KITCHEN.measure.line0.x2) / 2.0,
          top: pointer.y,
          style: null,
          originX: "center",
        });

        KITCHEN.canvas.add(KITCHEN.measure.widthText0);

        KITCHEN.measure.stage += 1;
        return;
      case 1:
        KITCHEN.measure.line1 = new fabric.Line(points, {
          hasControls: false,
          name: "measure_" + KITCHEN.measure.dimension + KITCHEN.measure.stage,
          strokeWidth: KITCHEN.measure.lineWidth,
          fill: KITCHEN.measure.lineColor,
          stroke: KITCHEN.measure.lineColor,
          originX: "center",
          originY: "center",
          noScaleCache: true,
          selectable: true,
        });
        KITCHEN.canvas.add(KITCHEN.measure.line1);
        KITCHEN.canvas.remove(KITCHEN.measure.widthText0);

        KITCHEN.measure.stage += 1;
        return;
      case 2:
        if (KITCHEN.measure.dimension === "width") {
          points = [KITCHEN.measure.line0.x2, pointer.y, KITCHEN.measure.line1.x2, pointer.y];
          var width = Math.floor(Math.abs(KITCHEN.measure.line0.x1 - KITCHEN.measure.line1.x1 - 1) / KITCHEN.gridScale);
        } else {
          points = [pointer.x, KITCHEN.measure.line0.y2, pointer.x, KITCHEN.measure.line1.y2];
          width = Math.floor(Math.abs(KITCHEN.measure.line0.y1 - KITCHEN.measure.line1.y1 - 1) / KITCHEN.gridScale);
        }

        KITCHEN.measure.line2 = new fabric.Line(points, {
          hasControls: false,
          name: "measure_" + KITCHEN.measure.dimension + KITCHEN.measure.stage,
          strokeWidth: KITCHEN.measure.lineWidth,
          fill: KITCHEN.measure.lineColor,
          stroke: KITCHEN.measure.lineColor,
          originX: "center",
          originY: "center",
          selectable: true,
          noScaleCache: true,
        });
        KITCHEN.canvas.add(KITCHEN.measure.line2);

        var slash1 = new fabric.Line([points[0] - 5, points[1] + 5, points[0] + 6, points[1] - 6], {
          hasControls: false,
          strokeWidth: KITCHEN.measure.lineWidth,
          fill: KITCHEN.measure.lineColor,
          stroke: KITCHEN.measure.lineColor,
          originX: "center",
          originY: "center",
          selectable: true,
          noScaleCache: true,
        });

        var slash2 = new fabric.Line([points[2] - 5, points[3] + 5, points[2] + 6, points[3] - 6], {
          hasControls: false,
          strokeWidth: KITCHEN.measure.lineWidth,
          fill: KITCHEN.measure.lineColor,
          stroke: KITCHEN.measure.lineColor,
          originX: "center",
          originY: "center",
          selectable: true,
          noScaleCache: true,
        });

        KITCHEN.canvas.add(slash1);
        KITCHEN.canvas.add(slash2);

        KITCHEN.measure.widthText = new fabric.Text(width.toString(), {
          fontSize: KITCHEN.measure.fontSize,
          fontFamily: KITCHEN.measure.fontFamily,
          fill: KITCHEN.measure.fontColor,
          left: (KITCHEN.measure.line0.x2 + KITCHEN.measure.line1.x2) / 2.0,
          top: pointer.y,
          styles: "",
        });

        KITCHEN.measure.widthText.set({
          originX: "center",
        });

        if (KITCHEN.measure.dimension === "width") {
          if (KITCHEN.measure.line0.y1 > KITCHEN.measure.line0.y2) {
            KITCHEN.measure.widthText.set({ originY: "bottom" });
          } else {
            KITCHEN.measure.widthText.set({ originY: "top" });
          }

          KITCHEN.measure.widthText.set({
            left: (KITCHEN.measure.line0.x2 + KITCHEN.measure.line1.x2) / 2.0,
            top: pointer.y,
          });
        } else {
          if (KITCHEN.measure.line0.x1 < KITCHEN.measure.line0.x2) {
            KITCHEN.measure.widthText.set({ originY: "bottom" });
          } else {
            KITCHEN.measure.widthText.set({ originY: "top" });
          }

          KITCHEN.measure.widthText.set({
            left: pointer.x,
            top: (KITCHEN.measure.line0.y2 + KITCHEN.measure.line1.y2) / 2.0,
            angle: 90,
          });
        }

        KITCHEN.canvas.add(KITCHEN.measure.widthText);

        KITCHEN.measure.widthText.toObject = (function (toObject) {
          return function () {
            return fabric.util.object.extend(toObject.call(this), {
              styles: "",
            });
          };
        })(KITCHEN.measure.widthText.toObject);

        KITCHEN.measure.ruler = new fabric.Group(
          [
            KITCHEN.measure.line0,
            KITCHEN.measure.line1,
            KITCHEN.measure.line2,
            KITCHEN.measure.widthText,
            slash1,
            slash2,
          ],
          {
            hasControls: false,
          }
        );

        KITCHEN.measure.ruler.setControlsVisibility({
          mb: true,
          mr: true,
          ml: true,
          mt: true,
        });

        KITCHEN.measure.ruler.name = "ruler";

        KITCHEN.canvas.add(KITCHEN.measure.ruler);

        KITCHEN.canvas.remove(KITCHEN.measure.line0);
        KITCHEN.canvas.remove(KITCHEN.measure.line1);
        KITCHEN.canvas.remove(KITCHEN.measure.line2);
        KITCHEN.canvas.remove(KITCHEN.measure.widthText);
        KITCHEN.canvas.remove(slash1);
        KITCHEN.canvas.remove(slash2);

        KITCHEN.measure.deactivate();

        KITCHEN.measure.stage = 0;

        $("#measure-btn").removeClass("active").removeClass("focus");
        setTimeout(function () {
          KITCHEN.canvas.renderAll();
          KITCHEN.canvasHistory.saveState("addMeasure");
        }, 200);
        return;
    }
  },
  drawMiddleLine: function (pointer) {
    var points = [pointer.x, pointer.y, pointer.x, pointer.y];
    if (typeof KITCHEN.measure.line2 === "undefined") {
      KITCHEN.measure.line2 = new fabric.Line(points, {
        hasControls: false,
        name: "measure_" + KITCHEN.measure.dimension + KITCHEN.measure.stage,
        strokeWidth: KITCHEN.measure.lineWidth,
        fill: KITCHEN.measure.lineColor,
        stroke: KITCHEN.measure.lineColor,
        originX: "center",
        originY: "center",
        selectable: true,
        targetFindTolerance: true,
      });
      KITCHEN.canvas.add(KITCHEN.measure.line2);
    } else {
      KITCHEN.measure.line2
        .set({
          x1: KITCHEN.measure.line0.x2,
          y1: KITCHEN.measure.line0.y2,
          x2: pointer.x,
          y2: pointer.y,
        })
        .setCoords();
    }
  },
  mouseMove: function (options) {
    var pointer = KITCHEN.canvas.getPointer(options.e);
    var width;

    switch (KITCHEN.measure.stage) {
      case 0:
        break;
      case 1:
        if (Math.abs(pointer.x - KITCHEN.measure.line0.x1) > Math.abs(pointer.y - KITCHEN.measure.line0.y1)) {
          KITCHEN.measure.dimension = "height";
          width = Math.floor(Math.abs(KITCHEN.measure.line0.x1 - KITCHEN.measure.line0.x2) / KITCHEN.gridScale);
          KITCHEN.measure.line0
            .set({
              x2: pointer.x,
              y2: KITCHEN.measure.line0.y1,
            })
            .setCoords();
        } else {
          KITCHEN.measure.dimension = "width";
          width = Math.floor(Math.abs(KITCHEN.measure.line0.y1 - KITCHEN.measure.line0.y2) / KITCHEN.gridScale);
          KITCHEN.measure.line0
            .set({
              x2: KITCHEN.measure.line0.x1,
              y2: pointer.y,
            })
            .setCoords();
        }

        KITCHEN.measure.widthText0.set({
          text: width.toString(),
        });

        if (KITCHEN.measure.dimension === "width") {
          if (KITCHEN.measure.line0.y1 > KITCHEN.measure.line0.y2) {
            KITCHEN.measure.widthText0.set({ originY: "bottom" });
          } else {
            KITCHEN.measure.widthText0.set({ originY: "top" });
          }

          KITCHEN.measure.widthText0.set({
            top: (KITCHEN.measure.line0.y1 + KITCHEN.measure.line0.y2) / 2.0,
            left: KITCHEN.measure.line0.x1,
            angle: 90,
            fill: "#fff",
          });
        } else {
          if (KITCHEN.measure.line0.x1 < KITCHEN.measure.line0.x2) {
            KITCHEN.measure.widthText0.set({ originY: "bottom" });
          } else {
            KITCHEN.measure.widthText0.set({ originY: "top" });
          }

          KITCHEN.measure.widthText0.set({
            top: KITCHEN.measure.line0.y1,
            left: (KITCHEN.measure.line0.x1 + KITCHEN.measure.line0.x2) / 2.0,
            angle: 0,
            fill: "#fff",
          });
        }

        KITCHEN.canvas.renderAll();
        break;
      case 2:
        if (isBetween(pointer.x, KITCHEN.measure.line0.x1, KITCHEN.measure.line1.x1)) {
          KITCHEN.measure.dimension = "width";
          KITCHEN.measure.line0
            .set({
              x2: KITCHEN.measure.line0.x1,
              y2: pointer.y + KITCHEN.measure.lineWidth / 2.0,
            })
            .setCoords();
          KITCHEN.measure.line1
            .set({
              x2: KITCHEN.measure.line1.x1,
              y2: pointer.y + KITCHEN.measure.lineWidth / 2.0,
            })
            .setCoords();
        } else {
          KITCHEN.measure.dimension = "height";
          // if (isBetween (pointer.y, KITCHEN.measure.line0.y1, KITCHEN.measure.line1.y1)) {
          KITCHEN.measure.line0
            .set({
              x2: pointer.x + KITCHEN.measure.lineWidth / 2.0,
              y2: KITCHEN.measure.line0.y1,
            })
            .setCoords();
          KITCHEN.measure.line1
            .set({
              x2: pointer.x + KITCHEN.measure.lineWidth / 2.0,
              y2: KITCHEN.measure.line1.y1,
            })
            .setCoords();
          // }
        }
        KITCHEN.canvas.renderAll();
        break;
    }
  },
};

KITCHEN.canvasHistory = {
  stateQueue: [],
  stateIndex: 0,
  stateMaxIndex: 10,
  state: {
    tabletop: JSON.stringify({ objects: [] }),
    table: JSON.stringify({ X: 2000, Y: 1200, offsetX: 25, offsetY: 25, type: "metric" }),
  },

  reset: function () {
    KITCHEN.canvasHistory.stateIndex = 0;
    KITCHEN.canvasHistory.stateQueue = [];
    // console.log('state history resetted', KITCHEN.canvasHistory.stateQueue, KITCHEN.canvasHistory.stateQueue.length);
    setTimeout(function () {
      KITCHEN.canvasHistory.state = {
        tabletop: JSON.stringify(KITCHEN.canvas.toJSON()),
        table: JSON.stringify(KITCHEN.tableParameters),
      };
    }, 100);
  },

  saveState: function (caller) {
    $("#undo-btn").removeClass("disabled");

    if (KITCHEN.canvasHistory.stateIndex !== 0) {
      KITCHEN.canvasHistory.stateQueue.splice(0, KITCHEN.canvasHistory.stateIndex);
      KITCHEN.canvasHistory.stateIndex = 0;
    }

    if (KITCHEN.canvasHistory.stateQueue.length === KITCHEN.canvasHistory.stateMaxIndex) {
      KITCHEN.canvasHistory.stateQueue.splice(KITCHEN.canvasHistory.stateMaxIndex - 1, 1);
    }

    KITCHEN.canvasHistory.stateQueue.unshift(KITCHEN.canvasHistory.state);
    switch (caller) {
      case "rotate":
      case "addLine":
        KITCHEN.canvasHistory.state = { tabletop: JSON.stringify(KITCHEN.canvas.toJSON()), table: null };
        break;
      case "table":
        // KITCHEN.canvasHistory.state = {tabletop: null, table: JSON.stringify(KITCHEN.tableCanvas.toJSON())};
        KITCHEN.canvasHistory.state = {
          tabletop: JSON.stringify(KITCHEN.canvas.toJSON()),
          table: JSON.stringify(KITCHEN.tableParameters),
        };
        break;
      default:
        setTimeout(function () {
          KITCHEN.canvasHistory.state = { tabletop: JSON.stringify(KITCHEN.canvas.toJSON()), table: null };
        }, 200);
        break;
    }
    // console.log('state saved by', caller, KITCHEN.canvasHistory.stateQueue, KITCHEN.canvasHistory.state);
  },

  undo: function () {
    if (KITCHEN.canvasHistory.stateIndex < KITCHEN.canvasHistory.stateQueue.length) {
      if (KITCHEN.canvasHistory.stateQueue[KITCHEN.canvasHistory.stateIndex].tabletop !== null) {
        KITCHEN.canvas.loadFromJSON(
          JSON.parse(KITCHEN.canvasHistory.stateQueue[KITCHEN.canvasHistory.stateIndex].tabletop),
          function () {
            KITCHEN.canvas.renderAll();
            KITCHEN.canvasHistory.state = { tabletop: JSON.stringify(KITCHEN.canvas.toJSON()), table: null };
          }
        );
      }
      if (KITCHEN.canvasHistory.stateQueue[KITCHEN.canvasHistory.stateIndex].table !== null) {
        KITCHEN.tableCanvas.clear();
        KITCHEN.tableParameters = JSON.parse(KITCHEN.canvasHistory.stateQueue[KITCHEN.canvasHistory.stateIndex].table);
        KITCHEN.drawTable(KITCHEN.tableParameters);

        KITCHEN.canvasHistory.state = { tabletop: null, table: JSON.stringify(KITCHEN.tableParameters) };
        KITCHEN.canvasHistory.stateIndex += 1;
      }
      KITCHEN.canvasHistory.stateIndex += 1;
    }
    $("#undo-btn").toggleClass("disabled", KITCHEN.canvasHistory.stateIndex >= KITCHEN.canvasHistory.stateQueue.length);
  },
};

//return max id number of canvas objects
KITCHEN.maxId = function () {
  var max = -1;

  for (const i in KITCHEN.canvas._objects) {
    if (Object.prototype.hasOwnProperty.call(KITCHEN.canvas._objects[i], "id")) {
      if (KITCHEN.canvas._objects[i].id > max) {
        max = KITCHEN.canvas._objects[i].id;
      }
    }
  }

  return max;
};

KITCHEN.createPNG = function () {
  if (!window.localStorage) {
    $("#png-fail-indicator").show();
    setTimeout(function () {
      $("#png-fail-indicator").animate({ width: "hide" });
    }, 2000);
  }
  // to PNG
  KITCHEN.makePNG();

  var can = document.getElementById("table-merged");
  can.getContext("2d");

  can.toBlobHD(
    function () {
      //will clone the tableCanvas as SVG and puts it in the back of tabletop canvas
      //after exporting to png, will be deleted
      var tableSvg = KITCHEN.tableCanvas.toSVG({
        suppressPreamble: true,
      });

      //copy of table canvas
      JSON.stringify(KITCHEN.tableCanvas);

      var tableSvgImage = "data:image/svg+xml; charset=utf8, " + encodeURIComponent(tableSvg.split('svg11.dtd">')[0]);

      var tableCloneObject;

      fabric.Image.fromURL(tableSvgImage, function (o) {
        KITCHEN.canvas.add(o);
        tableCloneObject = o;
        o.sendToBack();
        KITCHEN.canvas.renderAll();
        $("#loader").show();
      });

      setTimeout(function () {
        var png_image_tabletop = KITCHEN.canvas.toDataURL({
          format: "png",
          multiplier: 3,
          width: can.width,
          height: can.height,
        });

        var byteCharacters = atob(png_image_tabletop.replace("data:image/png;base64,", ""));

        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        new Blob([byteArray], { type: "image/png" });

        $("#loader").hide();

        KITCHEN.canvas.remove(tableCloneObject);

        KITCHEN.pngImageHD = png_image_tabletop.replace("data:image/png;base64,", "");
      }, 1000);
    },
    "image/png",
    1.0
  );
};

//return png image string
KITCHEN.makePNG2 = function () {
  if (!window.localStorage) {
    return;
  }

  KITCHEN.pngImage = "not initialized";

  var tableSvg = KITCHEN.tableCanvas.toSVG({
    suppressPreamble: true,
  });

  //copy of table canvas
  JSON.stringify(KITCHEN.tableCanvas);

  var tableSvgImage = "data:image/svg+xml; charset=utf8, " + encodeURIComponent(tableSvg.split('svg11.dtd">')[0]);

  var tableCloneObject;
  var png_image_tabletop = "not initialized";

  fabric.Image.fromURL(tableSvgImage, function (o) {
    KITCHEN.canvas.add(o);
    tableCloneObject = o;
    o.sendToBack();
    KITCHEN.canvas.renderAll();
    $("#loader").show();

    png_image_tabletop = KITCHEN.canvas.toDataURL({
      format: "png",
      multiplier: 1,
      width: KITCHEN.width,
      height: KITCHEN.height,
    });

    KITCHEN.pngImage = png_image_tabletop;

    $("#loader").hide();

    KITCHEN.canvas.remove(tableCloneObject);
    KITCHEN.canvas.renderAll();
  });

  return png_image_tabletop;
};

//return png image string
KITCHEN.makePNG = function () {
  if (!window.localStorage) {
    return;
  }

  // KITCHEN.canvas.deactivateAll();
  KITCHEN.canvas.renderAll();

  var can = document.getElementById("table-merged");
  var ctx = can.getContext("2d");
  ctx.clearRect(0, 0, can.width, can.height);

  ctx.drawImage(document.getElementById("table"), 0, 0, 900, 556);
  ctx.drawImage(document.getElementById("tabletop"), 0, 0, 900, 556);

  return can.toDataURL("image/png", 1.0);
};

//Drawing table function
KITCHEN.drawTable = function (table) {
  var i;
  var j;
  var x;
  var y;

  var tableArray = [];

  //var shape = [[]];

  KITCHEN.width = table.X * KITCHEN.gridScale;
  KITCHEN.height = table.Y * KITCHEN.gridScale;

  KITCHEN.widthOffset = Object.prototype.hasOwnProperty.call(table, "offsetX")
    ? table.offsetX * KITCHEN.gridScale
    : 0.0;
  KITCHEN.heightOffset = Object.prototype.hasOwnProperty.call(table, "offsetY")
    ? table.offsetY * KITCHEN.gridScale
    : 0.0;

  //table border
  var tabletop = new fabric.Rect({
    left: 0,
    top: 0,
    width: KITCHEN.width,
    height: KITCHEN.height,
    fill: "#76767d",
    stroke: "#000000",
    rx: 20 * KITCHEN.gridScale,
    ry: 20 * KITCHEN.gridScale,
  });

  tableArray.push(tabletop);

  //create optics table
  for (i = 0; i < (KITCHEN.width - KITCHEN.widthOffset) / KITCHEN.grid; i += 1) {
    for (j = 0; j < (KITCHEN.height - KITCHEN.heightOffset) / KITCHEN.grid; j += 1) {
      x = i * KITCHEN.grid - KITCHEN.holeRadius + KITCHEN.widthOffset;
      y = j * KITCHEN.grid - KITCHEN.holeRadius + KITCHEN.heightOffset;

      tableArray.push(
        new fabric.Circle({
          left: x,
          top: y,
          radius: KITCHEN.holeRadius,
          fill: "#000",
          selectable: false,
          evented: false,
          hoverCursor: "null",
        })
      );
    }
  }

  var dx = KITCHEN.width - x;
  var dy = KITCHEN.height - y;

  //rightmost bottommost hole
  if (dy < Math.sqrt(tabletop.rx * tabletop.rx - dx * dx)) {
    tableArray.pop();
  }

  //table dimensions
  if (KITCHEN.tableParameters.type === "metric") {
    tableArray.push(
      new fabric.Text(Math.round(table.X) + " mm", {
        fontSize: 10,
        fontFamily: "Helvetica",
        fill: "#76767d",
        originX: "center",
        originY: "top",
        top: KITCHEN.height + 3,
        left: KITCHEN.width / 2.0,
      })
    );
    tableArray.push(
      new fabric.Text(Math.round(table.Y) + " mm", {
        fontSize: 10,
        fontFamily: "Helvetica",
        fill: "#76767d",
        originX: "center",
        originY: "top",
        top: KITCHEN.height / 2.0,
        left: KITCHEN.width + 3,
        angle: -90,
      })
    );
  }

  if (KITCHEN.tableParameters.type === "imperial") {
    tableArray.push(
      new fabric.Text(Math.round((table.X / 10.0 / ft_to_cm) * 100.0) / 100.0 + " ft", {
        fontSize: 10,
        fontFamily: "Helvetica",
        fill: "#76767d",
        originX: "center",
        originY: "top",
        top: KITCHEN.height + 3,
        left: KITCHEN.width / 2.0,
      })
    );
    tableArray.push(
      new fabric.Text(Math.round((table.Y / 10.0 / ft_to_cm) * 100.0) / 100.0 + " ft", {
        fontSize: 10,
        fontFamily: "Helvetica",
        fill: "#76767d",
        originX: "center",
        originY: "top",
        top: KITCHEN.height / 2.0,
        left: KITCHEN.width + 3,
        angle: -90,
      })
    );
  }

  KITCHEN.table = new fabric.Group(tableArray, {
    name: "table",
    selectable: false,
    evented: false,
    hoverCursor: "null",
  });

  KITCHEN.tableCanvas.add(KITCHEN.table);
  KITCHEN.tableObjectNumber = KITCHEN.tableCanvas._objects.length - 1;

  KITCHEN.tableCanvas.renderAll();
};

// Table settings window fill values
KITCHEN.prepareTableSettingsWindow = function (caller) {
  $("#layout-title").val(KITCHEN.layoutSettings.title || "");
  $("#layout-author").val(KITCHEN.layoutSettings.author || "");
  $("#layout-email").val(KITCHEN.layoutSettings.email || "");
  $("#layout-comments").val(KITCHEN.layoutSettings.comments || "");

  switch (KITCHEN.tableParameters.type) {
    case "metric":
      switch (KITCHEN.tableParameters.X + "x" + KITCHEN.tableParameters.Y) {
        case "2000x1200":
          $("#table-metric-1").prop("checked", true);
          break;
        case "3000x1500":
          $("#table-metric-2").prop("checked", true);
          break;
        case "2000x800":
          $("#table-metric-3").prop("checked", true);
          break;
        default:
          $("#table-metric-4").prop("checked", true);
          $("#metric_width").val(KITCHEN.tableParameters.X / 10.0);
          $("#metric_height").val(KITCHEN.tableParameters.Y / 10.0);
          break;
      }
      break;
    case "imperial":
      (KITCHEN.tableParameters.X / 10.0 / ft_to_cm).toFixed(0) +
        "x" +
        (KITCHEN.tableParameters.Y / 10.0 / ft_to_cm).toFixed(0);
      switch (
      (KITCHEN.tableParameters.X / 10.0 / ft_to_cm).toFixed(0) +
      "x" +
      (KITCHEN.tableParameters.Y / 10.0 / ft_to_cm).toFixed(0)
      ) {
        case "7x4":
          $("#table-imperial-1").prop("checked", true);
          break;
        case "10x5":
          $("#table-imperial-2").prop("checked", true);
          break;
        case "7x3":
          $("#table-imperial-3").prop("checked", true);
          break;
        default:
          $("#table-imperial-4").prop("checked", true);
          $("#imperial_width").val(KITCHEN.tableParameters.X / 10.0 / ft_to_cm);
          $("#imperial_height").val(KITCHEN.tableParameters.Y / 10.0 / ft_to_cm);
          break;
      }

      break;
  }

  switch (caller) {
    case "modify":
      $("#table-settings-new-apply-btn").css("display", "none");
      $("#table-settings-modify-apply-btn").css("display", "inline");

      $('[data-toggle="btns"]').hide();
      break;
    case "new":
      $("#table-settings-new-apply-btn").css("display", "inline");
      $("#table-settings-modify-apply-btn").css("display", "none");

      $('[data-toggle="btns"]').show();
      break;
  }
};

KITCHEN.getCanvasWidth = function () {
  return parseFloat($("#table").css("width"));
};

KITCHEN.getCanvasHeight = function () {
  return parseFloat($("#table").css("height"));
};

// New table / apply table settings
KITCHEN.apply = function (value) {
  var type;
  var height;
  var width;
  var myRe = /(\d+)x(\d+)/;
  var valArray;
  var canvasWidth;
  var canvasHeight;
  //var preset;

  KITCHEN.layoutSettings = {
    title: $("#layout-title").val(),
    author: $("#layout-author").val(),
    email: $("#layout-email").val(),
    comments: $("#layout-comments").val(),
    notificationSent: KITCHEN.layoutSettings.notificationSent,
  };

  //KITCHEN.usingPreset = $("#layout-preset-btn").hasClass("active") && typeof KITCHEN.activePreset !== "undedined";

  if (KITCHEN.usingPreset) {
    KITCHEN.hideAllTerminals();
  }

  if (!KITCHEN.usingPreset) {
    if (value.search("cm") !== -1) {
      type = "metric";
      KITCHEN.gridSpacing = 25; //milimeters between holes
    } else {
      type = "imperial";
      KITCHEN.gridSpacing = 25.4;
    }

    if (value.search("custom") !== -1) {
      switch (type) {
        case "metric":
          height = $("#metric_height").val();
          width = $("#metric_width").val();
          break;
        case "imperial":
          height = $("#imperial_height").val() * ft_to_cm;
          width = $("#imperial_width").val() * ft_to_cm;
          break;
      }
    } else {
      valArray = myRe.exec(value);
      width = valArray[1];
      height = valArray[2];

      if (type === "imperial") {
        width *= ft_to_cm;
        height *= ft_to_cm;
      }
    }

    //converting to mm
    width *= 10;
    height *= 10;

    //canvas width & height
    canvasWidth = KITCHEN.getCanvasWidth() - 10;
    canvasHeight = KITCHEN.getCanvasHeight() - 10;

    var TEMP = {};
    var ratioGridScale;
    //var ratioGrid;
    //delete current table
    KITCHEN.tableCanvas.clear();
    KITCHEN.tableCanvas.renderAll();

    //if not empty canvas
    if (canvasWidth / canvasHeight < width / height) {
      TEMP.gridScale = canvasWidth / width;

      TEMP.grid = Math.floor(TEMP.gridScale * KITCHEN.gridSpacing);
      TEMP.gridScale = TEMP.grid / KITCHEN.gridSpacing;
    } else {
      TEMP.gridScale = canvasHeight / height;
      TEMP.grid = Math.floor(TEMP.gridScale * KITCHEN.gridSpacing);
      TEMP.gridScale = TEMP.grid / KITCHEN.gridSpacing;
    }

    ratioGridScale = TEMP.gridScale / KITCHEN.gridScale;
    KITCHEN.gridScale = TEMP.gridScale;
    KITCHEN.grid = TEMP.grid;

    KITCHEN.canvas.forEachObject(function (obj) {
      if (obj.name == null) return;
      //console.log(obj);
      obj.top = obj.top * ratioGridScale;
      obj.left = obj.left * ratioGridScale;
      if (obj.snapX) {
        obj.snapPositionX = obj.snapPositionX * ratioGridScale;
      }
      if (obj.snapY) {
        obj.snapPositionY = obj.snapPositionY * ratioGridScale;
      }

      switch (obj.name) {
        case "terminal":
          obj.topInit = obj.topInit * ratioGridScale;
          obj.leftInit = obj.leftInit * ratioGridScale;
          break;
        case "ruler":
          //var originTop = obj.getTop();
          //var originLeft = obj.getLeft();

          obj.scale(obj.scaleX * ratioGridScale);

          // var l0 = obj.item(0);
          //  var l1 = obj.item(1);
          // var l2 = obj.item(2);
          //var l3 = obj.item(3);
          //var l4 = obj.item(4);
          //var l5 = obj.item(5);

          // obj.removeWithUpdate(l5);
          // obj.removeWithUpdate(l4);
          // obj.removeWithUpdate(l3);

          obj.item(0).strokeWidth = KITCHEN.measure.lineWidth / obj.item(0).scaleX;
          obj.item(1).strokeWidth = KITCHEN.measure.lineWidth / obj.item(1).scaleX;
          obj.item(2).strokeWidth = KITCHEN.measure.lineWidth / obj.item(2).scaleX;
          // obj.addWithUpdate(l3.setFontSize(KITCHEN.measure.fontSize));
          // obj.addWithUpdate((l4.strokeWidth = KITCHEN.measure.lineWidth));
          // obj.addWithUpdate((l5.strokeWidth = KITCHEN.measure.lineWidth));

          break;
        case "line":
          obj.x1 = obj.x1 * ratioGridScale;
          obj.x2 = obj.x2 * ratioGridScale;
          obj.y1 = obj.y1 * ratioGridScale;
          obj.y2 = obj.y2 * ratioGridScale;

          obj.width = obj.width * ratioGridScale;
          obj.height = obj.height * ratioGridScale;

          break;
        case "TUBE":
        case "TUBE-16":
        case "TUBE-25":
        case "TUBE-32":
          obj.setScaleX(obj.scaleX * ratioGridScale);
          obj.setScaleY(obj.scaleY * ratioGridScale);
          break;

        case "BLACK-BOX":
          obj.width = obj.width * ratioGridScale;
          obj.height = obj.height * ratioGridScale;
          break;
        default:
      }

      switch (obj.name.name) {
        case "TUBE":
        case "TUBE-16":
        case "TUBE-25":
        case "TUBE-32":
          obj.scaleX = obj.scaleX * ratioGridScale;
          obj.scaleY = obj.scaleY * ratioGridScale;
          break;

        case "BLACK-BOX":
          obj.width = obj.width * ratioGridScale;
          obj.height = obj.height * ratioGridScale;
          break;
        default:
      }

      obj.setCoords();
    });

    KITCHEN.restoreAllSizes();

    KITCHEN.tableParameters = { X: width, Y: height, offsetX: 25.0, offsetY: 25.0, type: type };

    KITCHEN.drawTable(KITCHEN.tableParameters);
  }

  KITCHEN.canvasHistory.saveState("table");

  $("#layout-empty-btn").trigger("click");
  $('[data-toggle="btns"]').hide();
};

// Invoke Table settings modification window
KITCHEN.modify = function () {
  KITCHEN.prepareTableSettingsWindow("modify");
  $("#table_settings").modal("show");
};

// Invoke Table settings new window
KITCHEN.new = function () {
  KITCHEN.layoutSettings = { notificationSent: false };
  KITCHEN.prepareTableSettingsWindow("new");

  $("#table_settings").modal("show");
};

//Add product from products.json catalogue
KITCHEN.addProduct = function (product, left, top, angle, height) {
  var obj;
  var shape;
  obj = product;
  if (obj == null) return;

  if (obj.name == "BLACK-BOX") {
    shape = new fabric.Rect({
      name: "BLACK-BOX",
      fill: "#97989c",
      stroke: "#7d7d81",
      originX: "left",
      originY: "top",
      angle: 0,
      visible: true,
      strokeWidth: 0,
      hasBorders: false,
      width: obj.width * KITCHEN.gridScale,
      height: obj.height * KITCHEN.gridScale,
    });
    //  }

    shape = new fabric.Group([shape]);
    KITCHEN.formatShape(product, shape, obj)

    KITCHEN.canvas.add(shape);
    shape.set({ id: KITCHEN.maxId() + 1 });

    shape.toObject = (function (toObject) {
      return function () {
        return fabric.util.object.extend(toObject.call(this), {
          name: "BLACK-BOX",
          strokeWidth: 0.0,
          lockRotation: this.lockRotation,
          id: this.id,
          styles: this.styles,
          snapX: this.snapX,
          snapY: this.snapY,
          snapPositionY: this.snapPositionY,
          snapPositionX: this.snapPositionX,
          freeRotate: this.freeRotate,
          lockScalingX: this.lockScalingX,
          lockScalingY: this.lockScalingY,
          terminals: this.terminals,
          _controlsVisibility: this._controlsVisibility,
        });
      };
    })(shape.toObject);

  } else if (Object.prototype.hasOwnProperty.call(obj, "svgImage")) {
    fabric.loadSVGFromURL(
      process.env.VUE_APP_IMAGES + product.svgImage + "?" + Date.now(),
      function (objects, options) {
        shape = fabric.util.groupSVGElements(objects, options);

        //shape.;
        shape.scaleToWidth(obj.width * KITCHEN.gridScale);
        // shape.height = obj.height;
        //shape.width = shape.width * KITCHEN.gridScale;
        //.set({ sourcePath: "/dev/static/kitchen/images-v2/" + obj.svgImage });
        // shape.scaleToHeight(obj.height*KITCHEN.gridScale).scaleToWidth(obj.width*KITCHEN.gridScale).set({"sourcePath": KITCHEN.rootImageFolder+obj.svg_image});
        // shape.set({height: obj.height*KITCHEN.gridScale, width: obj.width*KITCHEN.gridScale, scaleX: 1.0, scaleY: 1.0}).set({"sourcePath": '../../static/kitchen/'+obj.svg_image});

        KITCHEN.canvas.add(shape);
        KITCHEN.canvas.bringToFront(shape);

        KITCHEN.lastAddedObject = KITCHEN.canvas._objects[KITCHEN.canvas._objects.length - 1];

        KITCHEN.formatShape(product, shape, obj);

        shape.set({ id: KITCHEN.maxId() + 1 });

        // console.log(shape.name, shape.id, KITCHEN.maxId());
        if (left != null && top != null && angle != null) {
          shape.set({ angle: angle, left: left, top: top });
        }
        if (height != null) {
          shape.set({ angle: angle, left: left, top: top, scaleY: height / shape.height });
        }

        shape.setCoords();

        KITCHEN.showObjectTerminals(shape);

        //add custom properties to object (important when serializing to JSON)
        shape.toObject = (function (toObject) {
          return function () {
            return fabric.util.object.extend(toObject.call(this), {
              name: this.name.name,
              strokeWidth: 0.0,
              lockRotation: this.lockRotation,
              id: this.id,
              styles: this.styles,
              snapX: this.snapX,
              snapY: this.snapY,
              snapPositionY: this.snapPositionY,
              snapPositionX: this.snapPositionX,
              freeRotate: this.freeRotate,
              lockScalingX: this.lockScalingX,
              lockScalingY: this.lockScalingY,
              terminals: this.terminals,
              _controlsVisibility: this._controlsVisibility,
            });
          };
        })(shape.toObject);

        KITCHEN.bringForwardObjectTerminals(shape);
        if (shape.name.name == "TUBE" || shape.name.name == "TUBE-16" || shape.name.name == "TUBE-25" || shape.name.name == "TUBE-32") {
          shape.set({ scaleY: 1.0 });
          shape.setCoords();
          KITCHEN.canvas.sendToBack(shape);
        }

        KITCHEN.canvas.renderAll();
        //KITCHEN.canvas._activeObject.bringToFront();
        //KITCHEN.canvas.bringForward(KITCHEN.lastAddedObject);
      }
    );
  } else {
    if (Object.prototype.hasOwnProperty.call(obj, "png_image")) {
      fabric.Image.fromURL("/dev/static/kitchen/images-v2/" + obj.png_image, function (img) {
        shape = img
          .scaleToHeight(obj.height * KITCHEN.gridScale)
          .scaleToWidth(obj.width * KITCHEN.gridScale)
          .set({ sourcePath: "/dev/static/kitchen/images-v2/" + obj.png_image });

        KITCHEN.canvas.add(shape);
        KITCHEN.lastAddedObject = KITCHEN.canvas._objects[KITCHEN.canvas._objects.length - 1];

        KITCHEN.formatShape(product, shape, obj);
        shape.set({ id: KITCHEN.canvas._objects.length - 1 });
        KITCHEN.showObjectTerminals(shape);

        shape.setCoords();

        //add custom properties to object (important when serializing to JSON)
        shape.toObject = (function (toObject) {
          return function () {
            return fabric.util.object.extend(toObject.call(this), {
              name: this.name,
              lockRotation: this.lockRotation,
              id: this.id,
              snapX: this.snapX,
              snapY: this.snapY,
              snapPositionY: this.snapPositionY,
              snapPositionX: this.snapPositionX,
              lockScalingX: this.lockScalingX,
              lockScalingY: this.lockScalingY,
              freeRotate: this.freeRotate,
              terminals: this.terminals,
              _controlsVisibility: this._controlsVisibility,
            });
          };
        })(shape.toObject);

        KITCHEN.bringForwardObjectTerminals(shape);
      });
    } else {
      shape = new fabric.Rect({
        name: "rectangle",
        fill: "#97989c",
        stroke: "#7d7d81",
        originX: "left",
        originY: "top",
        angle: 0,
        visible: true,
        strokeWidth: 2,
        hasBorders: true,
        width: obj.width * KITCHEN.gridScale,
        height: obj.height * KITCHEN.gridScale,
      });

      KITCHEN.canvas.add(shape);
      KITCHEN.lastAddedObject = KITCHEN.canvas._objects[KITCHEN.canvas._objects.length - 1];

      KITCHEN.formatShape(product, shape, obj);
      shape.set({ id: KITCHEN.canvas._objects.length - 1, lockScalingY: false, lockScalingX: false });
      KITCHEN.showObjectTerminals(shape);

      shape.setCoords();

      shape.setControlsVisibility({
        bl: true,
        br: true,
        tl: true,
        tr: true,
      });

      //add custom properties to object (important when serializing to JSON)
      shape.toObject = (function (toObject) {
        return function () {
          return fabric.util.object.extend(toObject.call(this), {
            name: this.name,
            hasBorders: this.hasBorders,
            lockRotation: this.lockRotation,
            originX: this.originX,
            originY: this.originY,
            angle: this.angle,
            visible: this.visible,
            id: this.id,
            snapX: this.snapX,
            snapY: this.snapY,
            snapPositionY: this.snapPositionY,
            snapPositionX: this.snapPositionX,
            lockScalingX: this.lockScalingX,
            lockScalingY: this.lockScalingY,
            freeRotate: this.freeRotate,
            terminals: this.terminals,
            strokeWidth: this.strokeWidth,
            strokeWidthUnscaled: this.strokeWidthUnscaled,
            _controlsVisibility: this._controlsVisibility,
          });
        };
      })(shape.toObject);

      KITCHEN.bringForwardObjectTerminals(shape);

      KITCHEN.canvas.requestRenderAll();
    }
  }

  KITCHEN.canvasHistory.saveState();
  KITCHEN.canvas.requestRenderAll();
  KITCHEN.canvas.renderAll();
  console.log("🚀 ~ file: kitchenplanner.js:1387 ~ KITCHEN.canvas:", KITCHEN.canvas)
};

//Recalculate terminals of given object and show if KITCHEN.terminalsVisible === true
KITCHEN.showObjectTerminals = function (obj) {
  KITCHEN.updateObjectTerminals(obj, true);
};

KITCHEN.updateObjectTerminals = function (obj, visibility) {
  var j;
  var i;
  var top;
  var left;
  var terminal;
  var len;
  //var temp;

  if (
    Object.prototype.hasOwnProperty.call(obj, "id") &&
    obj.name !== "terminal" &&
    Object.prototype.hasOwnProperty.call(obj, "terminals")
  ) {
    var phi = (obj.angle / 180.0) * Math.PI; // angle

    len = obj.terminals.length;

    for (j = 0; j < len; j++) {
      terminal = KITCHEN.getObjectById(obj.terminals[j]);

      //if number of terminals does not match; this comes with updates
      if (typeof obj.name === "undefined") {
        break;
      }

      if (typeof terminal === "undefined" || typeof obj.name.terminals[j] === "undefined") {
        break;
      }

      var w = obj.name.terminals[j].left * KITCHEN.gridScale;
      var h = obj.name.terminals[j].top * KITCHEN.gridScale;

      left = w * Math.cos(phi) - h * Math.sin(phi);
      top = h * Math.cos(phi) + w * Math.sin(phi);

      if (visibility === null) {
        visibility = terminal.visible;
      }

      if (visibility != true && visibility !== false) {
        visibility = true;
      }

      terminal
        .set({
          top: top + obj.top,
          left: left + obj.left,
          angle: obj.angle,
          selectable: false,
          evented: false,
          hoverCursor: "null",
          snapX: false,
          snapY: false,
          visible: visibility,
          originX: "center",
          originY: "center",
        })
        .bringToFront();
    }
  } else {
    //if it is a selection, terminals are not showing
    //because in selection, relative coordinates are fucked-up
    if (Object.prototype.hasOwnProperty.call(obj, "_objects")) {
      for (i in obj._objects) {
        KITCHEN.hideObjectTerminals(obj._objects[i]);
      }
    }
  }
};

//Hide terminals of given object and show if KITCHEN.terminalsVisible === false
KITCHEN.hideObjectTerminals = function (obj) {
  KITCHEN.updateObjectTerminals(obj, false);
};

//Bring forward all terminals of given object
KITCHEN.bringForwardObjectTerminals = function (obj) {
  var j;
  var len;

  if (Object.prototype.hasOwnProperty.call(obj, "id") && obj.name !== "terminal") {
    len = obj.terminals.length;

    for (j = 0; j < len; j++) {
      var varrr = KITCHEN.getObjectById(obj.terminals[j]);
      KITCHEN.canvas.bringForward(varrr);
      //bringToFront();
    }
  }
};

//Format object of given product before adding to canvas
KITCHEN.formatShape = function (product, shape, obj) {
  var i;
  var terminals;
  var newTerminal;
  var nTerminals;
  var firstTerminalId;

  shape
    .set({
      name: product,
      left: KITCHEN.width / 2.0,
      top: KITCHEN.height / 2.0,
      lockRotation: true,
    })
    .setCoords();

  if (Object.prototype.hasOwnProperty.call(obj, "scalableX") && !obj.scalableX) {
    shape.set({ lockScalingX: true });
    shape.setControlsVisibility({ ml: false, mt: true, mr: false, mb: true });
  } else {
    shape.set({ lockScalingX: false });
  }

  if (Object.prototype.hasOwnProperty.call(obj, "scalableY") && !obj.scalableY) {
    shape.set({ lockScalingY: true });
    shape.setControlsVisibility({ ml: true, mt: false, mr: true, mb: false });
  } else {
    shape.set({ lockScalingY: false });
  }

  if (Object.prototype.hasOwnProperty.call(obj, "snapPositionX")) {
    shape.set({ snapPositionXInit: obj.snapPositionX * KITCHEN.gridScale });
    shape.set({ snapPositionX: obj.snapPositionX * KITCHEN.gridScale });
  } else {
    shape.set({ snapPositionXInit: 0 });
    shape.set({ snapPositionX: 0 });
  }

  if (Object.prototype.hasOwnProperty.call(obj, "snapPositionY")) {
    shape.set({ snapPositionYInit: obj.snapPositionY * KITCHEN.gridScale });
    shape.set({ snapPositionY: obj.snapPositionY * KITCHEN.gridScale });
  } else {
    shape.set({ snapPositionYInit: 0 });
    shape.set({ snapPositionY: 0 });
  }

  if (Object.prototype.hasOwnProperty.call(obj, "snapX")) {
    shape.set({ snapX: obj.snapX });
  } else {
    shape.set({ snapX: true }); //all objects are snapped by default
  }

  if (Object.prototype.hasOwnProperty.call(obj, "snapY")) {
    shape.set({ snapY: obj.snapY });
  } else {
    shape.set({ snapY: true }); //all objects are snapped by default
  }

  if (Object.prototype.hasOwnProperty.call(obj, "freeRotate")) {
    shape.set({ freeRotate: obj.freeRotate });
  } else {
    shape.set({ freeRotate: false }); //all objects are not free-rotating by default
  }

  if (Object.prototype.hasOwnProperty.call(obj, "terminals")) {
    //var terminalsInit = [];
    terminals = [];
    nTerminals = obj.terminals.length;

    firstTerminalId = KITCHEN.maxId() + 1;

    for (i = 0; i < nTerminals; i += 1) {
      if (typeof KITCHEN.getObjectById(firstTerminalId + i) !== "undefined") {
        console.log("TERMINAL ERROR");
      }

      // console.log("terminal", firstTerminalId+i, KITCHEN.maxId());

      newTerminal = new fabric.Rect({
        id: firstTerminalId + i,
        name: "terminal",
        left: shape.left,
        top: shape.top,
        leftInit: obj.terminals[i].left * KITCHEN.gridScale,
        topInit: obj.terminals[i].top * KITCHEN.gridScale,
        width: KITCHEN.terminalMarkWidth,
        height: KITCHEN.terminalMarkWidth,
        fill: KITCHEN.terminalMarkFill,
        hasRotatingPoint: false,
        stroke: "#aaa",
        selectable: false,
        evented: false,
        hoverCursor: "null",
        snapX: false,
        snapY: false,
        visible: false,
        originX: "center",
        originY: "center",
      });

      newTerminal.toObject = (function (toObject) {
        return function () {
          return fabric.util.object.extend(toObject.call(this), {
            name: this.name,
            id: this.id,
            left: this.left,
            top: this.top,
            leftInit: this.leftInit,
            topInit: this.topInit,
            width: this.width,
            height: this.height,
            fill: this.fill,
            hasRotatingPoint: this.hasRotatingPoint,
            stroke: this.stroke,
            selectable: this.selectable,
            evented: this.evented,
            hoverCursor: this.hoverCursor,
            snapX: false,
            snapY: false,
            visible: this.visible,
            originX: "center",
            originY: "center",
          });
        };
      })(newTerminal.toObject);

      KITCHEN.canvas.add(newTerminal);

      KITCHEN.canvas.bringToFront(newTerminal);

      terminals.push(newTerminal.id);
    }
    shape.set({ terminals: terminals });
  }

  shape.setControlsVisibility({
    bl: false,
    br: false,
    tl: false,
    tr: false,
    mtr: false,
  });
  shape.bringToFront();
  KITCHEN.align(shape);
};

//Returns canvas._objects object by key "id" value
KITCHEN.getObjectById = function (id) {
  for (var i in KITCHEN.canvas._objects) {
    if (KITCHEN.canvas._objects[i].id === id) {
      return KITCHEN.canvas._objects[i];
    }
  }
  return;
};

//Align given object to grid
KITCHEN.align = function (obj) {
  //Object can be object or function
  if (typeof obj.set === "function") {
    if (obj.snapX) {
      obj.set({
        left:
          Math.round((obj.left + (obj.snapPositionX || 0)) / KITCHEN.grid) * KITCHEN.grid -
          (obj.snapPositionX || 0) +
          KITCHEN.widthOffset,
      });
    }
    if (obj.snapY) {
      obj.set({
        top:
          Math.round((obj.top + (obj.snapPositionY || 0)) / KITCHEN.grid) * KITCHEN.grid -
          (obj.snapPositionY || 0) +
          KITCHEN.heightOffset,
      });
    }
  } else {
    if (obj.snapX) {
      obj.left = Math.round(obj.left / KITCHEN.grid) * KITCHEN.grid - (obj.snapPositionX || 0) + KITCHEN.widthOffset;
    }
    if (obj.snapY) {
      obj.top = Math.round(obj.top / KITCHEN.grid) * KITCHEN.grid - (obj.snapPositionY || 0) + KITCHEN.heightOffset;
    }
  }
};

//Toggle visibility of ALL terminals
KITCHEN.toggleAllTerminals = function () {
  if (KITCHEN.terminalsVisible) {
    KITCHEN.hideAllTerminals();
  } else {
    KITCHEN.showAllTerminals();
  }
};

//Show ALL terminals
KITCHEN.showAllTerminals = function () {
  KITCHEN.terminalsVisible = true;

  $("#terminals-btn").toggleClass("active", KITCHEN.terminalsVisible);

  //remove selection; otherwise, relative coordinates are wrong and terminals are drawn not in the right positions
  //KITCHEN.canvas.deactivateAll();

  KITCHEN.canvas.forEachObject(function (obj) {
    KITCHEN.showObjectTerminals(obj, KITCHEN.terminalsVisible);
  });

  KITCHEN.canvas.renderAll();
};

//Hide ALL terminals
KITCHEN.hideAllTerminals = function () {
  KITCHEN.terminalsVisible = false;

  $("#terminals-btn").toggleClass("active", KITCHEN.terminalsVisible);

  KITCHEN.canvas.forEachObject(function (obj) {
    KITCHEN.hideObjectTerminals(obj, KITCHEN.terminalsVisible);
  });

  KITCHEN.canvas.renderAll();
};

//Update ALL terminals
KITCHEN.updateAllTerminals = function () {
  KITCHEN.canvas.forEachObject(function (obj) {
    KITCHEN.updateObjectTerminals(obj, KITCHEN.terminalsVisible);
  });
};

KITCHEN.disableSelection = function () {
  KITCHEN.hoverCursor = "default";
};

KITCHEN.enableSelection = function () {
  KITCHEN.hoverCursor = "move";
};

//tries to resolve duplicate ids
KITCHEN.resolveDuplicateIds = function () {
  var mydict = {};
  var maxid = 0;
  KITCHEN.canvas.forEachObject(function (obj) {
    if (Object.prototype.hasOwnProperty.call(obj, "id")) {
      if (obj.id > maxid) {
        maxid = obj.id;
      }
      if (Object.prototype.hasOwnProperty.call(mydict, obj.id)) {
        mydict[obj.id] = mydict[obj.id] + 1;
      } else {
        mydict[obj.id] = 1;
      }
    }
  });

  KITCHEN.canvas.forEachObject(function (obj) {
    if (Object.prototype.hasOwnProperty.call(obj, "name")) {
      if (obj.name === "BLACK-BOX" || obj.name === "TUBE" || obj.name === "TUBE-16" || obj.name === "TUBE-25" || obj.name === "TUBE-32") {
        if (mydict[obj.id] > 1) {
          obj.id = maxid++;
        }
      }
    }
  });
};

//rescales all objects according to product specifications; EXCEPT tube
KITCHEN.restoreAllSizes = function () {
  var angle;

  KITCHEN.canvas.forEachObject(function (obj) {

    if (obj.name == null) return;
    var productInList = KITCHEN.products.find((element) => element.name === obj.name.name);

    if (Object.prototype.hasOwnProperty.call(obj, "name")) {
      if (
        productInList != null &&
        obj.name.name !== "TUBE" &&
        obj.name.name !== "TUBE-16" &&
        obj.name.name !== "TUBE-25" &&
        obj.name.name !== "TUBE-32" &&
        obj.name.name !== "BLACK-BOX"
      ) {
        angle = obj.angle;

        while (angle < 0) {
          angle += 360;
        }

        if (obj.angle % 180 === 0) {
          obj.scaleToHeight(
            KITCHEN.products.find((element) => element.name === obj.name.name).height * KITCHEN.gridScale
          );
        } else {
          obj.scaleToHeight(
            KITCHEN.products.find((element) => element.name === obj.name.name).width * KITCHEN.gridScale
          );
        }

        KITCHEN.showObjectTerminals(obj);
      }
    }
  });

  KITCHEN.hideAllTerminals();

  KITCHEN.canvas.renderAll();
};

//Move selected object left/top by given number of pixels
KITCHEN.moveSelectedObject = function (left, top) {
  if (KITCHEN.canvas == null) {
    return;
  }
  KITCHEN.lastTarget = KITCHEN.canvas._activeObject;
  if (!KITCHEN.lastTarget) {
    return;
  }
  KITCHEN.lastTarget
    .set({
      top: KITCHEN.lastTarget.top + top,
      left: KITCHEN.lastTarget.left + left,
    })
    .setCoords();

  var obj = KITCHEN.lastTarget;
  var angle = obj.angle;
  var limits = [0, 0, KITCHEN.width - 25.0 * KITCHEN.gridScale, KITCHEN.height - 25.0 * KITCHEN.gridScale];

  while (angle < 0) {
    angle += 360;
  }

  switch (angle % 360) {
    case 0:
      limits[0] -= (obj.width * obj.scaleX) / 2.0;
      limits[1] -= (obj.height * obj.scaleY) / 2.0;
      break;
    case 90:
      limits[0] += (obj.height * obj.scaleY) / 2.0;
      limits[1] -= (obj.width * obj.scaleX) / 2.0;
      limits[2] += obj.height * obj.scaleY;
      break;
    case 180:
      limits[0] = (obj.width * obj.scaleX) / 2.0;
      limits[2] += obj.width * obj.scaleX;

      limits[1] += (obj.height * obj.scaleY) / 2.0;
      limits[3] += obj.height * obj.scaleY;
      break;
    case 270:
      limits[0] -= (obj.height * obj.scaleY) / 2.0;
      limits[1] += (obj.width * obj.scaleX) / 2.0;
      limits[3] += obj.width * obj.scaleX;
      break;
  }

  if (obj.left < limits[0]) {
    obj.left = limits[0];
  }

  if (obj.top < limits[1]) {
    obj.top = limits[1];
  }

  if (obj.left > limits[2]) {
    obj.left = limits[2];
  }

  if (obj.top > limits[3]) {
    obj.top = limits[3];
  }
  KITCHEN.updateObjectTerminals(KITCHEN.lastTarget, null);

  KITCHEN.canvas.renderAll();
};

//Move KITCHEN.lastTarget object forward
KITCHEN.toFrontSelectedObject = function () {
  if (KITCHEN.canvas._activeObject) {
    KITCHEN.canvas._activeObject.bringToFront();
  }
  KITCHEN.canvas.renderAll();
};

//Move KITCHEN.lastTarget object to back
KITCHEN.toBackSelectedObject = function () {
  if (KITCHEN.canvas._activeObject) {
    KITCHEN.canvas._activeObject.sendToBack();
  }
  KITCHEN.canvas.renderAll();
};

KITCHEN.onSettings = function () {
  KITCHEN.canvas.on("mouse:move", function (options) {
    if (KITCHEN.drawLine.active) {
      KITCHEN.drawLine.mouseMove(options);
    }

    if (KITCHEN.measure.active) {
      KITCHEN.measure.mouseMove(options);
    }
  });

  KITCHEN.canvas.on("object:modified", function () {
    KITCHEN.canvasHistory.saveState();
  });

  KITCHEN.canvas.on("object:added", function (options) {
    options.target;
    KITCHEN.canvas.renderAll();
  });

  KITCHEN.canvas.on("selection:cleared", function () {
    KITCHEN.lastTarget = null;
    delete KITCHEN.lastTargetId;
    KITCHEN.updateAllTerminals();
  });

  KITCHEN.canvas.on("object:moving", function (options) {
    var obj = options.target;
    var angle = obj.angle;
    var limits = [0, 0, KITCHEN.width - 25.0 * KITCHEN.gridScale, KITCHEN.height - 25.0 * KITCHEN.gridScale];

    while (angle < 0) {
      angle += 360;
    }

    switch (angle % 360) {
      case 0:
        limits[0] -= (obj.width * obj.scaleX) / 2.0;
        limits[1] -= (obj.height * obj.scaleY) / 2.0;
        break;
      case 90:
        limits[0] += (obj.height * obj.scaleY) / 2.0;
        limits[1] -= (obj.width * obj.scaleX) / 2.0;
        limits[2] += obj.height * obj.scaleY;
        break;
      case 180:
        limits[0] = (obj.width * obj.scaleX) / 2.0;
        limits[2] += obj.width * obj.scaleX;

        limits[1] += (obj.height * obj.scaleY) / 2.0;
        limits[3] += obj.height * obj.scaleY;
        break;
      case 270:
        limits[0] -= (obj.height * obj.scaleY) / 2.0;
        limits[1] += (obj.width * obj.scaleX) / 2.0;
        limits[3] += obj.width * obj.scaleX;
        break;
    }

    KITCHEN.align(obj);

    if (obj.left < limits[0]) {
      obj.left = limits[0];
    }

    if (obj.top < limits[1]) {
      obj.top = limits[1];
    }

    if (obj.left > limits[2]) {
      obj.left = limits[2];
    }

    if (obj.top > limits[3]) {
      obj.top = limits[3];
    }

    var objX, objY;
    var termX, termY;

    //tube snap
    if (Object.prototype.hasOwnProperty.call(obj, "name") && obj.name != null) {
      if (obj.name.name === "TUBE" || obj.name.name === "TUBE-16" || obj.name.name === "TUBE-25" || obj.name.name === "TUBE-32") {
        //has to be ===  TODO snap to objects
        var objectsCount = KITCHEN.canvas.getObjects().length;
        for (var i = 0; i < objectsCount; i++) {
          if (KITCHEN.canvas.item(i).name === "terminal") {
            termX = KITCHEN.canvas.item(i).left;
            termY = KITCHEN.canvas.item(i).top;
            objX = obj.left;
            objY = obj.top;

            angle = obj.angle;
            while (angle < 0) {
              angle = angle + 360;
            }

            obj.angle = angle % 180;

            switch (obj.angle) {
              case 0:
                if (Math.pow(objX - termX, 2.0) + Math.pow(objY - termY, 2.0) < Math.pow(KITCHEN.grid, 2.0)) {
                  obj.left = termX + (-obj.width * obj.scaleX) / 2.0;
                  obj.top = termY;
                  break;
                }
                if (
                  Math.pow(objX - termX, 2.0) + Math.pow(objY + obj.height * obj.scaleY - termY, 2.0) <
                  Math.pow(KITCHEN.grid, 2.0)
                ) {
                  obj.left = termX + (-obj.width * obj.scaleX) / 2.0;
                  obj.top = termY - obj.height * obj.scaleY;
                  break;
                }
                break;
              case 90:
                if (Math.pow(objX - termX, 2.0) + Math.pow(objY - termY, 2.0) < Math.pow(KITCHEN.grid, 2.0)) {
                  obj.top = termY + (-obj.width * obj.scaleX) / 2.0;
                  obj.left = termX;
                  break;
                }

                if (
                  Math.pow(objX - obj.height * obj.scaleY - termX, 2.0) + Math.pow(objY - termY, 2.0) <
                  Math.pow(KITCHEN.grid, 2.0)
                ) {
                  obj.top = termY + (-obj.width * obj.scaleX) / 2.0;
                  obj.left = termX + obj.height * obj.scaleY;
                  break;
                }

                break;
            }
          }
        }
      }
    }

    KITCHEN.updateObjectTerminals(obj, KITCHEN.terminalsVisible);
  });

  KITCHEN.canvas.on("object:scaling", function (options) {
    var obj = options.target;
    if (Object.prototype.hasOwnProperty.call(obj, "name")) {
      if (obj.name.name === "BLACK-BOX") {
        $("#product_select").trigger("change");
        if (!obj.strokeWidthUnscaled && obj.strokeWidth) {
          obj.strokeWidthUnscaled = obj.strokeWidth;
        }
        if (obj.strokeWidthUnscaled) {
          obj.strokeWidth = obj.strokeWidthUnscaled / obj.scaleX;
        }
      }
    }

    store.commit("SET_PlannerActiveObjectSize", KITCHEN.canvas._activeObject);
    store.commit("SET_PlannerActiveObject", KITCHEN.canvas._activeObject);

  });

  KITCHEN.canvas.on("object:rotating", function (options) {
    options.target.setCoords();
    KITCHEN.showObjectTerminals(options.target);
  });

  KITCHEN.canvas.on("mouse:down", function (options) {
    $("#save-success-indicator").animate({ width: "hide" });
    $("#save-error-indicator").animate({ width: "hide" });
    //For drawing lines

    if (KITCHEN.drawLine.active && options.button === 3) {
      KITCHEN.drawLine.terminate();

      $("#showColors").click();
      KITCHEN.drawLine.deactivate();
      $("#drawline-btn").toggleClass("active");
    }

    if (KITCHEN.drawLine.active) {
      KITCHEN.drawLine.mouseDown(options);
    }

    if (KITCHEN.measure.active) {
      KITCHEN.measure.mouseDown(options);
    }
  });

  KITCHEN.canvas.on("selection:created", function () {

    if (KITCHEN.canvas._activeObject.name != null) {
      store.commit("SET_PlannerActiveObjectName", KITCHEN.canvas._activeObject.name);
      store.commit("SET_PlannerActiveObject", KITCHEN.canvas._activeObject);
    } else {
      KITCHEN.canvas._activeObject.setControlsVisibility({
        bl: false,
        br: false,
        tl: false,
        tr: false,
        ml: false,
        mt: false,
        mr: false,
        mb: false,
        mtr: false,
      });
    }
  });

  KITCHEN.canvas.on("selection:updated", function () {
    store.commit("SET_PlannerActiveObjectName", KITCHEN.canvas._activeObject.name);
    store.commit("SET_PlannerActiveObject", KITCHEN.canvas._activeObject);
  });
};

//Delete KITCHEN.lastTarget object and its terminals
KITCHEN.removeSelectedObject = function () {
  if (!KITCHEN.canvas._activeObject) return;
  if (!KITCHEN.canvas.getActiveObjects()) return;

  KITCHEN.removeObject(KITCHEN.canvas._activeObject);
  KITCHEN.removeObject(KITCHEN.canvas.getActiveObjects());

  KITCHEN.canvas.requestRenderAll();

  KITCHEN.canvasHistory.saveState();

  return;
};

//removes given object
KITCHEN.removeObject = function (obj) {
  var i;

  if (obj === null) {
    return;
  }

  if (Object.prototype.hasOwnProperty.call(obj, "_objects")) {
    if (obj.type === "group") {
      obj._restoreObjectsState();
      KITCHEN.canvas.remove(obj);
    }

    obj.forEachObject(function (o) {
      KITCHEN.removeObject(o);
    });
  } else {
    for (i in obj.terminals) {
      KITCHEN.canvas.remove(KITCHEN.getObjectById(obj.terminals[i]));
    }
    obj.terminals = [];

    KITCHEN.canvas.remove(obj);
    return;
  }
};

//Rotate KITCHEN.lastTarget object by given angle in deg
KITCHEN.rotateSelectedObject = function (angle) {
  var obj;
  var temp_snap;

  KITCHEN.lastTarget = KITCHEN.canvas._activeObject;

  if (!KITCHEN.lastTarget) {
    return;
  }

  var initAngle = KITCHEN.lastTarget.angle;

  KITCHEN.hideObjectTerminals(KITCHEN.lastTarget);

  KITCHEN.lastTarget.rotate(initAngle + angle);

  if (Object.prototype.hasOwnProperty.call(KITCHEN.lastTarget, "_objects")) {
    for (obj in KITCHEN.lastTarget._objects) {
      initAngle = KITCHEN.lastTarget._objects[obj].angle - angle;

      Math.abs(
        KITCHEN.lastTarget._objects[obj].width * Math.cos(deg_to_rad(initAngle)) +
        KITCHEN.lastTarget._objects[obj].height * Math.sin(deg_to_rad(initAngle))
      );
      Math.abs(
        KITCHEN.lastTarget._objects[obj].width * Math.sin(deg_to_rad(initAngle)) +
        KITCHEN.lastTarget._objects[obj].height * Math.cos(deg_to_rad(initAngle))
      );

      temp_snap = KITCHEN.lastTarget._objects[obj].snapX;
      KITCHEN.lastTarget._objects[obj].snapX = KITCHEN.lastTarget._objects[obj].snapY;
      KITCHEN.lastTarget._objects[obj].snapY = temp_snap;

      temp_snap = KITCHEN.lastTarget._objects[obj].snapPositionX;
      KITCHEN.lastTarget._objects[obj].snapPositionX = KITCHEN.lastTarget._objects[obj].snapPositionY;
      KITCHEN.lastTarget._objects[obj].snapPositionY = temp_snap;

      if (angle == 90) {
        KITCHEN.lastTarget._objects[obj].snapPositionX = -KITCHEN.lastTarget._objects[obj].snapPositionX;
      }
      if (angle == -90) {
        KITCHEN.lastTarget._objects[obj].snapPositionY = -KITCHEN.lastTarget._objects[obj].snapPositionY;
      }
    }
  } else {
    //if object is object

    Math.abs(
      KITCHEN.lastTarget.width * Math.cos(deg_to_rad(initAngle)) +
      KITCHEN.lastTarget.height * Math.sin(deg_to_rad(initAngle))
    );
    Math.abs(
      KITCHEN.lastTarget.width * Math.sin(deg_to_rad(initAngle)) +
      KITCHEN.lastTarget.height * Math.cos(deg_to_rad(initAngle))
    );

    temp_snap = KITCHEN.lastTarget.snapX;
    KITCHEN.lastTarget.snapX = KITCHEN.lastTarget.snapY;
    KITCHEN.lastTarget.snapY = temp_snap;

    temp_snap = KITCHEN.lastTarget.snapPositionX;
    KITCHEN.lastTarget.snapPositionX = KITCHEN.lastTarget.snapPositionY;
    KITCHEN.lastTarget.snapPositionY = temp_snap;

    if (angle == 90) {
      KITCHEN.lastTarget.snapPositionX = -KITCHEN.lastTarget.snapPositionX - 1;
    }
    if (angle == -90) {
      KITCHEN.lastTarget.snapPositionY = -KITCHEN.lastTarget.snapPositionY - 1;
    }
  }

  KITCHEN.align(KITCHEN.lastTarget);
  KITCHEN.showObjectTerminals(KITCHEN.lastTarget);

  KITCHEN.lastTarget.setCoords();

  KITCHEN.canvas.renderAll();
  KITCHEN.canvasHistory.saveState("rotate");
};

KITCHEN.saveAsPng = async function (name, download) {

  var can = document.getElementById("table-merged");
  can.getContext("2d");
  await can.toBlob(
    (blob) => {
      //will clone the tableCanvas as SVG and puts it in the back of tabletop canvas
      //after exporting to png, will be deleted
      var tableSvg = KITCHEN.tableCanvas.toSVG({
        suppressPreamble: true,
      });

      URL.createObjectURL(blob);

      //copy of table canvas
      JSON.stringify(KITCHEN.tableCanvas);

      var tableSvgImage = "data:image/svg+xml; charset=utf8, " + encodeURIComponent(tableSvg.split('svg11.dtd">')[0]);

      var tableCloneObject;

      fabric.Image.fromURL(tableSvgImage, function (o) {
        KITCHEN.canvas.add(o);
        tableCloneObject = o;
        o.sendToBack();
        KITCHEN.canvas.renderAll();
        $("#loader").show();
      });

      setTimeout(function () {
        var tableTop = KITCHEN.canvas.toDataURL({
          format: "png",
          multiplier: 3,
          width: can.width,
          height: can.height,
        });

        store.commit("SET_TempImage", tableTop);

        if (download) {
          var link = document.createElement("a");
          link.download = name + ".png";
          link.href = tableTop;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }

        KITCHEN.canvas.remove(tableCloneObject);
      }, 160);
    },
    "image/png",
    1.0
  );
};

KITCHEN.saveScheme = function () {
  return KITCHEN.canvas.toJSON();
};

KITCHEN.loadSettingsFromJSON = function (input) {
  KITCHEN.canvas.loadFromJSON(input.canvas);

  //delete current table
  KITCHEN.tableCanvas.clear();
  KITCHEN.tableCanvas.renderAll();

  //applying grid options
  KITCHEN.grid = input.grid;
  KITCHEN.gridSpacing = input.gridSpacing;
  KITCHEN.gridScale = KITCHEN.grid / KITCHEN.gridSpacing;
  KITCHEN.tableParameters = input.tableParameters;
  KITCHEN.tableDefined = true;

  if (Object.prototype.hasOwnProperty.call(input, "layoutSettings")) {
    KITCHEN.layoutSettings = input.layoutSettings;
  }

  //draw new table
  KITCHEN.drawTable(input.tableParameters);

  KITCHEN.canvas.renderAll();
  KITCHEN.updateObjectTerminals;
  //loading might take some time, let's wait before rendering
  setTimeout(function () {
    KITCHEN.canvas.forEachObject(function (obj) {
      if (obj.name != null &&
        obj.name !== "terminal" &&
        obj.name.name !== "TUBE" &&
        obj.name.name !== "TUBE-16" &&
        obj.name.name !== "TUBE-25" &&
        obj.name.name !== "TUBE-32" &&
        obj.name.name !== "BLACK-BOX"
      ) {
        var test = KITCHEN.products.find((element) => element.name === obj.name);
        obj.name = test;
      }
      if (obj.type === "group" && obj._objects.length > 3) {
        obj._objects[3].styles = "";
      }
    });
    if (KITCHEN.terminalsVisible) {
      KITCHEN.showAllTerminals();
    } else {
      KITCHEN.hideAllTerminals();
    }
    KITCHEN.canvasHistory.reset();
  }, 500);
};

KITCHEN.save = function () {
  var output = {};

  //some properties are not exported when serializing to JSON by default
  KITCHEN.canvas.forEachObject(function (obj) {
    if (obj.name == null) {
      obj.name = "other";
    }

    switch (obj.name) {
      case "terminal":
      case "BLACK-BOX":
        obj.toObject = (function (toObject) {
          return function () {
            return fabric.util.object.extend(toObject.call(this), {
              name: this.name,
              id: this.id,
              leftInit: this.leftInit,
              topInit: this.topInit,
              snapX: false,
              snapY: false,
              _controlsVisibility: {
                mb: true,
                ml: true,
                mr: true,
                mt: true,
              }
            });
          };
        })(obj.toObject);
        break;
      case "other":
        obj.toObject = (function (toObject) {
          return function () {
            return fabric.util.object.extend(toObject.call(this), {
              lockRotation: this.lockRotation,
              name: this.name,
              id: this.id,
              snapX: this.snapX,
              snapY: this.snapY,
              snapPositionY: this.snapPositionY,
              snapPositionX: this.snapPositionX,
              lockScalingX: this.lockScalingX,
              lockScalingY: this.lockScalingY,
              freeRotate: this.freeRotate,
              terminals: this.terminals,
              paths: this.paths,
              _controlsVisibility: this._controlsVisibility,
              styles: "",
            });
          };
        })(obj.toObject);
        break;
      default:
        obj.toObject = (function (toObject) {
          return function () {
            return fabric.util.object.extend(toObject.call(this), {
              name: this.name.name,
              lockRotation: this.lockRotation,
              id: this.id,
              snapX: this.snapX,
              snapY: this.snapY,
              snapPositionY: this.snapPositionY,
              snapPositionX: this.snapPositionX,
              lockScalingX: this.lockScalingX,
              lockScalingY: this.lockScalingY,
              freeRotate: this.freeRotate,
              terminals: this.terminals,
              paths: this.paths,
              _controlsVisibility: this._controlsVisibility,
            });
          };
        })(obj.toObject);
        break;
    }
  });

  output.canvas = KITCHEN.canvas.toDatalessJSON();

  //keep table parameters
  output.tableParameters = KITCHEN.tableParameters;

  output.products = KITCHEN.exportObjectList();

  if (!KITCHEN.layoutSettings.notificationSent) {
    // var recipient_email = KITCHEN.layoutSettings.email;
    KITCHEN.layoutSettings.notificationSent = true;
  }

  output.layoutSettings = KITCHEN.layoutSettings;

  output.grid = KITCHEN.grid;
  output.gridSpacing = KITCHEN.gridSpacing;

  if (!window.localStorage) {
    $("#png-fail-indicator").show();
    setTimeout(function () {
      $("#png-fail-indicator").animate({ width: "hide" });
    }, 2000);
  }
  // to PNG
  //var dt = KITCHEN.makePNG();

  var can = document.getElementById("table-merged");
  can.getContext("2d");

  return output;
};

KITCHEN.exportObjectList = function () {

  var j;
  var items = [];
  var product;
  var size;
  var box_size;

  // LIST OF items
  KITCHEN.canvas.forEachObject(function (obj) {
    if (Object.prototype.hasOwnProperty.call(obj, "name")) {
      product = KITCHEN.products.find((element) => element.name === obj.name.name);
      if (product != null) {
        switch (obj.name.name) {
          case "TUBE":
          case "TUBE-16":
          case "TUBE-25":
          case "TUBE-32":
            size = Math.floor((obj.scaleY * obj.height) / KITCHEN.gridScale) + "mm";
            box_size = "-";
            break;
          case "BLACK-BOX":
            size =
              Math.floor((obj.scaleX * obj.width) / KITCHEN.gridScale) +
              "mm x " +
              Math.floor((obj.scaleY * obj.height) / KITCHEN.gridScale) +
              "mm";
            box_size = "-";
            break;
          default:
            size = product.width + "mm x " + product.height + "mm";
            if (product.boxWidth) {
              box_size = product.boxWidth + "mm x " + product.boxHeight + "mm";
            } else {
              box_size = "-";
            }
            break;
        }

        items.push({
          id: items.length + 1,
          name: product.longName,
          code: obj.name.name,
          size: size,
          boxSize: box_size,
          navCode: product.navCode,
        });
      }
    }
  });

  //SORTING THE list
  function compare(item1, item2) {
    if (item1.name < item2.name) {
      return -1;
    }
    if (item1.name > item2.name) {
      return 1;
    }
    return 0;
  }
  items.sort(compare);

  //creating numeration of sorted array
  for (j = 0; j < items.length; j += 1) {
    items[j].id = j + 1;
  }

  return items;
};

fabric.util.addListener(document.body, "keydown", function (options) {
  var step = KITCHEN.grid;

  var key = options.which || options.keyCode; // key detection
  switch (key) {
    //shift
    case 16:
      KITCHEN.shiftPressed = true;
      break;
    case 17:
      KITCHEN.ctrlPressed = true;
      break;
    //delete
    case 46:
      var activeObject = KITCHEN.canvas.getActiveObject();
      if (Object.prototype.hasOwnProperty.call(activeObject, 'type') && activeObject.type === "i-text") {

        var text = activeObject.text;
        var selectionStart = activeObject.selectionStart;

        if (selectionStart < text.length) {
          const updatedText = text.slice(0, selectionStart) + text.slice(selectionStart + 1);
          activeObject.set('text', updatedText);
        }

      } else {
        KITCHEN.hideAllTerminals();
        KITCHEN.removeSelectedObject();
      }
      break;
    //move left
    case 37:
      if (KITCHEN.shiftPressed) {
        KITCHEN.moveSelectedObject(-step / 10, 0);
      } else {
        KITCHEN.moveSelectedObject(-step, 0);
      }
      break;
    //move up
    case 38:
      if (KITCHEN.shiftPressed) {
        KITCHEN.moveSelectedObject(0, -step / 10);
      } else {
        KITCHEN.moveSelectedObject(0, -step);
      }
      break;
    //move right
    case 39:
      if (KITCHEN.shiftPressed) {
        KITCHEN.moveSelectedObject(step / 10, 0);
      } else {
        KITCHEN.moveSelectedObject(step, 0);
      }
      break;
    //move down
    case 40:
      if (KITCHEN.shiftPressed) {
        KITCHEN.moveSelectedObject(0, step / 10);
      } else {
        KITCHEN.moveSelectedObject(0, step);
      }
      break;
    //PageUp - to front
    case 33:
      KITCHEN.toFrontSelectedObject();
      break;
    //PageDown - to back
    case 34:
      KITCHEN.toBackSelectedObject();
      break;
    //Esc - stop line drawing
    case 27:
      if (KITCHEN.drawLine.active) {
        KITCHEN.drawLine.terminate();
        KITCHEN.drawLine.deactivate();
        $("#drawline-btn").toggleClass("active");
        $("#showColors").click();
      }
      break;
    // "C" add new
    case 67:
      if (KITCHEN.ctrlPressed) KITCHEN.copiedObject = store.state.plannerActiveObjectName;
      break;
    case 86:
      if (KITCHEN.ctrlPressed && KITCHEN.copiedObject != null) KITCHEN.addProduct(KITCHEN.copiedObject);
      break;
  }
});

fabric.util.addListener(document.body, "keyup", function (options) {
  var key = options.which || options.keyCode; // key detection
  switch (key) {
    //shift
    case 16:
      KITCHEN.shiftPressed = false;
      break;
    case 17:
      KITCHEN.ctrlPressed = false;
      break;
  }
});

KITCHEN.loadOldSchemeFromJSON = function (input) {
  KITCHEN.canvas.loadFromJSON(input.canvas);

  //delete current table
  KITCHEN.tableCanvas.clear();
  KITCHEN.tableCanvas.renderAll();

  //applying grid options
  KITCHEN.grid = input.grid;
  KITCHEN.gridSpacing = input.gridSpacing;
  KITCHEN.gridScale = KITCHEN.grid / KITCHEN.gridSpacing;
  KITCHEN.tableParameters = input.tableParameters;
  KITCHEN.tableDefined = true;

  if (Object.prototype.hasOwnProperty.call(input, "layoutSettings")) {
    KITCHEN.layoutSettings = input.layoutSettings;
  }

  if (input.tableParameters != null) KITCHEN.drawTable(input.tableParameters); //draw new table

  KITCHEN.canvas.renderAll();
  KITCHEN.updateObjectTerminals;
  //loading might take some time, let's wait before rendering
  setTimeout(function () {
    KITCHEN.canvas.forEachObject(function (obj) {
      if (obj.type === "group" && obj._objects.length > 3) {
        obj._objects[3].styles = "";
      }
      if (obj.name === "terminal") {
        KITCHEN.removeObject(obj);
      }
      if (obj.type === "i-text") {
        obj.styles = "";
      }
      if (
        obj.name != null &&
        obj.name !== "terminal" &&
        obj.name.name !== "TUBE" &&
        obj.name.name !== "TUBE-16" &&
        obj.name.name !== "TUBE-25" &&
        obj.name.name !== "TUBE-32" &&
        obj.name.name !== "BLACK-BOX" &&
        obj.name !== "ruler" &&
        obj.name !== "line" &&
        obj.name !== "TUBE" &&
        obj.name !== "TUBE-16" &&
        obj.name !== "TUBE-25" &&
        obj.name !== "TUBE-32" &&
        obj.name !== "BLACK-BOX"
      ) {
        if (names[obj.name] != null) obj.name = names[obj.name];
        var test = KITCHEN.products.find((element) => element.name === obj.name);
        obj.name = test;
        var left = obj.left;
        var top = obj.top;
        var angle = obj.angle;
        KITCHEN.removeObject(obj);
        KITCHEN.addProduct(test, left, top, angle);
        KITCHEN.canvas.renderAll();
      }
      if (obj.name === "TUBE" || obj.name === "TUBE-16" || obj.name === "TUBE-25" || obj.name === "TUBE-32") {
        KITCHEN.removeObject(obj);
        var ratioY = obj.height * obj.scaleY;

        KITCHEN.addProduct(KITCHEN.products.find((element) => element.name === obj.name), obj.left, obj.top, obj.angle, ratioY);

        KITCHEN.canvas.renderAll();
      }


    });
    if (KITCHEN.terminalsVisible) {
      KITCHEN.showAllTerminals();
    } else {
      KITCHEN.hideAllTerminals();
    }
    //KITCHEN.resolveDuplicateIds();
    KITCHEN.restoreAllSizes();

    KITCHEN.canvasHistory.reset();
  }, 500);

};

KITCHEN.toggleTable = function (bool) {
  if (bool) {
    KITCHEN.tableCanvas.forEachObject(function (obj) {
      KITCHEN.tableCanvas.remove(obj);
    });
  } else {
    KITCHEN.drawTable(KITCHEN.tableParameters);
  }
  KITCHEN.tableCanvas.renderAll();
};

const names = {
  "TP-ORPHEUS-VIS": "ORPHEUS-VIS",
  "TOPAS-White": "TP-TOPAS-White",
  "TOPAS-C": "TP-TOPAS-C",
  "ON-ORPHEUS-N": "ORPHEUS-N",
  "OF-ORPHEUS-F": "ORPHEUS-F",
  "OF-ORPHEUS-F-COMPR": "ORPHEUS-F-COMPR",
  "OP-ORPHEUS-PS": "ORPHEUS-PS",
  "OT-ORPHEUS-TWINS": "ORPHEUS-TWINS",
  "OH-ORPHEUS-HP+DFG+MW": "ORPHEUS-HP+DFG+MW",
  "OH-ORPHEUS-HP": "ORPHEUS-HP",
  "OH-ORPHEUS-ONE-HP": "ORPHEUS-ONE-HP",
  "OR-ORPHEUS-ONE": "ORPHEUS-ONE",
  "AC-GECO-V2": "GECO",
  "TP-SHBC": "SHBC",
  "OR-ORPHEUS": "ORPHEUS",
  "LR-DFG": "LYRA-DFP",
  "LR-SHG": "LYRA-SHG/DFG"
}