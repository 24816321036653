import { uc_to_omega, uc_omega_to } from "@/tools/UnitConverterOmega";
import { bc_omega_to, bc_to_omega } from "@/tools/BandwidthConverterOmega";
import { bc_FWHM_to, bc_to_FWHM } from "@/tools/BandwidthConverterFWHM";

const c = 299792458;

export function UpdateParameter(caller, bandwidthConverterParameters) {
  if (typeof bandwidthConverterParameters === "undefined") return bandwidthConverterParameters;
  switch (caller) {
    case "deltalambda":
    case "lambda":
      if (bandwidthConverterParameters.lambda === null) break;
      bandwidthConverterParameters.k = uc_omega_to(
        uc_to_omega(bandwidthConverterParameters.lambda, "nm"),
        "cm-1"
      ).toFixed(5);

      if (bandwidthConverterParameters.deltalambda === null) break;
      bandwidthConverterParameters.deltak = bc_omega_to(
        bc_to_omega(bandwidthConverterParameters.deltalambda, "nm", bandwidthConverterParameters.lambda),
        "cm-1",
        bandwidthConverterParameters.lambda
      ).toFixed(5);
      UpdateParameter("TBP", bandwidthConverterParameters);
      break;

    case "k":
    case "deltak":
      if (bandwidthConverterParameters.lambda === null) break;
      bandwidthConverterParameters.lambda = uc_omega_to(
        uc_to_omega(bandwidthConverterParameters.k, "cm-1"),
        "nm"
      ).toFixed(5);
      UpdateParameter("TBP", bandwidthConverterParameters);

      if (bandwidthConverterParameters.lambda === null) break;
      bandwidthConverterParameters.deltalambda = bc_omega_to(
        bc_to_omega(bandwidthConverterParameters.deltak, "cm-1", bandwidthConverterParameters.lambda),
        "nm",
        bandwidthConverterParameters.lambda
      ).toFixed(5);
      UpdateParameter("TBP", bandwidthConverterParameters);

      break;

    case "TBP":
      if (bandwidthConverterParameters.TBP < 0.0) {
        bandwidthConverterParameters.deltat = null;
        return;
      }
      bandwidthConverterParameters.deltat = (
        (bandwidthConverterParameters.TBP / bandwidthConverterParameters.deltak / c) *
        1.0e13
      ).toFixed(5);

      break;

    case "deltat":
      if (bandwidthConverterParameters.deltat < 0.0) {
        bandwidthConverterParameters.deltak = null;
        bandwidthConverterParameters.deltalambda = null;
        return;
      }
      bandwidthConverterParameters.deltak =
        (bandwidthConverterParameters.TBP / bandwidthConverterParameters.deltat / c) * 1.0e13;
      bandwidthConverterParameters.deltalambda = bc_omega_to(
        bc_to_omega(bandwidthConverterParameters.deltak, "cm-1", bandwidthConverterParameters.lambda),
        "nm",
        bandwidthConverterParameters.lambda
      ).toFixed(5);
      break;
  }
  return bandwidthConverterParameters;
}

export function UpdateBWType(caller, bandwidthConverterParameters) {
  switch (caller) {
    case "lambda":
      bandwidthConverterParameters.deltalambda = bc_FWHM_to(
        bc_to_FWHM(bandwidthConverterParameters.deltalambda, bandwidthConverterParameters.lambda_bw_old_type),
        bandwidthConverterParameters.lambda_bw_type
      ).toFixed(5);
      bandwidthConverterParameters.lambda_bw_old_type = bandwidthConverterParameters.lambda_bw_type;
      break;
    case "k":
      bandwidthConverterParameters.deltak = bc_FWHM_to(
        bc_to_FWHM(bandwidthConverterParameters.deltak, bandwidthConverterParameters.k_bw_old_type),
        bandwidthConverterParameters.k_bw_type
      ).toFixed(5);
      bandwidthConverterParameters.k_bw_old_type = bandwidthConverterParameters.k_bw_type;
      break;
  }
  return bandwidthConverterParameters;
}
