<template lang="">
  <div
    id="pulsebroadening"
    ng-controller="diffractionCtrl"
    class="row"
  >
    <!-- TITLE -->
    <div class="col-lg-12 col-md-12 col-xs-12 settings">
      <h3>
        Pulse elongation with known GDD
        <small><span
          data-toggle="collapse"
          data-target="#info_pulsebroadening"
          class="glyphicon glyphicon-info-sign info-glyph"
        /></small>
      </h3>
    </div>

    <!-- INPUT/OUTPUT -->
    <div class="col-lg-6 col-md-6 col-xs-12 settings">
      <div
        id="info_pulsebroadening"
        class="collapse"
      >
        <p>
          For given <abbr title="Group delay dispersion">GDD</abbr>, dispersed pulse length <math-jax latex=" \Delta t " /> is related to
          the initial pulse length <math-jax latex=" \Delta t_0 " /> as 
          <math-jax
            latex="\Delta t = \Delta t_0 \sqrt{1 + \left (4 \ln 2
          \frac{\mathrm{GDD}}{\Delta t_0^2}\right)^2}."
            :block="true"
          />
          In a situation where the GDD is fixed (an optical fiber or fixed free-space compressor) and the input bandwidth <math-jax
            latex="
          \Delta t_0 "
          /> can be adjusted, the shortest pulse duration that can be obtained is 
          <math-jax
            latex="
          \Delta t = \sqrt{2} \Delta t_0 "
          />, when <math-jax latex=" \Delta t_0 = 2\sqrt{\ln 2 \cdot \mathrm{GDD}}. " />
        </p>
      </div>

      <div class="row bottom_align_row">
        <!-- TIME -->
        <div class="col-xs-4">
          <h5 for="deltat_text">
            Initial pulse length <small>FWHM</small>
          </h5>
          <div class="input-group">
            <input
              id="deltat_text"
              v-model="deltat"
              step="1"
              type="number"
              class="form-control indicator"
            >
            <span class="input-group-addon">fs</span>
          </div>
        </div>

        <!-- GDD -->
        <div class="col-xs-4 no-left-padding">
          <h5 for="GDD_text">
            GDD
          </h5>
          <div class="input-group">
            <input
              id="GDD_text"
              v-model="GDD"
              step="1"
              type="number"
              class="form-control indicator"
            >
            <span class="input-group-addon">fs&sup2;</span>
          </div>
        </div>

        <!-- TIME RESULT -->
        <div class="col-xs-4 no-right-padding">
          <h5 for="deltat_after_text">
            Dispersed pulse length <small>FWHM</small>
          </h5>
          <div class="input-group">
            <input
              id="deltat_after_text"
              v-model="deltat_after"
              step="1"
              type="number"
              class="form-control indicator"
              readonly
            >
            <span class="input-group-addon">fs</span>
          </div>
        </div>
      </div>
    </div>
    <!-- PLOT -->
    <div class="col-lg-6 col-md-6 col-xs-12 settings">
      <div id="pulse_broadening_plot" />
    </div>
  </div>
</template>
<script>
import { drawPlotD3 } from "@/tools/Plot.js";

export default {
  data() {
    return {
      deltat: 50,
      GDD: 1000,
    };
  },
  computed: {
    deltat_after() {
      return Math.round10(
        this.deltat * Math.sqrt(1.0 + Math.pow((4.0 * Math.log(2.0) * this.GDD) / Math.pow(this.deltat, 2), 2)),
        0
      );
    },
  },
  watch: {
    GDD() {
      this.drawPlot();
    },
    deltat() {
      this.drawPlot();
    },
  },
  mounted() {
    this.drawPlot();
  },
  methods: {
    drawPlot() {
      if (this.GDD <= 10.0) return;
      var deltat_min = 2.0;
      var deltat_max = 5000.0;

      var deltat = deltat_min;
      var deltat_at_min = Math.sqrt(4 * Math.log(2) * Math.abs(this.GDD));

      var x = [];

      //making x axis
      while (deltat < deltat_max) {
        x.push(deltat);

        if (Math.abs(deltat - deltat_at_min) < deltat_at_min) {
          deltat += 1.0;
        } else {
          if (Math.abs(deltat - deltat_at_min) < deltat_at_min * 2.0) {
            deltat += 10.0;
          } else {
            if (Math.abs(deltat - deltat_at_min) < 10.0 * deltat_at_min) {
              deltat += 100.0;
            } else {
              deltat += 1000.0;
            }
          }
        }
      }

      var fun_deltat_template = function (GDD) {
        return function (deltat) {
          return deltat * Math.sqrt(1.0 + Math.pow((4.0 * Math.log(2.0) * GDD) / Math.pow(deltat, 2), 2));
        };
      };

      drawPlotD3(
        x,
        [
          {
            title: "GDD=" + this.GDD + " fs\u00B2",
            fun: fun_deltat_template(this.GDD),
            tooltipfun: function (x, y) {
              return "in: " + x.toFixed(0) + " fs, out: " + y.toFixed(0) + " fs";
            },
          },
        ],
        "pulse_broadening_plot",
        { title: "Initial pulse duration", units: "fs" },
        { title: "Dispersed pulse duration, fs" },
        { hAxis: { logScale: true }, vAxis: { logScale: true } }
      );
    },
  },
};
</script>
<style lang=""></style>
