<template lang="">
  <div
    id="pulseintensity"
    class="row"
  >
    <!-- TITLE -->
    <div class="col-lg-12 col-md-12 col-xs-12 settings">
      <h3>Pulse peak intensity and fluence</h3>
    </div>
    <!-- INFO/OUTPUT -->
    <div class="col-lg-6 col-md-6 col-xs-12">
      <div class="row">
        <div class="col-xs-6">
          <h5 for="fluence_text">
            Peak fluence
            <span
              class="glyphicon glyphicon-info-sign info-glyph"
              data-toggle="modal"
              data-target="#description_peak_fluence"
            />
          </h5>
          <div class="input-group">
            <input
              id="fluence_text"
              v-model="fluence"
              type="number"
              class="form-control indicator"
              readonly
            >
            <span class="input-group-addon">&mu;J/cm&sup2;</span>
          </div>
        </div>
      </div>
      <div class="row bottom_align_row">
        <div class="col-xs-6">
          <h5 for="intensity_text">
            Peak intensity <small>Gaussian pulse</small>&nbsp;
            <span
              class="glyphicon glyphicon-info-sign info-glyph"
              data-toggle="modal"
              data-target="#description_peak_intensity_gaussian"
            />
          </h5>
          <div class="input-group">
            <input
              id="intensity_text"
              v-model="intensity"
              type="number"
              class="form-control indicator"
              readonly
            >
            <span class="input-group-addon">GW/cm&sup2;</span>
          </div>
        </div>
        <div class="col-xs-6">
          <h5 for="intensity_text">
            Peak intensity <small>sech&sup2; pulse</small>&nbsp;
            <span
              class="glyphicon glyphicon-info-sign info-glyph"
              data-toggle="modal"
              data-target="#description_peak_intensity_sech2"
            />
          </h5>
          <div class="input-group">
            <input
              id="intensity_sech2_text"
              v-model="intensity_sech2"
              type="number"
              class="form-control indicator"
              readonly
            >
            <span class="input-group-addon">GW/cm&sup2;</span>
          </div>
        </div>
      </div>
    </div>
    <!-- INPUT -->
    <div class="col-lg-6 col-md-6 col-xs-12 settings">
      <div class="row">
        <!-- ENERGY -->
        <div class="col-xs-6">
          <h5 for="E_text">
            Pulse energy
          </h5>
          <div class="input-group">
            <input
              id="E_text"
              v-model="E"
              min="0"
              step="1"
              type="number"
              class="form-control indicator"
            >
            <span class="input-group-addon">&mu;J</span>
          </div>
        </div>
        <!-- LENGTH  -->
        <div class="col-lg-6 col-xs-6">
          <h5 for="deltat_text">
            Pulse length <small>FWHM</small>
          </h5>
          <div class="input-group">
            <input
              id="deltat_text"
              v-model="deltat"
              min="0"
              step="1"
              type="number"
              class="form-control indicator"
            >
            <span class="input-group-addon">fs</span>
          </div>
        </div>
      </div>
      <div class="row bottom_align_row">
        <!-- DIAMETER -->
        <div class="col-lg-6 col-xs-6">
          <h5 for="D_text">
            Beam diameter <small>[mm]</small>
          </h5>
          <div class="input-group">
            <span class="input-group-addon">at e&#8315;&sup2;:</span>
            <input
              v-model="D_e_2"
              min="0"
              step="1"
              type="number"
              class="form-control indicator"
            >
          </div>
          <div class="input-group">
            <span class="input-group-addon">at FWHM:</span>
            <input
              v-model="D_FWHM"
              min="0"
              step="1"
              type="number"
              class="form-control indicator"
            >
          </div>
        </div>

        <!-- (SUPER)GAUSSIAN PARAMETER -->
        <div class="col-lg-6 col-xs-6">
          <h5 for="n_text">
            Super-Gaussian coefficient
            <span
              class="glyphicon glyphicon-info-sign info-glyph"
              data-toggle="modal"
              data-target="#description_supergaussian_parameter"
            />
          </h5>
          <div class="input-group">
            <input
              id="n_text"
              v-model="n"
              min="1"
              max="49"
              step="1"
              type="number"
              class="form-control indicator"
            >
            <span class="input-group-addon">deg</span>
          </div>
        </div>
      </div>
    </div>

    <SuperGaussianParameterDescriptionModal id="description_supergaussian_parameter" />
    <PeakIntensityGaussianDescriptionModal id="description_peak_intensity_gaussian" />
    <PeakIntensitySech2DescriptionModal id="description_peak_intensity_sech2" />
    <PeakFluenceDescriptionModal id="description_peak_fluence" />
  </div>
</template>
<script>
import SuperGaussianParameterDescriptionModal from "@/components/descriptions/beamProperties/SuperGaussianParameter.vue";
import PeakIntensityGaussianDescriptionModal from "@/components/descriptions/beamProperties/PeakIntensityGaussian.vue";
import PeakIntensitySech2DescriptionModal from "@/components/descriptions/beamProperties/PeakIntensitySech2.vue";
import PeakFluenceDescriptionModal from "@/components/descriptions/beamProperties/PeakFluence.vue";
import { sg_bc_to_FWHM, bc_FWHM_to } from "@/tools/BandwidthConverterFWHM.js";
import { pi, inverseGammas } from "@/tools/InverseGammas.js";

export default {
  components: {
    SuperGaussianParameterDescriptionModal,
    PeakIntensityGaussianDescriptionModal,
    PeakIntensitySech2DescriptionModal,
    PeakFluenceDescriptionModal,
  },
  data() {
    return {
      E: 40.0,
      deltat: 200.0,
      D_e_2: 60.0,
      n: 1.0,
    };
  },
  computed: {
    D_FWHM: {
      get: function () {
        return Math.round10(sg_bc_to_FWHM(this.D_e_2, "e-2", this.n),-3);
      },
      set: function (newValue) {
        if (newValue) {
          this.D_e_2 = bc_FWHM_to(newValue, "e-2").toFixed(3);
        }
      },
    },
    w0() {
      return this.D_e_2 / 2.0;
    },
    gama() {
      return inverseGammas[this.n];
    },
    fluence() {
      return (
        ((this.E * Math.pow(2.0, 1.0 / this.n) * this.n) /
          (pi * this.w0 * this.w0 * this.gama)) *
        1.0e2
      ).toFixed(3); // in mJ/cm^2
    },
    intensity() {
      var deltat = this.deltat * 1.0e-15;
      return (
        ((2.0 * this.fluence) / 1.0e3 / deltat) *
        Math.sqrt(Math.log(2.0) / pi) *
        1.0e-12
      ).toFixed(3); // in GW/cm^2
    },
    intensity_sech2() {
      var deltat = this.deltat * 1.0e-15;
      return (
        ((0.88137358701954302523260932497979 * this.fluence) / 1.0e3 / deltat) *
        1.0e-12
      ).toFixed(3);
    },
  },
};
</script>
