<template lang="">
  <div
    v-if="$store.state.dataReady"
    class="row"
  >
    <!-- TITLE -->
    <div class="col-lg-12 col-md-12 col-xs-12">
      <h3 id="SFG">
        Noncolinear <abbr title="Sum frequency generation">SFG</abbr> internal angles
        <small><span
          data-toggle="collapse"
          data-target="#info_SFGinternalangles"
          class="glyphicon glyphicon-info-sign info-glyph"
        /></small>
      </h3>
    </div>
    <!-- INFO/OUTPUT -->
    <div class="col-lg-6 col-md-6 col-xs-12">
      <div class="row">
        <div
          id="info_SFGinternalangles"
          class="col-lg-12 col-md-12 col-xs-12 collapse"
        >
          <p>Calculation of internal angles in noncolinear SFG</p>
          <img
            class="img-center"
            src="@/assets/static/media/svg/SFGinternalangles.svg"
          >
        </div>

        <div class="col-xs-6">
          <h5><math-jax latex="\phi_1" /> <small>[deg]</small></h5>
          <input
            id="phi1text"
            v-model="phi1"
            type="text"
            class="form-control"
            readonly
          >
        </div>
        <div class="col-xs-6">
          <h5><math-jax latex="\phi_2" /> <small>[deg]</small></h5>
          <input
            id="phi2text"
            v-model="phi2"
            type="text"
            class="form-control"
            readonly
          >
        </div>
      </div>
      <br>
    </div>
    <!-- INPUT -->
    <div class="col-lg-6 col-md-6 col-xs-12 settings">
      <!-- MATERIAL SELECT -->
      <Selector
        :list="materials"
        :list-name="'Materials'"
        :default-selection="'BaB2O4'"
        :with-label="true"
        @selectedMaterial="onSelection"
      />

      <h5>Wavelength range: {{ wlrangeMin }} - {{ wlrangeMax }}</h5>

      <div class="row bottom_align_row">
        <div class="col-lg-3 col-md-3 col-xs-3 vcenter no-right-padding">
          <h5><math-jax latex="\lambda_1" /> <small>[nm]</small></h5>
          <input
            id="wl1text"
            v-model="lambda1"
            step="1.0"
            type="number"
            class="form-control indicator"
          >
        </div>
        <div class="col-lg-3 col-md-3 col-xs-3 vcenter no-right-padding">
          <h5><math-jax latex="\lambda_2" /> <small>[nm]</small></h5>
          <input
            id="wl2text"
            v-model="lambda2"
            step="1.0"
            type="number"
            class="form-control indicator"
          >
        </div>
        <div class="col-lg-3 col-md-3 col-xs-3 vcenter no-right-padding">
          <h5><math-jax latex="\lambda_3" /> <small>[nm]</small></h5>
          <input
            id="wl3text"
            v-model="lambda3"
            step="1.0"
            type="number"
            class="form-control indicator"
          >
        </div>
        <div class="col-lg-3 col-md-3 col-xs-3">
          <h5>Interaction</h5>
          <select
            id="interaction_type"
            v-model="interaction_type"
            class="form-control indicator"
          >
            <option value="oo">
              oo*
            </option>
            <option value="eo">
              eo*
            </option>
            <option value="oe">
              oe*
            </option>
            <option value="ee">
              ee*
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-4 col-xs-4 vcenter">
          <h5><math-jax latex="\theta_\mathrm{c}" /> <small>[deg]</small></h5>
          <input
            id="thetactext"
            v-model="thetac"
            step="1.0"
            type="number"
            class="form-control indicator"
          >
        </div>
        <div class="col-lg-4 col-md-4 col-xs-4 vcenter">
          <h5><math-jax latex="\theta_0" /> <small>[deg]</small></h5>
          <input
            id="theta0text"
            v-model="theta0"
            step="1.0"
            type="number"
            class="form-control indicator"
          >
        </div>
        <div class="col-lg-4 col-md-4 col-xs-4 vcenter">
          <h5><math-jax latex="\alpha" /> <small>[deg]</small></h5>
          <input
            id="alphatext"
            v-model="alpha"
            step="1.0"
            type="number"
            class="form-control indicator"
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataService from "@/services/data.service";
import Selector from "@/components/Selector.vue";
import { deg_to_rad, make_phi_function, fsolve, make_n_function, rad_to_deg } from "@/tools/Utils.js";
import { RefractiveIndex } from "@/tools/MaterialDispersion.js";

export default {
  components: {
    Selector,
  },
  data() {
    return {
      material: null,
      interaction_type: "oo",
      wlrangeMin: null,
      wlrangeMax: null,
      wl1: 1030,
      wl2: 1030,
      wl3: 515,
      wlorder: [0, 1, 2],
      thetac: 0,
      theta0: 0,
      alpha: 45,
    };
  },
  computed: {
    materials(){
      return this.$store.getters.materialsByType("Uniaxial crystals");
    },
    lambda1: {
      get: function () {
        return Math.round10(this.wl1, -3);
      },
      set: function (newValue) {
        this.wl1 = newValue;
        this.update_wl_order(1);
        if (!this.wlorder[1]) {
          this.wl2 = 1.0 / (1.0 / this.wl3 - 1.0 / this.wl1);
        } else {
          this.wl3 = 1.0 / (1.0 / this.wl1 + 1.0 / this.wl2);
        }
      },
    },
    lambda2: {
      get: function () {
        return Math.round10(this.wl2, -3);
      },
      set: function (newValue) {
        this.wl2 = newValue;
        this.update_wl_order(2);
        if (!this.wlorder[2]) {
          this.wl3 = 1.0 / (1.0 / this.wl1 + 1.0 / this.wl2);
        } else {
          this.wl1 = 1.0 / (1.0 / this.wl3 - 1.0 / this.wl2);
        }
      },
    },
    lambda3: {
      get: function () {
        return Math.round10(this.wl3, -3);
      },
      set: function (newValue) {
        this.wl3 = newValue;
        this.update_wl_order(3);

        if (!this.wlorder[0]) {
          this.wl1 = 1.0 / (1.0 / this.wl3 - 1.0 / this.wl2);
        } else {
          this.wl2 = 1.0 / (1.0 / this.wl3 - 1.0 / this.wl1);
        }
      },
    },
    phi_array() {
      if (this.material === null) return 0;
      var i;
      var phi_array = [0.0, 0.0, 0.0];
      var theta0_array = [-deg_to_rad(this.theta0 + this.alpha / 2.0), -deg_to_rad(this.theta0 - this.alpha / 2.0)];
      var wl = [this.wl1, this.wl2, this.wl3];
      var thetac = deg_to_rad(this.thetac);
      var walkoff;
      var delta_theta = 1.0e-6;
      var fun;
      var n_e;

      //going through all rays in interaction_type
      for (i = 0; i < 2; i += 1) {
        switch (this.interaction_type.charAt(i)) {
          case "o":
            phi_array[i] = Math.asin(Math.sin(theta0_array[i]) / RefractiveIndex(wl[i] / 1.0e3, 0, this.material));
            break;
          case "e":
            fun = make_phi_function(wl[i] / 1.0e3, theta0_array[i], thetac, this.material);

            //solve - initial condition - as if it was o ray
            phi_array[i] = fsolve(
              fun,
              Math.asin(Math.sin(theta0_array[i]) / RefractiveIndex(wl[i] / 1.0e3, 0, this.material))
            );

            n_e = make_n_function(wl[i] / 1.0e3, "e", this.material);
            walkoff =
              (-(n_e(thetac - phi_array[i] + delta_theta) / n_e(thetac - phi_array[i]) - 1.0) / delta_theta) *
              (i === 0 ? 1.0 : -1.0);

            phi_array[i] += walkoff;

            break;
        }
      }

      return phi_array;
    },
    phi1() {
      return rad_to_deg(this.phi_array[0]).toFixed(3);
    },
    phi2() {
      return rad_to_deg(this.phi_array[1]).toFixed(3);
    },
  },
  watch: {
    material(newValue) {
      this.wlrangeMin = parseFloat(newValue.parameters[0].wl_n_range[0]) * 1.0e3;
      this.wlrangeMax = parseFloat(newValue.parameters[0].wl_n_range[1]) * 1.0e3;
    },
  },
  methods: {
    onSelection(material) {
      DataService.getMaterialData(material).then((response) => {
        this.material = response.data.value;
      });
    },
    update_wl_order(id) {
      if (this.wlorder[id - 1] === 2) {
        return;
      }
      this.wlorder[id - 1] = 2;

      for (var i = 0; i < 3; i += 1) {
        if (i != id - 1) {
          this.wlorder[i] -= 1;
        }
        if (this.wlorder[i] < 0.0) {
          this.wlorder[i] = 0;
        }
      }
    },
  },
};
</script>
<style lang=""></style>
