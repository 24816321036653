<template lang="">
  <div
    class="panel panel-default"
    style="margin-bottom: 5px"
  >
    <!-- OUTPUT ( ORDINARY )-->
    <div
      id="headingOne"
      class="panel-heading"
      role="tab"
      data-ng-show="true"
    >
      <h4 class="panel-title">
        <a
          data-toggle="collapse"
          :data-target="titleid"
          href="#collapseOne"
          aria-expanded="true"
          aria-controls="collapseOne"
        >
          <span :class="icon">&nbsp;</span>{{ Title }} ray
        </a>
      </h4>
    </div>
    <div
      :id="Title"
      class="panel-collapse collapse in"
      role="tabpanel"
      aria-labelledby="headingOne"
    >
      <div class="row bottom_align_row">
        <div class="col-md-6 col-xs-6">
          <h5>
            Refraction angle <math-jax latex="\vartheta_1" />&nbsp;
            <span
              class="glyphicon glyphicon-info-sign info-glyph"
              data-toggle="modal"
              data-target="#description_theta1"
            />
          </h5>
          <div :class="`${hasError ? 'input-group has-error' : 'input-group'}`">
            <input
              id="theta1_text"
              v-model="theta1"
              type="number"
              class="form-control indicator"
              readonly
            >
            <span class="input-group-addon">deg</span>
          </div>
        </div>

        <div class="col-md-6 col-xs-6">
          <h5>
            Deviation angle <math-jax latex="\delta" />&nbsp;
            <span
              class="glyphicon glyphicon-info-sign info-glyph"
              data-toggle="modal"
              data-target="#description_delta"
            />
          </h5>
          <div :class="`${hasError ? 'input-group has-error' : 'input-group'}`">
            <input
              id="delta_text"
              v-model="delta"
              type="number"
              class="form-control indicator"
              readonly
            >
            <span class="input-group-addon">deg</span>
          </div>
        </div>
      </div>
      <div class="row bottom_align_row">
        <div class="col-md-6 col-xs-6">
          <h5>
            Brewster's angle <math-jax latex="\vartheta_\mathrm{Br}" />&nbsp;
            <span
              class="glyphicon glyphicon-info-sign info-glyph"
              data-toggle="modal"
              data-target="#description_thetabr"
            />
          </h5>
          <div class="input-group">
            <input
              id="thetabr_text"
              v-model="thetabr"
              onClick="this.select();"
              type="number"
              class="form-control indicator"
              readonly
            >
            <span class="input-group-addon">deg</span>
          </div>
        </div>

        <div class="col-md-6 col-xs-6">
          <h5>
            Min. deviation apex angle <math-jax latex="\alpha_0" />&nbsp;
            <span
              class="glyphicon glyphicon-info-sign info-glyph"
              data-toggle="modal"
              data-target="#description_alpha0"
            />
          </h5>
          <div :class="`${hasError ? 'input-group has-error' : 'input-group'}`">
            <input
              id="alpha0_text"
              v-model="alpha0"
              onClick="this.select();"
              type="number"
              class="form-control indicator"
              readonly
            >
            <span class="input-group-addon">deg</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <BrewstersAngleDescriptionModal id="description_thetabr" />
  <DeviationAngleDescriptionModal id="description_delta" />
  <RefrectionAngleDescriptionModal id="description_theta1" />
  <MinDeviationAngleDescriptionModal id="description_alpha0" />
</template>
<script>
import { RefractiveIndex } from "@/tools/MaterialDispersion.js";
import { deg_to_rad, rad_to_deg } from "@/tools/Utils.js";
import BrewstersAngleDescriptionModal from "@/components/descriptions/geometricalOptics/BrewstersAngle.vue";
import DeviationAngleDescriptionModal from "@/components/descriptions/geometricalOptics/DeviationAngle.vue";
import RefrectionAngleDescriptionModal from "@/components/descriptions/geometricalOptics/RefrectionAngle.vue";
import MinDeviationAngleDescriptionModal from "@/components/descriptions/geometricalOptics/MinDeviationAngle.vue";

export default {
  components: {
    BrewstersAngleDescriptionModal,
    DeviationAngleDescriptionModal,
    RefrectionAngleDescriptionModal,
    MinDeviationAngleDescriptionModal
  },
  props: {
    Title: String,
    icon: String,
    material: null,
    extraordinayRay: {
      type: Boolean,
      default() {
        return false;
      },
    },
    wl: null,
    theta0: null,
    alpha: null,
  },
  emits: ["hasError"],
  data() {
    return {
      titleid: "#" + this.Title,
    };
  },
  computed: {
    n() {
      if (this.material === null) return null;
      if (this.extraordinayRay) return RefractiveIndex(this.wl / 1.0e3, 1, this.material);
      return RefractiveIndex(this.wl / 1.0e3, 0, this.material);
    },
    theta1Value() {
      if (this.n === null) return null;
      var theta0 = deg_to_rad(this.theta0);
      var alpha = deg_to_rad(this.alpha);
      return Math.asin(this.n * Math.sin(alpha - Math.asin(Math.sin(theta0) / this.n)));
    },
    theta1() {
      return Math.round10(rad_to_deg(this.theta1Value), -3);
    },
    deltaValue() {
      return this.theta1Value + deg_to_rad(this.theta0) - deg_to_rad(this.alpha);
    },
    delta() {
      return Math.round10(rad_to_deg(this.deltaValue), -3);
    },
    thetabr() {
      return Math.round10(rad_to_deg(Math.atan(this.n)), -3);
    },
    alpha0() {
      return Math.round10(rad_to_deg(2.0 * Math.asin(Math.sin(deg_to_rad(this.theta0)) / this.n)), -3);
    },
    theta0critical() {
      return Math.asin(this.n * Math.sin(deg_to_rad(this.alpha) - Math.asin(1.0 / this.n)));
    },
    hasError() {
      var error = deg_to_rad(this.theta0) < this.theta0critical || isNaN(this.theta0critical);
      this.$emit("hasError", error);
      return error;
    },
  },
};
</script>
<style lang=""></style>
