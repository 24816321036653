<template lang="">
  <div>
    <div class="row">
      <div class="col-sm-4">
        <ProductInfoForUser
          v-if="product !== null"
          :product="product"
        />
      </div>
      <div class="col-sm-8">
        <div class="row">
          <div class="col-sm-8">
            <div
              class="list"
              style="list-style-type: none"
            >
              <li>
                <label :class="`${hasError ? 'text-danger' : ''}`">Name</label>
                <div :class="`${hasError ? 'has-error' : ''}`">
                  <input
                    v-model="product.name"
                    type="text"
                    class="form-control indicator has-error"
                  >
                </div>
              </li>
              <li>
                <label :class="`${hasError ? 'text-danger' : ''}`">Category</label>
                <div :class="`${hasError ? 'has-error' : ''}`">
                  <input
                    v-model="product.category"
                    type="text"
                    class="form-control indicator"
                  >
                </div>
              </li>
              <li>
                <label :class="`${hasError ? 'text-danger' : ''}`">Long name</label>
                <div :class="`${hasError ? 'has-error' : ''}`">
                  <input
                    v-model="product.longName"
                    type="text"
                    class="form-control indicator"
                  >
                </div>
              </li><li>
                <label :class="`${hasError ? 'text-danger' : ''}`">Dynamics NAV code</label>
                <div :class="`${hasError ? 'has-error' : ''}`">
                  <input
                    v-model="product.navCode"
                    type="text"
                    class="form-control indicator"
                  >
                </div>
              </li>
              <li>
                <label>Description text</label>
                <textarea
                  v-model="product.descriptionText"
                  type="text"
                  class="form-control indicator"
                  rows="8"
                />
              </li>
              <li>
                <label>Link to LC website</label>
                <input
                  v-model="product.descriptionLink"
                  type="text"
                  class="form-control indicator"
                >
              </li>
            </div>
          </div>
          <div class="col-sm-4">
            <div
              class="list"
              style="list-style-type: none"
            >
              <li>
                <label :class="`${hasError ? 'text-danger' : ''}`">Width</label>
                <div :class="`${hasError ? 'has-error' : ''}`">
                  <input
                    v-model="product.width"
                    type="number"
                    step="0.01"
                    class="form-control indicator has-error"
                  >
                </div>
              </li>
              <li>
                <label :class="`${hasError ? 'text-danger' : ''}`">Height</label>
                <div :class="`${hasError ? 'has-error' : ''}`">
                  <input
                    v-model="product.height"
                    type="number"
                    step="0.01"
                    class="form-control indicator has-error"
                  >
                </div>
              </li>
              <li>
                <label>Box Width</label>
                <input
                  v-model="product.boxWidth"
                  type="number"
                  step="0.01"
                  class="form-control indicator has-error"
                >
              </li>
              <li>
                <label>Box Height</label>
                <input
                  v-model="product.boxHeight"
                  type="number"
                  step="0.01"
                  class="form-control indicator has-error"
                >
              </li>
              <li>
                <label :class="`${hasSnapError ? 'text-danger' : ''}`">Snap Position X</label>
                <div :class="`${hasSnapError ? 'has-error' : ''}`">
                  <input
                    v-model="product.snapPositionX"
                    type="number"
                    step="0.01"
                    class="form-control indicator has-error"
                  >
                </div>
              </li>
              <li>
                <label :class="`${hasSnapError ? 'text-danger' : ''}`">Snap Position Y</label>
                <div :class="`${hasSnapError ? 'has-error' : ''}`">
                  <input
                    v-model="product.snapPositionY"
                    type="number"
                    step="0.01"
                    class="form-control indicator has-error"
                  >
                </div>
              </li>
              <li>
                <div class="row">
                  <div class="col-sm-9">
                    <label>Visible From Outside</label>
                  </div>
                  <div class="col-sm-2">
                    <input
                      v-model="product.visibleFromOutside"
                      type="checkbox"
                      class="checkbox"
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="row">
                  <div class="col-sm-9">
                    <label>Scalable X</label>
                  </div>
                  <div class="col-sm-2">
                    <input
                      v-model="product.scalableX"
                      type="checkbox"
                      class="checkbox"
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="row">
                  <div class="col-sm-9">
                    <label>Scalable Y</label>
                  </div>
                  <div class="col-sm-2">
                    <input
                      v-model="product.scalableY"
                      type="checkbox"
                      class="checkbox"
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="row">
                  <div class="col-sm-9">
                    <label>Free Rotate</label>
                  </div>
                  <div class="col-sm-2">
                    <input
                      v-model="product.freeRotate"
                      type="checkbox"
                      class="checkbox"
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="row">
                  <div class="col-sm-9">
                    <label>Snap X</label>
                  </div>
                  <div class="col-sm-2">
                    <input
                      v-model="product.snapX"
                      type="checkbox"
                      class="checkbox"
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="row">
                  <div class="col-sm-9">
                    <label>Snap Y</label>
                  </div>
                  <div class="col-sm-2">
                    <input
                      id="customSwitch1"
                      v-model="product.snapY"
                      type="checkbox"
                      class="custom-control-input"
                    >
                  </div>
                </div>
              </li>
            </div>
            <div
              class="btn-group pull-right"
              role="group"
              aria-label="..."
            >
              <button
                type="button"
                class="btn btn-sm btn-primary"
                @click="save()"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row top-padding10">
      <div class="col-sm-4">
        <div class="panel panel-default">
          <div class="panel-heading">
            Description image
          </div>
          <div class="panel-body">
            <base-image-input
              ref="image1"
              :accept="'image/png, image/jpeg'"
              @input="onFileChanged"
            />
          </div>
          <div
            v-if="image"
            class="panel-footer"
          >
            <button
              type="button"
              class="btn btn-sm btn-danger"
              @click="$refs.image1.reset(); image=null;"
            >
              Change
            </button>
            {{ image.name }}
          </div>
        </div>
      </div>

      <div class="col-sm-4">
        <div :class="`${hasError ? 'panel panel-danger' : 'panel panel-default'}`">
          <div class="panel-heading">
            SVG image
          </div>
          <div class="panel-body">
            <base-image-input
              ref="image2"
              :accept="'image/svg+xml'"
              @input="onSvgFileChanged"
            />
          </div>
          <div
            v-if="svgImage"
            class="panel-footer"
          >
            <button
              type="button"
              class="btn btn-sm btn-danger"
              @click="$refs.image2.reset(); svgImage=null;"
            >
              Change
            </button>
            {{ svgImage.name }}
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="panel panel-default">
          <div class="panel-heading">
            Terminals
          </div>
          <div class="panel-body">
            <TerminalsTable
              :terminals="product.terminals"
              :new-terminals="'true'"
              @add="onAdd"
              @change="onChange"
              @remove="onRemove"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProductInfoForUser from "@/components/planner/ProductInfoForUser.vue";
import TerminalsTable from "@/components/planner/TerminalsTable.vue";
import DataService from "@/services/data.service";
import BaseImageInput from "@/components/BaseImageInput.vue";

export default {
  components: {
    ProductInfoForUser,
    TerminalsTable,
    BaseImageInput,
  },
  data() {
    return {
      product: [],
      image: null,
      svgImage: null,
      hasError: false,
      hasSnapError: false,
    };
  },
  methods: {
    save() {
      this.$store.commit("SET_Loading", true)
      if (this.product.name == null || this.product.category == null || this.product.width == null ||
          this.product.height == null  || this.svgImage == null || this.product.longName == null || 
          this.product.navCode == null) {
          this.hasError = true;
          return;
      }
      if ((this.product.snapX === true || this.product.snapY === true) && (this.product.snapPositionX == null || this.product.snapPositionY == null)){
        this.hasSnapError = true;
        return;
      }
      this.hasError = false;
      this.hasSnapError = false;
      
      var formData = new FormData();

      formData.append("Name", this.product.name);
      formData.append("Category", this.product.category);
      formData.append("LongName", this.product.longName);
      formData.append("DescriptionText", this.checkString(this.product.descriptionText));
      formData.append("DescriptionLink", this.checkString(this.product.descriptionLink));
      formData.append("VisibleFromOutside", this.checkBool(this.product.visibleFromOutside));
      formData.append("Width", this.product.width);
      formData.append("Height", this.product.height);
      if(this.product.boxWidth != null){
        formData.append("BoxWidth", this.product.boxWidth);
      }
      if(this.product.boxHeight != null){
        formData.append("BoxHeight", this.product.boxHeight);

      }
      if(this.product.snapPositionX != null){
        formData.append("SnapPositionX", this.checkBool(this.product.snapPositionX));

      }
      if(this.product.snapPositionY != null){
        formData.append("SnapPositionY", this.checkBool(this.product.snapPositionY));
      }
      if(this.product.scalableX != null){
        formData.append("ScalableX", this.checkBool(this.product.scalableX));

      }
      if(this.product.scalableY != null){
        formData.append("ScalableY", this.checkBool(this.product.scalableY));
      }
      formData.append("FreeRotate", this.checkBool(this.product.freeRotate));
      formData.append("SnapX", this.checkBool(this.product.snapX));
      formData.append("SnapY", this.checkBool(this.product.snapY));
      formData.append("Version", 1);
      if(this.image != null){
        formData.append("Image", this.image);
      }
      formData.append("SvgImage", this.svgImage);
      formData.append("NavCode", this.product.navCode);

      DataService.registerProduct(formData).then((response) => {
        if (response.status === 201) {
          var newProduct = response.data;
          newProduct.terminals = this.product.terminals;
          DataService.updateProduct(newProduct.id, newProduct)
            .then((response) => {
              if (response.status === 204) {
                this.$store.commit("SET_Loading", false)
                this.saveInfo();
                this.$store.dispatch("loadProducts");
                this.product = [];
                this.image = null;
                this.$refs.image1.reset();
                this.$refs.image2.reset();
                this.svgImage = null;
              }
            })
            .catch(this.errorHandling());
        }
      });
      this.$store.dispatch("loadProducts");
    },
    checkBool(prop) {
      if (prop == null) return false;
      return prop;
    },
    checkString(prop) {
      if (prop == null) return "";
      return prop;
    },
    saveInfo() {
      this.$swal.fire({
        position: "bottom-end",
        icon: "success",
        title: "Your work has been saved",
        showConfirmButton: false,
        timer: 1500,
      });
    },
    errorHandling() {
      this.$swal.fire({
        position: "bottom-end",
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    },
    onFileChanged(file) {
      if (this.product.name == null && this.product.category == null) this.hasError = true;
      this.image = file;
    },
    onSvgFileChanged(file) {
      if (this.product.name == null && this.product.category == null) this.hasError = true;
      this.svgImage = file;
    },
    onAdd() {
      if (this.product.terminals == null) {
        this.product.terminals = [];
      }
      this.product.terminals.push({ left: 0, top: 0 });
    },
    onChange(terminals) {
      this.product.terminals = terminals;
    },
    onRemove(index) {
      this.product.terminals.splice(index, 1);
    },
  },
};
</script>
<style scoped></style>
