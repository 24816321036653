<template lang="">
  <nav class="navbar navbar-inverse row header">
    <div class="container">
      <div class="container-fluid">
        <div class="navbar-header">
          <button
            type="button"
            class="navbar-toggle"
            data-toggle="collapse"
            data-target="#myNavbar"
          >
            <span class="icon-bar" />
            <span class="icon-bar" />
            <span class="icon-bar" />
          </button>
          <a
            class="navbar-brand header h1a"
            href="/"
          >Optics Toolbox</a>
        </div>
        <div
          id="myNavbar"
          class="collapse navbar-collapse"
        >
          <ul class="nav navbar-nav">
            <!-- BASIC -->
            <li class="dropdown">
              <a
                href="#"
                class="dropdown-toggle"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >CONVERTERS
              </a>
              <ul class="dropdown-menu">
                <li>
                  <router-link :to="{ name: 'unitconverter' }">
                    Unit converter
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'bandwidthconverter' }">
                    Bandwidth converter
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'pulselengthbandwidthconverter' }">
                    Pulse length-bandwidth converter
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'sfconverter' }">
                    SFG/OPO wavelength converter
                  </router-link>
                </li>
                <!--<li><a href="#">Calculator</a></li>-->
              </ul>
            </li>
            <!--  BEAM PROPERTIES-->
            <li class="dropdown">
              <a
                href="#"
                class="dropdown-toggle"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >BEAM PROPERTIES<span class="caret" /></a>
              <ul class="dropdown-menu">
                <li>
                  <router-link :to="{ name: 'pulseintensity' }">
                    Pulse peak intensity and fluence
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'pulsepower' }">
                    Pulse peak power
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'gaussianbeampropagation' }">
                    Gaussian beam propagation
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'beamdisplacement' }">
                    Beam displacement
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'beamdisplacementsandwich' }">
                    Optical path in heterostructures
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'beamdisplacementslabpair' }">
                    Beam displacement (slab pair)
                  </router-link>
                </li>
              </ul>
            </li>
            <!-- GEOMETRICAL OPTICSS -->
            <li class="dropdown">
              <a
                href="#"
                class="dropdown-toggle"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >GEOMETRICAL OPTICS<span class="caret" /></a>
              <ul class="dropdown-menu">
                <li
                  class="disabled"
                  aria-disabled="true"
                >
                  <a style="cursor: initial"><small>Refraction</small></a>
                </li>
                <li>
                  <router-link :to="{ name: 'refractiveindex' }">
                    <span>&nbsp;&nbsp;</span>Refractive index
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'comparematerials' }">
                    <span>&nbsp;&nbsp;</span>Comparison of materials
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'prismrefraction' }">
                    <span>&nbsp;&nbsp;</span>Beam refraction in prism
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'lensradius' }">
                    <span>&nbsp;&nbsp;</span>PCC/PCX lens radius
                  </router-link>
                </li>
                <li
                  role="presentation"
                  class="divider"
                />
                <li
                  class="disabled"
                  aria-disabled="true"
                >
                  <a style="cursor: initial"><small>Reflection and diffraction</small></a>
                </li>
                <li>
                  <router-link :to="{ name: 'fresnelreflection' }">
                    <span>&nbsp;&nbsp;</span>Fresnel reflection
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'diffractionangles' }">
                    <span>&nbsp;&nbsp;</span>Diffraction angles
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'gratingcalculator' }">
                    <span>&nbsp;&nbsp;</span>Grating calculator
                  </router-link>
                </li>
              </ul>
            </li>
            <!-- DISPERSION -->
            <li class="dropdown">
              <a
                href="#"
                class="dropdown-toggle"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >DISPERSION<span class="caret" /></a>
              <ul class="dropdown-menu">
                <li>
                  <router-link :to="{ name: 'dispersionparameters' }">
                    Material dispersion
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'gratingpair' }">
                    Grating pair compressor
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'prismpair' }">
                    Two prism compressor
                  </router-link>
                </li>
                <li
                  role="presentation"
                  class="divider"
                />
                <li>
                  <router-link :to="{ name: 'pulsebroadening' }">
                    Pulse elongation with known GDD
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'gaussianbeamelongation' }">
                    Pulse elongation through material
                  </router-link>
                </li>
              </ul>
            </li>
            <!-- NONLINEAR OPTICSS -->
            <li class="dropdown">
              <a
                href="#"
                class="dropdown-toggle"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >NONLINEAR OPTICS<span class="caret" /></a>
              <ul class="dropdown-menu">
                <li>
                  <router-link :to="{ name: 'PMangles' }">
                    Nonlinear optical interacions
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'SFG' }">
                    Sum frequency generation
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'SFGinternalangles' }">
                    Noncolinear SFG internal angles
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Bintegral' }">
                    B-integral
                  </router-link>
                </li>
              </ul>
            </li>
            <!-- OTHER -->
            <!-- <li class="dropdown">
              <a
                href="#"
                class="dropdown-toggle"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
                >OTHER<span class="caret"></span>
              </a>
              <ul class="dropdown-menu">
                <li>
                  <a href="{% url 'tools:FFT' %}">Discrete Fourier transform</a>
                </li> -->
            <!--					<li><a href="{% url 'tools:harpia' %}">HARPIA model number encoder/decoder</a></li>-->
            <!-- </ul>
            </li> -->
          </ul>

          <ul class="nav navbar-nav navbar-right">
            <li>
              <a
                id="feedback-button"
                href="#"
                data-toggle="modal"
              ><span class="glyphicon glyphicon-bullhorn" /></a>
            </li>
            <li>
              <a
                href="#"
                data-toggle="modal"
                data-target="#about"
              ><span class="glyphicon glyphicon-info-sign" /></a>
            </li>
            <li>
              <router-link :to="{ name: 'admin' }">
                <span class="glyphicon glyphicon-log-in" />
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>

  <div
    id="about"
    class="modal"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
          >
            &times;
          </button>
          <h4 class="modal-title">
            About "Optics toolbox"
          </h4>
        </div>
        <div
          class="modal-body"
          style="text-align: center"
        >
          <img
            src="./../assets/static/media/icons/logo.svg"
            alt="Light Conversion logo"
          >
          <br><br>
          Optics Toolbox: interactive online calculators of optical system and material properties and converters for
          scientists and engineers.
          <br><br>
          Contacts:
          <a href="mailto:wiseteam_software@lightcon.com">Optics Toolbox</a>,
          <a href="mailto:sales@lightcon.com">Sales</a>
          <br>
          &copy; Light Conversion, UAB,
          <a href="http://www.lightcon.com/">www.lightcon.com</a>
        </div>
        <div class="text-center">
          Version: {{ version }}
        </div>
        <div
          v-if="apiVersion"
          class="text-center"
        >
          API Version: {{ apiVersion }}
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-default"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import {version} from '../../package'

$.ajax({
  url: "https://lightcon.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/1jmxwi/b/8/b0105d975e9e59f24a3230a22972a71a/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs.js?locale=en-US&collectorId=f45f176d",
  type: "get",
  cache: true,
  dataType: "script",
});

window.ATL_JQ_PAGE_PROPS = {
  triggerFunction: function (showCollectorDialog) {
    $("#feedback-button").click(function (e) {
      e.preventDefault();
      showCollectorDialog();
    });
  },
  fieldValues: {
    components: "10168", //toolbox component ID jira
  },
};

export default {
  computed: {
    version() {
      return version;
    },
    apiVersion(){
      return this.$store.state.apiVersion;
    }
  },
};
</script>
<style scoped>
.navbar-inverse .navbar-nav > li > a:hover,
.navbar-inverse .navbar-nav > .open > a {
  box-shadow: 0 -3px #8892e3 inset;
}
</style>
