<template lang="">
  <div class="panel panel-default">
    <div
      id="product_annotation"
      class="panel-body"
    >
      <h4>{{ product.longName }}</h4>
      <p>{{ product.descriptionText }}</p>
      <img
        class="img-responsive"
        :src="imgUrl"
      >
      <a
        :href="product.descriptionLink"
        target="_blank"
      >More...</a>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    product: null,
  },
  computed: {
    imgUrl() {
      if (this.product.descriptionImage == null) return null;

      return process.env.VUE_APP_IMAGES + this.product.descriptionImage + "?" + Date.now();
    },
  },
};
</script>
<style lang=""></style>
