<template lang="">
  <div>
    <div
      v-for="(group, key) in materials"
      :key="group.type"
      class="panel panel-default"
    >
      <!-- Default panel contents -->
      <div
        id="headingOne"
        class="panel-heading"
        role="tab"
      >
        <a
          data-toggle="collapse"
          :data-target="'#'+ key"
          aria-expanded="true"
          aria-controls="collapseTwo"
          style="color: #2C3E50"
        >
          {{ group.type }}</a>
      </div>

      <div
        :id="key"
        class="panel-collapse collapse"
        role="tabpanel"
        aria-labelledby="headingOne"
        aria-expanded="true"
      >
        <!-- Table -->
        <table class="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Info</th>
              <th>Type</th>
              <th>N2 Defined</th>
              <th>Birefrigerence Type</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="material in group.materials"
              :key="material"
            >
              <td>{{ material.name }}</td>
              <td>{{ material.info }}</td>
              <td>{{ material.type }}</td>
              <td>{{ material.n2_defined }}</td>
              <td>{{ material.birefrigerence_type }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    materials() {
      return this.$store.state.groupedMaterials;
    },
  },
};
</script>
<style lang=""></style>
