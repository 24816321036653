<template lang="">
  <div
    id="diffractionangles"
    ng-controller="diffractionCtrl"
    class="row"
  >
    <!-- TITLE -->
    <div class="col-lg-12 col-md-12 col-xs-12 settings">
      <h3>
        Diffraction angles
        <small><span
          data-toggle="collapse"
          data-target="#info_diffractionangles"
          class="glyphicon glyphicon-info-sign info-glyph"
        /></small>
      </h3>
    </div>
    <!-- PLOT -->
    <div class="col-lg-6 col-md-6 col-xs-12 settings">
      <div
        id="info_diffractionangles"
        class="collapse"
      >
        <p>Diffraction angle dependence on wavelength and angle of incidence.</p>
        <img
          class="img-center"
          src="@/assets/static/media/svg/diffractionangles.svg"
        >
      </div>

      <div id="diffraction_angles_plot" />
    </div>
    <!-- INPUT/OUTPUT -->
    <div class="col-lg-6 col-md-6 col-xs-12 settings">
      <div class="row">
        <!-- AOI SELECT -->
        <div class="col-lg-6 col-xs-6">
          <h5 for="theta0_text">
            AOI <math-jax latex="\vartheta_0" />&nbsp;
            <span
              class="glyphicon glyphicon-info-sign info-glyph"
              data-toggle="modal"
              data-target="#description_theta0"
            />
          </h5>
          <div class="input-group">
            <input
              id="theta0_text"
              v-model="theta0"
              min="0"
              max="90"
              step="1"
              type="number"
              class="form-control indicator"
              tabindex="1"
            >
            <span class="input-group-addon">deg</span>
          </div>
        </div>

        <!-- GRATING SELECT -->
        <div class="col-lg-6 col-xs-6">
          <h5 for="gpmm_text">
            Grating frequency <math-jax latex="d^{-1} " /> <span />
          </h5>
          <div class="input-group">
            <input
              v-model="gpmm"
              max="9999"
              step="1"
              type="number"
              class="form-control indicator"
              tabindex="2"
            >
            <span class="input-group-addon">/mm</span>
          </div>
        </div>
      </div>
      <div class="row">
        <!-- WAVELENGTH SELECT -->
        <div class="col-lg-6 col-xs-6">
          <h5 for="wl_text">
            Wavelength <math-jax latex="\lambda " />
          </h5>
          <div class="input-group">
            <input
              id="wl_text"
              v-model="wl"
              min="1"
              max="99999"
              step="1"
              type="number"
              class="form-control indicator"
              tabindex="3"
            >
            <span class="input-group-addon">nm</span>
          </div>
        </div>

        <!-- DEVIATION SELECT -->
        <div class="col-lg-6 col-xs-6">
          <h5 for="thetad_text">
            Deviation <math-jax latex="\vartheta_\mathrm{d}" />&nbsp;
            <span
              class="glyphicon glyphicon-info-sign info-glyph"
              data-toggle="modal"
              data-target="#description_thetad"
            />
          </h5>
          <div class="input-group">
            <input
              id="thetad_text"
              v-model.lazy="thetad"
              min="-90"
              max="90"
              step="1"
              type="number"
              class="form-control indicator"
              tabindex="4"
            >
            <span class="input-group-addon">deg</span>
          </div>
        </div>
      </div>
      <div class="row">
        <!-- LITTROW ANGLE  -->
        <div class="col-lg-6 col-xs-6">
          <h5 for="thetaL_text">
            Littrow angle <math-jax latex="\vartheta_\mathrm{L}" />&nbsp;
            <span
              class="glyphicon glyphicon-info-sign info-glyph"
              data-toggle="modal"
              data-target="#description_thetaL"
            />
          </h5>
          <div class="input-group">
            <input
              id="thetaL_text"
              v-model="thetaL"
              type="number"
              class="form-control indicator"
              readonly
            >
            <span class="input-group-addon">deg</span>
          </div>
          <!-- BLAZE ANGLE  -->
          <h5 for="thetab_text">
            Blaze angle <math-jax latex="\vartheta_\mathrm{B} " />
          </h5>
          <div class="input-group">
            <input
              id="thetab_text"
              v-model="thetab"
              type="number"
              class="form-control indicator"
              readonly
            >
            <span class="input-group-addon">deg</span>
          </div>
        </div>

        <div class="col-xs-6">
          <h5>Diffraction angles <small>[deg]</small></h5>
          <div class="form-group">
            <textarea
              v-model="textarea_text"
              class="form-control"
              rows="5"
              readonly
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <DeviationAngle2DescriptionModal id="description_thetad" />
  <LittrowAngleDescriptionModal id="description_thetaL" />
  <AngleOfIncidenceDescriptionModal id="description_theta0" />
</template>
<script>
import { rad_to_deg, deg_to_rad, diffraction_angles, AOI_diffraction_deviance } from "@/tools/Utils.js";
import { drawPlotD3 } from "@/tools/Plot.js";
import DeviationAngle2DescriptionModal from "@/components/descriptions/geometricalOptics/DeviationAngle2.vue";
import LittrowAngleDescriptionModal from "@/components/descriptions/geometricalOptics/LittrowAngle.vue";
import AngleOfIncidenceDescriptionModal from "@/components/descriptions/geometricalOptics/AngleOfIncidence.vue";

export default {
  components: {
    DeviationAngle2DescriptionModal,
    LittrowAngleDescriptionModal,
    AngleOfIncidenceDescriptionModal
  },
  data() {
    return {
      theta0: 25,
      gpmm: 800,
      wl: 1030,
    };
  },
  computed: {
    thetad: {
      get: function () {
        return Math.round10(
          rad_to_deg(diffraction_angles(this.wl, deg_to_rad(this.theta0), -1, this.gpmm)) - this.theta0,
          -3
        );
      },
      set: function (newValue) {
        this.drawPlot();
        this.theta0 = Math.round10(
          rad_to_deg(AOI_diffraction_deviance(deg_to_rad(newValue), this.wl * 1.0e-9, this.gpmm * 1.0e3)),
          -3
        );
      },
    },
    thetaL() {
      return Math.round10(rad_to_deg(Math.asin(((this.wl * 1.0e-9) / 2.0) * this.gpmm * 1.0e3)), -3);
    },
    thetab() {
      return Math.round10(this.theta0 - this.thetaL, -3);
    },
    textarea_text() {
      var textToReturn = "m\t Diffraction angle";

      if(!this.wl || !this.gpmm) return textToReturn;

      var m_min = -Math.floor(((Math.sin(deg_to_rad(this.theta0)) + 1.0) / this.wl / this.gpmm) * 1.0e6, 0);
      var m_max = -Math.ceil(((Math.sin(deg_to_rad(this.theta0)) - 1.0) / this.wl / this.gpmm) * 1.0e6, 0);

      for (var m = m_min; m <= m_max; m += 1) {
        textToReturn +=
          "\n" +
          m +
          "\t" +
          (rad_to_deg(diffraction_angles(this.wl, deg_to_rad(this.theta0), m, this.gpmm)) || 0.0).toFixed(3);
      }
      return textToReturn;
    },
  },
  watch: {
    theta0() {
      this.drawPlot();
    },
    gpmm() {
      this.drawPlot();
    },
    wl() {
      this.drawPlot();
    },
  },
  mounted(){
    this.drawPlot();
  },
  methods: {
    drawPlot() {
      var wl = [];
      var y = [];
      var wl_min = 400.0;
      var wl_max = this.wl;
      var wl_step = 5.0;
      var wl_val;
      var m;
      var m_min;
      var m_max;

      var fun_diffraction_angles_template = function (theta0, m, gpmm) {
        return function (wl) {
          return rad_to_deg(diffraction_angles(wl, theta0, m, gpmm));
        };
      };

      if (this.gpmm >= 100) {
        m_min = -Math.round10(((Math.sin(deg_to_rad(this.theta0)) + 1.0) / wl_min / this.gpmm) * 1.0e6, 0);
        m_max = -Math.round10(((Math.sin(deg_to_rad(this.theta0)) - 1.0) / wl_min / this.gpmm) * 1.0e6, 0);

        if (m_min < 0.0) {
          m_min += 1.0;
        }

        for (wl_val = wl_min; wl_val <= wl_max; wl_val += wl_step) {
          wl.push(wl_val);
        }

        for (m = 0; m <= m_max; m += 1) {
          y.push({ title: "m=" + m, fun: fun_diffraction_angles_template(deg_to_rad(this.theta0), m, this.gpmm) });
        }
        for (m = m_min; m < 0; m += 1) {
          y.push({ title: "m=" + m, fun: fun_diffraction_angles_template(deg_to_rad(this.theta0), m, this.gpmm) });
        }

        drawPlotD3(
          wl,
          y,
          "diffraction_angles_plot",
          { title: "Wavelength", units: "nm" },
          { title: "Diffraction angle, deg" }
        );
      }
    },
  },
};
</script>
<style lang=""></style>
