<template>
  <div
    :id="id"
    class="modal"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
          >
            &times;
          </button>
          <h4 class="modal-title">
            Conversion from wavelength
          </h4>
        </div>
        <div class="modal-body">
          Wavenumber
          <math-jax
            latex=" k = \frac{1}{\lambda} \Longrightarrow k\mathrm{[cm^{-1}]} = \frac{10^{7}}{\lambda\mathrm{[nm]}}"
            :block="true"
          />
          Optical period
          <math-jax
            latex="T = \frac{\lambda}{c} \Longrightarrow T[\mathrm{fs}] \approx
          \frac{\lambda[\mathrm{nm}]}{299.792}"
            :block="true"
          />
          Angular frequency
          <math-jax
            latex="\omega = \frac{2\pi c}{\lambda} \Longrightarrow \omega[\mathrm{fs^{-1}}] \approx \frac{1883.652}{\lambda[\mathrm{nm}]} "
            :block="true"
          />
          Energy
          <math-jax
            latex="E = \frac{2\pi c\hbar}{\lambda} \Longrightarrow E[\mathrm{eV}] \approx \frac{1239.841}{\lambda[\mathrm{nm}]}"
            :block="true"
          />
          Frequency
          <math-jax
            latex="f = \frac{c}{\lambda} \Longrightarrow f[\mathrm{THz}] \approx \frac{299792.458}{\lambda[\mathrm{nm}]}"
            :block="true"
          />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-default"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: null,
  },
};
</script>
