<template lang="">
  <div
    id="elementId"
    class="row"
  >
    <div class="col-xs-12">
      <h3>
        Bandwidth converter
        <small><span
          data-toggle="collapse"
          data-target="#info_bandwidthconverter"
          class="glyphicon glyphicon-info-sign info-glyph"
        /></small>
      </h3>
    </div>

    <div
      id="info_bandwidthconverter"
      class="collapse col-xs-12"
    >
      <BandwidthConverterDescription />
    </div>

    <div class="col-lg-2 col-md-5 col-xs-12">
      <h5>Wavelength <math-jax latex="\lambda_0" /></h5>
      <div class="input-group">
        <input
          ref="focusMe"
          v-model="bandwidthConverterParameters.lambda"
          type="number"
          step="1.0"
          class="form-control indicator"
          @change="update('lambda')"
        >
        <span class="input-group-addon">nm</span>
      </div>
    </div>
    <div class="col-lg-4 col-md-7 col-xs-12">
      <h5>
        Bandwidth <math-jax latex="\Delta\lambda" />&nbsp;  
        <span
          class="glyphicon glyphicon-info-sign info-glyph"
          data-toggle="modal"
          data-target="#description_deltalambda"
        />
      </h5>
      <div class="input-group">
        <select
          v-model="bandwidthConverterParameters.lambda_bw_type"
          class="selectpicker"
        >
          <option
            name="FWHM"
            value="FWHM"
          >
            FWHM
          </option>
          <option
            name="e-1"
            value="e-1"
          >
            e&#8315;&sup1;
          </option>
          <option
            name="e-2"
            value="e-2"
          >
            e&#8315;&sup2;
          </option>
          <option
            name="sigma"
            value="sigma"
          >
            &sigma;
          </option>
        </select>
        <input
          v-model="bandwidthConverterParameters.deltalambda"
          type="number"
          data-toggle="popover"
          title="Enter bandwidth"
          step="1.0"
          class="form-control indicator"
          @change="update('deltalambda')"
        >
        <span class="input-group-addon">nm</span>
      </div>
      <br>
    </div>
    <div class="col-lg-2 col-md-5 col-xs-12">
      <h5>Wavenumber <math-jax latex="k_0" /></h5>
      <div class="input-group">
        <input
          v-model="bandwidthConverterParameters.k"
          type="number"
          step="1.0"
          class="form-control indicator"
          @change="update('k')"
        >
        <span class="input-group-addon">cm&#8315;&sup1;</span>
      </div>
    </div>
    <div class="col-lg-4 col-md-7 col-xs-12">
      <h5>
        Bandwidth <math-jax latex="\Delta k" />&nbsp;
        <span
          class="glyphicon glyphicon-info-sign info-glyph"
          data-toggle="modal"
          data-target="#description_deltak"
        />
      </h5>
      <div class="input-group">
        <select
          v-model="bandwidthConverterParameters.k_bw_type"
          class="selectpicker"
        >
          <option
            name="FWHM"
            value="FWHM"
          >
            FWHM
          </option>
          <option
            name="e-1"
            value="e-1"
          >
            e&#8315;&sup1;
          </option>
          <option
            name="e-2"
            value="e-2"
          >
            e&#8315;&sup2;
          </option>
          <option
            name="sigma"
            value="sigma"
          >
            &sigma;
          </option>
        </select>
        <input
          v-model="bandwidthConverterParameters.deltak"
          type="number"
          step="1.0"
          class="form-control indicator"
          @change="update('deltak')"
        >
        <span class="input-group-addon">cm&#8315;&sup1;</span>
      </div>
    </div>
  </div>

  <DeltaLambdaDescriptionModal id="description_deltalambda" />
  <DeltaKDescriptionModal id="description_deltak" />
</template>

<script>
import DeltaLambdaDescriptionModal from "@/components/descriptions/converters/DeltaLambda.vue";
import DeltaKDescriptionModal from "@/components/descriptions/converters/DeltaK.vue";
import BandwidthConverterDescription from "@/components/descriptions/BandwidthConverter.vue";
import { UpdateParameter, UpdateBWType } from "@/tools/BandwidthConverterParameter";

export default {
  components: {
    BandwidthConverterDescription,
    DeltaLambdaDescriptionModal,
    DeltaKDescriptionModal,
  },
  data() {
    return {
      bandwidthConverterParameters: {
        lambda: null,
        deltak: null,
        deltalambda: null,
        k: null,
        k_bw_type: "FWHM",
        k_bw_old_type: "FWHM",
        lambda_bw_type: "FWHM",
        lambda_bw_old_type: "FWHM",
        TBP: null,
      },
    };
  },
  watch: {
    lambda_bw_type() {
      this.bandwidthConverterParameters = UpdateBWType("lambda");
    },
    k_bw_type() {
      this.bandwidthConverterParameters = UpdateBWType("k");
    },
  },
  mounted () {
    this.$refs.focusMe.focus()    
  },
  methods: {
    update(parameter) {
      this.bandwidthConverterParameters = UpdateParameter(parameter, this.bandwidthConverterParameters);
    },
  }
};
</script>
