<template lang="">
  <!-- TITLE -->
  <div class="col-xs-12">
    <h3>Harmonic module optimization (pulse energy vs repetition rate)</h3>
  </div>
  <div class="row">
    <!-- PLOT -->
    <div class="col-lg-8 col-xs-12">
      <div id="tuningcurves_plot" />
    </div>

    <!-- INPUT -->
    <div
      v-if="harmonicOptimizationSpec"
      class="col-lg-4 col-xs-12"
    >
      <!-- PRODUCT SELECTOR -->
      <Selector
        :list="harmonicOptimizationSpec"
        :list-name="'harmonicOptimizationSpec'"
        @selectedMaterial="onSelection"
      />
      <div>
        <div>
          <div>
            <h5 for="Energy_max">
              Energy max <math-jax latex="" />
            </h5>
            <div class="input-group">
              <input
                id="Energy_max"
                v-model="energy_max"
                min="1"
                step="1"
                type="number"
                class="form-control indicator"
                tabindex="1"
              >
              <span class="input-group-addon">μJ</span>
            </div>
          </div>
          <div>
            <h5 for="power">
              Power <math-jax latex="" />
            </h5>
            <div class="input-group">
              <input
                id="power"
                v-model="power"
                min="1"
                step="1"
                type="number"
                class="form-control indicator"
                tabindex="2"
              >
              <span class="input-group-addon">W</span>
            </div>
          </div>
          <div>
            <h5 for="Pump_energy_1">
              Pump energy 1 <math-jax latex="" />
            </h5>
            <div class="input-group">
              <input
                id="Pump_energy_1"
                v-model="Pump_energy_1"
                min="0"
                step="1"
                type="number"
                class="form-control indicator"
                tabindex="3"
              >
              <span class="input-group-addon">μJ</span>
            </div>
          </div>
          <div class="col">
            <h5 for="Pump_energy_2">
              Pump energy 2 <math-jax latex="" />
            </h5>
            <div class="input-group">
              <input
                id="Pump_energy_2"
                v-model="Pump_energy_2"
                min="0"
                step="1"
                type="number"
                class="form-control indicator"
                tabindex="4"
              >
              <span class="input-group-addon">μJ</span>
            </div>
          </div>
          <div>
            <button
              type="button"
              class="btn btn-default"
              style="width: 100%"
              @click="savePng"
            >
              Export PNG
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <div
            id="special_notes"
            style="display: none"
            class="panel panel-primary panel-heading"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div
      v-for="(image, index) in images"
      :key="image"
      class="col-xs-6 col-lg-2"
    >
      <div class="panel panel-primary">
        <div class="panel-heading">
          {{ image.name
          }}<button
            type="button"
            class="close"
            data-dismiss="alert"
            @click="deleteImageFromArray(index)"
          >
            <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
          </button>
        </div>
        <div class="panel-body">
          <img
            class="panel-image"
            :src="image.image"
            @click="downloadPng(image.image, image.name)"
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Selector from "@/components/Selector.vue";
import { xticksList, yticksList, colors } from "@/tools/TuningCurves.js";
import $ from "jquery";
import { drawTuningCurvesD3 } from "@/tools/Plot.js";

export default {
  components: {
    Selector,
  },
  data() {
    return {
      Pump_energy_1: 100,
      Pump_energy_2: 200,
      selectedProduct: null,
      power: 20,
      pulseEnergy: 10,
      repetitionRate: 500,
      images: [],
      target_div: "tuningcurves_plot",
      title: "",
      energy_max: 2000,
    };
  },
  computed: {
    harmonicOptimizationSpec() {
      return this.$store.state.harmonicOptimizationSpec;
    },
  },

  watch: {
    Pump_energy_1(newVal) {
      if (newVal > this.energy_max) this.Pump_energy_1 = this.energy_max;
      this.plot("tuningcurves_plot");
    },
    Pump_energy_2(newVal) {
      if (newVal > this.energy_max) this.Pump_energy_2 = this.energy_max;
      this.plot("tuningcurves_plot");
    },
    energy_max() {
      this.plot("tuningcurves_plot");
    },
    power() {
      this.plot("tuningcurves_plot");
    },
  },
  methods: {
    savePng() {
      const d3ToPng = require("d3-svg-to-png");
      var svg = document.getElementById("tuningcurves_plot").firstChild;
      d3ToPng(svg, this.title, {
        scale: 3,
        format: "png",
        quality: 0.01,
        download: false,
        ignore: ".ignored",
        background: "white",
      }).then((fileData) => {
        let image = { name: this.title, image: fileData };
        this.images.push(image);
      });
    },
    downloadPng(image, title) {
      var a = document.createElement("a");
      a.href = image;
      a.download = title;
      a.click();
    },
    deleteImageFromArray(index) {
      this.images.splice(index, 1);
    },
    onSelection(specs) {
      if ("value" in specs) {
        this.power = specs.value.info["power, W"];
        this.energy_max = specs.value.info["max pulse energy, uJ"];
        this.selectedProduct = specs.value;
        this.productName = specs.key;
      }
      //   this.selectedProduct = specs;
      this.restart();
    },
    restart() {
      this.energy_max = this.selectedProduct.info["max pulse energy, uJ"];

      this.title =
        this.productName +
        "-" +
        this.power +
        "W-" +
        "energy1:" +
        this.Pump_energy_1 +
        "uJ-" +
        "energy2:" +
        this.Pump_energy_2 +
        "uJ";
      this.plot("tuningcurves_plot");
    },
    plot(caller) {
      var i, a0, a1, a2, a3, freq;
      var x = [],
        y = [],
        xrange = [],
        yrange = [],
        xticks = [],
        yticks = [],
        xseries = [];

      var target_div = caller;

      var freq_max = this.selectedProduct.info["max repetition rate, kHz"];

      var productName = this.productName;

      this.title =
        productName +
        "-" +
        this.power +
        "W-" +
        this.energy_max +
        "uJ-" +
        "energy1:" +
        this.Pump_energy_1 +
        "uJ-" +
        "energy2:" +
        this.Pump_energy_2 +
        "uJ";

      var yval = {
        title: productName + "-" + this.power + "W-" + this.energy_max + "uJ",
        val: [],
        tooltip: [],
      };

      var get_conversion = (type, x) => {
        a0 = this.selectedProduct[type]["a0"];
        a1 = this.selectedProduct[type]["a1"];
        a2 = this.selectedProduct[type]["a2"];
        a3 = this.selectedProduct[type]["a3"];
        return a0 + a1 * x + a2 * x * x + a3 * x * x * x;
      };

      xrange = { min: 1, max: freq_max };
      yrange = { min: 0.1, max: this.energy_max };

      let dataSteps = [];

      for (let i = 1; i <= 10 && i <= freq_max; i++) {
        dataSteps.push(i);
      }
      for (let i = 20; i <= 100 && i <= freq_max; i += 10) {
        dataSteps.push(i);
      }
      for (let i = 200; i <= freq_max; i += 100) {
        dataSteps.push(i);
      }
      
      for (i = 0; i < dataSteps.length; i++) {
        freq = dataSteps[i];
        x[i] = parseFloat(freq);
        yval.val[i] =
          (this.power / freq) * 1000.0 < this.energy_max
            ? (this.power / freq) * 1000.0
            : this.energy_max;
        yval.tooltip[i] =
          freq.toFixed(1) + " kHz, " + yval.val[i].toFixed(1) + " uJ";
      }

      y.push(yval);

      xseries.push({
        isStacked: true,
        targetAxisIndex: 1,
        areaOpacity: 0.0,
        color: colors["hrD"],
        visibleInLegend: true,
      });

      var energies = [];
      if (this.Pump_energy_1 > 0 && this.Pump_energy_1 <= this.energy_max)
        energies.push(this.Pump_energy_1);
      if (this.Pump_energy_2 > 0 && this.Pump_energy_2 <= this.energy_max)
        energies.push(this.Pump_energy_2);

      var types = Object.keys(this.selectedProduct).filter(
        (key) => key !== "info"
      );
      
      for (const energy of energies) {
        for (const type of types) {
          yval = {
            title: type.toUpperCase() + " (" + energy + "uJ pump)",
            val: [],
            tooltip: [],
          };


          for (i = 0; i < dataSteps.length; i++) {
            freq = dataSteps[i];
            var energy_fundamental =
              (this.power / freq) * 1000.0 < this.energy_max
                ? (this.power / freq) * 1000.0
                : this.energy_max;
            var conversion = get_conversion(type, energy_fundamental / energy);
            var energy_converted = (conversion / 100.0) * energy_fundamental;

            if (energy_fundamental <= energy && energy_converted >= 0.2)
              yval.tooltip[i] =
                freq.toFixed(1) +
                " kHz, " +
                energy_converted.toFixed(1) +
                " uJ" +
                " (" +
                energy +
                "uJ pump), " +
                conversion.toFixed(2) +
                "% conversion";
            else energy_converted = null;

            yval.val[i] = energy_converted;
          }
          y.push(yval);
          
          xseries.push({
            lineDashStyle: energy == energies[0] ? [10, 0] : [5, 3],
            color: colors[type],
          });
        }
      }
      
      if (this.selectedProduct.info["special notes"] == "NaN") {
        $("#special_notes").hide();
      } else {
        $("#special_notes").html(this.selectedProduct.info["special notes"]);
        $("#special_notes").show();
      }

      xticks = xticksList.filter((stick) => {
        return stick >= xrange.min && stick <= xrange.max;
      });

      var yticksfiltered = yticksList.filter((stick) => {
        return stick >= yrange.min && stick <= yrange.max;
      });

      var marginStick =
        yticksList[
          yticksList.indexOf(yticksfiltered[yticksfiltered.length - 1]) + 1
        ];

      if (marginStick) {
        yticksfiltered = yticksfiltered.concat(marginStick);
      }

      yticks = yticksfiltered.map((stick) => {
        return { v: stick, f: stick };
      });

      var width = $("#" + this.target_div).width();
      var xAxisName = { title: "Repetition rate", units: "kHz" };
      var yAxisName = { title: "Energy", units: "\u03BCJ" };
      
      var options = {
        isStacked: true,
        title:
          productName +
          " " +
          this.energy_max.toFixed(0) +
          "\u03BCJ, " +
          this.power.toFixed(1) +
          " W",
        legend: {
          position: "top",
          maxLines: 4,
          textStyle: { fontSize: 12 },
          translate: "30,60",
        },
        chartArea: {
          backgroundColor: { stroke: "#000", strokeWidth: 1 },
          left: 80,
          right: 80,
          bottom: 80,
          top: "10%",
        },
        width: width,
        height: width / 1.2,
        pointSize: 4,
        vAxes: {
          0: {
            title: "Energy, \u03BCJ",
            viewWindowMode: "explicit",
            viewWindow: { min: yticks[0], max: yticks[yticks.length - 1] },
            logScale: true,
            titleTextStyle: { italic: false },
            ticks: yticks,
          },
        },
        series: xseries,
        hAxis: {
          logScale: true,
          titleTextStyle: { italic: false },
          viewWindow: { min: xticks[0], max: xticks[xticks.length - 1] },
          ticks: xticks,
          format: "#",
          slantedText: true,
          slantedTextAngle: 90,
        },
      };

      setTimeout(function () {
        drawTuningCurvesD3(x, y, target_div, xAxisName, yAxisName, options);
      }, 50);
      return;
    },
  },
};
</script>
<style lang="css" scoped>
label {
  display: block;
  padding-left: 15px;
  text-indent: -15px;
}

input {
  width: 35px;
  height: 35px;
  padding: 0;
  margin: 0;
  vertical-align: bottom;
  position: relative;
  top: -1px;
}
input[type="checkbox"] {
  width: 15px;
  height: 15px;
}

label small {
  font-weight: normal;
  color: #999;
}
.tooltip2 .tooltiptext2 {
  top: -50px;
  left: 0px;
  width: 130px;
  right: 0px;
  font-size: 11px;
  visibility: visible;
}

.panel-image {
  width: 100%;
}
</style>
