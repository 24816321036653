<template lang="">
  <table
    v-if="terminals != null"
    class="table"
  >
    <thead>
      <tr>
        <th scope="col">
          #
        </th>
        <th scope="col">
          Left
        </th>
        <th scope="col">
          Top
        </th>
        <th
          v-if="edit || newTerminals"
          scope="col"
        />
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(item, index) in terminals"
        :key="index"
      >
        <th scope="row">
          {{ index }}
        </th>
        <td v-if="!edit && !newTerminals">
          {{ item.left }}
        </td>
        <td v-if="edit || newTerminals">
          <input
            v-model="item.left"
            type="number"
            step="0.01"
            class="form-control indicator"
            @change="onChange"
          >
        </td>
        <td v-if="!edit && !newTerminals">
          {{ item.top }}
        </td>
        <td v-if="edit || newTerminals">
          <input
            v-model="item.top"
            type="number"
            step="0.01"
            class="form-control indicator"
            @change="onChange"
          >
        </td>
        <td v-if="edit || newTerminals">
          <button
            type="button"
            class="btn btn-sm btn-danger"
            @click="$emit('remove', index)"
          >
            <span class="glyphicon glyphicon-trash" />
          </button>
        </td>
      </tr>
      <tr v-if="edit || newTerminals">
        <td scope="col" />
        <td scope="col" />
        <td scope="col" />
        <td scope="col">
          <button
            v-if="edit || newTerminals"
            type="button"
            class="btn btn-sm btn-success"
            @click="$emit('add')"
          >
            <span class="glyphicon glyphicon-plus" />
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <div
    v-if="!newTerminals"
    class="btn-group"
    role="group"
  >
    <button
      v-if="!edit"
      type="button"
      class="btn btn-sm btn-primary"
      @click="edit = !edit"
    >
      Edit
    </button>
    <button
      v-if="edit"
      type="button"
      class="btn btn-sm btn-default"
      @click="edit = !edit"
    >
      Back
    </button>
    <button
      v-if="edit"
      type="button"
      class="btn btn-sm btn-default"
      @click="$emit('save'); edit = !edit;"
    >
      Save
    </button>
  </div>
</template>
<script>
export default {
  props: {
    terminals: {
      default() {
        return []
      }
    },
    newTerminals: {
      default() {
        return false
      }
    },
  },
  emits: ["add", "change", "remove", "save"],
  data() {
    return {
      edit: false,
    };
  },
  methods: {
    onChange() {
      this.$emit("change", this.terminals);
    },
  }
};
</script>
<style lang=""></style>
