<template lang="">
  <div class="row">
    <div class="col-lg-12">
      <h5>{{ name }}</h5>
    </div>

    <div class="col-lg-5 col-xs-6 no-right-padding">
      <div :class="`${outOfBoundsError ? 'input-group has-error' : 'input-group'}`">
        <span class="input-group-addon">
          <label class="myCheckbox">
            <input
              type="checkbox"
              aria-label="Lock power"
              title="Lock power"
              @click="lock"
            >
            <span />
          </label>
        </span>
        <div
          v-if="outOfBoundsError"
          class="tooltip2"
        >
          <span class="tooltiptext2">Out of bounds</span>
        </div>
        <input
          :id="name"
          v-model="inputValue"
          data-toggle="tooltip"
          data-trigger="manual"
          data-placement="top"
          :title="name"
          step="0.1"
          min="0.1"
          type="number"
          class="form-control indicator"
          tabindex="2"
          @change="onInputChange()"
        >
        <span
          v-if="name === 'Pulse Energy'"
          class="input-group-addon"
        >μJ</span>
        <span
          v-if="name === 'Power'"
          class="input-group-addon"
        >W</span>
        <span
          v-if="name === 'Repetition rate'"
          class="input-group-addon"
        >kHz</span>
      </div>
    </div>
    <div class="col-lg-7 col-xs-6 top-padding10">
      <Slider
        v-model="slider.value"
        v-bind="slider"
        @update="onSliderChange"
      />
    </div>
  </div>
</template>
<script>
import Slider from "@vueform/slider";

export default {
  components: {
    Slider,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    range: {
      type: Object,
      required: true,
    },
    value:null,
    initValue: {
      type: Number,
      required: false,
    },
  },
  emits: ["onValueChange", "freeze", "error"],
  data() {
    return {
      slider: {
        animate: false,
        value: 1,
        lazy: false,
        showTooltip: "drag",
        step: -1,
      },
      inputValue: 1,
      outOfBoundsError: false,
    };
  },
  watch: {
    range(newValue) {
      this.slider.max = newValue.max;
      this.slider.min = newValue.min;

      if (this.slider.value > newValue.max || this.slider.value < newValue.min) this.outOfBoundsError = true;
    },
    outOfBoundsError() {
      this.$emit("error");
    },
    value(newValue) {
      
      this.outOfBoundsError = false;
      if (newValue > this.range.max) {
        this.slider.value = this.range.max;
        this.outOfBoundsError = true;
      } else if (newValue < this.range.min) {
        this.slider.value = this.range.min;
        this.outOfBoundsError = true;
      } else this.slider.value = newValue;
      {
        this.inputValue = newValue;
      }
    },
  },
  mounted() {
    this.slider.max = this.range.max;
    this.slider.min = this.range.min;
    this.slider.value = this.initValue;
    this.inputValue = this.value;
  },
  methods: {
    onInputChange() {
      this.slider.value = this.inputValue;
    },
    onSliderChange() {
      this.$emit("onValueChange", this.slider.value);
    },
    lock() {
      this.$emit("freeze");
    },
    setSliderValue(value) {
      this.slider.value = value;
      this.inputValue = value;
    },
  },
};
</script>
<style lang="css"></style>
