//BANDWIDTH CONVERTER
//converts FWHM to e-1 or sigma
export function bc_FWHM_to(FWHM, type) {
  switch (type) {
    case "FWHM":
      return FWHM;
    case "sigma":
      return FWHM / 2.3548200450309493; // == FWHM / (2.0 * Math.sqrt(2.0*Math.log(2.0)));)
    case "e-1":
      return FWHM / 0.8325546111576977563531646448952; // == FWHM / (Math.sqrt(Math.log(2.0))) = sigma * 2 * Math.sqrt(2.0)
    case "e-2":
      return FWHM / 0.58870501125773734550578466322985; // == FWHM * Math.sqrt(2.0/Math.log(2.0))
  }
}

//BANDWIDTH CONVERTER
//converts anything to FWHM
export function bc_to_FWHM(value, type) {
  switch (type) {
    case "FWHM":
      return value;
    case "sigma":
      return value * 2.3548200450309493;
    case "e-1":
      return value * 0.8325546111576977563531646448952;
    case "e-2":
      return value * 0.58870501125773734550578466322985;
  }
}

//converts super-gaussian bandwidth anything to FWHM
export function sg_bc_to_FWHM(value, type, n) {
  switch (type) {
    case "FWHM":
      return value;
    case "e-2":
      return value * Math.pow(0.5 * Math.log(2.0), 0.5 / n);
    case "e-1":
      return value * Math.pow(0.5 * Math.log(2.0), 0.5 / n);
  }
}
