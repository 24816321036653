<template lang="">
  <div>
    <Planner
      v-if="$store.state.dataReady"
      :show-selector="true"
    />
  </div>
</template>
<script>
import Planner from "@/components/planner/KitchenPlanner.vue";
export default {
  components: {
    Planner,
  },
};
</script>
<style lang=""></style>
