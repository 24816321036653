<template>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
        >
          &times;
        </button>
        <h4 class="modal-title">
          Group velocity dispersion
        </h4>
      </div>
      <div class="modal-body">
        <p>
          Group velocity dispersion (GVD) in material with refraction index
          <math-jax latex="n(\lambda)" />:
          <math-jax
            latex="\mathrm{GVD}(\lambda) = \frac{\lambda^3}{2\pi
          c^2}\frac{\partial^2 n(\lambda)}{\partial \lambda^2}."
            :block="true"
          />
        </p>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-default"
          data-dismiss="modal"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
