<template>
  <div
    :id="id"
    class="modal"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
          >
            &times;
          </button>
          <h4 class="modal-title">
            Conversion from frequency
          </h4>
        </div>
        <div class="modal-body">
          Wavelength 
          <math-jax
            latex="\lambda = \frac{c}{f} \Longrightarrow \lambda[\mathrm{nm}] \approx \frac
          {299792.458}{f[\mathrm{THz}]}"
            :block="true"
          /> 
          Wavenumber
          <math-jax
            latex="k = \frac{f}{c} \Longrightarrow \approx 33.356 \cdot
          f[\mathrm{THz}]"
            :block="true"
          />
          Optical period 
          <math-jax
            latex="T = \frac{1}{f} \Longrightarrow T[\mathrm{fs}] =
          \frac{10^3}{f[\mathrm{THz}]}"
            :block="true"
          />
          Angular frequency 
          <math-jax
            latex="\omega = 2\pi f \Longrightarrow \omega[\mathrm{cm^{-1}}]
          \approx \frac{f[\mathrm{THz}]}{159.160}"
            :block="true"
          />
          Energy 
          <math-jax
            latex="E = 2\pi\hbar f \Longrightarrow E[\mathrm{eV}] \approx
          \frac{f[\mathrm{THz}]}{241.764} "
            :block="true"
          />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-default"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: null,
  },
};
</script>
