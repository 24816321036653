<template lang="">
  <div
    v-if="$store.state.dataReady"
    id="prismpair"
    class="row"
  >
    <!-- TITLE -->
    <div class="col-lg-12 col-md-12 col-xs-12">
      <h3>
        Two prism compressor
        <small><span
          data-toggle="collapse"
          data-target="#info_prismpair"
          class="glyphicon glyphicon-info-sign info-glyph"
        /></small>
      </h3>
    </div>
    <!-- OUTPUT/PLOT -->
    <div class="col-lg-6 col-md-6 col-xs-12">
      <div
        id="info_prismpair"
        class="collapse"
      >
        <p>
          Dispersion parameters of two-prism compressor/stretcher for given material and wavelength
          <math-jax latex="lambd" />. Initial geometry (distances <math-jax latex="h" /> and <math-jax latex="w" />) is
          calculated using parameters of the reference beam (wavelength <math-jax latex="\lambda_0" />, prism separation
          <math-jax latex="L" /> and insertions <math-jax latex="l_1" />, <math-jax latex="l_2" />). Optionally,
          Brewster configuration can be chosen for any given reference wavelength. Output - double-pass
          <abbr title="Group delay dispersion">GDD</abbr> and <abbr title="Third-order dispersion">TOD</abbr>, distances
          <math-jax latex="\mathrm{P}_{AB}" />, <math-jax latex="\mathrm{P}_{BC}" /> and
          <math-jax latex="\mathrm{P}_{CD}" />, distance of the point of incidence and the second prism apex
          <math-jax latex="l_2(\lambda)" />, critical wavelength <math-jax latex="\lambda_\mathrm{c}" />, for which rays
          of <math-jax latex="\lambda<\lambda_\mathrm{c}" /> do not hit the surface of the second prism. Prism heights
          are infinite.
        </p>
        <img
          class="img-center"
          src="@/assets/static/media/svg/prismpair.svg"
        >
      </div>

      <div class="row">
        <div class="col-xs-6">
          <h5 for="GDD_text">
            GDD
            <span
              class="glyphicon glyphicon-info-sign info-glyph"
              data-toggle="modal"
              data-target="#description_GDD"
            />
          </h5>
          <div class="input-group">
            <input
              id="GDD_text"
              v-model="GDD"
              type="number"
              class="form-control indicator"
              readonly
            >
            <span class="input-group-addon">fs&sup2;</span>
          </div>
        </div>

        <div class="col-xs-6">
          <h5 for="TOD_text">
            TOD
            <span
              class="glyphicon glyphicon-info-sign info-glyph"
              data-toggle="modal"
              data-target="#description_TOD"
            />
          </h5>
          <div class="input-group">
            <input
              v-model="TOD"
              tid="TOD_text"
              type="number"
              class="form-control indicator"
              readonly
            >
            <span class="input-group-addon">fs&sup3;</span>
          </div>
        </div>

        <div class="col-xs-12">
          <h5>
            Output
            <span
              class="glyphicon glyphicon-info-sign info-glyph"
              data-toggle="modal"
              data-target="#description_output"
            />
          </h5>
          <div class="form-group">
            <textarea
              v-model="prismpair_textarea_text"
              class="form-control"
              rows="8"
              readonly
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div id="prismpair_GDD_plot" />
      </div>
      <h5>
        Wavelength range: <span>{{ rangeSlider.value[0] }}</span>-<span>{{ rangeSlider.value[1] }}</span> nm
      </h5>
      <div class="top-padding">
        <Slider
          v-model="rangeSlider.value"
          v-bind="rangeSlider"
          @update="onRangeSliderChange()"
        />
      </div>
    </div>
    <!-- INPUT -->
    <div class="col-lg-6 col-md-6 col-xs-12">
      <!-- MATERIAL SELECT -->
      <Selector
        :list="materials"
        :list-name="'Materials'"
        :default-selection="'Al2O3'"
        @selectedMaterial="onSelection"
      />

      <!-- WAVELENGTH INPUT -->
      <h5>
        Wavelength range: <span>{{ wlrangeMin }}</span>-<span>{{ wlrangeMax }}</span> nm
      </h5>
      <div class="row">
        <div class="col-md-8 col-xs-6 top-padding">
          <Slider
            v-model="slider.value"
            v-bind="slider"
            @update="onSliderChange()"
          />
        </div>
        <div class="col-md-4 col-xs-6">
          <div class="input-group">
            <input
              id="wltext"
              v-model="inputValue"
              step="1"
              type="number"
              class="form-control indicator"
              tabindex="1"
              @change="onInputChange()"
            >
            <span class="input-group-addon">nm</span>
          </div>
        </div>
      </div>
      <div class="row">
        <!-- WAVELENGTH -->
        <div class="col-xs-6">
          <h5 for="wl0_text">
            Ref. wavelength <math-jax latex="\lambda_0" />
          </h5>
          <div class="input-group">
            <input
              id="wl0_text"
              v-model="wl0"
              min="0"
              max="30000"
              step="1"
              type="number"
              class="form-control indicator"
              tabindex="2"
            >
            <span class="input-group-addon">nm</span>
          </div>
        </div>
        <!-- DISTANCE L -->
        <div class="col-lg-6 col-xs-6">
          <h5 for="L_text">
            Prism separation <math-jax latex="L" />&nbsp;
            <span
              class="glyphicon glyphicon-info-sign info-glyph"
              data-toggle="modal"
              data-target="#description_L"
            />
          </h5>
          <div class="input-group">
            <input
              id="L_text"
              v-model="L"
              min="0"
              step="1"
              type="number"
              class="form-control indicator"
              tabindex="3"
            >
            <span class="input-group-addon">mm</span>
          </div>
        </div>
      </div>
      <div class="row bottom_align_row">
        <!-- AOI SELECT -->
        <div class="col-lg-4 col-xs-4 no-right-padding">
          <h5 for="theta0_text">
            AOI <math-jax latex="\vartheta_0" />
          </h5>
          <div class="input-group">
            <input
              v-model="theta0"
              min="0"
              max="90"
              step="1"
              type="number"
              class="form-control indicator"
              :disabled="brewsterConfiguration"
            >
            <span class="input-group-addon">deg</span>
          </div>
        </div>
        <!-- PRISM APEX ANGLE SELECT -->
        <div class="col-lg-4 col-xs-4 no-right-padding">
          <h5 for="alpha_text">
            Apex angle <math-jax latex="\alpha" />
          </h5>
          <div class="input-group">
            <input
              v-model="alpha"
              min="0"
              max="90"
              step="1"
              type="number"
              class="form-control indicator"
              :disabled="brewsterConfiguration"
            >
            <span class="input-group-addon">deg</span>
          </div>
        </div>
        <!-- BREWSTER CONFIGURATION BUTTON -->
        <div class="col-lg-4 col-xs-4">
          <h5>
            Brewster configuration
            <span
              class="glyphicon glyphicon-info-sign info-glyph"
              data-toggle="modal"
              data-target="#description_brewster_configuration"
            />
          </h5>
          <button
            v-if="!brewsterConfiguration"
            type="button"
            class="btn btn-default"
            style="width: 100%"
            @click="brewsterConfiguration = !brewsterConfiguration"
          >
            NO
          </button>
          <button
            v-if="brewsterConfiguration"
            type="button"
            class="btn btn-default active"
            style="width: 100%"
            @click="brewsterConfiguration = !brewsterConfiguration"
          >
            YES
          </button>
        </div>
      </div>
      <div class="row">
        <!-- INSERTION DISTANCE 1 -->
        <div class="col-xs-6">
          <h5 for="l1_text">
            Prism #1 insertion <math-jax latex="l_1" />&nbsp;
            <span
              class="glyphicon glyphicon-info-sign info-glyph"
              data-toggle="modal"
              data-target="#description_l1"
            />
          </h5>
          <div class="input-group">
            <input
              id="l1_text"
              v-model="l1"
              min="0"
              max="100"
              step="1"
              type="number"
              class="form-control indicator"
              tabindex="7"
            >
            <span class="input-group-addon">mm</span>
          </div>
        </div>

        <!-- INSERTION DISTANCE 2 -->
        <div class="col-xs-6">
          <h5 for="l2_text">
            Prism #2 insertion <math-jax latex="l_2" />&nbsp;
            <span
              class="glyphicon glyphicon-info-sign info-glyph"
              data-toggle="modal"
              data-target="#description_l2"
            />
          </h5>
          <div :class="`${hasError ? 'input-group has-error' : 'input-group'}`">
            <input
              v-model="l2"
              min="0"
              max="100"
              step="1"
              type="number"
              class="form-control indicator"
              tabindex="8"
            >
            <span class="input-group-addon">mm</span>
          </div>
          <p
            v-if="hasError"
            class="text-center text-danger"
          >
            Beam out of bounds
          </p>
        </div>
      </div>

      <div class="row">
        <!-- DISTANCE h -->
        <div class="col-xs-6">
          <h5 for="h_text">
            Height <math-jax latex="h" />&nbsp;
            <span
              class="glyphicon glyphicon-info-sign info-glyph"
              data-toggle="modal"
              data-target="#description_hw"
            />
          </h5>
          <div class="input-group">
            <input
              id="h_text"
              v-model="h"
              min="0"
              max="1000"
              step="1"
              type="number"
              class="form-control indicator"
              readonly
            >
            <span class="input-group-addon">mm</span>
          </div>
        </div>
        <!-- DISTANCE w -->
        <div class="col-xs-6">
          <h5 for="w_text">
            Width <math-jax latex="w" />&nbsp;
            <span
              class="glyphicon glyphicon-info-sign info-glyph"
              data-toggle="modal"
              data-target="#description_hw"
            />
          </h5>
          <div class="input-group">
            <input
              id="w_text"
              v-model="w"
              min="0"
              max="1000"
              step="1"
              type="number"
              class="form-control indicator"
              readonly
            >
            <span class="input-group-addon">mm</span>
          </div>
        </div>
      </div>
      <br>
    </div>
  </div>
  <BrewsterConfigurationDescriptionModal id="description_brewster_configuration" />
  <DistanceBetweenPrismDescriptionModal id="description_hw" />
  <GroupDelayDispersionDescriptionModal id="description_GDD" />
  <InsertionAngleFirstPrismDescriptionModal id="description_l1" />
  <InsertionAngleSecondPrismDescriptionModal id="description_l2" />
  <OutputPrismPairDescriptionModal id="description_output" />
  <PrismSeparationDescriptionModal id="description_L" />
  <ThirdOrderDispersionOfPrismPairDescriptionModal id="description_TOD" />
</template>

<script>
import Slider from "@vueform/slider";
import Selector from "@/components/Selector.vue";
import { rad_to_deg, deg_to_rad, c, pi, fsolve } from "@/tools/Utils.js";
import { RefractiveIndex } from "@/tools/MaterialDispersion.js";
import BrewsterConfigurationDescriptionModal from "@/components/descriptions/dispersion/BrewsterConfiguration.vue";
import DistanceBetweenPrismDescriptionModal from "@/components/descriptions/dispersion/DistanceBetweenPrism.vue";
import GroupDelayDispersionDescriptionModal from "@/components/descriptions/dispersion/GroupDelayDispersion.vue";
import InsertionAngleFirstPrismDescriptionModal from "@/components/descriptions/dispersion/InsertionAngleFirstPrism.vue";
import InsertionAngleSecondPrismDescriptionModal from "@/components/descriptions/dispersion/InsertionAngleSecondPrism.vue";
import OutputPrismPairDescriptionModal from "@/components/descriptions/dispersion/OutputPrismPair.vue";
import PrismSeparationDescriptionModal from "@/components/descriptions/dispersion/PrismSeparation.vue";
import ThirdOrderDispersionOfPrismPairDescriptionModal from "@/components/descriptions/dispersion/ThirdOrderDispersionOfPrismPair.vue";
import { drawPlotD3 } from "@/tools/Plot.js";
import DataService from "@/services/data.service";

export default {
  components: {
    Slider,
    Selector,
    BrewsterConfigurationDescriptionModal,
    DistanceBetweenPrismDescriptionModal,
    GroupDelayDispersionDescriptionModal,
    InsertionAngleFirstPrismDescriptionModal,
    InsertionAngleSecondPrismDescriptionModal,
    OutputPrismPairDescriptionModal,
    PrismSeparationDescriptionModal,
    ThirdOrderDispersionOfPrismPairDescriptionModal,
  },
  data() {
    return {
      material: null,
      inputValue: 1030,
      slider: {
        value: 1030,
        max: 2000,
        lazy: false,
        showTooltip: "drag",
        step: -1,
      },
      rangeSlider: {
        value: [0, 5000],
        min: 100,
        max: 1773,
        lazy: false,
        showTooltip: "drag",
        step: -1,
      },
      wlrangeMin: null,
      wlrangeMax: null,
      brewsterConfiguration: false,
      wl0: 800,
      L: 300,
      l1: 0,
      l2: 0,
      theta0Default: 0,
      alphaDefault: 0,
    };
  },
  computed: {
    materials(){
      return this.$store.getters.materials;
    },
    n() {
      if (this.material === null) return 0;
      return RefractiveIndex(this.wl0 / 1.0e3, 0, this.material);
    },
    theta0: {
      get: function () {
        if (this.brewsterConfiguration) return Math.round10(rad_to_deg(Math.atan(this.n)), -3);
        return this.theta0Default;
      },
      set: function (newValue) {
        this.theta0Default = newValue;
        this.drawPlot();
      },
    },
    alpha: {
      get: function () {
        if (this.brewsterConfiguration)
          return Math.round10(rad_to_deg(2.0 * Math.asin(Math.sin(Math.atan(this.n)) / this.n)), -3);
        return this.alphaDefault;
      },
      set: function (newValue) {
        this.alphaDefault = newValue;
        this.drawPlot();
      },
    },
    GDD() {
      if (this.material === null) return 0;
      return Math.round10(this.GDDPrismPair(this.inputValue), -3);
    },
    TOD() {
      if (this.material === null) return 0;
      return Math.round10(this.TODPrismPair(this.inputValue), -3);
    },
    h() {
      var alpha = deg_to_rad(this.alpha);
      var phi11 = deg_to_rad(this.theta0);
      var l1 = this.l1 / 1.0e3;
      var l2 = this.l2 / 1.0e3;
      var L = this.L / 1.0e3;

      var psi11 = Math.asin(Math.sin(phi11) / this.n);
      var psi21 = alpha - psi11;
      var phi21 = Math.asin(this.n * Math.sin(psi21));

      var l1_prime = (l1 * Math.cos(psi11)) / Math.cos(psi21);

      var P_BC = (L - (l1_prime + l2) * Math.sin(alpha)) / Math.cos(alpha - phi21);

      var h = (l1_prime + l2) * Math.cos(alpha / 2.0) + P_BC * Math.sin(phi21 - alpha / 2.0);
      return Math.round10(h * 1.0e3, -3);
    },
    w() {
      var alpha = deg_to_rad(this.alpha);
      var phi11 = deg_to_rad(this.theta0);

      var l1 = this.l1 / 1.0e3;
      var l2 = this.l2 / 1.0e3;
      var L = this.L / 1.0e3;

      var psi11 = Math.asin(Math.sin(phi11) / this.n);
      var psi21 = alpha - psi11;
      var phi21 = Math.asin(this.n * Math.sin(psi21));

      var l1_prime = (l1 * Math.cos(psi11)) / Math.cos(psi21);

      var P_BC = (L - (l1_prime + l2) * Math.sin(alpha)) / Math.cos(alpha - phi21);
      var w = (l1_prime + l2) * Math.sin(alpha / 2.0) + P_BC * Math.cos(phi21 - alpha / 2.0);

      return Math.round10(w * 1.0e3, -3);
    },
    prismpair_textarea_text() {
      if (this.material === null) return "";
      var path = this.prism_pair_path(this.inputValue);
      var alpha = deg_to_rad(this.alpha);
      var phi11 = deg_to_rad(this.theta0);
      var psi11 = Math.asin(Math.sin(phi11) / this.n);
      var psi21 = alpha - psi11;

      var l1_prime = (this.l1 * Math.cos(psi11)) / Math.cos(psi21);
      var phi21c =
        Math.atan((l1_prime * Math.cos(alpha / 2.0) - this.h) / (l1_prime * Math.sin(alpha / 2.0) - this.w)) +
        alpha / 2.0;

      var fun = function (phi21, phi11, alpha, material) {
        return function (wl) {
          var ri = RefractiveIndex(wl / 1.0e3, 0, material);
          return Math.sin(phi21) / ri - Math.sin(alpha - Math.asin(Math.sin(phi11) / ri));
        };
      };
      var fun_wl = fun(phi21c, phi11, alpha, this.material);
      var wlc = fsolve(fun_wl, this.wl0);
      if (Math.round(wlc) === this.wl0) wlc = NaN;
      var prismpair_textarea_text =
        "Distances (" +
        this.inputValue +
        " nm):\nAB\t " +
        (path.AB * 1.0e3).toFixed(3) +
        " mm\nBC\t" +
        (path.BC * 1.0e3).toFixed(3) +
        " mm\nCD\t" +
        (path.CD * 1.0e3).toFixed(3) +
        " mm\nDE\t" +
        (path.DE * 1.0e3).toFixed(3) +
        " mm\n---\nl2:\t" +
        (path.l2 * 1.0e3).toFixed(3) +
        " mm\n";
      prismpair_textarea_text += "Critical wavelength:\t" + (!isNaN(wlc) ? wlc.toFixed(3) * 1.0 + " nm" : "-");

      return prismpair_textarea_text;
    },
    hasError() {
      if (this.prism_pair_path(this.inputValue).l2 < 0) return true;
      return false;
    },
  },
  watch: {
    material(newValue) {
      this.wlrangeMin = parseFloat(newValue.parameters[0].wl_n_range[0]) * 1.0e3;
      this.slider.min = this.wlrangeMin;
      this.rangeSlider.min = this.wlrangeMin;
      if(this.wlrangeMin > this.rangeSlider.value[0]) this.rangeSlider.value[0] = this.wlrangeMin;
      this.wlrangeMax = parseFloat(newValue.parameters[0].wl_n_range[1]) * 1.0e3;
      this.slider.max = this.wlrangeMax;
      this.rangeSlider.max = this.wlrangeMax;
      if(this.wlrangeMax < this.rangeSlider.value[1]) this.rangeSlider.value[1] = this.wlrangeMax;
      if (this.slider.value > this.wlrangeMax) this.inputValue = this.wlrangeMax;
      if (this.slider.value < this.wlrangeMin) this.inputValue = this.wlrangeMin;
      this.drawPlot();
      this.theta0Default = Math.round10(rad_to_deg(Math.atan(this.n)), -3);
      this.alphaDefault = Math.round10(rad_to_deg(2.0 * Math.asin(Math.sin(Math.atan(this.n)) / this.n)), -3);
      this.drawPlot();
    },
    L() {
      this.drawPlot();
    },
    l1() {
      this.drawPlot();
    },
    l2() {
      this.drawPlot();
    },
    brewsterConfiguration() {
      this.theta0Default = Math.round10(rad_to_deg(Math.atan(this.n)), -3);
      this.alphaDefault = Math.round10(rad_to_deg(2.0 * Math.asin(Math.sin(Math.atan(this.n)) / this.n)), -3);
      this.drawPlot();
    },
  },
  methods: {
    onInputChange() {
      this.slider.value = this.inputValue;
    },
    onSliderChange() {
      this.inputValue = this.slider.value;
    },
    onRangeSliderChange() {
      this.drawPlot();
    },
    onSelection(material) {
      DataService.getMaterialData(material).then((response) => {
        this.material = response.data.value;
      });
    },
    GDDPrismPair(wl) {
      var delta_wl = 5.0;
      return (
        ((((this.prism_pair_path(wl + delta_wl).sum -
          2.0 * this.prism_pair_path(wl).sum +
          this.prism_pair_path(wl - delta_wl).sum) /
          Math.pow(delta_wl, 2.0)) *
          Math.pow(wl, 3.0)) /
          (2.0 * pi * c * c)) *
        1.0e21
      ); //in fs2
    },
    TODPrismPair(wl) {
      var delta_wl = 15.0;

      var d2P =
        (this.prism_pair_path(wl + delta_wl).sum -
          2.0 * this.prism_pair_path(wl).sum +
          this.prism_pair_path(wl - delta_wl).sum) /
        Math.pow(delta_wl, 2.0);
      var d3P =
        (this.prism_pair_path(wl + 1.5 * delta_wl).sum -
          3.0 * this.prism_pair_path(wl + 0.5 * delta_wl).sum +
          3.0 * this.prism_pair_path(wl - 0.5 * delta_wl).sum -
          this.prism_pair_path(wl - 1.5 * delta_wl).sum) /
        Math.pow(delta_wl, 3.0);

      return ((-(3.0 * d2P + wl * d3P) * Math.pow(wl, 4)) / (4 * pi * pi * c * c * c)) * 1.0e27; // in fs3
    },
    prism_pair_path(wl) {
      if (this.material === null) return 0;
      var n = RefractiveIndex(wl / 1.0e3, 0, this.material);
      var alpha = deg_to_rad(this.alpha);
      var theta0 = deg_to_rad(this.theta0);
      var l1 = this.l1 / 1.0e3;
      var w = this.w / 1.0e3;
      var h = this.h / 1.0e3;

      var phi11 = theta0;
      var psi11 = Math.asin(Math.sin(phi11) / n);
      var psi21 = alpha - psi11;
      var phi21 = Math.asin(n * Math.sin(psi21));

      var l1_prime = (l1 * Math.cos(psi11)) / Math.cos(psi21);

      var P_AB = (l1 * Math.sin(alpha)) / Math.cos(psi21);
      var P_BC =
        (w - h * Math.tan(alpha / 2.0)) /
        (Math.cos(phi21 - alpha / 2.0) - Math.sin(phi21 - alpha / 2.0) * Math.tan(alpha / 2.0));

      var l2 = (w - l1_prime * Math.sin(alpha / 2.0) - P_BC * Math.cos(phi21 - alpha / 2.0)) / Math.sin(alpha / 2.0);

      var l2_prime = (l2 * Math.cos(psi21)) / Math.cos(psi11);
      var P_DE = (h / Math.cos(alpha / 2.0) - l2_prime) * Math.sin(phi11);

      var P_CD = (l2 * Math.sin(alpha)) / Math.cos(psi11);

      return { sum: 2 * (P_AB * n + P_BC + P_CD * n + P_DE), AB: P_AB, BC: P_BC, CD: P_CD, DE: P_DE, l2: l2 };
    },
    drawPlot() {
      var x = [];
      for (var j = this.rangeSlider.value[0]; j <= this.rangeSlider.value[1]; j += 10) {
        x.push(j);
      }
      var ref = this;
      var fun = function (wl) {
        return ref.GDDPrismPair(wl);
      };
      var fun_TOD = function (wl) {
        return ref.TODPrismPair(wl);
      };

      drawPlotD3(
        x,
        [
          { title: "GDD", fun: fun },
          { title: "TOD", fun: fun_TOD },
        ],
        "prismpair_GDD_plot",
        { title: "Wavelength", units: "nm" },
        { title: "GDD (fs\u00B2), TOD (fs\u00B3)" },
        { explorer: { axis: "horizontal" } },
        { top: 15, right: 15, bottom: 50, left: 85 }
      );
    },
  },
};
</script>
