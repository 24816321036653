<template lang="">
  <div
    :id="id"
    class="modal"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
          >
            &times;
          </button>
          <h4 class="modal-title">
            Prism apex angle at minimal deviation
          </h4>
        </div>
        <div class="modal-body">
          <p>
            For given angle of incidence <math-jax latex="\vartheta_0" />, prism with apex angle
            <math-jax
              latex="\alpha_0=2\arcsin\frac{\sin\vartheta_0}{n}"
              :block="true"
            />
            would cause minimal possible deviation angle <math-jax latex="\delta" />. In that case the refraction angle is equal to the angle of incidence, <math-jax latex="\vartheta_0=\vartheta_1" />.
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-default"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: null,
  },
};
</script>
