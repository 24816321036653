<template lang="">
  <div
    id="tbconverter"
    class="row"
    ng-controller="bandwidthconverterCtrl"
  >
    <div class="col-xs-12">
      <h3>Pulse length-bandwidth converter</h3>
    </div>

    <div
      id="info_tbconverter"
      class="collapse col-xs-12"
    >
      <div class="row col-lg-5 col-md-6 col-xs-12" />
    </div>

    <div class="row">
      <div class="col-lg-6 col-xs-12">
        <div class="col-xs-6">
          <h5>Wavelength <math-jax latex="\lambda_0" /> </h5>
          <div class="input-group">
            <input
              ref="focusMe"
              v-model="bandwidthConverterParameters.lambda"
              type="number"
              step="1.0"
              class="form-control indicator"
              @change="update('lambda')"
            >
            <span class="input-group-addon">nm</span>
          </div>
        </div>
        <div class="col-xs-6">
          <h5>Bandwidth <math-jax latex="\Delta\lambda" /><small>[FWHM]</small></h5>
          <div class="input-group">
            <input
              v-model="bandwidthConverterParameters.deltalambda"
              type="number"
              data-toggle="popover"
              title="Enter bandwidth"
              step="1.0"
              class="form-control indicator"
              @change="update('deltalambda')"
            >
            <span class="input-group-addon">nm</span>
          </div>
          <br>
        </div>
        <div class="col-xs-6">
          <h5>Wavenumber <math-jax latex="k_0" /></h5>
          <div class="input-group">
            <input
              id="ktext"
              v-model="bandwidthConverterParameters.k"
              type="number"
              step="1.0"
              class="form-control indicator"
              @change="update('k')"
            >
            <span class="input-group-addon">cm&#8315;&sup1;</span>
          </div>
        </div>
        <div class="col-xs-6">
          <h5>Bandwidth <math-jax latex="\Delta k" /><small>[FWHM]</small></h5>
          <div class="input-group">
            <input
              id="deltaktext"
              v-model="bandwidthConverterParameters.deltak"
              type="number"
              step="1.0"
              class="form-control indicator"
              @change="update('deltak')"
            >
            <span class="input-group-addon">cm&#8315;&sup1;</span>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-xs-12 top_align_row">
        <div class="col-xs-6">
          <h5>
            Time-bandwidth product
            <span
              class="glyphicon glyphicon-info-sign info-glyph"
              data-toggle="modal"
              data-target="#description_TB_product"
            />
          </h5>
          <div style="position: relative">
            <input
              id="TBP_field"
              v-model="bandwidthConverterParameters.TBP"
              type="number"
              step="0.01"
              class="form-control indicator"
              @change="update('TBP')"
            >
          </div>
          <span
            id="TBP-preset-Gaussian"
            href="#"
            :class="`${
              TBPpreset === 'Gaussian'
                ? 'label label-default label-pointer label-success'
                : 'label label-default label-pointer'
            }`"
            @click="
              bandwidthConverterParameters.TBP = 0.441271;
              TBPpreset = 'Gaussian';
              update('TBP');
            "
          >Gaussian</span>
          <span
            id="TBP-preset-sech"
            href="#"
            :class="`${
              TBPpreset === 'sech'
                ? 'label label-default label-pointer label-success'
                : 'label label-default label-pointer'
            }`"
            @click="
              bandwidthConverterParameters.TBP = 0.314833;
              TBPpreset = 'sech';
              update('TBP');
            "
          >sech</span>
          <span
            id="TBP-preset-Lorentz"
            href="#"
            :class="`${
              TBPpreset === 'Lorentzian'
                ? 'label label-default label-pointer label-success'
                : 'label label-default label-pointer'
            }`"
            @click="
              bandwidthConverterParameters.TBP = 0.141999;
              TBPpreset = 'Lorentzian';
              update('TBP');
            "
          >Lorentzian</span>
        </div>
        <div class="col-xs-6">
          <h5>Pulse length <small>[FWHM]</small></h5>
          <div class="input-group">
            <input
              id="deltaktext"
              v-model="bandwidthConverterParameters.deltat"
              type="number"
              step="1.0"
              class="form-control indicator"
              @change="update('deltat')"
            >
            <span class="input-group-addon">fs</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <TBProductDescriptionModal id="description_TB_product" />
</template>
<script>
import TBProductDescriptionModal from "@/components/descriptions/converters/TBProduct.vue";
import { UpdateParameter } from "@/tools/BandwidthConverterParameter";

export default {
  components: {
    TBProductDescriptionModal,
  },
  data() {
    return {
      TBPpreset: "Gaussian",
      bandwidthConverterParameters: {
        lambda: null,
        deltak: null,
        deltalambda: null,
        k: null,
        k_bw_type: "FWHM",
        k_bw_old_type: "FWHM",
        lambda_bw_type: "FWHM",
        lambda_bw_old_type: "FWHM",
        TBP: 0.441271,
      },
    };
  },
  mounted () {
    this.$refs.focusMe.focus()    
  },
  methods: {
    update(parameter) {
      this.bandwidthConverterParameters = UpdateParameter(parameter, this.bandwidthConverterParameters);
    },
  }
};
</script>
<style lang=""></style>
