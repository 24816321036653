<template lang="">
  <h4 v-if="preset !== null">
    {{ preset.title }}
  </h4>
  <img
    :src="imageUrl"
    alt=""
  >
</template>
<script>
export default {
  props: {
    preset: null,
  },
  computed: {
    imageUrl() {
      if (this.preset == null) return;
      return (
        process.env.VUE_APP_KITCHEN +
        this.preset.imageFilename.slice(2) +
        "?" +
        Date.now()
      );
    },
  },
};
</script>
<style lang="css" scoped>
img {
  width: 350px;
  height: auto;
  object-fit: cover;
}
</style>
