<template>
  <div
    :id="id"
    class="modal"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
          >
            &times;
          </button>
          <h4 class="modal-title">
            Conversion from energy
          </h4>
        </div>
        <div class="modal-body">
          Wavelength
          <math-jax
            latex="\lambda = \frac{2\pi c\hbar}{E} \Longrightarrow \lambda[\mathrm{nm}] \approx
          \frac{1239.841}{E[\mathrm{eV}]}"
            :block="true"
          />
          Wavenumber
          <math-jax
            latex="k = \frac{E}{2\pi c\hbar} \Longrightarrow k[\mathrm{cm^{-1}}]
          \approx 8065.550 \cdot E[\mathrm{eV}]"
            :block="true"
          />
          Optical period
          <math-jax
            latex=" T = \frac{2\pi\hbar}{E} \Longrightarrow
          T[\mathrm{fs}] \approx \frac{4.136}{E[\mathrm{eV}]}"
            :block="true"
          />
          Angular frequency 
          <math-jax
            latex="\omega = \frac{E}{\hbar}
          \Longrightarrow \omega \approx 1.519\cdot E[\mathrm{eV}]"
            :block="true"
          />
          Frequency
          <math-jax
            latex="f = \frac{E}{2\pi\hbar}
          \Longrightarrow f[\mathrm{THz}] \approx 241.764 \cdot E[\mathrm{eV}] "
            :block="true"
          />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-default"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: null,
  },
};
</script>
