<template lang="">
  <div
    :id="id"
    data-backdrop="static"
    data-keyboard="false"
    class="modal"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <!-- <button type="button" class="close" data-dismiss="modal">&times;</button> -->
          <h4 class="modal-title">
            <span class="glyphicon glyphicon-cog" /> Layout settings
          </h4>
        </div>
        <div class="modal-body">
          <input
            id="layout-title"
            v-model="layoutSettings.title"
            placeholder="Layout title (optional)"
            type="text"
            style="width: 100%"
            class="form-control"
          >

          <div class="row">
            <div class="col-lg-7">
              <label for="layout-author">Author:</label>
              <input
                id="layout-author"
                v-model="layoutSettings.author"
                type="text"
                style="width: 100%"
                class="form-control"
              >
            </div>
            <div class="col-lg-5">
              <label for="layout-email">E-mail:</label>
              <input
                id="layout-email"
                v-model="layoutSettings.email"
                type="text"
                style="width: 100%"
                class="form-control"
              >
            </div>
          </div>

          <label for="layout-comments">Additional information:</label>
          <textarea
            id="layout-comments"
            v-model="layoutSettings.additionalInformation"
            class="form-control"
            style="width: 100%"
          />

          <div
            v-if="newTable && presets"
            class="btn-group top-padding10"
            style="width: 100%"
          >
            <a
              id="layout-empty-btn"
              :class="`${usePreset ? 'btn btn-default' : 'btn btn-default active'}`"
              href="#layout-empty"
              data-toggle="tab"
              style="width: 50%"
              @click="usePreset = false"
            >empty layout</a>
            <a
              id="layout-preset-btn"
              :class="`${preset ? 'btn btn-default active' : 'btn btn-default'}`"
              href="#layout-preset"
              data-toggle="tab"
              style="width: 50%"
              @click="usePreset = true"
            >
              based on preset</a>
          </div>
          <div class="tab-content top-padding10">
            <div
              v-if="!usePreset"
              id="layout-empty"
              class="tab-pane active"
            >
              <table
                class="table-condensed"
                align="center"
              >
                <thead>
                  <tr>
                    <th
                      width="50%"
                      align="center"
                    >
                      <h5>Metric <small>[cm]</small></h5>
                    </th>
                    <th
                      width="50%"
                      align="center"
                    >
                      <h5>Imperial <small>[ft]</small></h5>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div class="radio">
                        <label>
                          <input
                            id="table-metric-1"
                            v-model="layoutSettings.size"
                            type="radio"
                            name="table_radio"
                            value="200x120cm"
                          >200x120
                        </label>
                      </div>
                      <div class="radio">
                        <label>
                          <input
                            id="table-metric-2"
                            v-model="layoutSettings.size"
                            type="radio"
                            name="table_radio"
                            value="300x150cm"
                          >300x150
                        </label>
                      </div>
                      <div class="radio">
                        <label><input
                          id="table-metric-3"
                          v-model="layoutSettings.size"
                          type="radio"
                          name="table_radio"
                          value="200x80cm"
                        >200x80</label>
                      </div>
                      <div class="radio">
                        <label>
                          <input
                            id="table-metric-4"
                            v-model="layoutSettings.size"
                            type="radio"
                            name="table_radio"
                            value="custom_cm"
                          >
                          <div class="form-group">
                            <input
                              id="metric_width"
                              v-model="size.w"
                              type="number"
                              min="50"
                              max="500"
                              class="form-control"
                              style="width: 40%; display: inline"
                              @change="layoutSettings.size = 'custom_cm'"
                            >
                            x
                            <input
                              id="metric_height"
                              v-model="size.h"
                              type="number"
                              min="50"
                              max="500"
                              class="form-control"
                              style="width: 40%; display: inline"
                              @change="layoutSettings.size = 'custom_cm'"
                            >
                          </div>
                        </label>
                      </div>
                    </td>
                    <td>
                      <div class="radio">
                        <label><input
                          id="table-imperial-1"
                          v-model="layoutSettings.size"
                          type="radio"
                          name="table_radio"
                          value="7x4ft"
                        >7x4</label>
                      </div>
                      <div class="radio">
                        <label><input
                          id="table-imperial-2"
                          v-model="layoutSettings.size"
                          type="radio"
                          name="table_radio"
                          value="10x5ft"
                        >10x5</label>
                      </div>
                      <div class="radio">
                        <label><input
                          id="table-imperial-3"
                          v-model="layoutSettings.size"
                          type="radio"
                          name="table_radio"
                          value="7x3ft"
                        >7x3</label>
                      </div>
                      <div class="radio">
                        <label>
                          <input
                            id="table-imperial-4"
                            v-model="layoutSettings.size"
                            type="radio"
                            name="table_radio"
                            value="custom_ft"
                          >
                          <div class="form-group">
                            <input
                              id="imperial_width"
                              v-model="sizeFT.w"
                              type="number"
                              min="1"
                              max="16"
                              class="form-control"
                              style="width: 40%; display: inline"
                              @change="layoutSettings.size = 'custom_ft'"
                            >
                            x
                            <input
                              id="imperial_height"
                              v-model="sizeFT.h"
                              type="number"
                              min="1"
                              max="16"
                              class="form-control"
                              style="width: 40%; display: inline"
                              @change="layoutSettings.size = 'custom_ft'"
                            >
                          </div>
                        </label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              v-if="usePreset && newTable"
              id="layout-preset"
              class="tab-pane row"
            >
              <div class="col-lg-4">
                <div
                  v-if="presets"
                  id="menu"
                >
                  <div
                    v-for="(category, index) in presets"
                    :key="index"
                    class="panel panel-default top-0"
                  >
                    <a
                      href="#"
                      class="list-group-item"
                      data-toggle="collapse"
                      data-parent="#menu"
                      :data-target="'#' + firstWord(category.category)"
                    ><span class="glyphicon glyphicon-chevron-right" /> {{ category.category }}
                    </a>
                      
                    <div
                      :id="firstWord(category.category)"
                      class="sublinks collapse "
                    >
                      <a
                        v-for="(preset, index) in category.list"
                        :key="index"
                        href="#"
                        :class="`${selectedPreset === preset ? 'list-group-item small active' : 'list-group-item small'}`"
                        style="border-radius: 0px;"
                        @click="selectedPreset = preset"
                      >
                        {{ preset.title }}</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-8">
                <PresetInfo
                  v-if="selectedPreset !== null"
                  :preset="selectedPreset"
                /> 
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            id="table-settings-close-btn"
            type="button"
            class="btn btn-default"
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            v-if="newTable"
            type="button"
            class="btn btn-default"
            data-dismiss="modal"
            @click="applySettings"
          >
            New layout
          </button>
          <button
            v-if="!newTable"
            type="button"
            class="btn btn-default"
            data-dismiss="modal"
            @click="applySettings"
          >
            Apply settings
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PresetInfo from "@/components/planner/PresetInfo.vue";

export default {
  components: {
    PresetInfo,
  },
  props: {
    newTable: {
      type: Boolean,
      default() {
        return false;
      },
    },
    settings: null,
  },
  data() {
    return {
      custom_cm: null,
      layoutSettings: {
        title: null,
        author: null,
        email: null,
        additionalInformation: null,
        size: "200x120cm",
      },
      size: {
        w: null,
        h: null,
      },
      sizeFT: {
        w: null,
        h: null,
      },
      id: null,
      usePreset: false,
      selectedPreset: null,
    };
  },
  computed: {
    presets() {
      return this.$store.getters.presets;
    },
  },
  watch: {
    settings(newValue){
      this.layoutSettings = newValue;
      if(newValue.size.includes("cm") && newValue.size !== "200x120cm" && newValue.size !== "300x150cm" && newValue.size !== "200x80cm" ){
        this.size.w = newValue.size.split(/[^0-9]/)[0];
        this.size.h = newValue.size.split(/[^0-9]/)[1]; 
        this.layoutSettings = newValue;
        this.layoutSettings.size = "custom_cm";
      }
      if(newValue.size.includes("ft") && newValue.size !== "7x4ft" && newValue.size !== "10x5ft" && newValue.size !== "7x3ft" ){
        this.sizeFT.w = newValue.size.split(/[^0-9]/)[0];
        this.sizeFT.h = newValue.size.split(/[^0-9]/)[1]; 
        this.layoutSettings = newValue;
        this.layoutSettings.size = "custom_ft";
      }
    },
  },
  methods: {
    applySettings() {
      if (this.layoutSettings.size === "custom_cm" && this.size.w !== null && this.size.h !== null) {
        this.layoutSettings.size = this.size.w + "x" + this.size.h + "cm";
      }
      if (this.layoutSettings.size === "custom_ft" && this.size.w !== null && this.size.h !== null) {
        this.layoutSettings.size = this.sizeFT.w + "x" + this.sizeFT.h + "ft";
      }
      if(this.newTable && this.selectedPreset != null){
        this.layoutSettings.presetName = this.selectedPreset.schemeId;
        this.$emit("add", this.layoutSettings);
        this.usePreset = false;
      }else {
        this.$emit("add", this.layoutSettings);
      }
    },
    firstWord(string){
      return string.split(" ")[0]
    },
  },
};
</script>
<style lang="css" scoped>
.panel {
    margin-top: -5px!important; 
    margin-bottom: 0!important; 
    margin-left: 0!important; 
    margin-right: 0!important;  
    border: 0;
}


</style>
