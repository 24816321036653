<template lang="">
  <div
    :id="id"
    class="modal"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
          >
            &times;
          </button>
          <h4 class="modal-title">
            Beam displacement
          </h4>
        </div>
        <div class="modal-body">
          <p>
            Lateral shift of optical axis after passing through a slab of thickness <math-jax latex="h" />, refractive index <math-jax latex="n=n(\lambda)" />
            at angle of indicence <math-jax latex="\vartheta_0" />,
            <math-jax
              latex="d = h \sin\vartheta_0\left( 1 - \sqrt{\frac{1-\sin^2\vartheta_0}{n^2-\sin^2\vartheta_0}}\right)."
              :block="true"
            />
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-default"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: null,
  },
};
</script>
