<template lang="">
  <div class="row padding-bottom-sm">
    <!-- MATERIAL SELECT TEMPLATE -->
    <div v-if="withThickness">
      <div class="col-lg-7 col-xs-7 no-right-padding">
        <Selector
          :list="materials"
          :list-name="'Materials'"
          :default-selection="defaultMaterialName"
          @selectedMaterial="onSelection"
        />
      </div>
      <div class="col-lg-3 col-xs-3">
        <input
          v-model="hValue"
          min="0.1"
          step="0.1"
          type="number"
          class="form-control indicator"
          @input="onInputChange"
        >
      </div>
    </div>

    <div v-if="!withThickness">
      <div class="col-lg-10 col-xs-10">
        <Selector
          :list="materials"
          :list-name="'Materials'"
          :default-selection="defaultMaterialName"
          @selectedMaterial="onSelection"
        />
      </div>
    </div>
    <div class="col-lg-2 col-xs-2 no-left-padding">
      <button
        type="button"
        class="btn btn-info btn-add-remove"
        href="#"
        style="width: 100%"
        @click="emitDelete"
      >
        <span class="glyphicon glyphicon-minus" />
      </button>
    </div>
  </div>
</template>
<script>
import Selector from "@/components/Selector.vue";
import DataService from "@/services/data.service";

export default {
  components: {
    Selector,
  },
  props: {
    n: null,
    defaultMaterialName: null,
    thickness: null,
    withThickness: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  emits: ["material", "delete", "hChange"],
  data() {
    return {
      hValue: null,
    };
  },
  computed: {
    materials(){
      return this.$store.getters.materials;
    },
  },
  mounted() {
    this.hValue = this.thickness;
  },
  methods: {
    onSelection(material) {
      DataService.getMaterialData(material).then((response) => {
        if(response.data.value.alias[0] === "Fused quartz") response.data.value.alias[0]="fused_silica";
        this.$emit("material", response.data.value, this.n);
      });
    },
    emitDelete() {
      this.$emit("delete");
    },
    onInputChange() {
      this.$emit("hChange", this.hValue, this.n);
    },
  }
};
</script>
<style lang="css">
.padding-bottom-sm {
  padding-bottom: 0.3em;
}
</style>
