import { createStore } from "vuex";
import DataService from "@/services/data.service";
import AuthService from "@/services/auth.service";

const user = JSON.parse(localStorage.getItem("user"));
const initialState = user ? { status: { loggedIn: true }, user } : { status: { loggedIn: false }, user: null };

if (user) {
  const jwtPayload = parseJwt(localStorage.getItem("user"));
  if (jwtPayload.exp < Date.now() / 1000) {
    // token expired
    localStorage.removeItem("user");
    initialState.status.loggedIn = false;
    initialState.status.user = null;
  }
}

export default createStore({
  state: {
    groupedMaterials: [],
    n2materials: [],
    products: [],
    categorizedProducts: [],
    schemes: [],
    presets: [],
    defaultMaterial: null,
    defaultMaterialReady: false,
    loading: false,
    dataReady: false,
    schemesReady: false,
    productsReady: false,
    n2MaterialsReady: false,
    c: 299792458,
    pi: 3.14159265359,
    initialState,
    status: [{ loggedIn: false }, { user: null }],
    tempImage: null,
    plannerActiveObjectName: null,
    apiVersion: null,
    productSpecs: null,
    plannerActiveObject: null,
    harmonicOptimizationSpec:null,
  },
  getters: {
    materialsByType: (state) => (type) => {
      return state.groupedMaterials.filter((x) => x.type === type);
    },
    n2materials: (state) => {
      return state.n2materials;
    },
    materials: (state) => {
      return state.groupedMaterials;
    },
    presets: (state) => {
      return state.presets;
    },
  },
  mutations: {
    SET_GroupedMaterials(state, materials) {
      state.groupedMaterials = materials;
      state.dataReady = true;
    },
    SET_N2Materials(state, materials) {
      state.n2materials = materials;
      state.n2MaterialsReady = true;
    },
    SET_CategorizedProducts(state, products) {
      state.categorizedProducts = products;
      state.productsReady = true;
    },
    DELETE_CategorizedProducts(state) {
      state.productsReady = false;
      state.categorizedProducts = null;
    },
    SET_Products(state, products) {
      state.products = products;
      state.productsReady = true;
    },
    DELETE_Products(state) {
      state.productsReady = false;
      state.products = null;
    },
    DELETE_Presets(state) {
      state.presetsReady = false;
      state.presets = null;
    },
    SET_Schemes(state, schemes) {
      state.schemes = schemes;
      state.schemesReady = true;
    },
    DELETE_Schemes(state) {
      state.schemesReady = false;
      state.schemes = null;
    },
    SET_Loading(state, status){
      state.loading = status;
    },
    SET_Presets(state, presets) {
      state.presets = presets;
    },
    SET_DefaultMaterial(state, material) {
      state.defaultMaterial = material;
      state.defaultMaterialReady = true;
    },
    SET_TempImage(state, tempImage){
      state.tempImage = tempImage;
    },
    SET_PlannerActiveObjectSize(state, object){
      state.plannerActiveObjectSize = { "width": Math.round(object.getScaledWidth()), "height": Math.round(object.getScaledHeight())};
    },
    SET_PlannerActiveObjectName(state, name){
      state.plannerActiveObjectName = name;
    },
    SET_PlannerActiveObject(state, object){
      state.plannerActiveObject = object;
      state.plannerActiveObjectSize = { "width": Math.round(object.getScaledWidth()), "height": Math.round(object.getScaledHeight())};
    },
    SET_ApiVersion(state, version){
      state.apiVersion = version;
    },
    SET_ProductSpecs(state, specs) {
      state.productSpecs = specs;
    },
    SET_HarmonicOptimizationSpec(state, specs) {
      state.harmonicOptimizationSpec = specs;
    },
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
      state.initialState.status.loggedIn = false;
      state.initialState.status.user = null;
    },
  },
  actions: {
    loadHarmonicOptimizationSpec({ commit }) {
      DataService.getHarmonicOptimizationSpec().then((response) => {
        commit("SET_HarmonicOptimizationSpec", response.data);
      });
    },
    loadGroupedMaterials({ commit }) {
      DataService.getMaterialsData().then((response) => {
        commit("SET_GroupedMaterials", response.data);
      });
    },
    loadN2Materials({ commit }) {
      DataService.getN2MaterialsData().then((response) => {
        commit("SET_N2Materials", response.data);
      });
    },
    loadProducts({ commit }) {
      commit("DELETE_Products");
      DataService.getProductsData().then((response) => {
        commit("SET_Products", response.data);
      });
    },
    loadCategorizedProducts({ commit }) {
      commit("DELETE_CategorizedProducts");
      DataService.getCategorizedProductsData().then((response) => {
        commit("SET_CategorizedProducts", response.data);
      });
    },
    loadProductSpecs({ commit}) {
      DataService.getProductSpecsData().then((response) => {
        commit("SET_ProductSpecs", response.data);
      });
    },
    loadSchemes({ commit }) {
      commit("DELETE_Schemes");
      DataService.getSchemesData().then((response) => {
        commit("SET_Schemes", response.data, true);
      });
    },
    loadPresets({ commit }) {
      DataService.getPresetsData().then((response) => {
        commit("SET_Presets", response.data);
      });
    },
    loadDefaultMaterial({ commit }) {
      DataService.getMaterialData("fused_silica").then((response) => {
        commit("SET_DefaultMaterial", response.data);
      });
    },
    loadDefaultPlannerProduct({commit}){
      DataService.getProductData( process.env.VUE_APP_DEFAULT_PLANNER_PRODUCT).then((response) => {
        commit("SET_PlannerActiveObjectName", response.data);
      });
    },
    loadApiVersion({commit}){
      DataService.getApiVersion().then((response) => {
        commit("SET_ApiVersion", response.data);
      });
    },
    login({ commit }, user) {
      return AuthService.login(user)
        .then((response) => response.data)
        .then(
          (user) => {
            commit("loginSuccess", user);
            return Promise.resolve(user);
          },
          (error) => {
            commit("loginFailure");
            return Promise.reject(error);
          }
        );
    },
    logout({ commit }) {
      AuthService.logout();
      commit("logout");
    },
  },
  modules: {},
});

function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}
