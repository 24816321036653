<template lang="">
  <div
    v-if="show"
    class="panel panel-default"
    style="margin-bottom: 5px"
  >
    <div
      id="headingOOE"
      class="panel-heading"
      role="tab"
    >
      <h4 class="panel-title">
        <a
          data-toggle="collapse"
          :data-target="titleid"
          href="#collapseOOE"
          aria-expanded="true"
          aria-controls="collapseOOE"
        >
          {{ Title }}: <small>{{ theta }} deg</small>
        </a>
      </h4>
    </div>
    <div
      :id="Title"
      :class="'panel-collapse collapse' + showExpanded"
      role="tabpanel"
      aria-labelledby="headingOOE"
    >
      <div class="row">
        <div class="col-md-7 col-xs-7">
          <h5>
            Phase matching angle
            <span
              class="glyphicon glyphicon-info-sign info-glyph"
              data-toggle="modal"
              :data-target="'#description_' + type"
            />
          </h5>
          <div class="input-group">
            <input
              id="OOEtheta"
              v-model="theta"
              type="number"
              class="form-control"
              readonly
            >
            <span class="input-group-addon">deg</span>
          </div>
        </div>
        <div class="col-md-5 col-xs-5">
          <h5><math-jax latex="d_\mathrm{eff}" /></h5>
          <div class="input-group">
            <input
              id="OOEdeff"
              v-model="deffReturn"
              type="text"
              class="form-control"
              readonly
            >
            <span class="input-group-addon">pm/V</span>
          </div>
        </div>
      </div>
      <h5>
        Angular walk-offs <math-jax latex="\rho_1" />, <math-jax latex="\rho_2" />, <math-jax latex="\rho_3" />
        <small>[deg]</small>
        <span
          class="glyphicon glyphicon-info-sign info-glyph"
          data-toggle="modal"
          data-target="#description_angular_walkoff"
        />
      </h5>
      <div class="row">
        <div class="col-md-4 col-xs-4">
          <input
            v-model="walkoff1"
            type="number"
            class="form-control"
            readonly
          >
        </div>
        <div class="col-md-4 col-xs-4">
          <input
            v-model="walkoff2"
            type="number"
            class="form-control"
            readonly
          >
        </div>
        <div class="col-md-4 col-xs-4">
          <input
            v-model="walkoff3"
            type="number"
            class="form-control"
            readonly
          >
        </div>
      </div>
      <h5>
        Delay (<abbr title="Group velocity mismatch">GVM</abbr>) of <math-jax latex="k_1" />, <math-jax latex="k_2" />
        <small>[fs/mm]</small>
      </h5>
      <div class="row">
        <div class="col-md-4 col-xs-4">
          <input
            v-model="delay1"
            type="number"
            class="form-control"
            readonly
          >
        </div>
        <div class="col-md-4 col-xs-4">
          <input
            v-model="delay2"
            type="number"
            class="form-control"
            readonly
          >
        </div>
      </div>
    </div>
  </div>

  <EEOthetaPMDescriptionModal id="description_e-e-o" />
  <EOEthetaPMDescriptionModal id="description_e-o-e" />
  <EOOthetaPMDescriptionModal id="description_e-o-o" />
  <OEEthetaPMDescriptionModal id="description_o-e-e" />
  <OEOthetaPMDescriptionModal id="description_o-e-o" />
  <OOEthetaPMDescriptionModal id="description_o-o-e" />
  <AngularWalkoffDescriptionModal id="description_angular_walkoff" />
</template>
<script>
import EEOthetaPMDescriptionModal from "@/components/descriptions/nonlinearOptics/EEOthetaPM.vue";
import EOEthetaPMDescriptionModal from "@/components/descriptions/nonlinearOptics/EOEthetaPM.vue";
import EOOthetaPMDescriptionModal from "@/components/descriptions/nonlinearOptics/EOOthetaPM.vue";
import OEEthetaPMDescriptionModal from "@/components/descriptions/nonlinearOptics/OEEthetaPM.vue";
import OEOthetaPMDescriptionModal from "@/components/descriptions/nonlinearOptics/OEOthetaPM.vue";
import OOEthetaPMDescriptionModal from "@/components/descriptions/nonlinearOptics/OOEthetaPM.vue";
import AngularWalkoffDescriptionModal from "@/components/descriptions/nonlinearOptics/AngularWalkoff.vue";

import { make_n_function, fsolve, d_eff, steepest_descent_w_sampling, pi, c, rad_to_deg } from "@/tools/Utils.js";

export default {
  components: {
    EEOthetaPMDescriptionModal,
    EOEthetaPMDescriptionModal,
    EOOthetaPMDescriptionModal,
    OEEthetaPMDescriptionModal,
    OEOthetaPMDescriptionModal,
    OOEthetaPMDescriptionModal,
    AngularWalkoffDescriptionModal,
  },
  props: {
    Title: String,
    material: null,
    expand: {
      type: Boolean,
      default() {
        return false;
      },
    },
    wl: null,
    type: null,
  },
  emits: ["state"],
  data() {
    return {
      titleid: "#" + this.Title,
      loading: false,
      GVM: [
        [0.0, 0.0],
        [0.0, 0.0],
        [0.0, 0.0],
      ],
      theta_PM: [0.0, 0.0, 0.0],
      walkoff: [
        [0.0, 0.0, 0.0],
        [0.0, 0.0, 0.0],
        [0.0, 0.0, 0.0],
      ],
      deff: [0.0, 0.0, 0.0],
    };
  },
  computed: {
    show() {
      var state = true;
      if (this.theta == 0) state = false;
      this.$emit("state", state, this.type);
      return state;
    },
    showExpanded() {
      if (this.expand) return "in";
      return "";
    },
    interaction_types() {
      if (typeof this.material !== "undefined" && this.material.birefrigerence_type == "negative") {
        return ["ooe", "eoe", "oee"];
      } else {
        return ["eeo", "oeo", "eoo"];
      }
    },
    thetaV() {
      switch (this.type) {
        case "o-o-e":
          if (typeof this.theta_PM[0] !== "undefined") return this.theta_PM[0];
          break;
        case "e-o-e":
          if (typeof this.theta_PM[1] !== "undefined") return this.theta_PM[1];
          break;
        case "o-e-e":
          if (typeof this.theta_PM[2] !== "undefined") return this.theta_PM[2];
          break;
        case "e-e-o":
          if (typeof this.theta_PM[0] !== "undefined") return this.theta_PM[0];
          break;
        case "o-e-o":
          if (typeof this.theta_PM[1] !== "undefined") return this.theta_PM[1];
          break;
        case "e-o-o":
          if (typeof this.theta_PM[2] !== "undefined") return this.theta_PM[2];
          break;
      }
      return 0;
    },
    walkoffV() {
      switch (this.type) {
        case "o-o-e":
          return this.walkoff[0];
        case "e-o-e":
          return this.walkoff[1];
        case "o-e-e":
          return this.walkoff[2];
        case "e-e-o":
          return this.walkoff[0];
        case "o-e-o":
          return this.walkoff[1];
        case "e-o-o":
          return this.walkoff[2];
      }
      return 0;
    },
    GVMV() {
      switch (this.type) {
        case "o-o-e":
          return this.GVM[0];
        case "e-o-e":
          return this.GVM[1];
        case "o-e-e":
          return this.GVM[2];
        case "e-e-o":
          return this.GVM[0];
        case "o-e-o":
          return this.GVM[1];
        case "e-o-o":
          return this.GVM[2];
      }
      return 0;
    },
    deffV() {
      switch (this.type) {
        case "o-o-e":
          return this.deff[0];
        case "e-o-e":
          return this.deff[1];
        case "o-e-e":
          return this.deff[2];
        case "e-e-o":
          return this.deff[0];
        case "o-e-o":
          return this.deff[1];
        case "e-o-o":
          return this.deff[2];
      }
      return 0;
    },
    theta() {
      return rad_to_deg(this.thetaV).toFixed(3);
    },
    walkoff1() {
      return rad_to_deg(this.walkoffV[0]).toFixed(3);
    },
    walkoff2() {
      return rad_to_deg(this.walkoffV[1]).toFixed(3);
    },
    walkoff3() {
      return rad_to_deg(this.walkoffV[2]).toFixed(3);
    },
    delay1() {
      return (this.GVMV[0] * 1.0e4).toFixed(0);
    },
    delay2() {
      return (this.GVMV[1] * 1.0e4).toFixed(0);
    },
    deffReturn() {
      return this.deffV.toFixed(3);
    },
  },
  watch: {
    wl(newValue) {
      this.update(newValue, this.material);
    },
  },
  methods: {
    updateData() {
      this.update(this.wl, this.material);
    },
    update(newwl, material) {
      if (this.material == null) return;
      var n = [0, 0, 0];
      var wl = newwl;
      var delta_theta = 1.0e-6;
      c;
      var deltawl = 1.0e-6;
      var internal_angle = 0.0;
      var make_d_eff_function = function (interaction_type, theta, mat) {
        return function (phi) {
          return -d_eff(interaction_type, theta, phi, mat);
        };
      };

      for (var i = 0; i < 3; i += 1) {
        var interaction_type = this.interaction_types[i];

        for (var j = 0; j < 3; j += 1) {
          n[j] = make_n_function(wl[j] / 1.0e3, interaction_type.charAt(j), material);
        }

        var fun = function (theta) {
          return n[2](theta) / wl[2] - Math.cos(internal_angle) * (n[1](theta) / wl[1] + n[0](theta) / wl[0]);
        };

        this.loading = true;
        this.theta_PM[i] = fsolve(fun, pi / 4.0);
        this.loading = false;
        // walkoff
        for (j = 0; j < 3; j += 1) {
          if (interaction_type.charAt(j) == "e") {
            this.walkoff[i][j] = -(n[j](this.theta_PM[i] + delta_theta) / n[j](this.theta_PM[i]) - 1.0) / delta_theta;
          } else {
            this.walkoff[i][j] = 0.0;
          }
        }

        //deff
        // this.deff[i] = Math.max(d_eff (interaction_type, this.theta_PM[i]+this.walkoff[i][2], 0.0, this.material), 
        //                         d_eff (interaction_type, this.theta_PM[i]+this.walkoff[i][2], pi/2.0, this.material), 
        //                         d_eff (interaction_type, this.theta_PM[i]+this.walkoff[i][2], -pi/2.0, this.material), 
        //                         d_eff (interaction_type, this.theta_PM[i]+this.walkoff[i][2], pi, this.material)); 

        if (typeof this.theta_PM[i] !== "undefined" && material.d) {
          var deffval = steepest_descent_w_sampling(
            make_d_eff_function(interaction_type, this.theta_PM[i] + this.walkoff[i][2], this.material),
            -pi,
            pi
          );
          this.deff[i] = deffval[1];
        } else {
          this.deff[i] = "undefined";
        }

        // GVM
        var n31 = make_n_function(wl[2] / 1.0e3, interaction_type.charAt(2), this.material)(this.theta_PM[i]);
        var n32 = make_n_function(wl[2] / 1.0e3 + deltawl, interaction_type.charAt(2), this.material)(this.theta_PM[i]);

        var n21 = make_n_function(wl[1] / 1.0e3, interaction_type.charAt(1), this.material)(this.theta_PM[i]);
        var n22 = make_n_function(wl[1] / 1.0e3 + deltawl, interaction_type.charAt(1), this.material)(this.theta_PM[i]);

        var n11 = make_n_function(wl[0] / 1.0e3, interaction_type.charAt(0), this.material)(this.theta_PM[i]);
        var n12 = make_n_function(wl[0] / 1.0e3 + deltawl, interaction_type.charAt(0), this.material)(this.theta_PM[i]);

        var GV3 = n31 - ((wl[2] / 1.0e3) * (n32 - n31)) / deltawl;
        var GV2 = n21 - ((wl[1] / 1.0e3) * (n22 - n21)) / deltawl;
        var GV1 = n11 - ((wl[0] / 1.0e3) * (n12 - n11)) / deltawl;

        this.GVM[i] = [1.0 / GV1 - 1.0 / GV3, 1.0 / GV2 - 1.0 / GV3];
      }
    },
  },
};
</script>
