<template lang="">
  <div
    :id="id"
    class="modal"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
          >
            &times;
          </button>
          <h4 class="modal-title">
            Insertion angle of the second prism
          </h4>
        </div>
        <div class="modal-body">
          <p>
            Distance between the second prism's apex and point, where the reference (<math-jax latex="\lambda_0" />) beam hits prism's
            surface. Here <math-jax latex="l_2" /> is given for the reference beam and is fixed. For any other ray of different
            wavelength, distance between the prism apex and point of incidence is given in the ouput as <math-jax latex="l_2" />, which
            is wavelength-dependent:
            <math-jax
              latex="l_2 = \frac{h\cos\left(\vartheta_1-\frac{\alpha}{2}\right)-w\sin\left(\vartheta_1-\frac{\alpha}{2}\right)}{\cos\vartheta_1}-l_{1}^{\prime}."
              :block="true"
            />
            Here <math-jax latex="\vartheta_1" /> is angle of reflection, 
            <math-jax
              latex="\vartheta_1 = \arcsin\left(n\sin(\alpha-\psi_1)\right),"
              :block="true"
            />
            and <math-jax latex="l_1^\prime" /> is the distance between the ray's exit
            point and the apex of the first prism,
            <math-jax
              latex="l_1^\prime = l_1\frac{\cos\psi_1}{\cos\psi_2}."
              :block="true"
            />
            <math-jax latex="\psi_1" /> and <math-jax latex="\psi_2" /> are the internal reflection angles of the first prism:
            <math-jax
              latex="\psi_1 = \arcsin\frac{\sin\vartheta_0}{n},"
              :block="true"
            />
            <math-jax
              latex="\psi_2 = \alpha-\psi_1."
              :block="true"
            />
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-default"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: null,
  },
};
</script>