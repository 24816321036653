<template lang="">
  <nav class="navbar navbar-inverse header">
    <div class="container">
      <div
        class="container-fluid"
        style="box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175)"
      >
        <div class="navbar-header">
          <button
            type="button"
            class="navbar-toggle"
            data-toggle="collapse"
            data-target="#myNavbar"
          >
            <span class="icon-bar" />
            <span class="icon-bar" />
            <span class="icon-bar" />
          </button>
          <a
            class="navbar-brand header h1a"
            href="/"
          >Optics Toolbox</a>
        </div>
        <div
          id="myNavbar"
          class="collapse navbar-collapse"
        >
          <ul class="nav navbar-nav">
            <li>
              <router-link :to="{ name: 'admindashboard' }">
                PRODUCTS
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'productregister' }">
                NEW PRODUCT
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'adminpresets' }">
                PRESETS
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'presetregister' }">
                NEW PRESET
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'adminschemes' }">
                SCHEMES
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'adminmaterials' }">
                MATERIALS
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'adminmaterialeditor' }">
                MATERIAL EDITOR
              </router-link>
            </li>
          </ul>
          <ul class="nav navbar-nav navbar-right">
            <li>
              <router-link
                :to="{ name: 'admin' }"
                @click="logout"
              >
                <span class="glyphicon glyphicon-log-out" />
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push({ name: "admin" });
    },
  },
};
</script>
<style scoped></style>
