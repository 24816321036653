<template lang="">
  <div
    id="prismrefraction"
    class="row"
    ng-controller="materialsdbCtrl"
  >
    <!-- TITLE -->
    <div class="col-lg-12 col-md-12 col-xs-12">
      <h3>
        Beam refraction in prism
        <small><span
          data-toggle="collapse"
          data-target="#info_prismrefraction"
          class="glyphicon glyphicon-info-sign info-glyph"
        /></small>
      </h3>
    </div>
    <!-- INFO/OUTPUT -->
    <div class="col-lg-6 col-md-6 col-xs-12">
      <div
        id="info_prismrefraction"
        class="collapse"
      >
        <p>Refraction angles in prisms.</p>
        <img
          class="img-center"
          src="@/assets/static/media/svg/prismrefraction.svg"
        >
      </div>
      <PrismRefractionRayOutput
        v-if="material !== null"
        Title="Ordinary"
        icon="glyphicon glyphicon-resize-horizontal"
        :material="material"
        :wl="inputValue"
        :theta0="theta0"
        :alpha="alpha"
        @hasError="onError"
      />

      <PrismRefractionRayOutput
        v-if="material !== null && isMaterialBiaxial"
        Title="Extraordinary"
        icon="glyphicon glyphicon-resize-vertical"
        :extraordinay-ray="true"
        :material="material"
        :wl="inputValue"
        :theta0="theta0"
        :alpha="alpha"
        @hasError="onError"
      />
      <!-- OUTPUT ( EXTRAORDINARY )-->
    </div>
    <!-- INPUT -->
    <div
      v-if="$store.state.dataReady"
      class="col-lg-6 col-md-6 col-xs-12"
    >
      <!-- MATERIAL SELECT -->
      <Selector
        :list="materials"
        :list-name="'Materials'"
        :default-selection="'Al2O3'"
        @selectedMaterial="onSelection"
      />
      <!-- WAVELENGTH SELECT -->
      <div class="row top-padding10">
        <div class="col-md-8 col-xs-6 top-padding">
          <Slider
            v-model="slider.value"
            v-bind="slider"
            @update="onSliderChange()"
          />
        </div>
        <div class="col-md-4 col-xs-6">
          <div class="input-group">
            <input
              id="wltext"
              v-model="inputValue"
              step="1"
              type="number"
              class="form-control indicator"
              tabindex="1"
              @change="onInputChange()"
            >

            <span class="input-group-addon">nm</span>
          </div>
        </div>
      </div>
      <!-- theta0, alpha SELECT -->
      <div class="row top_align_row">
        <div class="col-md-6 col-xs-6 vcenter">
          <h5>Angle of incidence <math-jax latex="\vartheta_0" />:</h5>
          <div :class="`${hasError ? 'input-group has-error' : 'input-group'}`">
            <input
              ref="theta0text"
              v-model="theta0"
              step="1.0"
              min="0"
              max="90"
              type="number"
              class="form-control indicator"
            >
            <span class="input-group-addon">deg</span>
          </div>
          <p
            v-if="hasError"
            class="text-center text-danger"
          >
            Internal reflection!
          </p>
        </div>
        <div class="col-md-6 col-xs-6">
          <h5>Prism apex angle <math-jax latex="\alpha" />:</h5>
          <div class="input-group">
            <input
              v-model="alpha"
              step="1.0"
              min="0"
              max="90"
              type="number"
              class="form-control indicator"
            >
            <span class="input-group-addon">deg</span>
          </div>
        </div>
      </div>

      <br>
    </div>
  </div>
</template>
<script>
import Slider from "@vueform/slider";
import DataService from "@/services/data.service";
import Selector from "@/components/Selector.vue";
import PrismRefractionRayOutput from "@/components/PrismRefractionRayOutput.vue";

export default {
  components: {
    Slider,
    Selector,
    PrismRefractionRayOutput,
  },
  data() {
    return {
      material: null,
      inputValue: 1030,
      slider: {
        value: 1030,
        max: 2000,
        lazy: false,
        showTooltip: "drag",
        step: -1,
      },
      wlrangeMin: null,
      wlrangeMax: null,
      theta0: 60,
      alpha: 60,
      hasError: false,
    };
  },
  computed: {
    materials(){
      return this.$store.getters.materials;
    },
    isMaterialBiaxial() {
      if (this.material != null && this.material.NumberOfAxis == 2) return true;
      return false;
    },
  },
  watch: {
    material(newValue) {
      this.wlrangeMin = parseFloat(newValue.parameters[0].wl_n_range[0]) * 1.0e3;
      this.slider.min = this.wlrangeMin;
      this.wlrangeMax = parseFloat(newValue.parameters[0].wl_n_range[1]) * 1.0e3;
      this.slider.max = this.wlrangeMax;
      if (this.slider.value > this.wlrangeMaxDef) this.inputValue = this.wlrangeMaxDef;
      if (this.slider.value < this.wlrangeMinDef) this.inputValue = this.wlrangeMinDef;
    },
  },
  methods: {
    onInputChange() {
      this.slider.value = this.inputValue;
    },
    onSliderChange() {
      this.inputValue = this.slider.value;
    },
    onSelection(material) {
      DataService.getMaterialData(material).then((response) => {
        this.material = response.data.value;
      });
    },
    onError(error) {
      this.hasError = error;
    },
  },
};
</script>
<style lang=""></style>
