<template lang="">
  <div
    class="panel panel-default"
    style="margin-bottom 5px;"
  >
    <div
      id="headingOne"
      class="panel-heading"
      role="tab"
      data-ng-show="true"
    >
      <h4 class="panel-title">
        <a
          data-toggle="collapse"
          :data-target="titleid"
          href="#collapseOne"
          aria-expanded="true"
          aria-controls="collapseOne"
        >
          <span :class="icon">&nbsp;</span>{{ Title }} ray
        </a>
      </h4>
    </div>
    <div
      :id="Title"
      class="panel-collapse collapse in"
      role="tabpanel"
      aria-labelledby="headingOne"
    >
      <!-- OUTPUT ( ORDINARY )-->
      <div class="row bottom_align_row">
        <div class="col-md-6 col-xs-6">
          <h5>
            Displacement <math-jax latex="d" />&nbsp;
            <span
              class="glyphicon glyphicon-info-sign info-glyph"
              data-toggle="modal"
              data-target="#description_beamdisplacement"
            />
          </h5>
          <div class="input-group">
            <input
              v-model="dForView"
              type="number"
              class="form-control indicator"
              readonly
            >
            <span class="input-group-addon">&mu;m</span>
          </div>
        </div>
        <div class="col-md-6 col-xs-6">
          <h5>
            Optical path
            <span
              class="glyphicon glyphicon-info-sign info-glyph"
              data-toggle="modal"
              data-target="#description_opticalpathslabpair"
            />
          </h5>
          <div class="input-group">
            <input
              v-model="opticalpathtime"
              type="number"
              class="form-control indicator"
              readonly
            >
            <span class="input-group-addon">ps</span>
          </div>
        </div>
      </div>
      <div class="row bottom_align_row">
        <div class="col-md-6 col-xs-6">
          <h5>Optical path  <math-jax latex="(\vartheta_0=0)" /></h5>
          <div class="input-group">
            <input
              v-model="opticalpathtime0"
              type="number"
              class="form-control indicator"
              readonly
            >
            <span class="input-group-addon">ps</span>
          </div>
        </div>
        <div class="col-md-6 col-xs-6">
          <h5>Optical path difference</h5>
          <div class="input-group">
            <input
              v-model="opticalpathtimedelta"
              type="number"
              class="form-control indicator"
              readonly
            >
            <span class="input-group-addon">fs</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <BeamDisplacementDescriptionModal id="description_beamdisplacement" />
  <OpticalPathSlabPairDescriptionModal id="description_opticalpathslabpair" />
</template>
<script>
import BeamDisplacementDescriptionModal from "./descriptions/beamProperties/BeamDisplacement.vue";
import OpticalPathSlabPairDescriptionModal from "./descriptions/beamProperties/OpticalSlabPair.vue";
import { RefractiveIndex, GroupVelocity } from "@/tools/MaterialDispersion.js";
import { c, deg_to_rad } from "@/tools/Utils.js";

export default {
  components: {
    BeamDisplacementDescriptionModal,
    OpticalPathSlabPairDescriptionModal,
  },
  props: {
    Title: String,
    icon: String,
    hValue: null,
    distanceValue: null,
    alphaValue: null,
    material: null,
    extraordinayRay: {
      type: Boolean,
      default() {
        return false;
      },
    },
    wl: null,
  },
  data() {
    return {
      titleid: "#" + this.Title,
    };
  },
  computed: {
    ri() {
      if (this.extraordinayRay) return RefractiveIndex(this.wl / 1.0e3, 1, this.material);
      return RefractiveIndex(this.wl / 1.0e3, 0, this.material);
    },
    sinalpha() {
      var alpha = deg_to_rad(this.alphaValue);
      return Math.sin(alpha);
    },
    h() {
      return this.hValue / 1.0e3;
    },
    distance() {
      return this.distanceValue / 1.0e3;
    },
    d() {
      var d =
        this.h *
        this.sinalpha *
        (1.0 - Math.sqrt((1.0 - this.sinalpha * this.sinalpha) / (this.ri * this.ri - this.sinalpha * this.sinalpha)));
      return d;
    },
    l() {
      return (this.ri * this.h) / Math.sqrt(this.ri * this.ri - this.sinalpha * this.sinalpha);
    },
    GV() {
      if (this.extraordinayRay) return GroupVelocity(this.material, this.wl / 1.0e3, 1);
      return GroupVelocity(this.material, this.wl / 1.0e3, 0);
    },
    opticalpathtime() {
      var opticalpathtime =
        (2.0 * this.l) / this.GV + (this.distance - 2.0 * Math.sqrt(this.l * this.l - this.d * this.d)) / c;
      return Math.round10(opticalpathtime * 1.0e12, -3);

    },
    opticalpathtime0() {
      var opticalpathtime0 = (2.0 * this.h) / this.GV + (this.distance - 2.0 * this.h) / c;
      return Math.round10(opticalpathtime0 * 1.0e12, -3);
    },
    opticalpathtimedelta() {
      var opticalpathtimedelta =
        (2.0 * this.l) / this.GV +
        (this.distance - 2.0 * Math.sqrt(this.l * this.l - this.d * this.d)) / c -
        ((2.0 * this.h) / this.GV + (this.distance - 2.0 * this.h) / c);
      return Math.round10(opticalpathtimedelta * 1.0e15, -3);
    },
    dForView() {
      return Math.round10(this.d * 1.0e6, -3);
    },
  },
};
</script>
<style lang=""></style>
