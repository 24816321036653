<template lang="">
  <div
    id="beamdisplacementslabpair"
    class="row"
    ng-controller="materialsdbCtrl"
  >
    <!-- TITLE -->
    <div class="col-lg-12 col-md-12 col-xs-12 settings">
      <h3>
        Beam displacement (slab pair)
        <small><span
          data-toggle="collapse"
          data-target="#info_beamdisplacementslabpair"
          class="glyphicon glyphicon-info-sign info-glyph"
        /></small>
      </h3>
    </div>
    <!-- OUTPUT -->
    <div class="col-lg-6 col-md-6 col-xs-12 settings">
      <div>
        <div
          id="info_beamdisplacementslabpair"
          class="collapse"
        >
          <p>Temporal and spatial displacement of Gaussian beam by two rotated slabs.</p>
          <img
            class="img-center"
            src="@/assets/static/media/svg/beamdisplacementslabpair.svg"
          >
        </div>

        <BeamDisplacementSlabPairRayOutput
          v-if="material !== null"
          Title="Ordinary"
          icon="glyphicon glyphicon-resize-horizontal"
          :material="material"
          :alpha-value="alphaValue"
          :distance-value="distanceValue"
          :h-value="hValue"
          :wl="inputValue"
        />

        <BeamDisplacementSlabPairRayOutput
          v-if="material !== null && isMaterialBiaxial"
          Title="Extraordinary"
          icon="glyphicon glyphicon-resize-vertical"
          :extraordinay-ray="true"
          :material="material"
          :alpha-value="alphaValue"
          :distance-value="distanceValue"
          :h-value="hValue"
          :wl="inputValue"
        />
      </div>
    </div>
    <!-- INPUT -->
    <div
      v-if="$store.state.dataReady"
      class="col-lg-6 col-md-6 col-xs-12 settings"
    >
      <!-- MATERIAL SELECT -->
      <Selector
        :list="materials"
        :list-name="'Materials'"
        :default-selection="'Al2O3'"
        @selectedMaterial="onSelection"
      />
      <!-- WAVELENGTH SELECT -->
      <h5>
        Wavelength range: <span>{{ wlrangeMin }}</span>-<span>{{ wlrangeMax }}</span> nm
      </h5>
      <div class="row">
        <div class="col-md-6 col-xs-7 top-padding">
          <Slider
            v-model="slider.value"
            v-bind="slider"
            @update="onSliderChange()"
          />
        </div>
        <div class="col-md-6 col-xs-5">
          <div class="input-group">
            <input
              id="wltext"
              v-model="inputValue"
              step="1"
              type="number"
              class="form-control indicator"
              tabindex="1"
              @change="onInputChange()"
            >
            <span class="input-group-addon">nm</span>
          </div>
        </div>
      </div>
      <!-- THETA, THICKNESS SELECT -->
      <div class="row bottom_align_row">
        <div class="col-md-6 col-xs-6 vcenter">
          <h5>Angle of incidence <math-jax latex="\vartheta_0" /></h5>
          <div class="input-group">
            <input
              v-model="alphaValue"
              min="0"
              max="90"
              step="1"
              type="number"
              class="form-control indicator"
            >
            <span class="input-group-addon">deg</span>
          </div>
        </div>
        <div class="col-md-6 col-xs-6">
          <h5>Thickness <math-jax latex="h" /></h5>
          <div class="input-group">
            <input
              v-model="hValue"
              step="0.1"
              type="number"
              class="form-control indicator"
            >
            <span class="input-group-addon">mm</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-xs-6">
          <h5>Distance between slabs <math-jax latex="L" /></h5>
          <div class="input-group">
            <input
              v-model="distanceValue"
              step="0.1"
              type="number"
              class="form-control indicator"
            >
            <span class="input-group-addon">mm</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BeamDisplacementSlabPairRayOutput from "@/components/BeamDisplacementSlabPairRayOutput.vue";
import Selector from "@/components/Selector.vue";
import Slider from "@vueform/slider";
import DataService from "@/services/data.service";

export default {
  components: {
    BeamDisplacementSlabPairRayOutput,
    Slider,
    Selector,
  },
  data() {
    return {
      material: null,
      inputValue: 1030,
      slider: {
        value: 1030,
        max: 2000,
        lazy: false,
        showTooltip: "drag",
        step: -1,
      },
      wlrangeMin: null,
      wlrangeMax: null,
      alphaValue: 0,
      hValue: 1,
      distanceValue: 50,
    };
  },
  computed: {
    materials(){
      return this.$store.getters.materials;
    },
    isMaterialBiaxial() {
      if (this.material != null && this.material.NumberOfAxis == 2) return true;
      return false;
    },
  },
  watch: {
    material(newValue) {
      this.wlrangeMin = parseFloat(newValue.parameters[0].wl_n_range[0]) * 1.0e3;
      this.slider.min = this.wlrangeMin;
      this.wlrangeMax = parseFloat(newValue.parameters[0].wl_n_range[1]) * 1.0e3;
      this.slider.max = this.wlrangeMax;
      if (this.slider.value > this.wlrangeMaxDef) this.inputValue = this.wlrangeMaxDef;
      if (this.slider.value < this.wlrangeMinDef) this.inputValue = this.wlrangeMinDef;
    },
  },
  methods: {
    onInputChange() {
      this.slider.value = this.inputValue;
    },
    onSliderChange() {
      this.inputValue = this.slider.value;
    },
    onSelection(material) {
      DataService.getMaterialData(material).then((response) => {
        this.material = response.data.value;
      });
    },
  },
};
</script>
<style lang=""></style>
