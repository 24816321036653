<template lang="">
  <div
    class="panel panel-default"
    style="margin-bottom: 5px"
  >
    <div
      id="headingOne"
      class="panel-heading"
      role="tab"
      data-ng-show="true"
    >
      <h4 class="panel-title">
        <a
          data-toggle="collapse"
          :data-target="titleid"
          href="#collapseOne"
          aria-expanded="true"
          aria-controls="collapseOne"
        >
          <span :class="icon">&nbsp;</span>{{ Title }}
        </a>
      </h4>
    </div>
    <div
      :id="Title"
      class="panel-collapse collapse in"
      role="tabpanel"
      aria-labelledby="headingOne"
    >
      <!-- OUTPUT ( ORDINARY )-->
      <div class="row bottom_align_row">
        <div class="col-md-6 col-xs-6">
          <h5>
            Displacement <math-jax latex="d" />&nbsp;
            <span
              class="glyphicon glyphicon-info-sign info-glyph"
              data-toggle="modal"
              data-target="#description_beamdisplacement"
            />
          </h5>
          <div class="input-group">
            <input
              :value="round6(d)"
              type="number"
              class="form-control indicator"
              readonly
            >
            <span class="input-group-addon">&mu;m</span>
          </div>
        </div>
        <div class="col-md-6 col-xs-6">
          <h5>Geometrical path</h5>
          <div class="input-group">
            <input
              :value="round6(opticalpath)"
              type="number"
              class="form-control indicator"
              readonly
            >
            <span class="input-group-addon">mm</span>
          </div>
        </div>
      </div>
      <div class="row bottom_align_row">
        <div class="col-md-6 col-xs-6">
          <h5>
            Optical path
            <span
              class="glyphicon glyphicon-info-sign info-glyph"
              data-toggle="modal"
              data-target="#description_opticalpath"
            />
          </h5>
          <div class="input-group">
            <input
              :value="round6(opticalpathtime)"
              type="number"
              class="form-control indicator"
              readonly
            >
            <span class="input-group-addon">ps</span>
          </div>
        </div>
        <div class="col-md-6 col-xs-6">
          <h5>
            CE phase shift <math-jax latex="\Delta\varphi_\mathrm{CE}" />&nbsp;
            <span
              class="glyphicon glyphicon-info-sign info-glyph"
              data-toggle="modal"
              data-target="#description_phiCE"
            />
          </h5>
          <div class="input-group">
            <input
              :value="round6(deltaphiCE)"
              type="number"
              class="form-control indicator"
              readonly
            >
            <span class="input-group-addon">rad</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <BeamDisplacementDescriptionModal id="description_beamdisplacement" />
  <OpticalPathDescriptionModal id="description_phiCE" />
  <PhiCEDescriptionModal id="description_opticalpath" />
</template>
<script>
import { RefractiveIndex, RefractiveIndexGeneral, GroupVelocity } from "@/tools/MaterialDispersion.js";
import { deg_to_rad } from "@/tools/Utils.js";
import OpticalPathDescriptionModal from "@/components/descriptions/beamProperties/OpticalPath.vue";
import PhiCEDescriptionModal from "@/components/descriptions/beamProperties/PhiCE.vue";
import BeamDisplacementDescriptionModal from "@/components/descriptions/beamProperties/BeamDisplacement.vue";

export default {
  components: {
    OpticalPathDescriptionModal,
    PhiCEDescriptionModal,
    BeamDisplacementDescriptionModal,
  },
  props: {
    Title: String,
    icon: String,
    hValue: null,
    wl: null,
    alphaValue: null,
    material: null,
    extraordinayRay: {
      type: Boolean,
      default() {
        return false;
      },
    },
    theta: null,
    phi: null,
  },
  data() {
    return {
      titleid: "#" + this.Title,
    };
  },
  computed: {
    ri() {
      if (this.extraordinayRay) {
        return RefractiveIndexGeneral(this.wl / 1.0e3, this.material, deg_to_rad(this.theta));
      }
      return RefractiveIndex(this.wl / 1.0e3, 0, this.material);
    },
    h() {
      return this.hValue * 1.0e3;
    },
    sinalpha() {
      return Math.sin((this.alphaValue / 180) * this.$store.state.pi);
    },
    GV() {
      if (this.extraordinayRay) {
        return GroupVelocity(this.material, this.wl / 1.0e3, 1, deg_to_rad(this.theta), deg_to_rad(this.phi));
      }
      return GroupVelocity(this.material, this.wl / 1.0e3, 0);
    },
    d() {
      return Math.round10(
        this.h *
          this.sinalpha *
          (1.0 -
            Math.sqrt((1.0 - this.sinalpha * this.sinalpha) / (this.ri * this.ri - this.sinalpha * this.sinalpha))),
        -6
      ); // in [um]
    },
    opticalpath() {
      var opticalpath = (this.ri * this.h) / Math.sqrt(this.ri * this.ri - this.sinalpha * this.sinalpha);
      return Math.round10(opticalpath / 1.0e3, -6);
    },
    opticalpathtime() {
      var opticalpathtime = this.opticalpath / this.GV;
      return Math.round10(opticalpathtime * 1e9, -6);
    },
    deltaphiCE() {
      return Math.round10(
        (((2.0 * this.$store.state.pi * this.$store.state.c) / (this.wl / 1.0e3)) *
          1.0e3 *
          (1.0 / this.GV - this.ri / this.$store.state.c) *
          this.opticalpath) %
          this.$store.state.pi,
        -6
      );
    },
  },
  methods: {
    round6(number) {
      return Math.round10(number, -3);
    },
  },
};
</script>
<style lang=""></style>
