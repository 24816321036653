<template>
  <div>
    <select
      v-if="listName === 'Materials'"
      id="mySelecttt"
      v-model="selected"
      class="selectpicker"
      data-live-search="true"
      data-width="100%"
      data-style="btn-primary"
      data-live-search-focus="true"
      data-live-search-placeholder="Material name"
      data-show-tick="true"
    >
      <optgroup
        v-for="subcategorie in list || []"
        :key="subcategorie"
        :label="subcategorie.type"
      >
        <option
          v-for="material in subcategorie.materials || []"
          :key="material"
          :value="material.name"
          :data-content="description(material)"
        />
      </optgroup>
    </select>

    <select
      v-if="listName === 'Presets' || listName === 'Products'"
      id="mySelect"
      v-model="selected"
      autofocus
      class="selectpicker"
      data-live-search="true"
      data-width="100%"
      data-style="btn-primary"
    >
      <optgroup
        v-for="subcategorie in list || []"
        :key="subcategorie"
        :label="subcategorie.category"
      >
        <option
          v-for="element in subcategorie.list || []"
          :key="element"
          :value="element"
        >
          <div v-if="listName === 'Presets'">
            {{ element.title }}
          </div>
          <div v-if="listName === 'Products'">
            {{ element.longName }}
          </div>
        </option>
      </optgroup>
    </select>
    <select
      v-if="listName === 'Schemes'"
      id="mySelect"
      v-model="selected"
      autofocus
      class="selectpicker"
      data-live-search="true"
      data-width="100%"
      data-style="btn-primary"
    >
      <option
        v-for="material in list || []"
        :key="material"
        :value="material"
      >
        <div v-if="listName === 'Schemes'">
          {{ material.name }} <strong>{{ formattedDate(material.created) }}</strong>
        </div>
      </option>
    </select>

    <select
      v-if="listName === 'ProductSpecs'"
      id="mySelect"
      v-model="selected"
      class="selectpicker"
      data-live-search="true"
      data-width="100%"
      data-style="btn-primary"
    >
      <option
        v-for="product in list || []"
        :key="product"
        :value="product"
      >
        <div>
          {{ product.friendlyModelName }}
        </div>
      </option>
    </select>


    <select
      v-if="listName === 'harmonicOptimizationSpec'"
      id="mySelect"
      v-model="selected"
      class="selectpicker"
      data-live-search="true"
      data-width="100%"
      data-style="btn-primary"
    >
      <option
        v-for="(product, index) in list" 
        :key="index"
        :value={key:index,value:product}
      >
        <div>
          {{ index }}
        </div>
      </option>
    </select>
  </div>
</template>
<script>

import $ from "jquery";

export default {
  name: "MaterialSelector",
  props: {
    list: null,
    listName: null,
    defaultSelection: null,
    withLabel: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  emits: ["selectedMaterial"],
  data() {
    return {
      selected: "fused_silica",
    };
  },
  watch: {
    selected(nv) {
      this.$emit("selectedMaterial", nv);
    },
    defaultSelection(nv) {
      this.selected = nv;
      setTimeout(function () {
        $(".selectpicker").selectpicker("refresh");
      }, 50);
    },
  },
  mounted() {
    $(".selectpicker").selectpicker("refresh");
    if (this.listName === "Materials") this.$emit("selectedMaterial", this.selected);
  },
  beforeMount() {
    if (typeof this.defaultSelection !== "undefined") {
      this.selected = this.defaultSelection;
    }
    if(this.listName === "ProductSpecs") {
      this.selected = this.list[0];
    }
    if(this.listName === "harmonicOptimizationSpec") {
      const keys = Object.keys(this.list);
      const firstKey = keys[0];
      this.selected = {key:firstKey,value:{...this.list[firstKey]}};
    }
  },
  methods: {
    formattedDate(dateToFormat) {
      var date = new Date(dateToFormat);
      return new Intl.DateTimeFormat("en-GB", { dateStyle: "medium", timeStyle: "short" }).format(date);
    },
    description(material) {
      if (this.withLabel) {
        return (
          material.name +
          " " +
          material.info +
          " " +
          `${
            material.birefrigerence_type === "positive"
              ? "<span class='label label-success'>Positive</span>"
              : "<span class='label label-warning'>Negative</span>"
          }`
        );
      } else { 
        return material.name + ' ' + material.info;
      }
    },
  },
};
</script>
<style></style>
