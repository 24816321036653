<template lang="">
  <div
    id="SFconverter"
    class="row"
    ng-controller="materialsdbCtrl"
  >
    <!-- TITLE -->
    <div class="col-xs-12">
      <h3 id="SFG">
        OPA/SFG wavelength converter
        <small><span
          data-toggle="collapse"
          data-target="#info_SFconverter"
          class="glyphicon glyphicon-info-sign info-glyph"
        /></small>
      </h3>
    </div>
    <!-- INFO -->
    <div
      id="info_SFconverter"
      class="collapse col-xs-12"
    >
      <p>
        Wavelengths <math-jax latex="\lambda_1" />, <math-jax latex="\lambda_2" /> and <math-jax latex="\lambda_3" />, satisfying 
        <math-jax
          latex="\frac{1}{\lambda_1} + \frac{1}{\lambda_2 } = \frac{1}{\lambda_3}"
          :block="true"
        />
      </p>
    </div>
    <!-- INPUT/OUTPUT -->
    <div class="col-md-4 col-xs-4">
      <h5><math-jax latex="\lambda_1" /> <small>[nm]</small></h5>
      <input
        id="wl1text"
        ref="focusMe"
        v-model="lambda1"
        step="1.0"
        type="number"
        class="form-control indicator"
      >
    </div>
    <div class="col-md-4 col-xs-4">
      <h5><math-jax latex="\lambda_2" /> <small>[nm]</small></h5>
      <input
        id="wl2text"
        v-model="lambda2"
        step="1.0"
        type="number"
        class="form-control indicator"
      >
    </div>
    <div class="col-md-4 col-xs-4">
      <h5><math-jax latex="\lambda_3" /> <small>[nm]</small></h5>
      <input
        id="wl3text"
        v-model="lambda3"
        step="1.0"
        type="number"
        class="form-control indicator"
      >
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      wl1: 1030,
      wl2: 1030,
      wl3: 515,
      wlorder: [0, 1, 2],
    };
  },
  computed: {
    lambda1: {
      get: function () {
        return Math.round10(this.wl1, -3);
      },
      set: function (newValue) {
        this.wl1 = newValue;
        this.update_wl_order(1);
        if (!this.wlorder[1]) {
          this.wl2 = 1.0 / (1.0 / this.wl3 - 1.0 / this.wl1);
        } else {
          this.wl3 = 1.0 / (1.0 / this.wl1 + 1.0 / this.wl2);
        }
      },
    },
    lambda2: {
      get: function () {
        return Math.round10(this.wl2, -3);
      },
      set: function (newValue) {
        this.wl2 = newValue;
        this.update_wl_order(2);
        if (!this.wlorder[2]) {
          this.wl3 = 1.0 / (1.0 / this.wl1 + 1.0 / this.wl2);
        } else {
          this.wl1 = 1.0 / (1.0 / this.wl3 - 1.0 / this.wl2);
        }
      },
    },
    lambda3: {
      get: function () {
        return Math.round10(this.wl3, -3);
      },
      set: function (newValue) {
        this.wl3 = newValue;
        this.update_wl_order(3);

        if (!this.wlorder[0]) {
          this.wl1 = 1.0 / (1.0 / this.wl3 - 1.0 / this.wl2);
        } else {
          this.wl2 = 1.0 / (1.0 / this.wl3 - 1.0 / this.wl1);
        }
      },
    },
  },
  mounted () {
    this.$refs.focusMe.focus()    
  },
  methods: {
    update_wl_order(id) {
      var i;

      if (this.wlorder[id - 1] === 2) {
        return;
      }

      this.wlorder[id - 1] = 2;

      for (i = 0; i < 3; i += 1) {
        if (i != id - 1) {
          this.wlorder[i] -= 1;
        }
        if (this.wlorder[i] < 0.0) {
          this.wlorder[i] = 0;
        }
      }
    },
  },
};
</script>
