<template>
  <div
    :id="id"
    class="modal"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
          >
            &times;
          </button>
          <h4 class="modal-title">
            Bandwidth in wavelength
          </h4>
        </div>
        <div class="modal-body">
          <p>
            Exact and approximate relations between the bandwidth in wavelength and wavenumber units is given by:
            <math-jax
              latex="\Delta\lambda = \frac{4\pi c}{\Delta \omega} \left( \sqrt{1+\frac{\lambda_0^2\Delta \omega^2}{4\pi^2 c^2}} -
            1 \right) \approx \frac{\Delta \omega\lambda_0^2}{2\pi c} = \Delta k \lambda_0^2. "
              :block="true"
            />
            If bandwidth
            <math-jax
              latex="\Delta
            k"
            />
            is given in inverse centimeters, bandwidth in nanometers is approximately
            <math-jax
              latex="\Delta\lambda\mathrm{[nm]}
            \approx 10^{-7} \cdot \Delta k\mathrm{[cm^{-1}]}\cdot(\lambda_0\mathrm{[nm]})^2."
              :block="true"
            />
          </p>
          <p>
            Peak width relations:
            <math-jax
              latex="\mathrm{FWHM} = 2\sqrt{2\ln2}\sigma,$$ $$D_{1/\mathrm{e}^2} = 4\sigma =
            \sqrt{\frac{2}{\ln2}}\mathrm{FWHM},$$ $$D_{1/\mathrm{e}} = 2\sqrt{2}\sigma = \frac{\mathrm{FWHM}}{\sqrt{\ln
            2}}."
              :block="true"
            />
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-default"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: null,
  },
};
</script>
