<template lang="">
  <div class="top-padding20">
    <KitchenPlanner
      :preset-mode="true"
      :show-selector="true"
    />
  </div>
</template>
<script>
import KitchenPlanner from "@/components/planner/KitchenPlanner.vue";
export default {
  components: {
    KitchenPlanner,
  },
};
</script>
<style scoped>
img {
  width: 100%;
}
</style>
