<template lang="">
  <div
    :id="id"
    class="modal"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
          >
            &times;
          </button>
          <h4 class="modal-title">
            Super-Gaussian coefficient
          </h4>
        </div>
        <div class="modal-body">
          <p>
            Coefficient <math-jax latex="n" /> of normalized super-Gaussian function
            <math-jax
              latex="f_\mathrm{SG}=\left(\frac{n2^{1/n}}{\pi w_{0}^{2}\Gamma(1/n)}\right)\exp\left[-2\left(\frac{r}{w_{0}}\right)^{2n}\right]."
              :block="true"
            /> 
            If <math-jax latex="n=1" />, function is Gaussian. 
            Here <math-jax latex="\Gamma" /> is gamma function, <math-jax latex="w_0" /> - half width of the peak at <math-jax latex="1/\mathrm{e}^2" /> intensity.
          </p> 
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-default"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: null,
  },
};
</script>
