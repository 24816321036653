<template lang="">
  <div
    class="panel panel-default"
    style="margin-bottom: 5px"
  >
    <!-- OUTPUT ( ORDINARY )-->
    <div
      id="headingOne"
      class="panel-heading"
      role="tab"
      data-ng-show="true"
    >
      <h4 class="panel-title">
        <a
          data-toggle="collapse"
          :data-target="titleid"
          href="#collapseOne"
          aria-expanded="true"
          aria-controls="collapseOne"
        >
          <span :class="icon">&nbsp;</span>{{ Title }} ray
        </a>
      </h4>
    </div>
    <div
      :id="Title"
      class="panel-collapse collapse in"
      role="tabpanel"
      aria-labelledby="headingOne"
    >
      <div class="row">
        <div class="col-xs-6">
          <h5>Incident pulse</h5>
          <div class="input-group">
            <input
              id="beforeFWHM_t_text"
              v-model="beforeFWHM_t"
              max="99999"
              type="number"
              class="form-control indicator"
            >
            <span class="input-group-addon">fs</span>
          </div>
        </div>
        <div class="col-xs-6">
          <h5>Transmitted pulse</h5>
          <div class="input-group">
            <input
              id="afterFWHM_t_text"
              v-model="afterFWHM_t"
              max="99999"
              type="number"
              class="form-control indicator"
              readonly
            >
            <span class="input-group-addon">fs</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { bc_to_omega } from "@/tools/BandwidthConverterOmega.js";
import { pi } from "@/tools/Utils.js";
import { GroupVelocityDispersion } from "@/tools/MaterialDispersion.js";
export default {
  props: {
    Title: String,
    icon: String,
    beforeFWHM_wValue: null,
    wl_units: null,
    wlsliderValue: null,
    material: null,
    LValue: null,
    extraordinayRay: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  emits: ["beforeFWHM_t"],
  data() {
    return {
      afterFWHM_t: null,
      beforeFWHM_t: null,
      titleid: "#" + this.Title,
    };
  },
  watch: {
    wlsliderValue() {
      this.update();
    },
    LValue() {
      this.update();
    },
    beforeFWHM_t(newValue) {
      this.$emit("beforeFWHM_t", newValue);
    },
    material() {
      this.update();
    },
    beforeFWHM_wValue(){
      this.update();
    }
  },
  methods: {
    update() {
      if (this.material === null) return;
      var wl = parseFloat(this.wlsliderValue); // in nm

      if (this.beforeFWHM_wValue === null || typeof this.beforeFWHM_wValue == "undefined") {
        return;
      }

      if (this.LValue === null || typeof this.LValue == "undefined") {
        return;
      }

      var beforeFWHM_w;
      var beforeFWHM_t;
      var L;
      var TBWP = 0.441271200305303186792912864236;

      beforeFWHM_w = bc_to_omega(parseFloat(this.beforeFWHM_wValue), this.wl_units, wl); //convert to fs-1

      beforeFWHM_t = (TBWP / beforeFWHM_w) * 2.0 * pi;

      this.beforeFWHM_t = Math.round10(beforeFWHM_t, -3);

      L = parseFloat(this.LValue) * 1.0e6; // converted to nm

      var GDD = GroupVelocityDispersion(wl / 1.0e3, 0, this.material) * L * 1.0e-9 * 1.0e30; // in [fs2]
      if (this.extraordinayRay) GDD = GroupVelocityDispersion(wl / 1.0e3, 1, this.material) * L * 1.0e-9 * 1.0e30; // in [fs2]

      var afterFWHM_t =
        beforeFWHM_t * Math.sqrt(1.0 + Math.pow((4.0 * Math.log(2.0) * GDD) / Math.pow(beforeFWHM_t, 2), 2));

      this.afterFWHM_t = Math.round10(afterFWHM_t, -3);
    },
  },
};
</script>
<style lang=""></style>
