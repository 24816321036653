<template lang="">
  <div
    :id="id"
    class="modal"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
          >
            &times;
          </button>
          <h4 class="modal-title">
            Output
          </h4>
        </div>
        <div class="modal-body">
          Optical paths:
          <math-jax
            latex="P_{AB} = \frac{l_{1}\sin\alpha}{\cos\vartheta_1}"
            :block="true"
          />
          <math-jax
            latex="P_{BC} = \frac{w\cos\frac{\alpha}{2}-h\sin\frac{\alpha}{2}}{\cos\vartheta_1}"
            :block="true"
          />
          <math-jax
            latex="P_{CD} = \frac{l_{2}\sin\alpha}{\cos\psi_1}"
            :block="true"
          />

          Distance between the second prism apex and point of incidence of <math-jax latex="\lambda" /> beam
          <math-jax
            latex="l_2 = \frac{h\cos\left(\vartheta_1-\frac{\alpha}{2}\right)-w\sin\left(\vartheta_1-\frac{\alpha}{2}\right)}{\cos\vartheta_1}-l_{1}^{\prime}."
            :block="true"
          />

          Critical wavelength <math-jax latex="\lambda_\mathrm{c}" />. Rays of
          <math-jax latex="\lambda<\lambda_\mathrm{c}" /> do not hit the surface of the second prism.
          <math-jax latex="\lambda_\mathrm{c}" /> satisfies equation
          <math-jax
            latex="\frac{\sin\vartheta_1^\mathrm{c}}{n(\lambda)}-\sin\left(\alpha-\arcsin\left(\frac{\sin\vartheta_0}{n(\lambda)}\right)\right)=0,"
            :block="true"
          />
          where the critical refraction angle is
          <math-jax
            latex="\vartheta_1^\mathrm{c} =
              \arctan\left(\frac{l_{1}^{\prime}\cos\frac{\alpha}{2}-h}{l_{1}^{\prime}\sin\frac{\alpha}{2}-w}\right)+\frac{\alpha}{2}."
            :block="true"
          />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-default"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: null,
  },
};
</script>
