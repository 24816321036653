<template lang="">
  <div class="form-signin">
    <h2 class="form-signin-heading">
      Please sign in
    </h2>
    <label
      for="username"
      class="sr-only"
    >Username</label>
    <div :class="`${loginFailed ? 'has-error' : ''}`">
      <input
        v-model="user.username"
        type="username"
        class="form-control"
        placeholder="Username"
        required
        autofocus
        @change="resetError"
      >
    </div>
    <label
      for="inputPassword"
      class="sr-only"
    >Password</label>
    <div :class="`${loginFailed ? 'has-error' : ''}`">
      <input
        v-model="user.password"
        type="password"
        class="form-control"
        placeholder="Password"
        required
        @change="resetError"
        @keyup.enter="login"
      >
    </div>
    <div class="checkbox">
      <label> <input
        v-model="user.staySignedIn"
        type="checkbox"
      > Stay Signed In</label>
    </div>
    <button
      class="btn btn-lg btn-primary btn-block"
      type="submit"
      @click="login()"
    >
      Sign in
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: { username: "", password: "", staySignedIn: false },
      loginFailed: false,
    };
  },
  created() {
    if (this.$store.state.initialState.status.loggedIn) {
      this.$router.push({ name: "admindashboard" });
    }
  },
  methods: {
    login() {
      this.$store.dispatch("login", this.user).then(
        () => {
          this.$router.push({ name: "admindashboard" }).then(() =>{
            location.reload();
              });
        },
        () => {
          this.loginFailed = true;
        }
      );
    },
    resetError(){
      this.loginFailed = false;
    },
  },
};
</script>

<style scoped>
body {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #eee;
}

.form-signin {
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}
.form-signin .form-signin-heading,
.form-signin .checkbox {
  margin-bottom: 10px;
  font-family: Helvetica;
}
.form-signin .checkbox {
  font-weight: normal;
}
.form-signin .form-control {
  position: relative;
  height: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
