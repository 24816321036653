<template lang="">
  <div
    :id="id"
    class="modal"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
          >
            &times;
          </button>
          <h4 class="modal-title">
            Peak fluence
          </h4>
        </div>
        <div class="modal-body">
          <p>
            Peak fluence <math-jax latex="F_0" /> - maximal energy density per unit area (at beam center). Pulse energy <math-jax latex="\mathcal{E}" />
            is equal to the integrated fluence <math-jax latex="F" />, 
            <math-jax
              latex="\mathcal{E}=\intop F(r)\mathrm{d}S."
              :block="true"
            />
            If fluence and beam intensity is super-Gaussian function,
            <math-jax
              latex="F(r)=F_0\left[-2\left(\frac{r}{w_{0}}\right)^{2n}\right],"
              :block="true"
            />
            peak fluence is obtained as 
            <math-jax
              latex="F_0 = \mathcal{E}\frac{2^{\frac{1}{n}}n}{\pi w_{0}^{2}\Gamma\left(\frac{1}{n}\right)}."
              :block="true"
            />
            Here <math-jax latex="\Gamma" /> is gamma function, <math-jax latex="w_0" /> - half width of the
            peak at <math-jax latex="1/\mathrm{e}^2" /> intensity. If <math-jax latex="n=1" /> (Gaussian beam), 
            <math-jax
              latex="F_0 = \mathcal{E}\frac{2}{\pi w_{0}^{2}}."
              :block="true"
            />
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-default"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: null,
  },
};
</script>
