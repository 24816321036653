<template lang="">
  <div
    :id="id"
    class="modal"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
          >
            &times;
          </button>
          <h4 class="modal-title">
            Deviation angle
          </h4>
        </div>
        <div class="modal-body">
          <p>
            Difference between <math-jax latex="m=-1" /> diffraction angle <math-jax latex="(\vartheta_{-1})" /> and AOI <math-jax latex="(\vartheta_0)" />
            <math-jax
              latex="\vartheta_\mathrm{d} = \arcsin\left(\frac{\lambda}{d}-\sin{\vartheta_0}\right) - \vartheta_0 ."
              :block="true"
            />
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-default"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: null,
  },
};
</script>
