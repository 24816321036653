<template>
  <div
    :id="id"
    class="modal"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
          >
            &times;
          </button>
          <h4 class="modal-title">
            Conversion from optical period
          </h4>
        </div>
        <div class="modal-body">
          Wavelength 
          <math-jax
            latex="\lambda = Tc \Longrightarrow \lambda[\mathrm{nm}] \approx T[\mathrm{fs}] \cdot 299.792"
            :block="true"
          />
          Wavenumber
          <math-jax
            latex="k = \frac{1}{Tc} \Longrightarrow k[\mathrm{cm^{-1}}] \approx \frac{3.335\cdot 10^4}{T[\mathrm{fs}]}"
            :block="true"
          />
          Angular frequency
          <math-jax
            latex="\omega = \frac{2\pi}{T} \Longrightarrow \omega[\mathrm{fs^{-1}}] \approx \frac{6.283}{T[\mathrm{fs}]}"
            :block="true"
          />
          Energy
          <math-jax
            latex="E = \frac{2\pi\hbar}{T} \Longrightarrow E[\mathrm{eV}] \approx \frac{4.136}{T[\mathrm{fs}]} "
            :block="true"
          />
          Frequency
          <math-jax
            latex=" f = \frac{1}{T} \Longrightarrow f[\mathrm{THz}] = \frac{10^3}{T[\mathrm{fs}]} "
            :block="true"
          />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-default"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: null,
  },
};
</script>
