<template lang="">
  <div
    id="refractiveindex"
    class="row"
    ng-controller="materialsdbCtrl"
  >
    <!-- TITLE -->
    <div class="col-xs-12">
      <h3>Refractive index</h3>
    </div>
    <!-- OUTPUT -->
    <div class="col-lg-4 col-xs-12">
      <div class="row">
        <div class="col-xs-6">
          <h5><math-jax latex="n_\mathrm{o}" /></h5>
          <input
            v-model="n_o"
            class="form-control indicator"
            readonly
          >
        </div>

        <div class="col-xs-6">
          <h5><math-jax latex="n_\mathrm{e}" /></h5>
          <input
            v-model="n_e"
            class="form-control indicator"
            readonly
          >
        </div>
      </div>
      <br>

      <!-- MATERIAL SELECT -->

      <div v-if="$store.state.dataReady">
        <Selector
          :list="materials"
          :list-name="'Materials'"
          :default-selection="'BeO'"
          @selectedMaterial="onSelection"
        />
      </div>

      <!-- WAVELENGTH INPUT -->
      <h5>
        Wavelength range: <span>{{ wlrangeMin }}</span>-<span>{{ wlrangeMax }}</span> nm
      </h5>
      <div class="row">
        <div class="col-md-8 col-xs-6 top-padding">
          <Slider
            v-model="slider.value"
            v-bind="slider"
            @update="onSliderChange()"
          />
        </div>
        <div class="col-md-4 col-xs-6">
          <div class="input-group">
            <input
              id="wltext"
              v-model="inputValue"
              step="1"
              type="number"
              class="form-control indicator"
              tabindex="1"
              @change="onInputChange()"
            >
            <span class="input-group-addon">nm</span>
          </div>
        </div>
      </div>
      <!-- <div class="row" style="visibility: hidden;"> -->
      <div class="row">
        <div class="col-xs-4">
          <h5><math-jax latex=" n(\vartheta,\phi) " /></h5>
          <input
            v-model="n_oe"
            type="number"
            class="form-control indicator"
            readonly
          >
        </div>

        <div class="col-xs-4">
          <h5><math-jax latex=" \vartheta " /></h5>
          <div class="input-group">
            <input
              v-model="theta"
              min="-180"
              max="180"
              step="1"
              type="number"
              class="form-control indicator"
            >
            <span class="input-group-addon">deg</span>
          </div>
        </div>

        <div class="col-xs-4">
          <h5><math-jax latex=" \phi " /></h5>
          <div class="input-group">
            <input
              v-model="phi"
              min="-180"
              max="180"
              step="1"
              type="number"
              class="form-control indicator"
            >
            <span class="input-group-addon">deg</span>
          </div>
        </div>
      </div>
    </div>
    <!-- INPUT -->
    <div class="col-lg-8 col-xs-12">
      <div id="refractiveindex_n_plot" />
    </div>
  </div>
</template>
<script>
import Slider from "@vueform/slider";
import DataService from "@/services/data.service";
import Selector from "@/components/Selector.vue";
import { RefractiveIndex, RefractiveIndexGeneral } from "@/tools/MaterialDispersion.js";
import { deg_to_rad } from "@/tools/Utils.js";
import { drawPlotD3 } from "@/tools/Plot.js";

export default {
  components: {
    Slider,
    Selector,
  },
  data() {
    return {
      material: null,
      inputValue: 1030,
      slider: {
        value: 1030,
        max: 2000,
        lazy: false,
        showTooltip: "drag",
        step: -1,
      },
      wlrangeMin: null,
      wlrangeMax: null,
      theta: 0,
      phi: 0,
    };
  },
  computed: {
    materials() {
      return this.$store.getters.materials;
    },
    ri() {
      if (this.material !== null) return RefractiveIndex(this.inputValue / 1.0e3, 0, this.material);
      return null;
    },
    n_o() {
      if (this.material !== null && typeof this.ri !== "undefined") return this.ri.toFixed(6);
      return null;
    },
    n_e() {
      if (this.material !== null) {
        var ri = RefractiveIndex(this.inputValue / 1.0e3, 1, this.material);
        return ri ? ri.toFixed(6) : null;
      }
      return null;
    },
    n_oe() {
      if (this.material !== null)
        return RefractiveIndexGeneral(
          this.inputValue / 1.0e3,
          this.material,
          deg_to_rad(this.theta),
          deg_to_rad(this.phi)
        );

      return null;
    },
  },
  watch: {
    material(newValue) {
      this.wlrangeMin = parseFloat(newValue.parameters[0].wl_n_range[0]) * 1.0e3;
      this.slider.min = this.wlrangeMin;
      this.wlrangeMax = parseFloat(newValue.parameters[0].wl_n_range[1]) * 1.0e3;
      this.slider.max = this.wlrangeMax;
      if (this.slider.value > this.wlrangeMaxDef) this.inputValue = this.wlrangeMaxDef;
      if (this.slider.value < this.wlrangeMinDef) this.inputValue = this.wlrangeMinDef;

      this.drawPlot(newValue);
    },
  },
  methods: {
    onInputChange() {
      this.slider.value = this.inputValue;
    },
    onSliderChange() {
      this.inputValue = this.slider.value;
    },
    onSelection(material) {
      DataService.getMaterialData(material).then((response) => {
        this.material = response.data.value;
      });
    },
    drawPlot(material) {
      var x = [];

      var wl_min = this.wlrangeMin;
      var wl_max = this.wlrangeMax;
      var wl_step = 10.0;
      var wlValue;

      for (wlValue = wl_min; wlValue <= wl_max; wlValue += wl_step) {
        x.push(wlValue);
      }

      var tooltipfun = function (ray) {
        return function (x, y) {
          return x.toFixed(0) + " nm, n(" + ray + ")=" + y.toFixed(4);
        };
      };

      var y = [
        {
          title: "n(o)",
          fun: function (wl) {
            return RefractiveIndex(wl / 1.0e3, 0, material);
          },
          tooltipfun: tooltipfun("o"),
        },
      ];

      if (material.NumberOfAxis !== 0) {
        y.push({
          title: "n(e)",
          fun: function (wl) {
            return RefractiveIndex(wl / 1.0e3, 1, material);
          },
          tooltipfun: tooltipfun("e"),
        });
      }

      var options = { title: material.name, vAxis: { format: "#.###" } };

      var x_axis = { title: "Wavelength", units: "nm" };
      var y_axis = { title: "Refractive index" };
      var target = "refractiveindex_n_plot";
      setTimeout(function () {
        drawPlotD3(x, y, target, x_axis, y_axis, options);
      }, 10);
    },
  },
};
</script>
<style lang=""></style>
