<template lang="">
  <div
    id="pulsepower"
    class="row"
  >
    <!-- TITLE -->
    <div class="col-lg-12 col-md-12 col-xs-12 settings">
      <h3>Pulse peak power</h3>
    </div>
    <!-- INFO/OUTPUT -->
    <div class="col-lg-6 col-md-6 col-xs-12">
      <div class="row bottom_align_row">
        <div class="col-xs-6">
          <h5 for="power_text">
            Peak power <small>Gaussian pulse</small>&nbsp;
            <span
              class="glyphicon glyphicon-info-sign info-glyph"
              data-toggle="modal"
              data-target="#description_peak_power_gaussian"
            />
          </h5>
          <div class="input-group">
            <input
              id="power_text"
              v-model="power"
              type="number"
              class="form-control indicator"
              readonly
            >
            <span class="input-group-addon">GW</span>
          </div>
        </div>
        <div class="col-xs-6">
          <h5 for="power_sech2_text">
            Peak power <small>sech&sup2; pulse</small>&nbsp;
            <span
              class="glyphicon glyphicon-info-sign info-glyph"
              data-toggle="modal"
              data-target="#description_peak_power_sech2"
            />
          </h5>
          <div class="input-group">
            <input
              id="power_sech2_text"
              v-model="power_sech2"
              type="number"
              class="form-control indicator"
              readonly
            >
            <span class="input-group-addon">GW</span>
          </div>
        </div>
      </div>
    </div>
    <!-- INPUT -->
    <div class="col-lg-6 col-md-6 col-xs-12 settings">
      <div class="row">
        <!-- ENERGY -->
        <div class="col-xs-6">
          <h5 for="E_text">
            Pulse energy
          </h5>
          <div class="input-group">
            <input
              id="E_text"
              v-model="E"
              min="0"
              step="1"
              type="number"
              class="form-control indicator"
            >
            <span class="input-group-addon">mJ</span>
          </div>
        </div>
        <!-- LENGTH  -->
        <div class="col-lg-6 col-xs-6">
          <h5 for="deltat_text">
            Pulse length <small>FWHM</small>
          </h5>
          <div class="input-group">
            <input
              id="deltat_text"
              v-model="deltat"
              min="0"
              step="1"
              type="number"
              class="form-control indicator"
            >
            <span class="input-group-addon">fs</span>
          </div>
        </div>
      </div>
    </div>

    <PeakPowerGaussianDescriptionModal id="description_peak_power_gaussian" />
    <PeakPowerSech2DescriptionModal id="description_peak_power_sech2" />
  </div>
</template>
<script>
import PeakPowerGaussianDescriptionModal from "@/components/descriptions/beamProperties/PeakPowerGaussian.vue";
import PeakPowerSech2DescriptionModal from "@/components/descriptions/beamProperties/PeakPowerSech2.vue";

export default {
  components: {
    PeakPowerGaussianDescriptionModal,
    PeakPowerSech2DescriptionModal,
  },
  data() {
    return {
      E: 40.0,
      deltat: 200.0,
      n: 1.0,
    };
  },
  computed: {
    power() {
      var deltat = this.deltat * 1.0e-15;
      return (((0.93943727869965133377234032841018 * this.E * 1.0e-3) / deltat) * 1.0e-9).toFixed(3);
    },
    power_sech2() {
      var deltat = this.deltat * 1.0e-15;
      return (((0.88137358701954302523260932497979 * this.E * 1.0e-3) / deltat) * 1.0e-9).toFixed(3);
    },
  },
};
</script>
<style lang=""></style>
