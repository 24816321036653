<template>
  <header>
    <router-view name="navbar" />
  </header>
  <main>
    <div class="container">
      <pulse-loader
        class="center"
        :loading="loading"
        :color="'#000E44'"
        :size="size"
      />
      <router-view /> 
    </div>
  </main>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {
  components: {
    PulseLoader,
  },
  computed: {
   loading(){
    return this.$store.state.loading;
   }
  },
  mounted() {
    this.$store.dispatch("loadDefaultMaterial");
    //this.$store.dispatch("loadSchemes");
    this.$store.dispatch("loadN2Materials");
    this.$store.dispatch("loadDefaultPlannerProduct")
    this.$store.dispatch("loadPresets");
    this.$store.dispatch("loadCategorizedProducts");
    this.$store.dispatch("loadProducts");
    this.$store.dispatch("loadProductSpecs");
    this.$store.dispatch("loadApiVersion");
    this.$store.dispatch("loadGroupedMaterials");
    this.$store.dispatch("loadHarmonicOptimizationSpec");
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}
.btn-primary {
  background-color: #020d43 !important;
}
.center {
  text-align: center;
}
:root {
  --slider-connect-bg: none;
  --slider-tooltip-bg: #020d43;
  --slider-handle-width: 30px;
  --slider-handle-height: 30px;
  --slider-handle-ring-color: none;
  --slider-handle-bg: #cf107c;
  --slider-height: 10px;
}

h1,
h2 {
  font-family: Lato;
}

.axis path,
.axis line {
  fill: none;
  stroke: grey;
  stroke-width: 1;
  shape-rendering: crispEdges;
}
.tick text {
  font-size: 12px;
}

.tick line {
  opacity: 0.2;
}

.legend rect {
  fill: white;
  stroke: none;
  stroke-width: 0.5;
  opacity: 0.8;
}

.overlay {
  fill: none;
  pointer-events: all;
}

.d3-tip {
  line-height: 1;
  font-weight: bold;
  padding: 12px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  border-radius: 8px;
}

/* Creates a small triangle extender for the tooltip */
.d3-tip:after {
  box-sizing: border-box;
  display: inline;
  font-size: 10px;
  width: 100%;
  line-height: 1;
  color: rgba(0, 0, 0, 0.5);
  content: "\25BC";
  position: absolute;
  text-align: center;
}

/* Style northward tooltips differently */
.d3-tip.n:after {
  margin: -1px 0 0 0;
  top: 100%;
  left: 0;
}
</style>
