const c = 299792458;
const pi = 3.14159265359;
import { uc_to_omega, uc_omega_to } from "@/tools/UnitConverterOmega";

export function bc_omega_to(omega, units, lambda0) {
  //console.log('omega: '+omega);

  if ((units === "nm" || units === "um") && !lambda0) {
    return;
  }

  switch (units) {
    case "fs-1":
    case "cm-1":
    case "eV":
    case "THz":
      return uc_omega_to(omega, units);
    case "nm":
      //return - (4.0 * pi * c) / omega * (1.0 - Math.sqrt(1.0+(omega*omega*lambda0*lambda0/(4.0 * pi * pi * c * c)))) * 1.0e6
      return ((omega * lambda0 * lambda0) / (2.0 * pi * c)) * 1.0e6; //approximation
    case "um":
      return bc_omega_to(omega, "nm", lambda0) * 1.0e-3;
  }
}

export function bc_to_omega(value, units, lambda0) {
  //console.log('value: '+value);

  if (typeof value === "undefined") {
    return;
  }

  if ((units === "nm" || units === "um") && !lambda0) {
    //console.log ('bc_to_omega: units in nm, no lamda0 provided');
    return;
  }

  switch (units) {
    case "fs-1":
    case "cm-1":
    case "eV":
    case "THz":
      return uc_to_omega(value, units);
    case "nm":
    case "um":
      //return uc_to_omega((lambda0*lambda0 - value*value/4.0)/value, units);
      return uc_to_omega((lambda0 * lambda0) / value, units); // approximation
  }
}
