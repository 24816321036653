import { createRouter, createWebHistory } from "vue-router";

//Converters
import UnitConverterView from "@/views/converters/UnitConverterView.vue";
import BandwidthConverterView from "@/views/converters/BandwidthConverterView.vue";
import PulseLengthBandwidthConverterView from "@/views/converters/PulseLengthBandwidthConverterView.vue";
import SFConverterView from "@/views/converters/SFConverterView.vue";

//Beam properties
import PulseIntensityView from "@/views/beamProperties/PulseIntensityView.vue";
import BeamDisplacementView from "@/views/beamProperties/BeamDisplacementView.vue";
import BeamDisplacementSandwichView from "@/views/beamProperties/BeamDisplacementSandwichView.vue";
import GaussianBeamPropagationView from "@/views/beamProperties/GaussianBeamPropagationView.vue";
import PulsePowerView from "@/views/beamProperties/PulsePowerView.vue";
import BeamDisplacementSlabPairView from "@/views/beamProperties/BeamDisplacementSlabPairView.vue";

//Geometrical optics
import RefractiveIndexView from "@/views/geometricalOptics/RefractiveIndexView.vue";
import CompareMaterialsView from "@/views/geometricalOptics/CompareMaterialsView.vue";
import PrismRefractionView from "@/views/geometricalOptics/PrismRefractionView.vue";
import LensRadiusView from "@/views/geometricalOptics/LensRadiusView.vue";
import FresnelReflectionView from "@/views/geometricalOptics/FresnelReflectionView.vue";
import DiffractionAnglesView from "@/views/geometricalOptics/DiffractionAnglesView.vue";
import GratingCalculatorView from "@/views/geometricalOptics/GratingCalculatorView.vue";

//Dispersion
import MaterialDispersionView from "@/views/dispersion/MaterialDispersionView.vue";
import GratingPairCompressorView from "@/views/dispersion/GratingPairCompressorView.vue";
import TwoPrismCompressorView from "@/views/dispersion/TwoPrismCompressorView.vue";
import PulseElongationView from "@/views/dispersion/PulseElongationView.vue";
import PulseElongationThroughMaterialView from "@/views/dispersion/PulseElongationThroughMaterialView.vue";

//Nonlinear optics
import BIntegralView from "@/views/nonlinearOptics/BIntegralView.vue";
import SumFrequencyGenerationView from "@/views/nonlinearOptics/SumFrequencyGenerationView.vue";
import NoncolinearSFGInternalAnglesView from "@/views/nonlinearOptics/NoncolinearSFGInternalAnglesView.vue";
import NonlinearOpticalInteractionsView from "@/views/nonlinearOptics/NonlinearOpticalInteractionsView.vue";

//Admin
import AdminView from "@/views/adminViews/AdminView.vue";
import AdminDashboardView from "@/views/adminViews/AdminDashboardView.vue";
import AdminSchemes from "@/views/adminViews/SchemesView.vue";
import AdminPresets from "@/views/adminViews/PresetsView.vue";
import NavBarAdmin from "@/components/TheNavBarAdmin.vue";
import AdminMaterials from "@/views/adminViews/MaterialsView.vue";
import AdminMaterialEditor from "@/views/adminViews/MaterialsEditorView.vue"

import PlannerView from "@/views/PlannerView.vue";
import HomeView from "@/views/HomeView.vue";
import KitchenPlanner from "@/components/planner/KitchenPlanner.vue";
import NavBar from "@/components/TheNavBar.vue";
import ProductInfo from "@/components/planner/ProductInfo.vue";
import PdfOutput from "@/components/planner/PdfOutput.vue";

import TuningCurvesView from "@/views/TuningCurvesView.vue";
import HarmonicModuleOptimization from "@/views/HarmonicModuleOptimization.vue";
import createStore from "@/store/index.js";
import ProductRegisterView from "@/views/adminViews/ProductRegisterView.vue";
import PresetRegisterView from "@/views/adminViews/PresetRegisterView.vue";

const routes = [
  { path: "/:pathMatch(.*)*", redirect: "/" },
  {
    path: "/",
    name: "home",
    components: {
      default: HomeView,
      navbar: NavBar,
    },
    meta: {
      title: "Optics Toolbox - Interactive Calculators for Scientists and Engineers",
      metaTags: [
        {
          name: "description",
          content: "The home page of optics toolbox.",
        },
        {
          property: "og:description",
          content: "The home page of optics toolbox.",
        },
      ],
    },
  },
  {
    path: "/planner",
    name: "planner",
    components: {
      default: PlannerView,
      navbar: NavBar,
    },
    props: true,
    children: [
      {
        path: ":name",
        name: "savedplanner",
        components: {
          default: KitchenPlanner,
          navbar: NavBar,
        },
        props: { default: true, navbar: false },
      },
    ],
    meta: { title: "Planner - Optics Toolbox" },
  },
  {
    path: "/planner/pdf/:id",
    name: "pdfview",
    components: {
      default: PdfOutput,
    },
    props: true,
    meta: { title: "Planner - PDF" },
  },
  {
    path: "/planner/png/:id",
    beforeEnter(to) {
      window.location.href = process.env.VUE_APP_SCHEMES_PATH + to.params.id + ".png";
    }
  },
  //----------------------Converters
  {
    path: "/tools/unitconverter",
    name: "unitconverter",
    components: {
      default: UnitConverterView,
      navbar: NavBar,
    },
    meta: { title: "Unit Converter - Optics Toolbox" },
  },
  {
    path: "/tools/unitconverter/clear",
    name: "unitconverterclear",
    components: {
      default: UnitConverterView,
    },
    meta: { title: "Unit Converter - Optics Toolbox" },
  },
  {
    path: "/tools/bandwidthconverter",
    name: "bandwidthconverter",
    components: {
      default: BandwidthConverterView,
      navbar: NavBar,
    },
    meta: { title: "Bandwidth converter - Optics Toolbox" },
  },
  {
    path: "/tools/tbconverter",
    name: "pulselengthbandwidthconverter",
    components: {
      default: PulseLengthBandwidthConverterView,
      navbar: NavBar,
    },
    meta: { title: "Pulse Length Bandwidth Converter - Optics Toolbox" },
  },
  {
    path: "/tools/SFconverter",
    name: "sfconverter",
    components: {
      default: SFConverterView,
      navbar: NavBar,
    },
    meta: { title: "OPA/SFG wavelength converter - Optics Toolbox" },
  },
  //----------------------Beam properties
  {
    path: "/tools/pulseintensity",
    name: "pulseintensity",
    components: {
      default: PulseIntensityView,
      navbar: NavBar,
    },
    meta: { title: "Pulse peak intensity and fluence - Optics Toolbox" },
  },
  {
    path: "/tools/pulsepower",
    name: "pulsepower",
    components: {
      default: PulsePowerView,
      navbar: NavBar,
    },
    meta: { title: "Pulse peak power - Optics Toolbox" },
  },
  {
    path: "/tools/gaussianbeampropagation",
    name: "gaussianbeampropagation",
    components: {
      default: GaussianBeamPropagationView,
      navbar: NavBar,
    },
    meta: { title: "Gaussian beam propagation - Optics Toolbox" },
  },
  {
    path: "/tools/beamdisplacement",
    name: "beamdisplacement",
    components: {
      default: BeamDisplacementView,
      navbar: NavBar,
    },
    meta: { title: "Beam displacement - Optics Toolbox" },
  },
  {
    path: "/tools/beamdisplacementsandwich",
    name: "beamdisplacementsandwich",
    components: {
      default: BeamDisplacementSandwichView,
      navbar: NavBar,
    },
    meta: { title: "Optical path in heterostructures - Optics Toolbox" },
  },
  {
    path: "/tools/beamdisplacementslabpair",
    name: "beamdisplacementslabpair",
    components: {
      default: BeamDisplacementSlabPairView,
      navbar: NavBar,
    },
    meta: { title: "Beam displacement (slab pair) - Optics Toolbox" },
  },
  //----------------------Geometrical optics
  {
    path: "/tools/refractiveindex",
    name: "refractiveindex",
    components: {
      default: RefractiveIndexView,
      navbar: NavBar,
    },
    meta: { title: "Refractive index - Optics Toolbox" },
  },
  {
    path: "/tools/comparematerials",
    name: "comparematerials",
    components: {
      default: CompareMaterialsView,
      navbar: NavBar,
    },
    meta: { title: "Comparison of materials - Optics Toolbox" },
  },
  {
    path: "/tools/prismrefraction",
    name: "prismrefraction",
    components: {
      default: PrismRefractionView,
      navbar: NavBar,
    },
    meta: { title: "Beam refraction in prism - Optics Toolbox" },
  },
  {
    path: "/tools/lensradius",
    name: "lensradius",
    components: {
      default: LensRadiusView,
      navbar: NavBar,
    },
    meta: { title: "Lens radius - Optics Toolbox" },
  },
  {
    path: "/tools/fresnelreflection",
    name: "fresnelreflection",
    components: {
      default: FresnelReflectionView,
      navbar: NavBar,
    },
    meta: { title: "Fresnel reflection - Optics Toolbox" },
  },
  {
    path: "/tools/diffractionangles",
    name: "diffractionangles",
    components: {
      default: DiffractionAnglesView,
      navbar: NavBar,
    },
    meta: { title: "Diffraction angles - Optics Toolbox" },
  },
  {
    path: "/tools/gratingcalculator",
    name: "gratingcalculator",
    components: {
      default: GratingCalculatorView,
      navbar: NavBar,
    },
    meta: { title: "Grating calculator - Optics Toolbox" },
  },
  //----------------------Dispersion

  {
    path: "/tools/dispersionparameters",
    name: "dispersionparameters",
    components: {
      default: MaterialDispersionView,
      navbar: NavBar,
    },
    meta: { title: "Material Dispersion - Optics Toolbox" },
  },
  {
    path: "/tools/gratingpair",
    name: "gratingpair",
    components: {
      default: GratingPairCompressorView,
      navbar: NavBar,
    },
    meta: { title: "Grating pair compressor - Optics Toolbox" },
  },
  {
    path: "/tools/prismpair",
    name: "prismpair",
    components: {
      default: TwoPrismCompressorView,
      navbar: NavBar,
    },
    meta: { title: "Two prism compressor - Optics Toolbox" },
  },
  {
    path: "/tools/pulsebroadening",
    name: "pulsebroadening",
    components: {
      default: PulseElongationView,
      navbar: NavBar,
    },
    meta: { title: "Pulse elongation with known GDD - Optics Toolbox" },
  },
  {
    path: "/tools/gaussianbeamelongation",
    name: "gaussianbeamelongation",
    components: {
      default: PulseElongationThroughMaterialView,
      navbar: NavBar,
    },
    meta: { title: "Pulse elongation (through material) - Optics Toolbox" },
  },
  //---------------------Nonlinear optics
  {
    path: "/tools/PMangles",
    name: "PMangles",
    components: {
      default: NonlinearOpticalInteractionsView,
      navbar: NavBar,
    },
    meta: { title: "Nonlinear optical interacions - Optics Toolbox" },
  },
  {
    path: "/tools/SFG",
    name: "SFG",
    components: {
      default: SumFrequencyGenerationView,
      navbar: NavBar,
    },
    meta: { title: "Sum frequency generation - Optics Toolbox" },
  },
  {
    path: "/tools/SFGinternalangles",
    name: "SFGinternalangles",
    components: {
      default: NoncolinearSFGInternalAnglesView,
      navbar: NavBar,
    },
    meta: { title: "Noncolinear SFG internal angles - Optics Toolbox" },
  },
  {
    path: "/tools/Bintegral",
    name: "Bintegral",
    components: {
      default: BIntegralView,
      navbar: NavBar,
    },
    meta: { title: "B-integral - Optics Toolbox" },
  },
  {
    path: "/tools/tuningcurves",
    name: "tuningcurves",
    components: {
      default: TuningCurvesView,
      navbar: NavBar,
    },
    meta: { title: "Tuning Curves - Optics Toolbox" },
  },
  {
    path: "/tools/HarmonicModuleOptimization",
    name: "harmonicmoduleoptimization",
    components: {
      default: HarmonicModuleOptimization,
      navbar: NavBar,
    },
    meta: { title: "Harmonic module optimization" },
  },
  //----------------------Admin
  {
    path: "/admin",
    name: "admin",
    components: {
      default: AdminView,
    },
    meta: { title: "Admin - Optics Toolbox" },
  },
  {
    path: "/admin/dashboard/products",
    name: "admindashboard",
    components: {
      default: AdminDashboardView,
      navbar: NavBarAdmin,
    },
    meta: { title: "Admin - Optics Toolbox" },
    beforeEnter: () => {
      if (createStore.state.status.loggedIn || createStore.state.initialState.status.loggedIn) {
        return true;
      }
      return { name: "admin" };
    },
    children: [
      {
        path: ":id",
        name: "productinfo",
        components: {
          default: ProductInfo,
        },
        props: true,
      },
    ],
  },
  {
    path: "/admin/dashboard/schemes",
    name: "adminschemes",
    components: {
      default: AdminSchemes,
      navbar: NavBarAdmin,
    },
    meta: { title: "Admin - Optics Toolbox" },
    beforeEnter: () => {
      if (createStore.state.status.loggedIn || createStore.state.initialState.status.loggedIn) {
        return true;
      }
      return { name: "admin" };
    },
    children: [
      {
        path: ":id",
        name: "schemeinfo",
        components: {
          default: KitchenPlanner,
        },
        props: true,
      },
    ],
  },
  {
    path: "/admin/dashboard/presets",
    name: "adminpresets",
    components: {
      default: AdminPresets,
      navbar: NavBarAdmin,
    },
    meta: { title: "Admin - Optics Toolbox" },
    beforeEnter: () => {
      if (createStore.state.status.loggedIn || createStore.state.initialState.status.loggedIn) {
        return true;
      }
      return { name: "admin" };
    },
    children: [
      {
        path: ":id",
        name: "presetinfo",
        components: {
          default: KitchenPlanner,
        },
        props: true,
      },
    ],
  },
  {
    path: "/admin/dashboard/products/register",
    name: "productregister",
    components: {
      default: ProductRegisterView,
      navbar: NavBarAdmin,
    },
    meta: { title: "Admin - Optics Toolbox" },
    beforeEnter: () => {
      if (createStore.state.status.loggedIn || createStore.state.initialState.status.loggedIn) {
        return true;
      }
      return { name: "admin" };
    },
  },
  {
    path: "/admin/dashboard/presets/register",
    name: "presetregister",
    components: {
      default: PresetRegisterView,
      navbar: NavBarAdmin,
    },
    meta: { title: "Admin - Optics Toolbox" },
    beforeEnter: () => {
      if (createStore.state.status.loggedIn || createStore.state.initialState.status.loggedIn) {
        return true;
      }
      return { name: "admin" };
    },
  },
  {
    path: "/admin/dashboard/materials",
    name: "adminmaterials",
    components: {
      default: AdminMaterials,
      navbar: NavBarAdmin,
    },
    meta: { title: "Admin - Optics Toolbox" },
    beforeEnter: () => {
      if (createStore.state.status.loggedIn || createStore.state.initialState.status.loggedIn) {
        return true;
      }
      return { name: "admin" };
    },
  },
  {
    path: "/admin/dashboard/materials/editor",
    name: "adminmaterialeditor",
    components: {
      default: AdminMaterialEditor,
      navbar: NavBarAdmin,
    },
    meta: { title: "Admin - Optics Toolbox" },
    beforeEnter: () => {
      if (createStore.state.status.loggedIn || createStore.state.initialState.status.loggedIn) {
        return true;
      }
      return { name: "admin" };
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map((el) => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

export default router;
