<template lang="">
  <div class="row">
    <div class="col-sm-4">
      <Selector
        v-if="$store.state.dataReady"
        :list="$store.state.categorizedProducts"
        :list-name="'Products'"
        @selectedMaterial="onSelection"
      /><!-- PRODUCT ADD -->
    </div>
  </div>

  <router-view v-if="selectedProduct !== null" />
</template>
<script>
import Selector from "@/components/Selector.vue";

export default {
  components: {
    Selector,
  },
  data() {
    return {
      selectedProduct: null,
    };
  },
  methods: {
    onSelection(product) {
      this.selectedProduct = product;
      this.$router.push({ name: "productinfo", params: { id: product.id } });
    },
    onClick(){
      this.$router.push({ name: "productregister" });
    }
  },
};
</script>
<style lang=""></style>
