<template>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
        >
          &times;
        </button>
        <h4 class="modal-title">
          Third-order dispersion
        </h4>
      </div>
      <div class="modal-body">
        <p>
          Third-order dispersion (TOD) in material with refraction index
          <math-jax latex="n(\lambda)" />:
          <math-jax
            latex="\mathrm{TOD}(\lambda) =
          -\frac{\lambda^{4}}{4\pi^{2}c^{3}}\left[3\frac{\mathrm{d}^{2}n}{\mathrm{d}\lambda^{2}}+\lambda\frac{\mathrm{d}^{3}n}{\mathrm{d}\lambda^{^{3}}}\right]."
            :block="true"
          />
        </p>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-default"
          data-dismiss="modal"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {

};
</script>
