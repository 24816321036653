<template lang="">
  <div
    id="description_brewster_configuration"
    class="modal"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
          >
            &times;
          </button>
          <h4 class="modal-title">
            Brewster configuration
          </h4>
        </div>
        <div class="modal-body">
          <p>
            <math-jax latex="\vartheta_0" /> and prism apex angle <math-jax latex="\alpha" /> are set so that Brewster's angle of incidence and minimum
            deviation conditions were met:
            <math-jax
              latex="\vartheta_0 = \vartheta_\mathrm{B}=\arctan n(\lambda),"
              :block="true"
            />
            <math-jax
              latex="\alpha = 2\arcsin \left(\frac{\sin\vartheta_\mathrm{B}}{n}\right)."
              :block="true"
            />
            <img
              src="@/assets/static/media/svg/prismbrewster.svg"
              class="img-responsive center-block"
            >
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-default"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: null,
  },
};
</script>
