<template lang="">
  <div v-if="product !== null">
    <div class="row">
      <div class="col-sm-4">
        <ProductInfoForUser
          v-if="product !== null"
          :product="product"
        />
      </div>
      <div class="col-sm-8">
        <div class="row">
          <div class="col-sm-8">
            <div
              class=""
              style="list-style-type: none"
            >
              <li>
                <label :class="`${hasError ? 'text-danger' : ''}`">Name</label>
                <div :class="`${hasError ? 'has-error' : ''}`">
                  <input
                    v-model="product.name"
                    type="text"
                    class="form-control indicator"
                    :disabled="disabled"
                  >
                </div>
              </li>
              <li>
                <label :class="`${hasError ? 'text-danger' : ''}`">Category</label>
                <div :class="`${hasError ? 'has-error' : ''}`">
                  <input
                    v-model="product.category"
                    type="text"
                    class="form-control indicator"
                    :disabled="disabled"
                  >
                </div>
              </li>
              <li>
                <label :class="`${hasError ? 'text-danger' : ''}`">Long name</label>
                <div :class="`${hasError ? 'has-error' : ''}`">
                  <input
                    v-model="product.longName"
                    type="text"
                    class="form-control indicator"
                    :disabled="disabled"
                  >
                </div>
              </li>
              <li>
                <label :class="`${hasError ? 'text-danger' : ''}`">Dynamics NAV code</label>
                <div :class="`${hasError ? 'has-error' : ''}`">
                  <input
                    v-model="product.navCode"
                    type="text"
                    class="form-control indicator"
                    :disabled="disabled"
                  >
                </div>
              </li>
              <li>
                <label>Description text</label>
                <textarea
                  v-model="product.descriptionText"
                  type="text"
                  class="form-control indicator"
                  rows="8"
                  :disabled="disabled"
                />
              </li>
              <li>
                <label>Link to LC website</label>
                <input
                  v-model="product.descriptionLink"
                  type="text"
                  class="form-control indicator"
                  :disabled="disabled"
                >
              </li>
            </div>
          </div>
          <div class="col-sm-4">
            <div
              class="list"
              style="list-style-type: none"
            >
              <li>
                <label :class="`${hasError ? 'text-danger' : ''}`">Width</label>
                <div :class="`${hasError ? 'has-error' : ''}`">
                  <input
                    v-model="product.width"
                    type="number"
                    step="0.01"
                    class="form-control indicator"
                    :disabled="disabled"
                  >
                </div>
              </li>
              <li>
                <label :class="`${hasError ? 'text-danger' : ''}`">Height</label>
                <div :class="`${hasError ? 'has-error' : ''}`">
                  <input
                    v-model="product.height"
                    type="number"
                    step="0.01"
                    class="form-control indicator"
                    :disabled="disabled"
                  >
                </div>
              </li>
              <li>
                <label>Box Width</label>
                <input
                  v-model="product.boxWidth"
                  type="number"
                  step="0.01"
                  class="form-control indicator"
                  :disabled="disabled"
                >
              </li>
              <li>
                <label>Box Height</label>
                <input
                  v-model="product.boxHeight"
                  type="number"
                  step="0.01"
                  class="form-control indicator"
                  :disabled="disabled"
                >
              </li>
              <li>
                <label :class="`${hasSnapError ? 'text-danger' : ''}`">Snap Position X</label>
                <div :class="`${hasSnapError ? 'has-error' : ''}`">
                  <input
                    v-model="product.snapPositionX"
                    type="number"
                    step="0.01"
                    class="form-control indicator"
                    :disabled="disabled"
                  >
                </div>
              </li>
              <li>
                <div :class="`${hasSnapError ? 'has-error' : ''}`">
                  <label :class="`${hasSnapError ? 'text-danger' : ''}`">Snap Position X</label>
                  <input
                    v-model="product.snapPositionY"
                    type="number"
                    step="0.01"
                    class="form-control indicator"
                    :disabled="disabled"
                  >
                </div>
              </li>
              <li>
                <div class="row">
                  <div class="col-sm-9">
                    <label>Visible From Outside</label>
                  </div>
                  <div class="col-sm-2">
                    <input
                      v-model="product.visibleFromOutside"
                      type="checkbox"
                      class="checkbox"
                      :disabled="disabled"
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="row">
                  <div class="col-sm-9">
                    <label>Scalable X</label>
                  </div>
                  <div class="col-sm-2">
                    <input
                      v-model="product.scalableX"
                      type="checkbox"
                      class="checkbox"
                      :disabled="disabled"
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="row">
                  <div class="col-sm-9">
                    <label>Scalable Y</label>
                  </div>
                  <div class="col-sm-2">
                    <input
                      v-model="product.scalableY"
                      type="checkbox"
                      class="checkbox"
                      :disabled="disabled"
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="row">
                  <div class="col-sm-9">
                    <label>Free Rotate</label>
                  </div>
                  <div class="col-sm-2">
                    <input
                      v-model="product.freeRotate"
                      type="checkbox"
                      class="checkbox"
                      :disabled="disabled"
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="row">
                  <div class="col-sm-9">
                    <label>Snap X</label>
                  </div>
                  <div class="col-sm-2">
                    <input
                      v-model="product.snapX"
                      type="checkbox"
                      class="checkbox"
                      :disabled="disabled"
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="row">
                  <div class="col-sm-9">
                    <label>Snap Y</label>
                  </div>
                  <div class="col-sm-2">
                    <input
                      id="customSwitch1"
                      v-model="product.snapY"
                      type="checkbox"
                      class="custom-control-input"
                      :disabled="disabled"
                    >
                  </div>
                </div>
              </li>
            </div>
            <div
              class="btn-group pull-right"
              role="group"
              aria-label="..."
            >
              <button
                v-if="!disabled"
                type="button"
                class="btn btn-sm btn-danger"
                @click="onDelete()"
              >
                Delete
              </button>
              <button
                v-if="!disabled"
                type="button"
                class="btn btn-sm btn-primary"
                @click="onSave()"
              >
                Save
              </button>
              <button
                type="button"
                :class="`${disabled === true ? 'btn btn-sm btn-primary' : 'btn btn-sm btn-default'}`"
                @click="disabled = !disabled"
              >
                {{ disabled ? "Enable Edit" : "Disable Edit" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row top-pad">
      <div class="col-sm-4">
        <div class="panel panel-default">
          <div class="panel-heading">
            Description image
          </div>
          <div class="panel-body">
            <img
              v-if="descImgPresent"
              class=""
              :src="imgUrl"
              height="300"
              width="300"
            >
          </div>
          <div class="panel-footer">
            <div
              class="btn-group"
              role="group"
            >
              <div class="file btn btn-sm btn-default">
                Upload <input
                  type="file"
                  accept="image/*"
                  capture
                  class="input"
                  @change="onFileChanged($event)"
                >
              </div>
              <button
                v-if="descImgPresent"
                type="button"
                class="btn btn-sm btn-danger"
                @click="onDeleteDescriptionImage()"
              >
                Delete
              </button>
            </div>
            {{ product.descriptionImage }}
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="panel panel-default">
          <div class="panel-heading">
            SVG image
          </div>
          <div class="panel-body">
            <img
              class=""
              :src="svgUrl"
              height="300"
              width="300"
            >
          </div>
          <div class="panel-footer">
            <div
              class="btn-group"
              role="group"
            >
              <div class="file btn btn-sm btn-default">
                Upload <input
                  type="file"
                  accept="image/*"
                  capture
                  class="input"
                  @change="onSvgFileChanged($event)"
                >
              </div>
            </div>
            {{ product.svgImage }}
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="panel panel-default">
          <div class="panel-heading">
            Terminals
          </div>
          <div class="panel-body">
            <TerminalsTable
              :terminals="product.terminals"
              @add="onAdd"
              @change="onChange"
              @remove="onRemove"
              @save="onSave"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProductInfoForUser from "@/components/planner/ProductInfoForUser.vue";
import DataService from "@/services/data.service";
import TerminalsTable from "./TerminalsTable.vue";

export default {
  descImgPresent: true,
  components: {
    ProductInfoForUser,
    TerminalsTable,
  },
  props: {
    id: null,
  },
  data() {
    return {
      disabled: true,
      product: null,
      img: null,
      hasError: false,
      hasSnapError: false,
    };
  },
  computed: {
    imgUrl() {
      if (this.product.descriptionImage === null) return null;

      return (
        process.env.VUE_APP_IMAGES +
        this.product.descriptionImage +
        "?" +
        Date.now()
      );
    },
    svgUrl() {
      if (this.product.svgImage === null) {
        return null;
      }
      return (
        process.env.VUE_APP_IMAGES +
        this.product.svgImage +
        "?" +
        Date.now()
      );
    },
  },
  watch: {
    id() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      DataService.getProductData(this.id).then((response) => {
        this.product = response.data;
        this.descImgPresent = this.product.descriptionImage != null;
      });
    },
    onSave() {
      if (!this.product.name || !this.product.category || !this.product.width ||
          !this.product.height || !this.product.longName || !this.product.navCode) {
        this.hasError = true;
        return;
      }
      if ((this.product.snapX === true || this.product.snapY === true) && (this.product.snapPositionX == null || this.product.snapPositionY == null)){
        this.hasSnapError = true;
        return;
      }
      this.hasError = false;
      DataService.updateProduct(this.id, this.product)
        .then((response) => {
          if (response.status === 204) {
            this.saveInfo();
            this.$store.dispatch("loadProducts");
          }
        })
        .catch(this.errorHandling());
      this.disabled = true;
    },
    onDelete() {
      DataService.deleteProduct(this.id).then(() => {
        this.confirmDialog();
      });
    },
    onAdd() {
      this.product.terminals.push({ left: 0, top: 0 });
    },
    onChange(terminals) {
      this.product.terminals = terminals;
    },
    onRemove(index) {
      this.product.terminals.splice(index, 1);
    },
    saveInfo() {
      this.$swal.fire({
        position: "bottom-end",
        icon: "success",
        title: "Your work has been saved",
        showConfirmButton: false,
        timer: 1500,
      });
    },
    errorHandling() {
      this.$swal.fire({
        position: "bottom-end",
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    },
    confirmDialog() {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$swal.fire("Deleted!", "Your file has been deleted.", "success");
            this.$store.dispatch("loadProducts");
            this.$router.push({ name: "admindashboard" });
          }
        });
    },
    onFileChanged(event) {
      DataService.postDescriptionImage(this.product.id, event.target.files[0]).then(async () => {
        this.getData();
        this.$store.dispatch("loadProducts");
      });
    },
    onSvgFileChanged(event) {
      DataService.postSvgImage(this.product.id, event.target.files[0]).then(() => {
        this.getData();
        this.$store.dispatch("loadProducts");
      });
    },
    onDeleteDescriptionImage() {
      DataService.deleteDescriptionImage(this.product.id).then(() => {
        this.getData();
        this.$store.dispatch("loadProducts");
      });
    },
  },
};
</script>
<style scoped>
div {
  position: relative;
  overflow: hidden;
}
.input {
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
}
img{
  display: block;
  width: 100%;
  height: 200px;
  background-size: cover;
  object-fit: contain;
}
</style>
