<template lang="">
  <div
    :id="id"
    class="modal"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
          >
            &times;
          </button>
          <h4 class="modal-title">
            Angular walk-off
          </h4>
        </div>
        <div class="modal-body">
          <p>
            Angle <math-jax latex="\rho_i" /> (<math-jax latex="i=1,2,3" />) between the wave vector <math-jax latex="\vec{k}_i" /> and direction of maximum beam
            intensity (Poyinting vector) of extraordinary ray:
            <math-jax
              latex="\rho_i = -\frac{1}{n_\mathrm{e}(\lambda_i,\vartheta_i)}\cdot\frac{\partial
            n_\mathrm{e}(\lambda_i,\vartheta_i)}{\partial\vartheta_i}."
              :block="true"
            /> Ordinary rays do not have spatial walk-off.
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-default"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: null,
  },
};
</script>