<template lang="">
  <div
    v-if="$store.state.dataReady"
    id="gaussianbeamelongation"
    ng-controller="materialsdbCtrl"
    class="row"
  >
    <!-- TITLE -->
    <div class="col-lg-12 col-md-12 col-xs-12 settings">
      <h3>
        Pulse elongation (through material)
        <small><span
          data-toggle="collapse"
          data-target="#info_beamdisplacement"
          class="glyphicon glyphicon-info-sign info-glyph"
        /></small>
      </h3>
    </div>
    <!-- INFO/OUTPUT -->
    <div class="col-lg-6 col-md-6 col-xs-12 settings">
      <div
        id="info_beamdisplacement"
        class="collapse"
      >
        <p>Material dispersion is calculated by multiplying thickness with GVD in fs2/mm, i.e. only second order dispersion (GDD in fs2) is taken into account</p>
        <img
          class="img-center"
          src="@/assets/static/media/svg/gaussianbeamelongation.svg"
        >
      </div>

      <PulseElongationRayOutput
        ref="ordinary"
        icon="glyphicon glyphicon-resize-horizontal"
        Title="Ordinary"
        :beforeFWHM_wValue="beforeFWHM_wValue"
        :wl_units="wl_units"
        :wlslider-value="inputValue"
        :material="material"
        :LValue="LValue"
        @beforeFWHM_t="onBeforeFWHM_tChange"
      />
      <PulseElongationRayOutput
        v-if="isMaterialBiaxial"
        ref="extraordinary"
        icon="glyphicon glyphicon-resize-vertical"
        Title="Extraordinary"
        :beforeFWHM_wValue="beforeFWHM_wValue"
        :wl_units="wl_units"
        :wlslider-value="inputValue"
        :material="material"
        :LValue="LValue"
        :extraordinay-ray="isMaterialBiaxial"
      />
    </div>
    <!-- INPUT -->
    <div class="col-lg-6 col-md-6 col-xs-12 settings">
      <!-- MATERIAL SELECT -->
      <Selector
        :list="materials"
        :list-name="'Materials'"
        :default-selection="'fused_silica'"
        @selectedMaterial="onSelection"
      />

      <!-- WAVELENGTH INPUT -->
      <h5>
        Wavelength range: <span>{{ wlrangeMin }}</span>-<span>{{ wlrangeMax }}</span> nm
      </h5>
      <div class="row">
        <div class="col-md-8 col-xs-6 top-padding">
          <Slider
            v-model="slider.value"
            v-bind="slider"
            @update="onSliderChange()"
          />
        </div>
        <div class="col-md-4 col-xs-6">
          <div class="input-group">
            <input
              id="wltext"
              v-model="inputValue"
              step="1"
              type="number"
              class="form-control indicator"
              tabindex="1"
              @change="onInputChange()"
            >
            <span class="input-group-addon">nm</span>
          </div>
        </div>
      </div>

      <!-- FWHM_w, THICKNESS SELECT -->
      <div class="row">
        <div class="col-md-6 col-xs-6 vcenter">
          <h5>Incident pulse <math-jax latex="\mathrm{FWHM}_\omega" />:</h5>
          <div class="input-group">
            <input
              id="FWHMText"
              v-model="beforeFWHM_wValue"
              step="0.001"
              type="number"
              class="form-control"
              tabindex="2"
            >
            <div class="input-group-btn">
              <button
                type="button"
                class="btn btn-default dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{ wl_units }} <span class="caret" />
              </button>
              <ul class="dropdown-menu dropdown-menu-right">
                <li><a @click="wl_units = 'cm-1'">cm-1</a></li>
                <li><a @click="wl_units = 'fs-1'">fs-1</a></li>
                <li><a @click="wl_units = 'nm'">nm</a></li>
              </ul>
            </div>
            <!--<span class="input-group-addon">fs<sup>-1</sup></span>-->
          </div>
        </div>
        <div class="col-md-6 col-xs-6">
          <h5>Material thickness <math-jax latex="h" />:</h5>
          <div class="input-group">
            <input
              id="LText"
              v-model="LValue"
              step="0.1"
              type="number"
              class="form-control indicator"
              tabindex="3"
            >
            <span class="input-group-addon">mm</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Slider from "@vueform/slider";
import DataService from "@/services/data.service";
import Selector from "@/components/Selector.vue";
import PulseElongationRayOutput from "@/components/PulseElongationRayOutput.vue";
import { bc_to_omega, bc_omega_to } from "@/tools/BandwidthConverterOmega.js";

export default {
  components: {
    Slider,
    Selector,
    PulseElongationRayOutput,
  },
  data() {
    return {
      material: null,
      inputValue: 1030,
      slider: {
        value: 1030,
        max: 2000,
        lazy: false,
        showTooltip: "drag",
        step: -1,
      },
      wlrangeMin: null,
      wlrangeMax: null,
      beforeFWHM_wValue: 500,
      LValue: 1,
      wl_units: "cm-1",
    };
  },
  computed: {
    materials() {
      return this.$store.getters.materials;
    },
    isMaterialBiaxial() {
      if (this.material != null && this.material.NumberOfAxis == 2) return true;
      return false;
    },
  },
  watch: {
    material(newValue) {
      this.wlrangeMin = parseFloat(newValue.parameters[0].wl_n_range[0]) * 1.0e3;
      this.slider.min = this.wlrangeMin;
      this.wlrangeMax = parseFloat(newValue.parameters[0].wl_n_range[1]) * 1.0e3;
      this.slider.max = this.wlrangeMax;
      if (this.slider.value > this.wlrangeMax) this.inputValue = this.wlrangeMax;
      if (this.slider.value < this.wlrangeMin) this.inputValue = this.wlrangeMin;
    },
    wl_units(newValue, oldValue) {
      var result = bc_to_omega(this.beforeFWHM_wValue, oldValue, this.inputValue);
      result = bc_omega_to(result, newValue, this.inputValue);

      switch (newValue) {
        case "cm-1":
          result = Math.round10(result, -2);
          break;
        case "fs-1":
          result = Math.round10(result, -6);
          break;
        case "nm":
          result = Math.round10(result, -3);
          break;
      }
      this.beforeFWHM_wValue = result;
    },
  },
  methods: {
    onInputChange() {
      this.slider.value = this.inputValue;
    },
    onSliderChange() {
      this.inputValue = this.slider.value;
    },
    onSelection(material) {
      DataService.getMaterialData(material).then((response) => {
        this.material = response.data.value;
      });
    },
    onBeforeFWHM_tChange(value) {
      var TBWP = 0.441271200305303186792912864236;
      var beforeFWHM_w = (TBWP / value) * 2.0 * this.$store.state.pi;

      var result = bc_omega_to(beforeFWHM_w, this.wl_units, this.inputValue);

      switch (this.wl_units) {
        case "cm-1":
          result = Math.round10(result, -2);
          break;
        case "fs-1":
          result = Math.round10(result, -6);
          break;
        case "nm":
          result = Math.round10(result, -3);
          break;
      }
      this.beforeFWHM_wValue = result;
    },
  },
};
</script>
<style lang=""></style>
