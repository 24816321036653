<template lang="">
  <div
    :id="id"
    class="modal"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
          >
            &times;
          </button>
          <h4 class="modal-title">
            Divergence angle
          </h4>
        </div>
        <div class="modal-body">
          <p>
            Divergence angle <math-jax latex=" \vartheta " /> describes how Gaussian beam diameter spreads in the far field (<math-jax
              latex="z\gg
            z_\mathrm{R} "
            />). For beam quality factor <math-jax latex=" M^2 " />, 
            <math-jax
              latex="\vartheta = 2M^2\frac{\lambda}{\pi w_0}."
              :block="true"
            />
            Beam divergence half-angle <math-jax latex=" \theta = \vartheta/2 " /> is often used.
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-default"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: null,
  },
};
</script>
