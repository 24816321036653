<template>
  <div
    :id="id"
    class="modal"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
          >
            &times;
          </button>
          <h4 class="modal-title">
            Time-bandwidth product
          </h4>
        </div>
        <div class="modal-body">
          <p>
            Product of pulse duration and spectral width frequency (both in FWHM). Has its minimum for ideal
            transform-limited pulses:
          </p>
          <ul>
            <li>
              Gaussian, <math-jax latex="I(t)\propto \exp\left[-(4\ln 2)t^2/\Delta t^2\right]" />:
              <math-jax
                latex="\Delta t\cdot \Delta\nu = \frac{2\ln 2}{\pi}\approx0.441."
                :block="true"
              />
            </li>
            <li>
              <math-jax latex="\mathrm{sech}^2" />, <math-jax latex="I(t)\propto\left[\exp(2t/\Delta t)+\exp(-2t/\Delta t)\right]^{-1}:" />
              <math-jax
                latex="\Delta t\cdot \Delta\nu = \frac{4\ln^2(\sqrt{2}+1)}{\pi^2}\approx0.315."
                :block="true"
              />
            </li>
            <li>
              Lorentzian, <math-jax latex="I(t)\propto \left[1+4\left(\sqrt{2}-1\right)\left(t/\Delta t\right)^{2}\right]^{-2}" />:
              <math-jax
                latex="\Delta t\cdot \Delta\nu = \frac{\ln 2\sqrt{\sqrt{2}-1}}{\pi}\approx0.142."
                :block="true"
              />
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-default"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: null,
  },
};
</script>