<template lang="">
  <div class="row">
    <div class="col-sm-4">
      <Selector
        v-if="$store.state.dataReady"
        :list="$store.state.presets"
        :list-name="'Presets'"
        @selectedMaterial="onSelection"
      />
    </div>   
    
    <div class="col-sm-8">
      <button
        v-if="selectedPreset !== null"
        class="btn btn-primary"
        @click="edit = !edit"
      >
        Edit
      </button>
    </div>
  </div>
  
  <router-view
    v-if="selectedPreset !== null"
    :show-selector="edit"
    :preset-mode="true"
    :preset="selectedPreset"
  />
</template>
<script>
import Selector from "@/components/Selector.vue";

export default {
  components: {
    Selector,
  },
  data() {
    return {
      selectedPreset: null,
      edit: false
    };
  },
  methods: {
    onSelection(preset) {
      this.selectedPreset = preset;
      this.$router.push({ name: "presetinfo", params: { id: preset.schemeId } });
    },
  },
};
</script>
<style lang=""></style>
