export const c = 299792458;
export const pi = 3.14159265359;

export function GroupVelocity(material, wl, index, theta, phi) {
  // result - in m/s

  if (typeof index == "undefined") {
    index = 0;
  }

  // since wl is given in um, delta is 0.000 01 = 1e-5 = 0.01 nm
  var deltawl = 5.0e-6;
  var denominator = 2.0 * deltawl;

  var n0, n1, n2;

  if (typeof theta === "undefined") {
    n0 = RefractiveIndex(wl, index, material);
    n1 = RefractiveIndex(wl - deltawl, index, material);
    n2 = RefractiveIndex(wl + deltawl, index, material);
  } else {
    n0 = RefractiveIndexGeneral(wl, material, theta, phi);
    n1 = RefractiveIndexGeneral(wl - deltawl, material, theta, phi);
    n2 = RefractiveIndexGeneral(wl + deltawl, material, theta, phi);
  }

  if (typeof n0 == "undefined") {
    return;
  }

  if (typeof n1 == "undefined") {
    n1 = n0;
    denominator = deltawl;
  }
  if (typeof n2 == "undefined") {
    n2 = n0;
    denominator = deltawl;
  }

  if (Math.abs(n2 - n1) < 1e-20) {
    return;
  }

  return c / (n0 - (wl * (n2 - n1)) / denominator);
}

export function RefractiveIndexGeneral(wl, material, theta, phi) {
  if (typeof material === "undefined") {
    return;
  }

  if (typeof phi === "undefined") {
    phi = 0.0;
  }

  if (typeof theta === "undefined") {
    theta = 0.0;
  }

  switch (material.NumberOfAxis) {
    case 0.0:
      return RefractiveIndexMaterial(wl, material, 0);
    case 1.0:
      var n_o = RefractiveIndexMaterial(wl, material, 0);
      var n_e = RefractiveIndexMaterial(wl, material, 1);
      return (n_o * n_e) / Math.sqrt(Math.pow(n_o * Math.sin(theta), 2.0) + Math.pow(n_e * Math.cos(theta), 2.0));
    case 2.0:
      var n_x = RefractiveIndexMaterial(wl, material, 0);
      var n_y = RefractiveIndexMaterial(wl, material, 1);
      var n_z = RefractiveIndexMaterial(wl, material, 2);
      return (
        (n_x * n_y * n_z) /
        Math.sqrt(
          Math.pow(n_z * Math.sin(theta), 2.0) *
            (Math.pow(n_x * Math.sin(phi), 2.0) + Math.pow(n_y * Math.cos(phi), 2.0)) +
            Math.pow(n_y * n_x * Math.cos(theta), 2.0)
        )
      );
  }
}

export function UpdateGVDZero (wl, material, isMaterialBiaxial) {
  var fun = function (x) {
    return (GroupVelocityDispersion(x, 0, material));
  }

  if(isMaterialBiaxial){
    fun = function (x) {
      return (GroupVelocityDispersion(x, 1, material));
    };
  }

  var wl0 = fsolve_monotonic (fun, material.parameters[0].wl_n_range[0], material.parameters[0].wl_n_range[1]);

  if ((typeof wl0 !== 'undefined') ) {
      return (wl0 * 1.0e3).toFixed(3);
  } else {
      return null;
  }

}

//FIND ROOTS OF MONOTONIC FUNCTION (GVD)
export function fsolve_monotonic (fun, xmin, xmax) {
  var precision=1.0e-5;

  if (fun(xmin) * fun(xmax) >=0 ) {
      return;
  }

  var dx = 1.0e-2;
  var limit_delta = dx * 2;

  var x = xmin + dx;
  var x_new = x - dx / (fun(x+dx)/fun(x)-1.0) / 5.0; // daliklis pabaigoje tik tam, kad per greit nesokinetu

  if ((x_new < xmin + limit_delta) || (x_new > xmax - limit_delta)) {
      return;
  }

  var iter = 0;

  while ((Math.abs(x_new - x)>precision) && (iter < 1000)) {
      iter = iter + 1

      x = x_new;
      x_new = x - dx / (fun(x+dx)/fun(x)-1.0) / 5.0; // daliklis pabaigoje tik tam, kad per greit nesokinetu

      if ((x_new < xmin + limit_delta) || (x_new > xmax - limit_delta)) {
          if (fun(xmin) * fun(xmax) < 0.0) {
              x_new = x + dx;
          } else {
              return;
          }
      }
  }

  return (x);
}

export function RefractiveIndex(wl, index, material) {
  if (typeof index == "undefined") {
    index = 0;
  }
  if (typeof material === "undefined" || typeof material.parameters[index] === "undefined") {
    return;
  }
  if (wl > material.parameters[index].wl_n_range[1] || wl < material.parameters[index].wl_n_range[0]) {
    return;
  }
  var C;
  var i;
  var Clength;
  var tmp;

  // data array given - interpolate!
  if (material.parameters[index].formula === 0) {
    var n_wl = material.parameters[index].data_n_wl;
    var n = material.parameters[index].data_n;

    if (n_wl.length != n.length) {
      return;
    }

    var len = n.length;

    var j = 0;
    while (n_wl[j] < wl && j < len) {
      j += 1;
    }

    if (j === 0) {
      return n[0];
    }

    var x1 = n_wl[j - 1];
    var x2 = n_wl[j];
    var y1 = n[j - 1];
    var y2 = n[j];

    return ((y2 - y1) / (x2 - x1)) * (wl - x1) + y1;
  }

  if (material.parameters[index].formula < 10 && material.parameters[index].n_defined) {
    C = material.parameters[index].sellmeier_coeffs;
    Clength = C.length;
    for (i = 0; i < 17 - Clength; i += 1) {
      C.push(0.0);
    }

    switch (material.parameters[index].formula) {
      case 1:
        return Math.sqrt(
          1 +
            C[0] +
            C[1] / (1 - Math.pow(C[2] / wl, 2)) +
            C[3] / (1 - Math.pow(C[4] / wl, 2)) +
            C[5] / (1 - Math.pow(C[6] / wl, 2)) +
            C[7] / (1 - Math.pow(C[8] / wl, 2)) +
            C[9] / (1 - Math.pow(C[10] / wl, 2)) +
            C[11] / (1 - Math.pow(C[12] / wl, 2)) +
            C[13] / (1 - Math.pow(C[14] / wl, 2)) +
            C[15] / (1 - Math.pow(C[16] / wl, 2))
        );
      case 2:
        return Math.sqrt(
          1 +
            C[0] +
            C[1] / (1 - C[2] / Math.pow(wl, 2)) +
            C[3] / (1 - C[4] / Math.pow(wl, 2)) +
            C[5] / (1 - C[6] / Math.pow(wl, 2)) +
            C[7] / (1 - C[8] / Math.pow(wl, 2)) +
            C[9] / (1 - C[10] / Math.pow(wl, 2)) +
            C[11] / (1 - C[12] / Math.pow(wl, 2)) +
            C[13] / (1 - C[14] / Math.pow(wl, 2)) +
            C[15] / (1 - C[16] / Math.pow(wl, 2))
        );
      case 6:
        return (
          1 +
          C[0] +
          C[1] / (C[2] - Math.pow(wl, -2)) +
          C[3] / (C[4] - Math.pow(wl, -2)) +
          C[5] / (C[6] - Math.pow(wl, -2)) +
          C[7] / (C[8] - Math.pow(wl, -2)) +
          C[9] / (C[10] - Math.pow(wl, -2))
        );
      case 3:
        return Math.sqrt(
          C[0] +
            C[1] * Math.pow(wl, C[2]) +
            C[3] * Math.pow(wl, C[4]) +
            C[5] * Math.pow(wl, C[6]) +
            C[7] * Math.pow(wl, C[8]) +
            C[9] * Math.pow(wl, C[10]) +
            C[11] * Math.pow(wl, C[12]) +
            C[13] * Math.pow(wl, C[14]) +
            C[15] * Math.pow(wl, C[16])
        );
      case 5:
        return (
          C[0] +
          C[1] * Math.pow(wl, C[2]) +
          C[3] * Math.pow(wl, C[4]) +
          C[5] * Math.pow(wl, C[6]) +
          C[7] * Math.pow(wl, C[8]) +
          C[9] * Math.pow(wl, C[10])
        );
      case 7:
        return (
          C[0] +
          C[1] / (Math.pow(wl, 2) - 0.028) +
          C[2] / Math.pow(Math.pow(wl, 2) - 0.028, 2) +
          C[3] * Math.pow(wl, 2) +
          C[4] * Math.pow(wl, 4) +
          C[5] * Math.pow(wl, 6)
        );
      case 8:
        tmp = C[0] + (C[1] * Math.pow(wl, 2)) / (Math.pow(wl, 2) - C[2]) + C[3] * Math.pow(wl, 2);
        return Math.sqrt((2 * tmp + 1) / (1 - tmp));
      case 9:
        return Math.sqrt(
          C[0] + C[1] / (Math.pow(wl, 2) - C[2]) + (C[3] * (wl - C[4])) / (Math.pow(wl - C[4], 2) + C[5])
        );
      case 4:
        if (material.parameters[index].formula === 4 && material.alias[0] === "TiO2") {
          return Math.sqrt(C[0] + (C[1] * Math.pow(wl, C[2])) / (Math.pow(wl, 2) - Math.pow(C[3], C[4])));
        } else {
          return Math.sqrt(
            C[0] +
              (C[1] * Math.pow(wl, C[2])) / (Math.pow(wl, 2) - Math.pow(C[3], C[4])) +
              (C[5] * Math.pow(wl, C[6])) / (Math.pow(wl, 2) - Math.pow(C[7], C[8])) +
              C[9] * Math.pow(wl, C[10]) +
              C[11] * Math.pow(wl, C[12]) +
              C[13] * Math.pow(wl, C[14]) +
              C[15] * Math.pow(wl, C[16])
          );
        }
    }
  }

  if (material.parameters[index].formula == 10 && material.parameters[index].n_defined) {
    if (index === 0) {
      C = material.parameters[index].XC;
    } else {
      C = material.parameters[index].YC;
    }

    return Math.sqrt(
      C[0] +
        C[1] / (Math.pow(wl, 2) - C[2]) +
        (C[3] * Math.pow(wl, 2)) / (Math.pow(wl, 2) * C[4] - 1) +
        C[5] / (Math.pow(wl, 4) - C[6]) +
        (C[7] * Math.pow(wl, 2)) / (C[8] * Math.pow(wl, 4) - 1) +
        (C[9] * Math.pow(wl, 4)) / (C[10] * Math.pow(wl, 4) - 1)
    );
  }

  return "--undefined--";
}

export function RefractiveIndexMaterial(wl, material, index) {
  if (typeof material === "undefined") {
    return;
  }

  if (typeof index === "undefined" || material.NumberOfAxis === 0) {
    index = 0;
  }

  if (index !== 2) {
    if (wl > material.parameters[index].wl_n_range[1] || wl < material.parameters[index].wl_n_range[0]) {
      return;
    }

    var C;
    var Clength;
    var i;
    var tmp;

    // data array given - interpolate!
    if (material.parameters[index].formula === 0) {
      var n_wl = material.parameters[index].data_n_wl;
      var n = material.parameters[index].data_n;

      if (n_wl.length != n.length) {
        return;
      }

      var len = n.length;

      var j = 0;
      while (n_wl[j] < wl && j < len) {
        j += 1;
      }

      if (j === 0) {
        return n[0];
      }

      var x1 = n_wl[j - 1];
      var x2 = n_wl[j];
      var y1 = n[j - 1];
      var y2 = n[j];

      return ((y2 - y1) / (x2 - x1)) * (wl - x1) + y1;
    }

    if (material.parameters[index].formula < 10 && material.parameters[index].n_defined) {
      C = material.parameters[index].sellmeier_coeffs;
      Clength = C.length;
      for (i = 0; i < 17 - Clength; i += 1) {
        C.push(0.0);
      }

      switch (material.parameters[index].formula) {
        case 1:
          return Math.sqrt(
            1 +
              C[0] +
              C[1] / (1 - Math.pow(C[2] / wl, 2)) +
              C[3] / (1 - Math.pow(C[4] / wl, 2)) +
              C[5] / (1 - Math.pow(C[6] / wl, 2)) +
              C[7] / (1 - Math.pow(C[8] / wl, 2)) +
              C[9] / (1 - Math.pow(C[10] / wl, 2)) +
              C[11] / (1 - Math.pow(C[12] / wl, 2)) +
              C[13] / (1 - Math.pow(C[14] / wl, 2)) +
              C[15] / (1 - Math.pow(C[16] / wl, 2))
          );
        case 2:
          return Math.sqrt(
            1 +
              C[0] +
              C[1] / (1 - C[2] / Math.pow(wl, 2)) +
              C[3] / (1 - C[4] / Math.pow(wl, 2)) +
              C[5] / (1 - C[6] / Math.pow(wl, 2)) +
              C[7] / (1 - C[8] / Math.pow(wl, 2)) +
              C[9] / (1 - C[10] / Math.pow(wl, 2)) +
              C[11] / (1 - C[12] / Math.pow(wl, 2)) +
              C[13] / (1 - C[14] / Math.pow(wl, 2)) +
              C[15] / (1 - C[16] / Math.pow(wl, 2))
          );
        case 3:
          return Math.sqrt(
            C[0] +
              C[1] * Math.pow(wl, C[2]) +
              C[3] * Math.pow(wl, C[4]) +
              C[5] * Math.pow(wl, C[6]) +
              C[7] * Math.pow(wl, C[8]) +
              C[9] * Math.pow(wl, C[10]) +
              C[11] * Math.pow(wl, C[12]) +
              C[13] * Math.pow(wl, C[14]) +
              C[15] * Math.pow(wl, C[16])
          );
        case 4:
          return Math.sqrt(
            C[0] +
              (C[1] * Math.pow(wl, C[2])) / (Math.pow(wl, 2) - Math.pow(C[3], C[4])) +
              (C[5] * Math.pow(wl, C[6])) / (Math.pow(wl, 2) - Math.pow(C[7], C[8])) +
              C[9] * Math.pow(wl, C[10]) +
              C[11] * Math.pow(wl, C[12]) +
              C[13] * Math.pow(wl, C[14]) +
              C[15] * Math.pow(wl, C[16])
          );
        case 5:
          return (
            C[0] +
            C[1] * Math.pow(wl, C[2]) +
            C[3] * Math.pow(wl, C[4]) +
            C[5] * Math.pow(wl, C[6]) +
            C[7] * Math.pow(wl, C[8]) +
            C[9] * Math.pow(wl, C[10])
          );
        case 6:
          return (
            1 +
            C[0] +
            C[1] / (C[2] - Math.pow(wl, -2)) +
            C[3] / (C[4] - Math.pow(wl, -2)) +
            C[5] / (C[6] - Math.pow(wl, -2)) +
            C[7] / (C[8] - Math.pow(wl, -2)) +
            C[9] / (C[10] - Math.pow(wl, -2))
          );
        case 7:
          return (
            C[0] +
            C[1] / (Math.pow(wl, 2) - 0.028) +
            C[2] / Math.pow(Math.pow(wl, 2) - 0.028, 2) +
            C[3] * Math.pow(wl, 2) +
            C[4] * Math.pow(wl, 4) +
            C[5] * Math.pow(wl, 6)
          );
        case 8:
          tmp = C[0] + (C[1] * Math.pow(wl, 2)) / (Math.pow(wl, 2) - C[2]) + C[3] * Math.pow(wl, 2);
          return Math.sqrt((2 * tmp + 1) / (1 - tmp));
        case 9:
          return Math.sqrt(
            C[0] + C[1] / (Math.pow(wl, 2) - C[2]) + (C[3] * (wl - C[4])) / (Math.pow(wl - C[4], 2) + C[5])
          );
      }
    }
  }

  if (material.parameters[0].formula == 10 && material.parameters[0].n_defined) {
    switch (index) {
      case 0.0:
        C = material.parameters[0].XC;
        break;
      case 1.0:
        C = material.parameters[0].YC;
        break;
      case 2.0:
        C = material.parameters[0].ZC;
        break;
    }

    return Math.sqrt(
      C[0] +
        C[1] / (Math.pow(wl, 2) - C[2]) +
        (C[3] * Math.pow(wl, 2)) / (Math.pow(wl, 2) * C[4] - 1) +
        C[5] / (Math.pow(wl, 4) - C[6]) +
        (C[7] * Math.pow(wl, 2)) / (C[8] * Math.pow(wl, 4) - 1) +
        (C[9] * Math.pow(wl, 4)) / (C[10] * Math.pow(wl, 4) - 1)
    );
  }

  //TLPI

  return "--undefined--";
}

//wl in [um], result in [s^2/m]
export function GVDMaterial(wl, material, index) {
  //calculates group velocity dispersion of given material. index=0/1 for ordinary/extraordinary
  if (typeof index === "undefined") {
    index = 0;
  }
  if (typeof material === "undefined") {
    return;
  }

  var deltawl = 1.0e-5;

  if (wl + 2.0 * deltawl >= material.parameters[index].wl_n_range[1]) {
    wl -= 2.0 * deltawl;
  }

  var n0 = RefractiveIndex(wl + deltawl, index, material);
  var n1 = RefractiveIndex(wl, index, material);
  var n2 = RefractiveIndex(wl + 2.0 * deltawl, index, material);

  return (((Math.pow(wl, 3.0) / (2.0 * pi * c * c)) * (n2 - 2.0 * n0 + n1)) / Math.pow(deltawl, 2.0)) * 1.0e-6;
}

//wl in [um], result in [s^3/m]
export function TODMaterial(wl, material, index) {
  //var tod;
  //calculates third-order dispersion of given material. index=0/1 for ordinary/extraordinary
  if (typeof index === "undefined") {
    index = 0;
  }

  if (typeof material === "undefined") {
    return;
  }

  var delta_wl = 1.0e-2;

  if (wl + 1.5 * delta_wl >= material.parameters[index].wl_n_range[1]) {
    wl -= 1.5 * delta_wl;
  }

  if (wl - 1.5 * delta_wl <= material.parameters[index].wl_n_range[0]) {
    wl += 1.5 * delta_wl;
  }
  var d2P =
    (RefractiveIndex(wl + delta_wl, index, material) -
      2.0 * RefractiveIndex(wl, index, material) +
      RefractiveIndex(wl - delta_wl, index, material)) /
    Math.pow(delta_wl, 2.0);
  var d3P =
    (RefractiveIndex(wl + 1.5 * delta_wl, index, material) -
      3.0 * RefractiveIndex(wl + 0.5 * delta_wl, index, material) +
      3.0 * RefractiveIndex(wl - 0.5 * delta_wl, index, material) -
      RefractiveIndex(wl - 1.5 * delta_wl, index, material)) /
    Math.pow(delta_wl, 3.0);

  return ((-(3.0 * d2P + wl * d3P) * Math.pow(wl, 4)) / (4 * pi * pi * c * c * c)) * 1.0e-12; // in s^3/m;
}

export function GroupVelocityDispersion (wl, index, material)  {// returns GVD in s^2/m
  if (typeof index == 'undefined') {
      index = 0;
  }

  var deltawl = 1.0e-5;


  if (wl+2.0 * deltawl >= material.parameters[index].wl_n_range[1]) {
      wl -= 2.0 * deltawl;
  }

  var n0 = RefractiveIndex (wl+deltawl, index, material);
  var n1 = RefractiveIndex (wl, index, material);
  var n2 = RefractiveIndex (wl+2.0*deltawl, index, material);

  return ( Math.pow(wl, 3.0) / (2.0 * pi * c * c) * (n2 - 2.0 * n0 + n1) / Math.pow(deltawl, 2.0) * 1.0e-6);
}

//Nonlinear refractive index; if wl out of bounds, returns boundary value; interpolates otherwise
export function  NLRefractiveIndex (wl, index, material) {
  var yValues;
  var xValues;
  var j;
  var len;
  var x1;
  var x2;
  var y1;
  var y2;

  if (typeof index == 'undefined') {
      index = 0;
  }

  if ((typeof material === 'undefined') || (typeof material.parameters[index] === 'undefined')) {
      return;
  }

  if (index === 0) {
    yValues = material.n2_o;
  } else {
    yValues = material.n2_e;
  }

  if (typeof yValues === 'undefined') {
      return;
  }

  xValues = material.n2_wl;

  len = xValues.length;

  if (wl > xValues[len-1]) {
      return yValues[len-1];
  }
  if (wl < xValues[0]) {
      return yValues[0];
  }

  //interpolation
  j=0;
  while (( xValues[j] <= wl) && ( j < len )){
    if (xValues[j] === wl) return yValues[j];
      j+=1;
  }

  x1 = xValues[j-1]; x2 = xValues[j];
  y1 = yValues[j-1]; y2 = yValues[j];

  return (y2-y1)/(x2-x1)*(wl-x1) + y1;
}
