<template lang="">
  <div
    id="gaussianbeampropagation"
    ng-controller="beamCtrl"
    class="row"
  >
    <!-- TITLE -->
    <div class="col-lg-12 col-md-12 col-xs-12 settings">
      <h3>
        Gaussian beam propagation
        <small><span
          data-toggle="collapse"
          data-target="#info_gaussianbeampropagation"
          class="glyphicon glyphicon-info-sign info-glyph"
        /></small>
      </h3>
    </div>
    <!-- PLOT -->
    <div class="col-lg-6 col-md-6 col-xs-12 settings">
      <div id="pulsebroadening_div" />

      <div
        id="info_gaussianbeampropagation"
        class="collapse"
      >
        <img
          class="img-center"
          src="@/assets/static/media/svg/gaussianbeampropagation.svg"
        >
        <p>
          Propagation of Gaussian beam in air is characterized by the change of its radius <math-jax latex="w" /> (at
          <math-jax latex="1/\mathrm{e}^2" />) and curvature radius <math-jax latex="R" /> dependence on coordinate <math-jax latex="z" />:
          <math-jax
            latex="w(z) = w_0\sqrt{1+{z^2}/{z_\mathrm{R}^2}},"
            :block="true"
          />
          <math-jax
            latex="R(z) = z \left( 1 + {z^2}/{z_\mathrm{R}^2} \right)."
            :block="true"
          />
          <math-jax latex="w_0" /> is
          beam radius at waist ( <math-jax latex=" z=0" /> ), Rayleigh length <math-jax latex=" z_\mathrm{R} = \pi w_0^2/\lambda" />. Gouy phase shift
          <math-jax
            latex="\phi_\mathrm{G} = -\arctan(z/z_\mathrm{R})"
            :block="true"
          />is difference between phase of propagating Gaussian beam and
          plane wave of the same frequency.
        </p>
      </div>

      <div class="row bottom_align_row">
        <ul class="nav nav-tabs">
          <li
            class="active"
            @click="
              activePlot = 'diameter';
              plot('diameter');
            "
          >
            <a
              data-toggle="tab"
              href="#diameter"
            >Diameter</a>
          </li>
          <li
            @click="
              activePlot = 'curvature';
              plot('curvature');
            "
          >
            <a
              data-toggle="tab"
              href="#curvature"
            >Curvature</a>
          </li>
          <li
            @click="
              activePlot = 'phase';
              plot('phase');
            "
          >
            <a
              data-toggle="tab"
              href="#phase"
            >Gouy phase shift</a>
          </li>
        </ul>

        <div
          class="tab-content"
          style="width: 100%"
        >
          <div
            id="diameter"
            class="tab-pane fade in active"
          >
            <div id="gaussian_diameter_plot" />
          </div>
          <div
            id="curvature"
            class="tab-pane fade"
          >
            <div id="gaussian_curvature_plot" />
          </div>
          <div
            id="phase"
            class="tab-pane fade"
          >
            <div id="gaussian_phase_plot" />
          </div>
        </div>

        <!-- DISTANCE SCALE -->
        <div class="col-xs-12 col-lg-4">
          <h5 for="zRayleigh_text">
            Maximal distance
          </h5>
          <div class="input-group">
            <input
              v-model="zMax"
              type="number"
              min="0"
              max="100"
              class="form-control indicator"
            >
            <span class="input-group-addon">m</span>
          </div>
        </div>
      </div>
    </div>
    <!-- INPUT/OUTPUT -->
    <div class="col-lg-6 col-md-6 col-xs-12 settings">
      <div class="row bottom_align_row">
        <!-- DIVERGENCE ANGLE -->
        <div class="col-xs-6">
          <h5 for="theta_text">
            Divergence angle
            <span
              class="glyphicon glyphicon-info-sign info-glyph"
              data-toggle="modal"
              data-target="#description_divergence_angle"
            />
          </h5>
          <div class="input-group">
            <input
              id="theta_text"
              v-model="theta"
              type="number"
              class="form-control indicator"
              readonly
            >
            <span class="input-group-addon">mrad</span>
          </div>
        </div>
        <!-- RAYLEIGH DISTANCE -->
        <div class="col-xs-6">
          <h5 for="zRayleigh_text">
            Rayleigh length
            <span
              class="glyphicon glyphicon-info-sign info-glyph"
              data-toggle="modal"
              data-target="#description_Rayleigh_length"
            />
          </h5>
          <div class="input-group">
            <input
              id="zRayleigh_text"
              v-model="zRayleigh"
              type="number"
              class="form-control indicator"
              readonly
            >
            <span class="input-group-addon">mm</span>
          </div>
        </div>
      </div>
      <div class="row bottom_align_row">
        <!-- WAVELENGTH -->
        <div class="col-xs-6">
          <h5 for="wl_text">
            Wavelength
          </h5>
          <div class="input-group">
            <input
              id="wl_text"
              v-model="wl"
              min="50"
              step="1"
              type="number"
              class="form-control indicator"
              tabindex="1"
            >
            <span class="input-group-addon">nm</span>
          </div>
        </div>
        <!-- M2 -->
        <div class="col-xs-6">
          <h5 for="M2_text">
            Beam quality factor <math-jax latex="M^2" />
          </h5>
          <input
            id="M2_text"
            v-model="M2"
            min="0.1"
            step="0.1"
            type="number"
            class="form-control indicator"
            tabindex="2"
          >
        </div>
      </div>
      <div class="row">
        <!-- BPP BEAM PARAMETER PRODUCT -->
        <div class="col-xs-6">
          <h5 for="BPP_text">
            Beam parameter product (BPP)
            <span
              class="glyphicon glyphicon-info-sign info-glyph"
              data-toggle="modal"
              data-target="#description_BPP"
            />
          </h5>
          <div class="input-group">
            <input
              id="BPP_text"
              v-model="BPP"
              min="0"
              step="0.1"
              type="number"
              class="form-control indicator"
              tabindex="3"
            >
            <span class="input-group-addon">mm mrad</span>
          </div>
        </div>
        <!-- INITIAL BEAM WAIST -->
        <div class="col-xs-6">
          <h5 for="D_text">
            Beam diameter at waist <small>[mm]</small>
          </h5>
          <div class="input-group">
            <span class="input-group-addon">at e&#8315;&sup2;:</span>
            <input
              v-model="d0"
              min="0"
              step="1"
              type="number"
              class="form-control indicator"
              tabindex="4"
            >
          </div>
          <div class="input-group">
            <span class="input-group-addon">at FWHM:</span>
            <input
              v-model="d0_FWHM"
              min="0"
              step="1"
              type="number"
              class="form-control indicator"
              tabindex="5"
            >
          </div>
        </div>
      </div>
      <p>&nbsp;</p>
      <BeamFocusing
        :z-rayleigh="zRayleigh"
        :w0="w0"
      />
    </div>

    <DivergenceAngleDescriptionModal id="description_divergence_angle" />
    <RayleighLengthDescriptionModal id="description_Rayleigh_length" />
    <BeamParameterProductDescriptionModal id="description_BPP" />
  </div>
</template>
<script>
import DivergenceAngleDescriptionModal from "@/components/descriptions/beamProperties/DivergenceAngle.vue";
import RayleighLengthDescriptionModal from "@/components/descriptions/beamProperties/RayleighLength.vue";
import BeamParameterProductDescriptionModal from "@/components/descriptions/beamProperties/BeamParameterProduct.vue";
import BeamFocusing from "@/components/BeamFocusing.vue";
import { bc_to_FWHM, bc_FWHM_to } from "@/tools/BandwidthConverterFWHM";
import { fun_phase_template, fun_curvature_template, fun_diameter_template } from "@/tools/Utils.js";
import { drawPlotD3 } from "@/tools/Plot.js";

export default {
  components: {
    BeamFocusing,
    DivergenceAngleDescriptionModal,
    RayleighLengthDescriptionModal,
    BeamParameterProductDescriptionModal,
  },
  data() {
    return {
      wl: 1030.0,
      M2: 1,
      zMax: 10.0,
      d0: 5.0,
      activePlot: "diameter",
    };
  },
  computed: {
    d0_FWHM: {
      get: function () {
        return Math.round10(bc_to_FWHM(this.d0, "e-2"), -3);
      },
      set: function (newValue) {
        this.d0 = bc_FWHM_to(newValue, "e-2").toFixed(3);
        this.w0 = this.d0 / 2.0;
      },
    },
    w0() {
      return this.d0 / 2.0;
    },
    BPP: {
      get: function () {
        return Math.round10(((this.M2 * this.wl) / this.$store.state.pi) * 1.0e-3, -3);
      },
      set: function (newValue) {
        this.M2 = ((newValue / this.wl) * this.$store.state.pi * 1.0e3).toFixed(3);
      },
    },
    theta() {
      return ((2.0 * this.BPP) / this.w0).toFixed(3);
    },
    zRayleigh() {
      return (((this.$store.state.pi * Math.pow(this.w0, 2)) / this.wl / this.M2) * 1.0e6).toFixed(3);
    },
  },
  mounted() {
    this.plot("diameter");
    this.plot("curvature");
    this.plot("phase");
  },
  created() {
    this.$watch("wl", () => {
      this.plot(this.activePlot);
    });
    this.$watch("zMax", (newVal) => {
      if(newVal!=0)
      this.plot(this.activePlot);
    });
    this.$watch("BPP", () => {
      this.plot(this.activePlot);
    });
    this.$watch("d0", () => {
      this.plot(this.activePlot);
    });
  },
  methods: {
    plot(activePlot) {
      var z = [];
      var z_positive = [];
      var x_axis = { title: "Distance", units: "m" };
      var z_min = -this.zMax;
      var z_max = this.zMax;
      var z_step = 1.0;
      var z_num = (z_max - z_min) / z_step + 1;

      if (z_num < 50) {
        z_num = 50;
        z_step = (z_max - z_min) / (z_num - 1);
      }

      for (var z_val = z_min; z_val <= z_max; z_val += z_step) {
        z.push(z_val);
      }

      for (z_val = (this.zRayleigh / 10.0) * 1.0e-3; z_val <= z_max; z_val += z_step / 5.0) {
        z_positive.push(z_val);
      }

      if (z_positive.length === 0) {
        z_positive = [(this.zRayleigh / 10.0) * 1.0e-3];
      }

      var fun_phase = fun_phase_template(this.zRayleigh * 1.0e-3);
      var fun_curvature = fun_curvature_template(this.zRayleigh * 1.0e-3);
      var fun_diameter = fun_diameter_template(this.w0 * 1.0e-3, this.wl * 1.0e-9, this.M2);

      setTimeout(function () {
        switch (activePlot) {
          case "diameter":
            drawPlotD3(
              z,
              [{ title: "Beam diameter, mm", fun: fun_diameter }],
              "gaussian_diameter_plot",
              x_axis,
              { title: "Beam diameter, mm" },
              { explorer: { axis: "horizontal" }, vAxis: { format: "#.##" } }
            );
            break;
          case "curvature":
            drawPlotD3(
              z_positive,
              [{ title: "Radius curvature, m", fun: fun_curvature }],
              "gaussian_curvature_plot",
              x_axis,
              { title: "Radius curvature, m" },
              { explorer: { axis: "horizontal" }, vAxis: { format: "#" } }
            );

            break;
          case "phase":
            drawPlotD3(
              z,
              [{ title: "Gouy phase shift, deg", fun: fun_phase }],
              "gaussian_phase_plot",
              x_axis,
              { title: "Gouy phase shift, deg" },
              { explorer: { axis: "horizontal" }, vAxis: { format: "#" } }
            );
            break;
          default:
            break;
        }
      }, 200);
    },
  },
};
</script>
