<template>
  <div
    v-if="$store.state.dataReady"
    id="dispersionparameters"
    class="row"
    ng-controller="materialsdbCtrl"
  >
    <!-- TITLE -->
    <div class="col-lg-12 col-md-12 col-xs-12">
      <h3>Material dispersion</h3>
    </div>
    <!-- OUTPUT -->
    <div class="col-lg-6 col-md-6 col-xs-12">
      <!-- OUTPUT ( ORDINARY )-->
      <RayOutput
        v-if="material"
        icon="glyphicon glyphicon-resize-horizontal"
        Title="Ordinary"
        :material="material"
        :wl="slider.value"
      />

      <!-- OUTPUT ( EXTRAORDINARY )-->
      <RayOutput
        v-if="isMaterialIsotropic"
        icon="glyphicon glyphicon-resize-vertical"
        Title="Extraordinary"
        :material="material"
        :wl="slider.value"
        :is-material-biaxial="isMaterialIsotropic"
      />

      <!-- PLOT -->
      <div class="">
        <div id="material_GVD_plot" />
      </div>
      <h5>
        Wavelength range: <span>{{ rangeSlider.value[0] }}</span>-<span>{{ rangeSlider.value[1] }}</span> nm
      </h5>
      <div class="top-padding">
        <Slider
          v-model="rangeSlider.value"
          v-bind="rangeSlider"
          @update="onRangeSliderChange()"
        />
      </div>
    </div>

    <!-- INPUT -->
    <div class="col-lg-6 col-md-6 col-xs-12 settings">
      <!-- MATERIAL SELECT -->
      <Selector
        :list="materials"
        :list-name="'Materials'"
        @selectedMaterial="onSelection"
      />

      <br>
      <!-- WAVELENGTH SELECT -->
      <h5>
        Wavelength range:
        <span> {{ wlrangeMin }} </span>-<span> {{ wlrangeMax }}</span>
        nm
      </h5>
      <div class="row">
        <div class="col-md-8 col-xs-7 top-padding">
          <Slider
            v-model="slider.value"
            v-bind="slider"
            @update="onSliderChange()"
          />
        </div>
        <div class="col-md-4 col-xs-5">
          <div class="input-group">
            <input
              id="wltext"
              v-model="inputValue"
              step="1"
              type="number"
              class="form-control indicator"
              tabindex="1"
              @change="onInputChange()"
            >
            <span class="input-group-addon">nm</span>
          </div>
        </div>
      </div>
      <br>
    </div>
  </div>
</template>

<script>
import Slider from "@vueform/slider";
import DataService from "@/services/data.service";
import RayOutput from "@/components/RayOutput.vue";
import Selector from "@/components/Selector.vue";
import { GVDMaterial, TODMaterial } from "@/tools/MaterialDispersion";
import { drawPlotD3 } from "@/tools/Plot.js";

export default {
  name: "HomeView",
  components: {
    Slider,
    RayOutput,
    Selector,
  },
  data() {
    return {
      rangeSlider: {
        value: [0, 5000],
        min: 100,
        max: 1773,
        lazy: false,
        showTooltip: "drag",
        step: -1,
      },
      material: null,
      inputValue: 1030,
      slider: {
        value: 1030,
        max: 2000,
        lazy: false,
        showTooltip: "drag",
        step: -1,
      },
      wlrangeMin: null,
      wlrangeMax: null,
    };
  },
  computed: {
    materials() {
      return this.$store.getters.materials;
    },
    isMaterialIsotropic() {
      if (this.material != null && this.material.NumberOfAxis > 0) return true;
      return false;
    },
  },
  watch: {
    material(newValue) {
      this.wlrangeMin = parseFloat(newValue.parameters[0].wl_n_range[0]) * 1.0e3;
      this.slider.min = this.wlrangeMin;
      this.rangeSlider.min = this.wlrangeMin;
      if(this.wlrangeMin > this.rangeSlider.value[0]) this.rangeSlider.value[0] = this.wlrangeMin;
      this.wlrangeMax = parseFloat(newValue.parameters[0].wl_n_range[1]) * 1.0e3;
      this.slider.max = this.wlrangeMax;
      this.rangeSlider.max = this.wlrangeMax;
      if(this.wlrangeMax < this.rangeSlider.value[1]) this.rangeSlider.value[1] = this.wlrangeMax;
      if (this.slider.value > this.wlrangeMaxDef) this.inputValue = this.wlrangeMaxDef;
      if (this.slider.value < this.wlrangeMinDef) this.inputValue = this.wlrangeMinDef;

      this.drawPlot(this.material);
    },
  },
  methods: {
    onRangeSliderChange() {
      this.drawPlot(this.material);
    },
    onInputChange() {
      this.slider.value = this.inputValue;
    },
    onSliderChange() {
      this.inputValue = this.slider.value;
    },
    onSelection(material) {
      this.$store.commit("SET_Loading", true);
      DataService.getMaterialData(material).then((response) => {
        this.material = response.data.value;
      });
    },
    drawPlot(material) {
      var fun_GDD = function (wl) {
        return GVDMaterial(wl / 1.0e3, material, 0) * 1.0e27;
      };
      var fun_TOD = function (wl) {
        return TODMaterial(wl / 1.0e3, material, 0) * 1.0e42;
      };

      var x = [];

      // var range = this.wlrangeMax - this.wlrangeMin;
      // var points = 3;
      // if (range > 2000 && range <= 500) points = 10;
      // if (range > 5000) points = 100;
      // for (var j = this.wlrangeMin; j <= this.wlrangeMax; j += points) {
      //   x.push(j);
      // }


      for (var j = this.rangeSlider.value[0]; j <= this.rangeSlider.value[1]; j += 10) {
        x.push(j);
      }




      var y = [
        { title: "GVD (o)", fun: fun_GDD },
        { title: "TOD (o)", fun: fun_TOD },
      ];
      var options = { series: [{ color: "#3366cc" }, { color: "#dc3912" }] };

      var x_axis = { title: "Wavelength", units: "nm" };
      var y_axis = { title: "GVD (fs\u00B2/mm), TOD (fs\u00B3/mm)" };
      var target = "material_GVD_plot";

      if (this.isMaterialIsotropic) {
        //plotting
        var fun_GDD_e = function (wl) {
          return GVDMaterial(wl / 1.0e3, material, 1) * 1.0e27;
        };
        var fun_TOD_e = function (wl) {
          return TODMaterial(wl / 1.0e3, material, 1) * 1.0e42;
        };

        y.push({ title: "GVD (e)", fun: fun_GDD_e }, { title: "TOD (e)", fun: fun_TOD_e });
        options.series.push({ color: "#3366cc", lineDashStyle: [10, 2] });
        options.series.push({ color: "#dc3912", lineDashStyle: [10, 2] });
      }
      drawPlotD3(x, y, target, x_axis, y_axis, options);
    },
  },
};
</script>

<style src="@vueform/slider/themes/default.css"></style>
