<template lang="">
  <div>
    <Selector
      v-if="$store.state.dataReady"
      :list="materials"
      :list-name="'Materials'"
      @selectedMaterial="onSelection"
    />
    <div class="container">
      <div class="row">
        <div class="col-sm-1">
          <button
            class="btn btn-md btn-primary"
            @click="onSave"
          >
            Save
          </button>
        </div>
        <div class="col-sm-1">
          <button
            class="btn btn-md btn-primary"
            @click="onSaveAs"
          >
            Save As
          </button>
        </div>
        <div class="col-sm-3">
          <input
            id="saveAsName"
            v-model="saveAsName" 
            class="form-control"
            type="text"
            placeholder="Type new name here"
          >  
        </div>
        <div class="col-sm-2">
          <button
            class="btn btn-md btn-danger"
            @click="onDelete"
          >
            Delete
          </button>
        </div>
      </div>
    </div>

    <div class="my-json-editor"> 
      <JsonEditorVue
        v-if="material"
        v-model="material.value"
        class="top-padding20"
      />
    </div>
  </div>
</template>
<script>
import JsonEditorVue from "json-editor-vue";
import Selector from "@/components/Selector.vue";
import DataService from "@/services/data.service";

export default {
  components: {
    JsonEditorVue,
    Selector,
  },
  data() {
    return {
      material: null,
      saveAsName: null,
      hasError: false
    };
  },
  computed: {
    materials() {
      return this.$store.state.groupedMaterials;
    },
    inputStyle(){
      return this.hasError ? { borderColor: "red" } : {};
    }
  },
  methods: {
    onSelection(material) {
      DataService.getMaterialData(material).then((response) => {
        this.material = response.data;
      });
    },
    onSave() {
      if(typeof this.material.value=="string")
        {this.material.value = JSON.parse(this.material.value);}
      console.log(`Saving material ${this.material.key}`)
      DataService.updateMaterial(this.material).then((response) => {
        if (response.status === 204) {
          this.$swal.fire({
            position: "bottom-end",
            icon: "success",
            title: "Saved",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          this.$swal.fire({
            position: "bottom-end",
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        }
      });
    },
    onSaveAs(){
      if(this.saveAsName == null){
        this.$swal.fire({
            position: "bottom-end",
            icon: "error",
            title: "Name cannot be empty",
            text: "",
          });
        return;
        }
      if(typeof this.material.value=="string")
        {this.material.value = JSON.parse(this.material.value);}
      
      console.log(`Saving material as ${this.material.key}`)
        
      DataService.saveAsMaterial(this.saveAsName,this.material).then((response) => {
        if (response.status === 201) {
          this.$swal.fire({
            position: "bottom-end",
            icon: "success",
            title: "Saved as '" + this.saveAsName+"'",
            showConfirmButton: false,
            timer: 2500,
          });
        }else if (response.status === 409) {
          this.$swal.fire({
            position: "bottom-end",
            icon: "error",
            title: "Oops... this material already exist",
            text: "Something went wrong!",
          });
        } else {
          let errorsString = JSON.stringify(response.data.errors, null, 2);

          this.$swal.fire({
            position: "bottom-end",
            icon: "error",
            title: "Oops...",
            text: errorsString,
          });
        }
      });      
    },
    async onDelete(){
      const confirmed = await this.$swal.fire({
            position: "bottom-end",
            icon: "warning",
            title: "Realy want delete "+this.material.key+"?",
            text: "This will work irreversibly!",
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel'
          });
      if (confirmed.isConfirmed==false) return;
      
      console.log(`Deleting material ${this.material.key}`)
      
      DataService.deleteMaterial(this.material.key).then((response) => {
        if (response.status === 200) {
          this.$swal.fire({
            position: "bottom-end",
            icon: "success",
            title: "Deleted '" + this.material.key+"'",
            text: "Last chance to save this view - press the 'Save As' button now, otherwise it will disappear irreversibly...",
            showConfirmButton: true,
          });
        }else if (response.status === 404) {
          this.$swal.fire({
            position: "bottom-end",
            icon: "error",
            title: "Oops... "+this.material.key+" material not found",
            text: "Something went wrong!",
          });
        } else {
          this.$swal.fire({
            position: "bottom-end",
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        }
      });
    }
  },
};
</script>
<style scoped> 
  .my-json-editor {
    /* define a custom theme color */
    --jse-theme-color: #000E44;
    --jse-theme-color-highlight: #DC1084;
    --jse-button-color: #DC1084;
  }
  .container {
    padding-top:10px;
    padding-bottom: 0px;
  }
  .form-control .input-md {
    display: inline-flex;
    align-items: center;
}
</style>