<template lang="">
  <div
    :id="id"
    class="modal"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
          >
            &times;
          </button>
          <h4 class="modal-title">
            Third-order dispersion of prism pair
          </h4>
        </div>
        <div class="modal-body">
          <p>
            TOD of a prism pair compressor/stretcher is equal to: 
            <math-jax
              latex="\mathrm{TOD} = \frac{\mathrm{d}^3\phi(\omega)}{\mathrm{d}\omega^3} = -\frac{\lambda^4}{4\pi^2
            c^3}\left[3\frac{\mathrm{d}^2P}{\mathrm{d}\lambda^2}+\lambda\frac{\mathrm{d}^3P}{\mathrm{d}\lambda^3}\right],"
              :block="true"
            />
            where the optical path is 
            <math-jax
              latex="P = 2\left[n(\lambda)P_{AB} + P_{BC} + n(\lambda)P_{CD} + P_{DE}\right]."
              :block="true"
            />
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-default"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: null,
  },
};
</script>