<template lang="">
  <div
    :id="id"
    class="modal"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
          >
            &times;
          </button>
          <h4 class="modal-title">
            Peak intensity of Gaussian pulse
          </h4>
        </div>
        <div class="modal-body">
          <p>
            Maximal pulse intensity (at beam center). For temporally Gaussian pulse, peak intensity is related to peak
            fluence as 
            <math-jax
              latex="I_0 =\frac{2F_{0}}{\Delta t}\sqrt{\frac{\ln2}{\pi}}\approx\frac{0.94F_0}{\Delta t}."
              :block="true"
            />
            Here <math-jax latex="\Delta t" /> is pulse length (FWHM).
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-default"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: null,
  },
};
</script>
