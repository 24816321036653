<template lang="">
  <div
    id="gratingangles"
    class="row"
  >
    <!-- TITLE -->
    <div class="col-lg-12 col-md-12 col-xs-12">
      <h3>
        Grating calculator
        <small><span
          data-toggle="collapse"
          data-target="#info_gratingcalculator"
          class="glyphicon glyphicon-info-sign info-glyph"
        /></small>
      </h3>
    </div>

    <!-- OUTPUT -->
    <div class="col-lg-6 col-md-6 col-xs-12">
      <div
        id="info_gratingcalculator"
        class="collapse"
      >
        <p>
          Calculate angle of incidence at Littrow configuration of the highest diffraction order possible, when the
          upper limit of angle of incidence is given.
        </p>
      </div>

      <div class="row">
        <div class="col-lg-6 col-xs-6">
          <!-- MAX ORDER  -->
          <h5 for="m_text">
            Max. diffraction order
          </h5>
          <input
            id="m_text"
            v-model="m"
            type="number"
            class="form-control indicator"
            readonly
          >

          <!-- LITTROW ANGLE  -->
          <h5 for="thetaL_text">
            Littrow angle
          </h5>
          <div class="input-group">
            <input
              id="thetaL_text"
              v-model="thetaL"
              type="number"
              class="form-control indicator"
              readonly
            >
            <span class="input-group-addon">deg</span>
          </div>
        </div>

        <div class="col-xs-6">
          <h5>All orders <small>[deg]</small></h5>
          <div class="form-group">
            <textarea
              v-model="textarea_text"
              class="form-control"
              rows="5"
              readonly
            />
          </div>
        </div>
      </div>
    </div>

    <!-- INPUT -->
    <div class="col-lg-6 col-md-6 col-xs-12 settings">
      <div class="row">
        <!-- AOI SELECT -->
        <div class="col-lg-6 col-xs-6">
          <h5 for="theta0_text">
            Max. AOI
          </h5>
          <div class="input-group">
            <input
              id="theta0_text"
              v-model="theta0"
              min="0"
              max="90"
              step="1"
              type="number"
              class="form-control indicator"
              tabindex="1"
            >
            <span class="input-group-addon">deg</span>
          </div>
        </div>

        <!-- GRATING SELECT -->
        <div class="col-lg-6 col-xs-6">
          <h5 for="gpmm_text">
            Grating frequency <math-jax latex="d^{-1}" /><span />
          </h5>
          <div class="input-group">
            <input
              id="gpmm_text"
              v-model="gpmm"
              min="300"
              max="9999"
              step="1"
              type="number"
              class="form-control indicator"
              tabindex="2"
            >
            <span class="input-group-addon">/mm</span>
          </div>
        </div>
      </div>
      <div class="row">
        <!-- WAVELENGTH SELECT -->
        <div class="col-lg-6 col-xs-6">
          <h5 for="wl_text">
            Wavelength <math-jax latex="\lambda" />
          </h5>
          <div class="input-group">
            <input
              id="wl_text"
              v-model="wl"
              min="50"
              max="99999"
              step="1"
              type="number"
              class="form-control indicator"
              tabindex="3"
            >
            <span class="input-group-addon">nm</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { deg_to_rad, rad_to_deg } from "@/tools/Utils.js";

export default {
  data() {
    return {
      theta0: 25,
      gpmm: 800,
      wl: 1030,
    };
  },
  computed: {
    m() {
      if(!this.gpmm || !this.wl)
      return 0
      return Math.ceil((-2.0 / this.gpmm / this.wl) * Math.sin(deg_to_rad(this.theta0)) * 1.0e6);
    },
    thetaL() {
      return -Math.round10(rad_to_deg(Math.asin(((this.m * this.wl * this.gpmm) / 2.0) * 1.0e-6)), -3);
    },
    textarea_text() {
      var textToReturn;
      var m = this.m;

      if (m < 0) {
        textToReturn = "m\tAOI\n";
        while (m < 0) {
          var thetaL = -Math.asin(((m * this.wl * this.gpmm) / 2.0) * 1.0e-6);
          if (thetaL <= 0 || thetaL > this.theta0) {
            break;
          } else {
            textToReturn += m + "\t" + Math.round10(rad_to_deg(thetaL), -3) + "\n";
            m += 1;
          }
        }
      } else {
        textToReturn = "";
      }
      return textToReturn;
    },
  },
};
</script>
<style lang=""></style>
