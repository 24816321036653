<template lang="">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
        >
          &times;
        </button>
        <h4 class="modal-title">
          Group refractive index
        </h4>
      </div>
      <div class="modal-body">
        <p>
          <math-jax
            latex="n_\mathrm{g} = \frac{c}{v_\mathrm{g}} = n(\lambda) - \lambda
          \frac{\partial n(\lambda)}{\partial \lambda}"
            :block="true"
          />
        </p>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-default"
          data-dismiss="modal"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang=""></style>
