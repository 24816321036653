<template lang="">
  <div
    v-if="$store.state.dataReady"
    id="PMangles"
    class="row"
  >
    <!-- TITLE -->
    <div class="col-lg-12 col-md-12 col-xs-12 settings">
      <h3>
        Nonlinear optical interactions
        <small><span
          data-toggle="collapse"
          data-target="#info_PMangles"
          class="glyphicon glyphicon-info-sign info-glyph"
        /></small>
      </h3>
    </div>
    <!-- OUTPUT -->
    <div class="col-lg-8 col-xs-12">
      <div class="row">
        <div
          id="info_PMangles"
          class="col-lg-12 col-md-12 col-xs-12 collapse"
        >
          Fill in appropriate wavelengths in columns to obtain phase matching angles, angular walk-offs, group velocity
          mismatches (GVM) of the corresponding collinear SFG/DFG/SHG/OPO process, satisfying relation
          <math-jax
            latex="\frac{1}{\lambda_1}+\frac{1}{\lambda_2}=\frac{1}{\lambda_3}."
            :block="true"
          />
          Values of <math-jax latex="d_\mathrm{eff}" /> are maximal and given for the indicated azimuthal angle
          <math-jax latex="\phi" />, with walk-off included.
          <!-- <img width="100%" src="{% static 'media/beamdisplacementsandwich.svg' %}" /> -->
        </div>

        <div class="col-lg-2 col-xs-3">
          <div :class="`${error ? 'input-group vertical has-error' : 'input-group vertical'}`">
            <select
              id="lambda_field1"
              v-model="lambda_field1"
              class="selectpicker_lambda form-control"
            >
              <option
                name="lambda_field1"
                value="lambda1"
                selected
              >
                &lambda;&#8321;
              </option>
              <option
                name="lambda_field1"
                value="lambda2"
              >
                &lambda;&#8322;
              </option>
              <option
                name="lambda_field1"
                value="lambda3"
              >
                &lambda;&#8323;
              </option>
            </select>
            <textarea
              id="textarea1"
              v-model="wl_field1"
              class="form-control"
              rows="7"
              style="resize: none"
            />
          </div>
        </div>
        <div class="col-lg-2 col-xs-3">
          <div :class="`${error ? 'input-group vertical has-error' : 'input-group vertical'}`">
            <select
              id="lambda_field2"
              v-model="lambda_field2"
              class="selectpicker_lambda form-control"
            >
              <option
                name="lambda_field2"
                value="lambda1"
              >
                &lambda;&#8321;
              </option>
              <option
                name="lambda_field2"
                value="lambda2"
                selected
              >
                &lambda;&#8322;
              </option>
              <option
                name="lambda_field2"
                value="lambda3"
              >
                &lambda;&#8323;
              </option>
            </select>
            <textarea
              id="textarea2"
              v-model="wl_field2"
              class="form-control"
              rows="7"
              style="resize: none"
            />
          </div>
        </div>
        <div class="col-lg-8 col-xs-6">
          <div class="input-group vertical">
            <textarea
              id="textarea3"
              v-model="wl_field3"
              class="form-control"
              rows="9"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- INPUT -->
    <div class="col-lg-4 col-xs-12">
      <!-- MATERIAL SELECT -->
      <Selector
        :list="materials"
        :list-name="'Materials'"
        :default-selection="'BaB2O4'"
        @selectedMaterial="onSelection"
      />

      <div class="row padding-top-sm">
        <div class="col-xs-6 no-right-padding">
          <button
            id="PMCalculate"
            class="btn btn-default"
            style="width: 100%"
            @click="UpdatePMAngles('PMangles')"
          >
            Phase matching angles
          </button>
        </div>
        <div class="col-xs-6">
          <button
            id="d_effCalculate"
            class="btn btn-default"
            style="width: 100%"
            @click="UpdatePMAngles('d_eff')"
          >
            <i>d</i><sub>eff</sub>
          </button>
        </div>
        <div class="col-xs-6 no-right-padding">
          <button
            id="GVMCalculate"
            class="btn btn-default"
            style="width: 100%"
            @click="UpdatePMAngles('GVM')"
          >
            GVM
          </button>
        </div>
        <div class="col-xs-6">
          <button
            id="walkoffCalculate"
            class="btn btn-default"
            style="width: 100%"
            @click="UpdatePMAngles('walkoff')"
          >
            Walkoff
          </button>
        </div>
        <div class="col-xs-12">
          <hr>
          <br>
        </div>
        <div class="col-xs-6 no-right-padding">
          <div class="input-group">
            <span class="input-group-addon">X axis</span>
            <select
              id="x_axis"
              v-model="x_axis"
              class="selectpicker_lambda_plot form-control"
            >
              <option
                name="x_axis"
                value="lambda1"
              >
                &lambda;&#8321;
              </option>
              <option
                name="x_axis"
                value="lambda2"
              >
                &lambda;&#8322;
              </option>
              <option
                name="x_axis"
                value="lambda3"
              >
                &lambda;&#8323;
              </option>
            </select>
          </div>
        </div>
        <div class="col-xs-6">
          <button
            id="walkoffCalculate"
            class="btn btn-default in"
            style="width: 100%"
            @click="UpdatePMAngles('plot')"
          >
            Plot
          </button>
        </div>
      </div>
    </div>
    <div class="col-xs-12">
      <div class="row">
        <div class="col-lg-6 col-xs-12">
          <div id="plot1" />
        </div>
        <div class="col-lg-6 col-xs-12">
          <div id="plot2" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Selector from "@/components/Selector.vue";
import { d_eff_phi, make_n_function, rad_to_deg, fsolve, d_eff, pi, deg_to_rad } from "@/tools/Utils.js";
import DataService from "@/services/data.service";
import { drawPlotD3 } from "@/tools/Plot";

export default {
  components: {
    Selector,
  },
  data() {
    return {
      material: null,
      x_axis: "lambda1",
      lambda_field1: "lambda1",
      lambda_field2: "lambda2",
      wl_field1: "1030",
      wl_field2: "1030",
      wl_field3: "",
      error: false,
    };
  },
  computed: {
    materials(){
      return this.$store.getters.materials;
    },
    wl_field1Values() {
      var valuesForReturn = this.wl_field1.replace(",", ".").split("\n");

      for (var i = 0; i < valuesForReturn.length; i++) {
        valuesForReturn[i] = parseFloat(valuesForReturn[i]);
        valuesForReturn[i] = parseFloat(valuesForReturn[i]);
      }
      return valuesForReturn;
    },
    wl_field2Values() {
      var valuesForReturn = this.wl_field2.replace(",", ".").split("\n");

      for (var i = 0; i < valuesForReturn.length; i++) {
        valuesForReturn[i] = parseFloat(valuesForReturn[i]);
        valuesForReturn[i] = parseFloat(valuesForReturn[i]);
      }
      return valuesForReturn;
    },
  },
  watch: {
    wl_field1() {
      if (this.error) this.wl_field3 = "";
      this.error = false;
    },
    wl_field2() {
      if (this.error) this.wl_field3 = "";
      this.error = false;
    },
    lambda_field1() {
      this.validateInput();
    },
    lambda_field2() {
      this.validateInput();
    },
  },
  methods: {
    onSelection(material) {
      DataService.getMaterialData(material).then((response) => {
        this.material = response.data.value;
      });
    },
    validateInput() {
      if (this.wl_field1Values.length != this.wl_field2Values.length) {
        this.error = true;
        this.wl_field3 = "ERROR: Data input sizes differ";
      } else if (this.lambda_field1 === this.lambda_field2) {
        this.error = true;
        this.wl_field3 = "ERROR: Same wavelength specifiers";
      } else {
        if (this.error) this.wl_field3 = "";
        this.error = false;
      }
    },
    UpdatePMAngles(caller) {
      var plot = false;
      var wl_field3 = [];
      var i;
      var j;
      var wl;
      var wl1 = [];
      var wl2 = [];
      var wl3 = [];
      var wl_label;
      var wl_index;
      var interaction_types;
      var interaction_type;
      var internal_angle = 0.0;

      var theta_PM = [0.0, 0.0, 0.0];
      var d_eff_max = ["-", "-", "-"];
      var GVM = [];
      var walkoff = [];

      var theta_PM_all = [[], [], []];
      var d_eff_max_all = [[], [], []];

      var walkoff_output;
      var GV1;
      var GV2;
      var GV3;

      var n31;
      var n32;
      var n21;
      var n22;
      var n11;
      var n12;

      var deltawl = 1.0e-6;
      var delta_theta = 1.0e-6;
      var phi = [0.0, 0.0, 0.0];
      var n = [0, 0, 0];
      var fun;
      var temp;
      var subscript_indices = ["\u2081", "\u2082", "\u2083"];

      var x;
      var y;
      var x_title;
      var y_title;
      var options;

      this.wl_field3 = "";

      switch (this.lambda_field1) {
        case "lambda1":
          wl1 = this.wl_field1Values;
          break;
        case "lambda2":
          wl2 = this.wl_field1Values;
          break;
        case "lambda3":
          wl3 = this.wl_field1Values;
          break;
      }

      switch (this.lambda_field2) {
        case "lambda1":
          wl1 = this.wl_field2Values;
          break;
        case "lambda2":
          wl2 = this.wl_field2Values;
          break;
        case "lambda3":
          wl3 = this.wl_field2Values;
          break;
      }

      if (wl1.length === 0) {
        wl_label = "\u03BB\u2081";
        wl_index = 0;
        wl_field3 = wl1;
        for (i = 0; i < this.wl_field1Values.length; i += 1) {
          wl1[i] = (wl2[i] * wl3[i]) / (wl2[i] - wl3[i]);
        }
      }

      if (wl2.length === 0) {
        wl_label = "\u03BB\u2082";
        wl_index = 1;
        wl_field3 = wl2;
        for (i = 0; i < this.wl_field1Values.length; i += 1) {
          wl2[i] = (wl1[i] * wl3[i]) / (wl1[i] - wl3[i]);
        }
      }

      if (wl3.length === 0) {
        wl_label = "\u03BB\u2083";
        wl_index = 2;
        wl_field3 = wl3;
        for (i = 0; i < this.wl_field1Values.length; i += 1) {
          wl3[i] = (wl1[i] * wl2[i]) / (wl1[i] + wl2[i]);
        }
      }

      //calculation
      if (this.material.birefrigerence_type == "negative") {
        interaction_types = ["ooe", "eoe", "oee"];
      } else {
        interaction_types = ["eeo", "oeo", "eoo"];
      }

      phi = [
        d_eff_phi(interaction_types[0], this.material),
        d_eff_phi(interaction_types[1], this.material),
        d_eff_phi(interaction_types[2], this.material),
      ];

      if (caller === "plot") {
        caller = "PMangles";
        plot = true;
      }

      switch (caller) {
        case "PMangles":
          this.wl_field3 = "Phase matching angles [deg]:\n";
          this.wl_field3 += "\u03D5" + "\t" + phi.join("\t") + "\n";
          this.wl_field3 += wl_label + "\t" + interaction_types.join("\t");

          break;
        case "d_eff":
          this.wl_field3 = "d_eff values:\n";
          this.wl_field3 += "\u03D5" + "\t" + phi.join("\t") + "\n";
          this.wl_field3 += wl_label + "\t" + interaction_types.join("\t");
          break;
        case "GVM":
          this.wl_field3 = "Group velocity mismatches [fs/mm]:\n";
          temp = [];
          for (i = 0; i < 3; i += 1) {
            temp.push("u" + subscript_indices[0] + subscript_indices[2] + "(" + interaction_types[i] + ")");
            temp.push("u" + subscript_indices[1] + subscript_indices[2] + "(" + interaction_types[i] + ")");
          }
          this.wl_field3 += wl_label + "\t" + temp.join("\t");
          break;
        case "walkoff":
          this.wl_field3 = "Angular walk-offs [deg]:\n";
          temp = [];
          for (i = 0; i < 3; i += 1) {
            for (j = 1; j <= 3; j += 1) {
              temp.push("\u03C1" + subscript_indices[j - 1] + "(" + interaction_types[i] + ")");
            }
          }
          this.wl_field3 += wl_label + "\t" + temp.join("\t");
          break;
      }

      this.wl_field3 += "\n";

      for (var k = 0; k < this.wl_field1Values.length; k += 1) {
        wl = [wl1[k], wl2[k], wl3[k]];
        theta_PM = [];
        d_eff_max = [];
        GVM = [];
        walkoff = [];

        if (isFinite(wl[0]) && isFinite(wl[1]) && isFinite(wl[2])) {
          for (i = 0; i < 3; i += 1) {
            interaction_type = interaction_types[i];

            for (j = 0; j < 3; j += 1) {
              n[j] = make_n_function(wl[j] / 1.0e3, interaction_type.charAt(j), this.material);
            }

            fun = function (theta) {
              return n[2](theta) / wl[2] - Math.cos(internal_angle) * (n[1](theta) / wl[1] + n[0](theta) / wl[0]);
            };

            this.loading = true;
            theta_PM[i] = fsolve(fun, pi / 4.0);

            // if (theta_PM[i] === pi/4.0) {
            //     theta_PM[i] = 'undefined';
            // }

            if (isFinite(theta_PM[i])) {
              // GVM
              n31 = make_n_function(wl[2] / 1.0e3, interaction_type.charAt(2), this.material)(theta_PM[i]);
              n32 = make_n_function(wl[2] / 1.0e3 + deltawl, interaction_type.charAt(2), this.material)(theta_PM[i]);

              n21 = make_n_function(wl[1] / 1.0e3, interaction_type.charAt(1), this.material)(theta_PM[i]);
              n22 = make_n_function(wl[1] / 1.0e3 + deltawl, interaction_type.charAt(1), this.material)(theta_PM[i]);

              n11 = make_n_function(wl[0] / 1.0e3, interaction_type.charAt(0), this.material)(theta_PM[i]);
              n12 = make_n_function(wl[0] / 1.0e3 + deltawl, interaction_type.charAt(0), this.material)(theta_PM[i]);

              GV3 = n31 - ((wl[2] / 1.0e3) * (n32 - n31)) / deltawl;
              GV2 = n21 - ((wl[1] / 1.0e3) * (n22 - n21)) / deltawl;
              GV1 = n11 - ((wl[0] / 1.0e3) * (n12 - n11)) / deltawl;

              GVM.push(((1.0 / GV1 - 1.0 / GV3) * 1.0e4).toFixed(0));
              GVM.push(((1.0 / GV2 - 1.0 / GV3) * 1.0e4).toFixed(0));

              // walkoffs
              for (j = 0; j < 3; j++) {
                if (interaction_type.charAt(j) == "e") {
                  walkoff.push(
                    rad_to_deg(-(n[j](theta_PM[i] + delta_theta) / n[j](theta_PM[i]) - 1.0) / delta_theta).toFixed(3)
                  );
                } else {
                  walkoff.push(0.0);
                }
              }

              //walkoff of generated beam
              if (interaction_type.charAt(wl_index) == "e") {
                walkoff_output =
                  -(n[wl_index](theta_PM[i] + delta_theta) / n[wl_index](theta_PM[i]) - 1.0) / delta_theta;
              } else {
                walkoff_output = 0.0;
              }

              d_eff_max[i] = Math.round10(
                Math.abs(d_eff(interaction_types[i], theta_PM[i] + walkoff_output, deg_to_rad(phi[i]), this.material)),
                -3
              );
              if (!isFinite(d_eff_max[i])) {
                d_eff_max[i] = "-";
              }

              d_eff_max_all[i][k] = d_eff_max[i];
              theta_PM_all[i][k] = rad_to_deg(theta_PM[i]);

              theta_PM[i] = rad_to_deg(theta_PM[i]).toFixed(3);
            } else {
              theta_PM[i] = "-";
              d_eff_max[i] = "-";

              walkoff = walkoff.concat(["-", "-", "-"]);
              GVM = GVM.concat(["-", "-"]);
            }
            this.loading = false;
          }

          this.wl_field3 += Math.round10(wl_field3[k], -3) + "\t";

          switch (caller) {
            case "PMangles":
              this.wl_field3 += theta_PM.join("\t");
              break;
            case "d_eff":
              this.wl_field3 += d_eff_max.join("\t");
              break;
            case "GVM":
              this.wl_field3 += GVM.join("\t");
              break;
            case "walkoff":
              this.wl_field3 += walkoff.join("\t");
              break;
          }
        } else {
          this.wl_field3 += "-";
        }
        this.wl_field3 += "\n";
      }

      if (plot) {
        switch (this.x_axis) {
          case "lambda1":
            x = wl1;
            wl_index = 0;
            break;
          case "lambda2":
            x = wl2;
            wl_index = 1;
            break;
          case "lambda3":
            x = wl3;
            wl_index = 2;
            break;
        }

        options = { pointSize: 4, hAxis: { format: "####.##" } };
        (x_title = { title: "Wavelength \u03BB" + subscript_indices[wl_index], units: "nm" }),
          //plot PM angle
          (y = []);
        y_title = { title: "PM angle, deg" };
        for (i = 0; i < 3; i += 1) {
          y.push({ title: interaction_types[i], val: theta_PM_all[i] });
        }

        drawPlotD3(x, y, "plot1", x_title, y_title, options);

        //plot d_eff
        y = [];
        y_title = { title: "d_eff, pm/V" };
        for (i = 0; i < 3; i += 1) {
          y.push({ title: interaction_types[i], val: d_eff_max_all[i] });
        }

        drawPlotD3(x, y, "plot2", x_title, y_title, options);
      }
    },
  },
};
</script>
<style lang="css">
.padding-top-sm {
  padding-top: 1em;
}
</style>
