<template>
  <div v-if="$store.state.dataReady">
    <!-- TESTING SCREEN DPI  -->
    <div
      id="testdiv"
      style="height: 1in; left: -100%; position: absolute; top: -100%; width: 1in"
    />

    <div class="top-padding10">
      <div class="row">
        <!-- HEADER -->
        <div
          v-if="!presetMode"
          class="col-xs-12"
        >
          <h3>Optical Layout Planner</h3>
        </div>

        <!-- MENU ICONS -->
        <div class="col-lg-9">
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <input
                id="layout-title"
                v-model="layoutSettings.title"
                placeholder="Layout title (optional)"
                type="text"
                style="width: 100%; border: none; border-bottom: 1.5px solid #C7007F; margin: 0 0 5px 0; font-size: 16pt; color: #C7007F;"
              >
            </div>
          </div>
          <div
            v-if="showSelector"
            class="btn-toolbar"
            role="toolbar"
          >
            <div
              v-if="!presetMode"
              class="btn-group mr-2"
              role="group"
              aria-label="First group"
            >
              <button
                id="new-btn"
                type="button"
                title="New layout"
                class="btn btn-default"
                data-toggle="modal"
                data-target="#table_settings"
                @click="newTable = true"
              >
                <div :class="`${newPlan ? 'tooltip2' : ''}`">
                  <span
                    v-if="newPlan"
                    class="tooltiptext2"
                  >Click here to <br>
                    start a new <br>
                    layout</span>
                  <span class="glyphicon glyphicon-file" />
                </div>
              </button>
              <button
                id="save-btn"
                type="button"
                title="Save layout"
                class="btn btn-default"
                @click="saveJson"
              >
                <span class="glyphicon glyphicon-floppy-disk" />
              </button>
              <span
                v-if="saved"
                id="save-success-indicator"
                title="Layout saved"
                class="btn btn-success btn-save-indicator"
              >
                Saved, code <input
                  id="save-input"
                  :value="savedScheme.name"
                > (<a
                  id="save-link"
                  style="color: #fff"
                  :href="schemeUrl"
                >link</a>)
              </span>
              <span
                v-if="savingError"
                id="save-error-indicator"
                title="Could not save"
                class="btn btn-danger btn-save-indicator"
              >Error while saving</span>
              <button
                id="saveas-btn"
                type="button"
                title="Save copy as..."
                class="btn btn-default"
                @click="saveAsCopy"
              >
                <span class="glyphicon glyphicon-duplicate" />
              </button>
              <span
                v-if="copySaved"
                id="save-success-indicator"
                title="Layout saved"
                class="btn btn-success btn-save-indicator"
              >
                Copy saved as <input
                  id="save-input"
                  :value="savedScheme.name"
                >(<a
                  id="save-link"
                  style="color: #fff"
                  :href="schemeUrl"
                >link</a>)
              </span>
              <span
                v-if="savingError"
                id="save-error-indicator"
                title="Could not save"
                class="btn btn-danger btn-save-indicator"
              >Error while saving</span>

              <button
                id="table-settings-btn"
                type="button"
                title="Layout/table settings"
                class="btn btn-default"
                data-toggle="modal"
                data-target="#table_settings"
                @click="newTable = false"
              >
                <span class="glyphicon glyphicon-cog" />
              </button>
            </div>
            <div
              v-if="!presetMode"
              class="btn-group mr-2"
              role="group"
              aria-label="Second group"
            >
              <button
                id="pdf-btn"
                type="button"
                title="Print/export to PDF"
                class="btn btn-default"
                @click="saveAsPdf"
              >
                <span class="glyphicon glyphicon-print" />
              </button>
              <button
                id="png-btn"
                type="button"
                title="Save layout as PNG"
                class="btn btn-default"
                @click="saveAsPng"
              >
                <span class="glyphicon glyphicon-save" />
              </button>
            </div>
            <div
              class="btn-group mr-2"
              role="group"
            >
              <button
                type="button"
                title="Rotate object clockwise"
                class="btn btn-default"
                @click="cwRotate"
              >
                <span class="glyphicon glyphicon-repeat" />
              </button>
              <button
                type="button"
                title="Rotate object counter-clockwise"
                class="btn btn-default"
                @click="ccwRotate"
              >
                <span class="glyphicon glyphicon-repeat gly-flip-horizontal" />
              </button>
              <button
                id="tofront-btn"
                type="button"
                title="Move object to front [PageUp]"
                class="btn btn-default"
                @click="onMoveToFront"
              >
                <span class="glyphicon glyphicon-arrow-up" />
              </button>
              <button
                type="button"
                title="Move object to back [PageDown]"
                class="btn btn-default"
                @click="onMoveToBack"
              >
                <span class="glyphicon glyphicon-arrow-down" />
              </button>

              <button
                id="del-btn"
                type="button"
                title="Delete object [Del]"
                class="btn btn-default"
                @click="deleteObjects"
              >
                <span class="glyphicon glyphicon-erase" />
              </button>
            </div>
            <div
              class="btn-group"
              role="group"
            >
              <button
                id="terminals-btn"
                title="Show/hide item terminals"
                type="button"
                class="btn btn-default"
                @click="toggleTerminals"
              >
                <span class="glyphicon glyphicon-record" />
              </button>
            </div>

            <div
              class="btn-group mr-2"
              role="group"
            >
              <button
                id="drawline-btn"
                type="button"
                title="Draw polygon line, RightClick/[Esc] to terminate, [Shift] for right angles"
                class="btn btn-default"
                @click="draw('')"
              >
                <span
                  class="glyphicon glyphicon-pencil"
                  :style="{ color: drawColor }"
                />
              </button>
              <!-- red -->
              <button
                v-if="showColors"
                id="drawline-color-1"
                class="btn btn-default"
                @click="draw('#c0392b')"
              >
                <span
                  class="glyphicon glyphicon-stop"
                  style="color: #c0392b"
                />
              </button>
              <!-- blue -->
              <button
                v-if="showColors"
                id="drawline-color-2"
                class="btn btn-default"
                @click="draw('#152fb6')"
              >
                <span
                  class="glyphicon glyphicon-stop"
                  style="color: #152fb6"
                />
              </button>
              <!-- green -->
              <button
                v-if="showColors"
                id="drawline-color-3"
                class="btn btn-default"
                @click="draw('#056b29')"
              >
                <span
                  class="glyphicon glyphicon-stop"
                  style="color: #056b29"
                />
              </button>
              <!-- yellow -->
              <button
                v-if="showColors"
                id="drawline-color-4"
                class="btn btn-default"
                @click="draw('#e0d52b')"
              >
                <span
                  class="glyphicon glyphicon-stop"
                  style="color: #e0d52b"
                />
              </button>
              <!-- black -->
              <button
                v-if="showColors"
                id="drawline-color-5"
                class="btn btn-default"
                @click="draw('#000000')"
              >
                <span
                  class="glyphicon glyphicon-stop"
                  style="color: #000000"
                />
              </button>
              <button
                id="measure-btn"
                type="button"
                title="Measure height/width"
                class="btn btn-default"
                data-toggle="button"
                @click="measure"
              >
                <img src="@/assets/static/media/icons/measure.svg">
              </button>
              <button
                id="annotate-btn"
                type="button"
                title="Add text"
                class="btn btn-default"
                @click="annotate"
              >
                <span class="glyphicon glyphicon-font" />
              </button>
            </div>

            <div
              v-if="presetMode"
              class="btn-group mr-2"
              role="group"
              aria-label="First group"
            >
              <button
                id="table-settings-btn"
                type="button"
                title="Layout/table settings"
                class="btn btn-default"
                data-toggle="modal"
                data-target="#table_settings"
              >
                <span class="glyphicon glyphicon-cog" />
              </button>
            </div>

            <div
              v-if="presetMode"
              :class="`${presetHasError ? 'has-error btn-group' : 'btn-group'}`"
              role="group"
            >
              <input
                v-model="presetTitle"
                type="text"
                class="form-control indicator"
                placeholder="Title"
              >
            </div>
            <div
              v-if="presetMode"
              :class="`${presetHasError ? 'has-error btn-group' : 'btn-group'}`"
              role="group"
            >
              <input
                v-model="presetCategory"
                type="text"
                class="form-control indicator"
                placeholder="Category"
              >
            </div>

            <div
              v-if="presetMode"
              class="btn-group mr-2"
              role="group"
              aria-label="Preset group"
            >
              <button
                id="pdf-btn"
                type="button"
                title="Save Preset"
                class="btn btn-default"
                @click="savePreset"
              >
                <span class="glyphicon glyphicon-floppy-disk" />
              </button>
              <button
                id="png-btn"
                type="button"
                title="Delete Preset"
                class="btn btn-default"
                @click="deletePreset"
              >
                <span class="glyphicon glyphicon-trash" />
              </button>
            </div>
            
            <div v-if="$store.state.initialState.status.loggedIn && $route.fullPath.includes('admin')">
              <button
                id="saveas-btn"
                type="button"
                title="Save scheme as preset"
                class="btn btn-default"
                @click="saveAsPreset"
              >
                <span class="glyphicon glyphicon-share-alt" />
              </button>
            </div>
            <div
              class="btn-group"
              role="group"
            >
              <button
                id="terminals-btn"
                title="Show/hide table"
                type="button"
                class="btn btn-default"
                @click="
                  toggleTable();
                  showTable = !showTable;
                "
              >
                <span class="glyphicon glyphicon-eye-close" />
              </button>
            </div>
          </div>
        </div>

        <!-- PRODUCT ADD -->
        <div
          v-if="showSelector"
          class="col-lg-3 padding-0"
        >
          <div class="col-lg-2 padding-0">
            <button
              id="addproduct-btn"
              title="Add to table"
              class="btn btn-default"
              style="width: 100%"
              @click="onAdd"
            >
              <span class="glyphicon glyphicon-plus" />
            </button>
          </div>
          <div class="col-lg-10 padding-0">
            <Selector
              :list="$store.state.categorizedProducts"
              :list-name="'Products'"
              :default-selection="activeObject"
              @selectedMaterial="onSelection"
            />
          </div>
        </div>

        <!-- LAYOUT -->
        <div class="col-lg-9 top-padding10">
          <div
            id="kitchenWrap"
            ref="kitchenWrap"
            style="position: relative"
          >
            <canvas
              id="table"
              ref="table"
              width="900"
              height="556"
              style="width: 1800px; height: 1112px; position: absolute; left: 0; top: 0; z-index: 0; border-radius: 4px"
            />
            <canvas
              id="tabletop"
              ref="tabletop"
              width="900"
              height="556"
              style="width: 1800px; height: 1112px; position: absolute; left: 0; top: 0; z-index: 0; border-radius: 4px"
            />
            <canvas
              id="table-merged"
              width="900"
              height="556"
              style="
                width: 1800px;
                height: 1112px;
                display: none;
                position: relative;
                left: 0;
                top: 0;
                z-index: 0;
                border-radius: 4px;
              "
            />
          </div>
        </div>

        <!-- DESCRIPTION -->
        <div
          v-if="selectedProduct !== null && showSelector && activeObject != null"
          class="col-lg-3 padding-left-15 top-padding10"
        >
          <ProductInfoForUser :product="selectedProduct" />
          <div
            v-if="activeObject.name === 'BLACK-BOX'"
            class="btn-group"
            role="group"
          >
            <h5>Width (mm)</h5>
            <input
              v-model="blackBoxWidth"
              type="number"
              min="0"
              class="form-control indicator"
              placeholder="Width"
            >
            <h5>Height (mm)</h5>
            <input
              v-model="blackBoxHeight"
              type="number"
              min="0"
              class="form-control indicator"
              placeholder="Height"
            >

            <!-- <button class="btn btn-block btn-primary">Save</button> -->
            <div />
          </div>
        </div>
      </div>
    </div>
  </div>
  <TableSettings
    id="table_settings"
    :new-table="newTable"
    :settings="layoutSettings"
    @add="newLayout"
  />
  <button
    id="showColors"
    style="visibility: hidden"
    @click="showColors = false"
  />
</template>
<script>
import Selector from "@/components/Selector.vue";
import ProductInfoForUser from "@/components/planner/ProductInfoForUser.vue";
import {KITCHEN} from "@/tools/kitchenplanner.js";
import {fabric} from "fabric";
import TableSettings from "@/components/planner/TableSettings.vue";
import DataService from "@/services/data.service";
//import $ from "jquery";

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default {
  components: {
    Selector,
    ProductInfoForUser,
    TableSettings,
  },
  props: {
    id: null,
    showSelector: {
      type: Boolean,
      default() {
        return false;
      },
    },
    presetMode: {
      type: Boolean,
      default() {
        return false;
      },
    },
    preset: null,
  },
  data() {
    return {
      test: null,
      showColors: false,
      drawColor: "#c0392b",
      selectedProduct: null,
      layoutSettings: {
        Title: null,
        Author: null,
        Email: null,
        AdditionalInformation: null,
        Size: "200x120cm",
      },
      savedScheme: null,
      savingError: false,
      saved: false,
      copySaved: false,
      newPlan: false,
      presetTitle: null,
      presetCategory: null,
      presetHasError: false,
      newTable: false,
      createdFromPreset: false,
      oldScheme: false,
      showTable: true,
      blackBoxWidth: 0,
      blackBoxHeight: 0
    };
  },
  computed: {
    schemeUrl() {
      return "/planner/" + this.savedScheme.name;
    },
    activeObject() {
      return this.$store.state.plannerActiveObjectName;
    },
    active() {
      return this.$store.state.plannerActiveObject;
    },
  },
  watch: {
    id() {
      this.getData(this.id);
    },
    preset() {
      if (this.preset != null) {
        this.presetCategory = this.preset.category;
        this.presetTitle = this.preset.title;
      }
    },
    blackBoxWidth(newVal){
      var activeObject = KITCHEN.canvas.getActiveObject()
      if(activeObject!=null){
        activeObject.set('scaleX',newVal/activeObject.width*KITCHEN.gridScale).setCoords();
        KITCHEN.canvas.renderAll();
      }
    },
    blackBoxHeight(newVal){
      var activeObject = KITCHEN.canvas.getActiveObject()
      if(activeObject!=null){
        activeObject.set('scaleY',newVal/activeObject.height*KITCHEN.gridScale).setCoords();
        KITCHEN.canvas.renderAll();
      }
    }
  },
  
  mounted() {
    setTimeout(() => {
      this.createCanvas();
      KITCHEN.canvas.on("selection:created", this.whenObjectSelected);
      KITCHEN.canvas.on("selection:updated", this.whenselectionUpdated);
      KITCHEN.canvas.on("selection:cleared", this.whenSelectionCleared);
      KITCHEN.canvas.on("object:scaling",this.scaling)
    
    }, 200);
    //this.createCanvas();
    if (this.preset != null) {
      this.presetCategory = this.preset.category;
      this.presetTitle = this.preset.title;
    }
    
  },
  
  methods: {
    scaling(event){
      if(event.target.name.name=="BLACK-BOX")
      {this.updateWidth(event.target.getObjectScaling())}
    },
    whenselectionUpdated(){ 
      if(Object.prototype.hasOwnProperty.call(KITCHEN.canvas.getActiveObject(),"_objects")&&
        KITCHEN.canvas.getActiveObject()._objects.length == 1 && 
        KITCHEN.canvas.getActiveObject().name.name=="BLACK-BOX"){
      this.blackBoxWidth = parseInt(KITCHEN.canvas.getActiveObject().width/KITCHEN.gridScale*KITCHEN.canvas.getActiveObject().scaleX);
      this.blackBoxHeight = parseInt(KITCHEN.canvas.getActiveObject().height/KITCHEN.gridScale*KITCHEN.canvas.getActiveObject().scaleY);
      KITCHEN.canvas.getActiveObject().setControlsVisibility({
            mb: true,
            ml: true,
            mr: true,
            mt: true,
          })
          .setCoords();
      }
    },
    whenSelectionCleared(){
      this.selectedProduct=null;
      this.$store.commit("SET_PlannerActiveObjectName", null);
    },
    whenObjectSelected(){
      this.selectedProduct=KITCHEN.canvas.getActiveObject().name;
      this.whenselectionUpdated();
    },
    updateWidth(scaling) {
       this.blackBoxWidth = parseInt(KITCHEN.canvas.getActiveObject().width/KITCHEN.gridScale*scaling.scaleX);
       this.blackBoxHeight = parseInt(KITCHEN.canvas.getActiveObject().height/KITCHEN.gridScale*scaling.scaleY);
    },
    async saveJson() {
      this.saved = false;
      var savedScheme = KITCHEN.save();
      await Promise.all([KITCHEN.saveAsPng("scheme", false), timeout(200)]);

      if (this.savedScheme != null) savedScheme.name = this.savedScheme.name;
      if (this.savedScheme != null && !this.createdFromPreset && !this.oldScheme) {
        await this.updateScheme(savedScheme);
      } else {
        await this.registerScheme(savedScheme);
      }
    },
    async updateScheme(jsonScheme) {
      await DataService.updateScheme(this.savedScheme.name, jsonScheme).then((response) => {
        this.savedScheme = response.data;
        this.uploadSchemeImage(response.data.name);
        this.saved = true;
      });
    },
    async registerScheme(jsonScheme) {
      await DataService.registerScheme(jsonScheme).then((response) => {
        this.savedScheme = response.data;
        this.uploadSchemeImage(response.data.name);
        this.saved = true;
      });
    },
    async uploadSchemeImage(schemeId) {
      await DataService.postSchemeImage(schemeId, this.$store.state.tempImage);
    },
    async saveAsCopy() {
      this.copySaved = false;
      await KITCHEN.saveAsPng("scheme", false);
      DataService.registerScheme(KITCHEN.save()).then((response) => {
        this.savedScheme = response.data;
        this.copySaved = true;
      });
    },
    newLayout(settings) {
      if (this.newTable && typeof settings.presetName !== "undefined") {
        this.createdFromPreset = true;
        this.getData(settings.presetName, settings);
        this.layoutSettings = settings;
      } else {
        this.layoutSettings = settings;
        KITCHEN.apply(this.layoutSettings.size);
        KITCHEN.restoreAllSizes();
      }
      this.newPlan = false;
    },
    onSelection(product) {
      this.selectedProduct = product;
    },
    onAdd() {
      if(Object.prototype.hasOwnProperty.call(this.selectedProduct,"name")&&this.selectedProduct.name=="BLACK-BOX"){
      this.blackBoxWidth=this.selectedProduct.width;
      this.blackBoxHeight = this.selectedProduct.height
    }
      KITCHEN.addProduct(this.selectedProduct);
    },
    async saveAsPdf() {
      this.$store.commit("SET_Loading", true);
      await Promise.all([await this.saveJson(), timeout(2000)]);
      console.log(this.savedScheme)
      this.$store.commit("SET_Loading", false);
      this.$router.push({name: "pdfview", params: {id: this.savedScheme.name}});
    },
    saveAsPng() {
      if (this.savedScheme != null) {
        KITCHEN.saveAsPng(this.savedScheme.name, true);
      } else {
        KITCHEN.saveAsPng("scheme", true);
      }
      //console.log(KITCHEN.makeHQpng());
    },
    deleteObjects() {
      KITCHEN.hideAllTerminals();
      KITCHEN.removeSelectedObject();
    },
    cwRotate() {
      KITCHEN.rotateSelectedObject(90);
    },
    ccwRotate() {
      KITCHEN.rotateSelectedObject(-90);
    },
    onMoveToFront() {
      KITCHEN.toFrontSelectedObject();
    },
    onMoveToBack() {
      KITCHEN.toBackSelectedObject();
    },
    draw(color) {
      if (color !== "") {
        this.showColors = false;
        this.drawColor = color;
        KITCHEN.drawLine.lineColor = color;
        KITCHEN.drawLine.deactivate();
        KITCHEN.drawLine.activate();
      } else if (KITCHEN.drawLine.active) {
        KITCHEN.drawLine.toggle();
        KITCHEN.drawLine.deactivate();
        this.showColors = false;
      } else {
        this.showColors = !this.showColors;
        KITCHEN.drawLine.toggle();
        KITCHEN.drawLine.activate();
      }

      KITCHEN.canvas.renderAll();

      setTimeout(function () {
        this.showColors = false;
      }, 500);
    },
    measure() {
      if (KITCHEN.measure.active) {
        KITCHEN.measure.deactivate();
      } else {
        KITCHEN.measure.activate();
      }
      KITCHEN.measure.dimension = "width";
    },
    annotate() {
      var newTextbox = new fabric.IText("Annotation text", {
        fill: "white",
        fontSize: 16,
        left: 50,
        top: 50,
        fontFamily: "Helvetica",
        evented: true,
        hasControls: true,
        visible: true,
        originY: "bottom",
      });
      KITCHEN.canvas.add(newTextbox).renderAll();

      newTextbox
          .setControlsVisibility({
            bl: true,
            mb: true,
            br: true,
            ml: true,
            mr: true,
            mt: true,
            tl: true,
            tr: true,
            mtr: true,
          })
          .setCoords();

      KITCHEN.canvasHistory.saveState("addText");
    },
    toggleTerminals() {
      KITCHEN.toggleAllTerminals();
    },
    getData(name, settings) {
      var tabelParam;
      DataService.getSchemeData(name)
          .then((response) => {
            this.savedScheme = response.data;
            this.layoutSettings = response.data.layoutSettings;

            if (response.data.tableParameters.type === "imperial") {
              this.layoutSettings.size =
                  response.data.tableParameters.X / 30.48 / 10 + "x" + response.data.tableParameters.Y / 30.48 / 10 + "ft";
            } else {
              this.layoutSettings.size =
                  response.data.tableParameters.X / 10 + "x" + response.data.tableParameters.Y / 10 + "cm";
            }
            tabelParam = this.layoutSettings.size;
            KITCHEN.loadSettingsFromJSON(response.data);
            if (settings != null) this.layoutSettings = settings;
            this.layoutSettings.size = tabelParam;
          })
          .catch((error) => {
            if (error.response.status === 404) {
              this.loadOldScheme(name);
            }
          });
    },
    getPresetData() {
      DataService.getPresetData;
    },
    toggleTable() {
      KITCHEN.toggleTable(this.showTable);
    },
    createCanvas() {
      KITCHEN.products = this.$store.state.products;
      //Table is drawn on different (bottom) STATIC canvas

      KITCHEN.tableCanvas = new fabric.StaticCanvas(this.$refs.table, {
        stateful: false,
        selection: false,
        renderOnAddRemove: false,
        preserveObjectStacking: true,
      });

      //Tabletop - other interactive objects
      KITCHEN.canvas = new fabric.Canvas(this.$refs.tabletop, {
        stateful: false,
        selection: true,
        renderOnAddRemove: false,
        preserveObjectStacking: true,
        perPixelTargetFind: true,
        fireRightClick: true,
        stopContextMenu: true,
        targetFindTolerance: 4,
      });

      KITCHEN.apply(this.layoutSettings.Size);
      KITCHEN.onSettings();
      if (this.$route.params.name !== null && typeof this.$route.params.name !== "undefined") {
        this.getData(this.$route.params.name);
      } else if (this.id !== null && typeof this.id !== "undefined") {
        this.getData(this.id);
      } else {
        this.newPlan = true;
      }
    },
    savePreset() {
      if (
          this.presetTitle == null ||
          this.presetTitle === "" ||
          this.presetCategory == null ||
          this.presetCategory === ""
      ) {
        this.presetHasError = true;
      } else {
        this.presetHasError = false;
        var savedJson = KITCHEN.save();
        if (this.preset != null) {
          DataService.updateScheme(this.preset.schemeId, savedJson).then((response) => {
            if (response.status === 200) {
              this.sendPresetData(response.data.name, false);
            }
          });
        } else {
          DataService.registerScheme(savedJson).then((response) => {
            if (response.status === 200) {
              this.sendPresetData(response.data.name, true);
            }
          });
        }
      }
    },
    deletePreset() {
      DataService.deletePreset(this.preset.id).then(() => {
        this.confirmDialog();
      })
    },
    confirmDialog() {
      this.$swal
          .fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.$swal.fire("Deleted!", "Your file has been deleted.", "success");
              this.$store.dispatch("loadPresets");
              this.$router.push({name: "adminpresets"});
            }
          });
    },
    sendPresetData(schemeId, register) {
      this.$store.commit("SET_Loading", true);
      var formData = new FormData();
      formData.append("Title", this.presetTitle);
      formData.append("Category", this.presetCategory);
      formData.append("SchemeId", schemeId);
      formData.append("Image", KITCHEN.makePNG());

      if (register) {
        DataService.registerPreset(formData).then((response) => {
          this.$store.commit("SET_Loading", false);
          switch (response.status) {
            case 500:
              this.presetHasError = true;
              break;
            case 201:
              this.saveInfo();
              break;
            default:
              break;
          }
        });
      } else {
        DataService.updatePreset(this.preset.id, formData).then((response) => {
          this.$store.commit("SET_Loading", false);
          switch (response.status) {
            case 500:
              this.presetHasError = true;
              break;
            case 204:
              this.saveInfo();
              break;
            default:
              break;
          }
        });
      }
    },
    saveAsPreset() {
      this.registerScheme(KITCHEN.save());
      DataService.uploadPresetFromScheme(this.savedScheme).then(() => {
        this.$router.push({name: "presetinfo", params: {id: this.savedScheme.name}}).then(() => {
          location.reload();
        })
      });
    },
    saveInfo() {
      this.$swal.fire({
        position: "bottom-end",
        icon: "success",
        title: "Your work has been saved",
        showConfirmButton: false,
        timer: 1500,
      });
    },
    loadOldScheme(name) {
      console.log("loadOldScheme"); //"02PWKV"
      var tableParam;
      DataService.getOldSchemeData(name).then((response) => {
        KITCHEN.loadOldSchemeFromJSON(response.data);
        this.savedScheme = KITCHEN.save();
        this.savedScheme.name = name;
        this.layoutSettings = response.data.layoutSettings;

        if (response.data.tableParameters.type === "imperial") {
          this.layoutSettings.size =
              response.data.tableParameters.X / 30.48 / 10 + "x" + response.data.tableParameters.Y / 30.48 / 10 + "ft";
        } else {
          this.layoutSettings.size =
              response.data.tableParameters.X / 10 + "x" + response.data.tableParameters.Y / 10 + "cm";
        }
        tableParam = this.layoutSettings.size;
        this.layoutSettings.size = tableParam;
        this.oldScheme = true;
      });
    },
  },
};
</script>
<style scoped>
.larger {
  width: 125%;
}

.padding-0 {
  padding-right: 0;
  padding-left: 0;
}

.padding-left-15 {
  padding-right: 0;
  padding-left: 15;
}

.gly-flip-horizontal {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
  -webkit-transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.tooltip2 .tooltiptext2 {
  width: 100px;
  background-color: #1d793d;
  top: -20px;
  left: -120px;
  right: 150%;
  font-size: 12px;
}

.tooltip2 .tooltiptext2::after {
  top: 50%;
  rotate: 0deg;
  left: 100%;
  border-color: transparent transparent transparent #1d793d;
}
</style>
