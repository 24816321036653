<template lang="">
  <div
    :id="id"
    class="modal"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
          >
            &times;
          </button>
          <h4 class="modal-title">
            Reflectance of s-polarized beam
          </h4>
        </div>
        <div class="modal-body">
          <p>
            <math-jax
              latex="R_\mathrm{s} = \frac{|E_\mathrm{r}^\mathrm{s}|^2}{|E_\mathrm{i}^\mathrm{s}|^2}=\frac{|\cos\vartheta_0-n\cos\vartheta_1|^2}{|\cos\vartheta_0+n\cos\vartheta_1|^2}."
              :block="true"
            />
            Here <math-jax latex="\vartheta_0" /> is <abbr title="Angle of incidence">AOI</abbr> and 
            <math-jax
              latex="\vartheta_1 = \arcsin\frac{\sin\vartheta_0}{n}"
              :block="true"
            /> is angle of refraction.
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-default"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: null,
  },
};
</script>
