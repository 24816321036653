<template lang="">
  <div
    v-if="$store.state.dataReady"
    id="SFG"
    class="row"
  >
    <!-- TITLE -->
    <div class="col-lg-12 col-md-12 col-xs-12">
      <h3 id="SFG">
        Sum frequency generation
        <small><span
          data-toggle="collapse"
          data-target="#info_SFG"
          class="glyphicon glyphicon-info-sign info-glyph"
        /></small>
      </h3>
    </div>
    <!-- INFO/OUTPUT -->
    <div class="col-lg-6 col-md-6 col-xs-12">
      <div
        id="info_SFG"
        class="collapse"
      >
        <p>Colinear sum frequency generation in uniaxial crystals</p>
      </div>

      <div
        v-if="noPMshow"
        class="alert alert-danger"
      >
        <strong>No phase match found!</strong>
      </div>
      <div
        v-if="wlOutOfBounds"
        class="alert alert-danger"
      >
        <strong>Wavelength out of bounds!</strong>
      </div>

      <!-- OOE PANEL -->
      <SFGPanel
        v-if="OOEshow"
        ref="ooe"
        Title="o-o-e"
        :material="material"
        :expand="true"
        :wl="wl"
        type="o-o-e"
        @state="onStateChange"
      />

      <!-- EOE PANEL -->
      <SFGPanel
        v-if="EOEshow"
        ref="eoe"
        Title="e-o-e"
        :material="material"
        :wl="wl"
        type="e-o-e"
        @state="onStateChange"
      />

      <!-- OEE PANEL -->
      <SFGPanel
        v-if="OEEshow"
        ref="oee"
        Title="o-e-e"
        :material="material"
        :wl="wl"
        type="o-e-e"
        @state="onStateChange"
      />

      <!-- EEO PANEL -->
      <SFGPanel
        v-if="EEOshow"
        ref="eeo"
        Title="e-e-o"
        :material="material"
        :wl="wl"
        type="e-e-o"
        @state="onStateChange"
      />

      <!-- OEO PANEL -->
      <SFGPanel
        v-if="OEOshow"
        ref="oeo"
        Title="o-e-o"
        :material="material"
        :wl="wl"
        type="o-e-o"
        @state="onStateChange"
      />

      <!-- EOO PANEL -->
      <SFGPanel
        v-if="EOOshow"
        ref="eoo"
        Title="e-o-o"
        :material="material"
        :wl="wl"
        type="e-o-o"
        @state="onStateChange"
      />

      <br>
    </div>
    <!-- INPUT -->
    <div class="col-lg-6 col-md-6 col-xs-12">
      <!-- MATERIAL SELECT -->
      <Selector
        :list="materials"
        :list-name="'Materials'"
        :default-selection="'BaB2O4'"
        :with-label="true"
        @selectedMaterial="onSelection"
      />

      <h5>Wavelength range: {{ wlrangeMin }} - {{ wlrangeMax }}</h5>

      <div class="row">
        <div class="col-lg-4 col-md-6 col-xs-6 vcenter">
          <h5><math-jax latex="\lambda_1" /></h5>
          <div class="input-group">
            <input
              id="wl1text"
              v-model="lambda1"
              step="1.0"
              type="number"
              class="form-control indicator"
              tabindex="1"
            >
            <span class="input-group-addon">nm</span>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-xs-6 vcenter">
          <h5><math-jax latex="\lambda_2" /></h5>
          <div class="input-group">
            <input
              id="wl2text"
              v-model="lambda2"
              step="1.0"
              type="number"
              class="form-control indicator"
              tabindex="2"
            >
            <span class="input-group-addon">nm</span>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-xs-6 vcenter">
          <h5><math-jax latex="\lambda_3" /></h5>
          <div class="input-group">
            <input
              id="wl3text"
              v-model="lambda3"
              step="1.0"
              type="number"
              class="form-control indicator"
              tabindex="3"
            >
            <span class="input-group-addon">nm</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Selector from "@/components/Selector.vue";
import SFGPanel from "@/components/SFGPanel.vue";
import DataService from "@/services/data.service";
import { test_inside } from "@/tools/Utils.js";

export default {
  components: {
    Selector,
    SFGPanel,
  },
  data() {
    return {
      wl1: 1030,
      wl2: 1030,
      wl3: 515,
      wlorder: [0, 1, 2],
      EEOshow: true,
      OEOshow: true,
      EOOshow: true,
      OOEshow: true,
      EOEshow: true,
      OEEshow: true,
      EEOstate: null,
      OEOstate: null,
      EOOstate: null,
      OOEstate: null,
      EOEstate: null,
      OEEstate: null,
      wlrangeMin: null,
      wlrangeMax: null,
      material: null,
    };
  },
  computed: {
    materials() {
      return this.$store.getters.materialsByType("Uniaxial crystals");
    },
    noPMshow() {
      if (!this.EEOstate && !this.OEOstate && !this.EOOstate && !this.OOEstate && !this.EOEstate && !this.OEEstate)
        return true;
      return false;
    },
    lambda1: {
      get: function () {
        return Math.round10(this.wl1, -3);
      },
      set: function (newValue) {
        this.wl1 = newValue;
        this.update_wl_order(1);
        if (!this.wlorder[1]) {
          this.wl2 = 1.0 / (1.0 / this.wl3 - 1.0 / this.wl1);
        } else {
          this.wl3 = 1.0 / (1.0 / this.wl1 + 1.0 / this.wl2);
        }
      },
    },
    lambda2: {
      get: function () {
        return Math.round10(this.wl2, -3);
      },
      set: function (newValue) {
        this.wl2 = newValue;
        this.update_wl_order(2);
        if (!this.wlorder[2]) {
          this.wl3 = 1.0 / (1.0 / this.wl1 + 1.0 / this.wl2);
        } else {
          this.wl1 = 1.0 / (1.0 / this.wl3 - 1.0 / this.wl2);
        }
      },
    },
    lambda3: {
      get: function () {
        return Math.round10(this.wl3, -3);
      },
      set: function (newValue) {
        this.wl3 = newValue;
        this.update_wl_order(3);

        if (!this.wlorder[0]) {
          this.wl1 = 1.0 / (1.0 / this.wl3 - 1.0 / this.wl2);
        } else {
          this.wl2 = 1.0 / (1.0 / this.wl3 - 1.0 / this.wl1);
        }
      },
    },
    wl() {
      return [this.lambda1, this.lambda2, this.lambda3];
    },
    wlOutOfBounds() {
      var lambda1Inside = test_inside(this.lambda1, this.wlrangeMin, this.wlrangeMax);
      var lambda2Inside = test_inside(this.lambda2, this.wlrangeMin, this.wlrangeMax);
      var lambda3Inside = test_inside(this.lambda3, this.wlrangeMin, this.wlrangeMax);

      if (!lambda1Inside || !lambda2Inside || !lambda3Inside) return true;
      return false;
    },
  },
  watch: {
    material(newValue) {
      this.wlrangeMin = parseFloat(newValue.parameters[0].wl_n_range[0]) * 1.0e3;
      this.wlrangeMax = parseFloat(newValue.parameters[0].wl_n_range[1]) * 1.0e3;
    },
  },
  methods: {
    update_wl_order(id) {
      if (this.wlorder[id - 1] === 2) {
        return;
      }
      this.wlorder[id - 1] = 2;

      for (var i = 0; i < 3; i += 1) {
        if (i != id - 1) {
          this.wlorder[i] -= 1;
        }
        if (this.wlorder[i] < 0.0) {
          this.wlorder[i] = 0;
        }
      }
    },
    onStateChange(state, type) {
      switch (type) {
        case "o-o-e":
          this.OOEstate = state;
          break;
        case "e-o-e":
          this.EOEstate = state;
          break;
        case "o-e-e":
          this.OEEstate = state;
          break;
        case "e-e-o":
          this.EEOstate = state;
          break;
        case "o-e-o":
          this.OEOstate = state;
          break;
        case "e-o-o":
          this.EOOstate = state;
          break;
      }
    },
    async onSelection(material) {
      await DataService.getMaterialData(material).then((response) => {
        this.material = response.data.value;
      });

      if (this.material.birefrigerence_type == "positive") {
        this.OOEshow = false;
        this.EOEshow = false;
        this.OEEshow = false;
      } else {
        this.EEOshow = false;
        this.OEOshow = false;
        this.EOOshow = false;
      }
      if (this.$refs.eeo !== null) this.$refs.eeo.updateData();
      if (this.$refs.oeo !== null) this.$refs.oeo.updateData();
      if (this.$refs.eoo !== null) this.$refs.eoo.updateData();
      if (this.$refs.ooe !== null) this.$refs.ooe.updateData();
      if (this.$refs.eoe !== null) this.$refs.eoe.updateData();
      if (this.$refs.oee !== null) this.$refs.oee.updateData();
    },
  },
};
</script>
<style lang=""></style>
