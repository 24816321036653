<template lang="">
  <div
    class="panel panel-default"
    style="margin-bottom: 5px"
  >
    <!-- OUTPUT ( EXTRAORDINARY )-->
    <div
      id="headingOne"
      class="panel-heading"
      role="tab"
      data-ng-show="true"
    >
      <h4 class="panel-title">
        <a
          data-toggle="collapse"
          :data-target="titleid"
          href="#collapseOne"
          aria-expanded="true"
          aria-controls="collapseOne"
        >
          <span :class="icon">&nbsp;</span>{{ Title }} ray
        </a>
      </h4>
    </div>
    <div
      :id="Title"
      class="panel-collapse collapse in"
      role="tabpanel"
      aria-labelledby="headingOne"
    >
      <div class="row bottom_align_row">
        <div class="col-md-4 col-xs-4 no-right-padding">
          <h5>
            Optical path
            <span
              class="glyphicon glyphicon-info-sign info-glyph"
              data-toggle="modal"
              data-target="#description_opticalpath_spatial"
            />
          </h5>
          <div class="input-group">
            <input
              v-model="L"
              type="number"
              class="form-control"
              readonly
            >
            <span class="input-group-addon">mm</span>
          </div>
        </div>
        <div class="col-md-4 col-xs-4 no-right-padding">
          <h5>
            Optical path
            <span
              class="glyphicon glyphicon-info-sign info-glyph"
              data-toggle="modal"
              data-target="#description_opticalpath_temporal"
            />
          </h5>
          <div class="input-group">
            <input
              v-model="t"
              type="number"
              class="form-control"
              readonly
            >
            <span class="input-group-addon">ps</span>
          </div>
        </div>
        <div class="col-md-4 col-xs-4">
          <h5>
            <math-jax latex="\Delta\varphi_\mathrm{CE}" />&nbsp;
            <span
              class="glyphicon glyphicon-info-sign info-glyph"
              data-toggle="modal"
              data-target="#description_phi_CE"
            />
          </h5>
          <div class="input-group">
            <input
              v-model="phi_CE"
              type="number"
              class="form-control"
              readonly
            >
            <span class="input-group-addon">rad</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <h5><abbr title="Group delay dispersion">GDD</abbr></h5>
          <div class="input-group">
            <input
              v-model="GDD"
              type="number"
              class="form-control"
              readonly
            >
            <span class="input-group-addon">fs&sup2;</span>
          </div>
        </div>
        <div class="col-xs-6">
          <h5><abbr title="Third-order dispersion">TOD</abbr></h5>
          <div class="input-group">
            <input
              v-model="TOD"
              type="number"
              class="form-control"
              readonly
            >
            <span class="input-group-addon">fs&sup3;</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <OpticalPathSpatialDescriptionModal id="description_opticalpath_spatial" />
  <OpticalPathTemporalDescriptionModal id="description_opticalpath_temporal" />
  <PhiCEDescriptionModal id="description_phi_CE" />
</template>
<script>
import OpticalPathSpatialDescriptionModal from "@/components/descriptions/beamProperties/OpticalPathSpatial.vue";
import OpticalPathTemporalDescriptionModal from "@/components/descriptions/beamProperties/OpticalPathTemporal.vue";
import PhiCEDescriptionModal from "@/components/descriptions/beamProperties/PhiCE.vue";
import { GroupVelocity, RefractiveIndex, GVDMaterial, TODMaterial, pi, c } from "@/tools/MaterialDispersion.js";

export default {
  components: {
    OpticalPathTemporalDescriptionModal,
    OpticalPathSpatialDescriptionModal,
    PhiCEDescriptionModal,
  },
  props: {
    Title: String,
    icon: String,
    materialList: null,
    wl: null,
    extraordinayRay: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      titleid: "#" + this.Title,
      L: 0.0,
      phi_CE: 0.0,
      t: 0.0,
      GDD: 0.0,
      TOD: 0.0,
      GV: 0,
      ri: 0,
    };
  },
  watch: {    
    wl(newValue) {
      this.calculate(newValue);
    },
    materialList() {
      this.calculate(this.wl);
    },
  },
  methods: {
    calculate(newWl) {
      this.L = 0.0;
      this.phi_CE = 0.0;
      this.t = 0.0;
      this.GDD = 0.0;
      this.TOD = 0.0;
      this.GV = 0;
      this.ri = 0;

      var ri_e = 0;
      var phi_CE_e = 0.0;
      var t_e = 0.0;
      var GDD_e = 0.0;
      var TOD_e = 0.0;
      var GV_e = 0;
      var L_e = 0.0;
      var wl = parseFloat(newWl);

      for (var i = 0; i < this.materialList.length; i += 1) {
        var material = this.materialList[i].material;
        var item = this.materialList[i];

        this.GV = GroupVelocity(material, wl / 1.0e3, 0);
        this.ri = RefractiveIndex(wl / 1.0e3, 0, material);

        this.GDD += item.thickness * GVDMaterial(wl / 1.0e3, material, 0) * 1.0e27;
        this.TOD += item.thickness * TODMaterial(wl / 1.0e3, material, 0) * 1.0e42;

        if (material.NumberOfAxis > 0) {
          GV_e = GroupVelocity(material, wl / 1.0e3, 1);
          ri_e = RefractiveIndex(wl / 1.0e3, 1, material);
          GDD_e += item.thickness * GVDMaterial(wl / 1.0e3, material, 1) * 1.0e27;
          TOD_e += item.thickness * TODMaterial(wl / 1.0e3, material, 1) * 1.0e42;
        } else {
          GV_e = this.GV;
          ri_e = this.ri;
          GDD_e += item.thickness * GVDMaterial(wl / 1.0e3, material, 0) * 1.0e27;
          TOD_e += item.thickness * TODMaterial(wl / 1.0e3, material, 0) * 1.0e42;
        }

        this.t = parseFloat(this.t) + (item.thickness / this.GV) * 1.0e-3; // in [s]: thickness in [mm], GV - in [m/s]
        this.L += item.thickness * this.ri;
        this.phi_CE += ((2.0 * pi * c) / wl) * (1.0 / this.GV - this.ri / c) * item.thickness * 1.0e6; // in rad: wl in [nm], GV in [m/s], thickness in [mm]

        t_e += (item.thickness / GV_e) * 1.0e-3; // in [s]: thickness in [mm], GV - in [m/s]
        L_e += item.thickness * ri_e;
        phi_CE_e += ((2.0 * pi * c) / wl) * (1.0 / GV_e - ri_e / c) * item.thickness * 1.0e6; // in rad: wl in [nm], GV in [m/s], thickness in [mm]
      }

      this.t = (this.t * 1.0e12).toFixed(3);
      this.phi_CE = (this.phi_CE % pi).toFixed(3);
      this.L = this.L.toFixed(3); // in [mm]
      this.GDD = this.GDD.toFixed(3);
      this.TOD = this.TOD.toFixed(3);

      t_e = (t_e * 1.0e12).toFixed(3); // in [ps]
      phi_CE_e = (phi_CE_e % pi).toFixed(3);
      L_e = L_e.toFixed(3);
      GDD_e = GDD_e.toFixed(3);
      TOD_e = TOD_e.toFixed(3);

      if (this.extraordinayRay) {
        this.t = t_e;
        this.phi_CE = phi_CE_e;
        this.L = L_e;
        this.GDD = GDD_e;
        this.TOD = TOD_e;
      }
    },
  },
};
</script>
<style lang=""></style>
