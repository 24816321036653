<template lang="">
  <!-- BEAM FOCUSING PANEL -->
  <div
    id="beam_focusing_panel"
    class="panel panel-default"
  >
    <div
      class="panel-heading"
      role="tab"
      data-ng-show="true"
    >
      <h4 class="panel-title">
        <a
          data-toggle="collapse"
          href="#collapse_beam_focusing"
          aria-expanded="true"
          aria-controls="collapse_beam_focusing"
          class="collapsed"
        >
          <img src="@/assets/static/media/icons/beam_focusing.svg"> Beam focusing
        </a>
      </h4>
    </div>
    <div
      id="collapse_beam_focusing"
      class="panel-collapse collapse"
      role="tabpanel"
    >
      <div
        id="info_gaussianbeamfocus"
        class="collapse"
      >
        <img
          class="img-center"
          src="@/assets/static/media/svg/gaussianbeamfocus.svg"
        >
        <p>
          Beam waist diameter at focus:
          <math-jax
            latex="w_{0}'=\frac{w_{0}}{\sqrt{\left(1+s/f\right)^{2}+\left(z_{\mathrm{R}}/f\right)^{2}}}."
            :block="true"
          />
          Distance to focus:
          <math-jax
            latex="s'=\frac{f\left[s(s-f)+z_{\mathrm{R}}^{2}\right]}{(s-f)^{2}+z_{\mathrm{R}}^{2}}."
            :block="true"
          />
        </p>
      </div>
      <div class="row">
        <!-- LENS FOCAL LENGTH-->
        <div class="col-xs-6">
          <h5>
            Lens focal length <math-jax latex="f" />
            <span
              data-toggle="collapse"
              data-target="#info_gaussianbeamfocus"
              class="glyphicon glyphicon-info-sign info-glyph"
            />
          </h5>
          <div class="input-group">
            <input
              v-model="f"
              step="1"
              type="number"
              class="form-control indicator"
              tabindex="6"
            >
            <span class="input-group-addon">mm</span>
          </div>
        </div>

        <!-- DISTANCE TO LENS -->
        <div class="col-xs-6">
          <h5>Distance to lens <math-jax latex="s" /></h5>
          <div class="input-group">
            <input
              v-model="s"
              step="1"
              type="number"
              class="form-control indicator"
              tabindex="7"
            >
            <span class="input-group-addon">mm</span>
          </div>
        </div>

        <!-- BEAM RADIUS AT FOCUS -->
        <div class="col-xs-12">
          <h5>Beam diameter at focus <math-jax latex="2w_0^\prime" /> (at e&#8315;&sup2;)</h5>
          <div class="input-group">
            <input
              v-model="d0_out"
              step="1"
              type="number"
              class="form-control indicator"
              tabindex="8"
              readonly
            >
            <span class="input-group-addon">mm</span>
          </div>
        </div>

        <!-- BEAM RADIUS AT FOCUS -->
        <div class="col-xs-6">
          <h5>Distance to focus <math-jax latex="s'" /></h5>
          <div class="input-group">
            <input
              v-model="s_out"
              type="number"
              class="form-control indicator"
              tabindex="9"
              readonly
            >
            <span class="input-group-addon">mm</span>
          </div>
        </div>

        <!-- BEAM RADIUS AT LENS -->
        <div class="col-xs-6">
          <h5>Beam diameter at lens <math-jax latex="d" /></h5>
          <div class="input-group">
            <input
              v-model="d0_lens"
              type="number"
              class="form-control indicator"
              tabindex="10"
              readonly
            >
            <span class="input-group-addon">mm</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    zRayleigh: null,
    w0: null,
  },
  data() {
    return {
      f: null,
      s: null,
    };
  },
  computed: {
    d0_out() {
      var w0_out = this.w0 / Math.sqrt(Math.pow(1 - this.s / this.f, 2.0) + Math.pow(this.zRayleigh / this.f, 2.0));
      return Math.round10(w0_out * 2.0, -3);
    },
    s_out() {
      return Math.round10((this.f * (this.s * (this.s - this.f) + Math.pow(this.zRayleigh, 2.0))) /
                          (Math.pow(this.s - this.f, 2.0) + Math.pow(this.zRayleigh, 2.0)), -3);
    },
    d0_lens() {
      var w0_lens = this.w0 * Math.sqrt(1 + Math.pow(this.s / this.zRayleigh, 2.0));
      return Math.round10(2.0 * w0_lens, -3);
    },
  },
};
</script>
