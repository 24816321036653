import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
//boostrap
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
//old css file
import "./assets/style.css";
//bootstrap-select
import "bootstrap-select/dist/css/bootstrap-select.css";
import "bootstrap-select/dist/js/bootstrap-select.min.js";
//MathJax
import MathJax, { initMathJax, renderByMathjax } from "mathjax-vue3";
//sweetalert
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


function onMathJaxReady() {
  renderByMathjax();
}
initMathJax({options: {
  enableMenu: false,          // set to false to disable the menu
  menuOptions: {
    settings: {
      inTabOrder: false,      // true if tabbing includes math
    }
  } 
},url: 'https://cdn.jsdelivr.net/npm/mathjax@3.2.0/es5/tex-svg.min.js'}, onMathJaxReady);

createApp(App).use(store).use(router).use(MathJax).use(VueSweetalert2).mount("#app");
