<template lang="">
  <div
    :id="id"
    class="modal"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
          >
            &times;
          </button>
          <h4 class="modal-title">
            Optical path
          </h4>
        </div>
        <div class="modal-body">
          <p>
            Time of flight of Gaussian beam through optical path length <math-jax latex="L" />,
            <math-jax
              latex="t = \sum_{i=1}^N\frac{h_i}{v_{\mathsf{g},i}}."
              :block="true"
            /> 
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-default"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: null,
  },
};
</script>
