<template lang="">
  <div
    v-if="$store.state.schemesReady"
    class="row"
  >
    <div class="col-sm-4">
      <Selector
        :list="$store.state.schemes"
        :list-name="'Schemes'"
        @selectedMaterial="onSelection"
      />
    </div>
    <!-- <ProductInfo :id="selectedProduct.id" v-if="selectedProduct !== null" /> -->
  </div>

  <router-view
    v-if="selectedProduct !== null"
    :show-selector="true"
  />
</template>
<script>
import Selector from "@/components/Selector.vue";

import $ from "jquery";

export default {
  components: {
    Selector,
  },
  data() {
    return {
      selectedProduct: null,
    };
  },
  mounted() {
    $('.panel-heading a').on('click', function() {
        $('.panel-heading').not($(this).parents('.panel-heading')).removeClass('active');
        $(this).parents('.panel-heading').toggleClass('active');
    });
},
  created(){
    this.$store.dispatch("loadSchemes");
  },
  methods: {
    onSelection(product) {
      this.selectedProduct = product;
      this.$router.push({ name: "schemeinfo", params: { id: product.name } });
    },
  }
};
</script>
<style scoped>
.panel {
  margin-bottom: 0!important;
  padding-left: 0!important;
}
</style>
