import * as d3 from "d3";
import d3Tip from "d3-tip";
import $ from "jquery";

import store from "@/store/index.js";

export function drawPlotD3(x, y, target, x_axis, y_axis, additional_options, margin = null) {
  if (y.length === 0) {
    return;
  }
  //plot options
  var options = {
    hAxis: {
      logScale: false,
      title: x_axis.title + (x_axis.units ? ", " + x_axis.units : ""),
      titleTextStyle: {
        italic: false,
      },
      tickOptions: {
        color: "#000",
      },
      format: "#",
    },
    vAxis: {
      logScale: false,
      title: y_axis.title + (y_axis.units ? ", " + y_axis.units : ""),
      titleTextStyle: {
        italic: false,
      },
      tickOptions: {
        color: "#000",
      },
      format: "#",
    },
    backgroundColor: "white",
    chartArea: {
      left: "15%",
      top: "10%",
      bottom: "20%",
      width: "85%",
      height: "75%",
    },
    height: 300,
    legend: { position: "in" },
    width: $("#" + target)
      .parent()
      .width(), /// CORRECT THIS
    explorer: {
      maxZoomIn: 0.0001,
      actions: ["dragToZoom", "rightClickToReset"],
    },
  };

  merge_objects(options, additional_options);

  var myColors = [
    "#3366CC",
    "#DC3912",
    "#FF9900",
    "#109618",
    "#990099",
    "#3B3EAC",
    "#0099C6",
    "#DD4477",
    "#66AA00",
    "#B82E2E",
    "#316395",
    "#994499",
    "#22AA99",
    "#AAAA11",
    "#6633CC",
    "#E67300",
    "#8B0707",
    "#329262",
    "#5574A6",
    "#3B3EAC",
  ];

  var defaultMargin = { top: 30, right: 5, bottom: 50, left: 60 };
  if (margin !== null) defaultMargin = margin;
  var padding = { top: 5, right: 5, bottom: 5, left: 10 };

  var width = options.width - defaultMargin.left - defaultMargin.right;
  var height = options.height - defaultMargin.top - defaultMargin.bottom;

  $("#" + target).empty();

  var translate_format = function (format) {
    switch (format) {
      case "scientific":
        return "e";
      default:
        var myRegexp1 = /^(#*)\.(#*)$/g;
        var myRegexp2 = /^(#*)$/g;
        var myMatch = myRegexp1.exec(format) || myRegexp2.exec(format);

        switch (myMatch.length) {
          case 3: // example: ["#.###", "#", "###", index: 0, input: "#.###"]
            return myMatch[1].length + "." + myMatch[2].length + "f";
          case 2: // example: ["#", "#", index: 0, input: "#"]
            return "";
          default:
            return "";
        }
    }
  };
  if (Object.prototype.hasOwnProperty.call(options.hAxis, "format")) {
    options.hAxis.format = translate_format(options.hAxis.format);
  }
  if (Object.prototype.hasOwnProperty.call(options.vAxis, "format")) {
    options.vAxis.format = translate_format(options.vAxis.format);
  }
  //svg area
  var svg = d3
    .select("#" + target)
    .append("svg")
    .attr("id", target + "_svg")
    .attr("width", options.width)
    .attr("height", options.height)
    .append("g")
    .attr("transform", "translate(" + defaultMargin.left + "," + defaultMargin.top + ")");

  //white rectangle at background
  d3.select("#" + target + " svg")
    .insert("rect", ":first-child")
    .attr("x", 0)
    .attr("y", 0)
    .attr("stroke", "none")
    .attr("stroke-width", 0)
    .attr("fill", options.backgroundColor)
    .attr("width", options.width)
    .attr("height", options.height);

  var xRange;

  var xAxis;

  if (options.hAxis.logScale) {
    xRange = d3.scale.log().range([0, width]);

    xAxis = d3.svg
      .axis()
      .scale(xRange)
      .orient("bottom")
      .tickFormat(function (d) {
        var log = Math.log(d) / Math.LN10;
        return Math.abs(Math.round(log) - log) < 1e-6 ? 10 : "";
      })
      .innerTickSize(-height)
      .outerTickSize(5)
      .tickPadding(10);

    var xAxis2 = d3.svg
      .axis()
      .scale(xRange)
      .orient("bottom")
      .tickFormat(function (d) {
        var log = Math.log(d) / Math.LN10;
        return Math.abs(Math.round(log) - log) < 1e-6 ? Math.round(log) : "";
      })
      .innerTickSize(0)
      .outerTickSize(0)
      .tickPadding(3);
  } else {
    xRange = d3.scale.linear().range([0, width]);

    xAxis = d3.svg
      .axis()
      .scale(xRange)
      .orient("bottom")
      .tickFormat(d3.format(options.hAxis.format))
      .innerTickSize(-height)
      .outerTickSize(5)
      .tickPadding(10);
  }

  var yRange;
  var yAxis;

  if (options.vAxis.logScale) {
    yRange = d3.scale.log().range([height, 0]);
    yAxis = d3.svg
      .axis()
      .scale(yRange)
      .orient("left")
      .tickFormat(function (d) {
        var log = Math.log(d) / Math.LN10;
        return Math.abs(Math.round(log) - log) < 1e-6 ? 10 : "";
      })
      .innerTickSize(-width)
      .outerTickSize(5)
      .tickPadding(10);

    var yAxis2 = d3.svg
      .axis()
      .scale(yRange)
      .orient("left")
      .tickFormat(function (d) {
        var log = Math.log(d) / Math.LN10;
        return Math.abs(Math.round(log) - log) < 1e-6 ? Math.round(log) : "";
      })
      .innerTickSize(0)
      .outerTickSize(0)
      .tickPadding(0);
  } else {
    yRange = d3.scale.linear().range([height, 0]);
    yAxis = d3.svg
      .axis()
      .scale(yRange)
      .orient("left")
      .tickFormat(d3.format(options.vAxis.format))
      .innerTickSize(-width)
      .outerTickSize(5)
      .tickPadding(10);
  }

  var xval, yval, tval;
  var yRangeMin = null,
    yRangeMax = null;
  var yRangeMinTemp, yRangeMaxTemp;

  // Define the div for the tooltip
  d3.select("body").append("div").attr("class", "tooltip").style("opacity", 0);

  var lineFunction = d3.svg
    .line()
    .x(function (d) {
      return xRange(d.x);
    })
    .y(function (d) {
      return yRange(d.y);
    })
    .defined(function (d) {
      return d.y;
    }); // Omit empty values.;

  var dataSet = [];

  var i, j;

  for (j = 0; j < y.length; j++) {
    dataSet[j] = [];

    for (i = 0; i < x.length; i++) {
      xval = x[i];

      //data given as data points
      if (typeof y[j].val !== "undefined") {
        yval = y[j].val[i];
      }

      //data given as function reference
      if (typeof y[j].fun !== "undefined") {
        yval = y[j].fun(xval);
      }

      //exact tooltip given
      if (typeof y[j].tooltip !== "undefined") {
        tval = y[j].tooltip[i];
      }
      //tooltip function given
      if (typeof y[j].tooltipfun === "undefined") {
        if (typeof xval !== "undefined" && typeof yval !== "undefined") {
          tval = y[j].title + ": (" + xval + "," + yval.toFixed(3) + ")";
        } else {
          tval = null;
        }
      } else {
        if (typeof xval !== "undefined" && typeof yval !== "undefined") {
          tval = y[j].tooltipfun(xval, yval);
        } else {
          tval = null;
        }
      }

      if (typeof yval === "undefined") {
        yval = null;
      }

      if (typeof tval === "undefined") {
        tval = y[j].title + ": (" + xval + "," + yval + ")";
      }

      dataSet[j].push({ x: xval, y: yval, t: tval });
    }

    yRangeMinTemp = d3.min(dataSet[j], function (d) {
      return d.y;
    });
    yRangeMaxTemp = d3.max(dataSet[j], function (d) {
      return d.y;
    });

    yRangeMin = yRangeMin === null ? yRangeMinTemp : yRangeMinTemp < yRangeMin ? yRangeMinTemp : yRangeMin;
    yRangeMax = yRangeMax === null ? yRangeMaxTemp : yRangeMaxTemp > yRangeMax ? yRangeMaxTemp : yRangeMax;
  }

  // extend domain if a single value on x axis
  var xRangeDomain = d3.extent(dataSet[0], function (d) {
    return d.x;
  });
  if (xRangeDomain[1] - xRangeDomain[0] === 0) {
    xRangeDomain = [xRangeDomain[0] * 0.95, xRangeDomain[0] * 1.05];
    options.pointSize = 4.0;
  }

  xRange.domain(xRangeDomain);
  yRange.domain([yRangeMin, yRangeMax]);

  var tip = d3Tip()
    .attr("class", "d3-tip")
    .offset([-12, 0])
    .html(function (d) {
      return d.t;
    });

  svg.call(tip);

  for (j = 0; j < dataSet.length; j++) {
    var path = svg
      .append("path")
      .attr("d", lineFunction(dataSet[j]))
      .attr("data-legend", y[j].title)
      .attr("stroke", myColors[j])
      .attr("stroke-width", 2)
      .attr("fill", "none");

    if (Object.prototype.hasOwnProperty.call(options, "series")) {
      if (Object.prototype.hasOwnProperty.call(options.series[j], "color")) {
        path.attr("stroke", options.series[j].color);
      }
      if (Object.prototype.hasOwnProperty.call(options.series[j], "lineDashStyle")) {
        path.attr("stroke-dasharray", options.series[j].lineDashStyle[0] + ", " + options.series[j].lineDashStyle[1]);
      }
    }

    if (Object.prototype.hasOwnProperty.call(options, "pointSize")) {
      svg
        .selectAll(".dot" + j)
        .data(
          dataSet[j].filter(function (d) {
            return d.y;
          })
        )
        .enter()
        .append("circle")
        .attr("class", "dot" + j)
        .attr("cx", function (d) {
          return xRange(d.x);
        })
        .attr("cy", function (d) {
          return yRange(d.y);
        })
        .style("fill", myColors[j])
        .style("stroke", "none")
        .attr("r", options.pointSize);
    }

    var hoverRange = svg
      .selectAll(".rect" + j)
      .data(
        dataSet[j].filter(function (d) {
          return d.y;
        })
      )
      .enter()
      .append("rect")
      .attr("class", "rect" + j)
      .style("fill", "none")
      .style("pointer-events", "all")
      .attr("y", function (d) {
        return yRange(d.y) - 15;
      })
      .attr("height", 30)
      .on("mouseover", tip.show)
      .on("mouseout", tip.hide);
    hoverRange
      .attr("x", function (d, i) {
        return i === 0 ? xRange(d.x) - 5 : xRange((hoverRange.data()[i - 1].x + hoverRange.data()[i].x) / 2.0);
        //return (i===hoverRange.data().length-1) ? xRange(d.x) : xRange((hoverRange.data()[i+1].x + hoverRange.data()[i].x)/2.0);
      })
      .attr("width", function (d, i) {
        var leftX = i === 0 ? xRange(d.x) - 5 : xRange((hoverRange.data()[i - 1].x + hoverRange.data()[i].x) / 2.0);
        var rightX =
          i === hoverRange.data().length - 1
            ? xRange(d.x) + 5
            : xRange((hoverRange.data()[i + 1].x + hoverRange.data()[i].x) / 2.0);

        return rightX - leftX;
      });
  }

  //Y AXIS
  svg.append("g").attr("class", "y axis").call(yAxis);

  if (options.vAxis.logScale) {
    svg.append("g").attr("class", "y axis").attr("transform", "translate(0,-5)").call(yAxis2);
  }

  //X AXIS
  svg
    .append("g")
    .attr("class", "x axis")
    .attr("transform", "translate(0," + height + ")")
    .call(xAxis);

  if (options.hAxis.logScale) {
    svg
      .append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(12," + height + ")")
      .call(xAxis2);
  }

  //Y AXIS LABEL
  svg
    .append("text")
    .attr("text-anchor", "middle")
    .attr("alignment-baseline", "hanging")
    .attr("transform", "translate(" + (-defaultMargin.left + padding.left) + "," + height / 2 + ")rotate(-90)")
    .text(options.vAxis.title);

  //X AXIS LABEL
  svg
    .append("text")
    .attr("text-anchor", "middle")
    .attr("alignment-baseline", "baseline")
    .attr("transform", "translate(" + width / 2 + "," + (height + defaultMargin.bottom - padding.bottom) + ")")
    .text(options.hAxis.title);

  //TITLE
  svg
    .append("text")
    .attr("text-anchor", "bottom")
    .attr("alignment-baseline", "baseline")
    .attr("transform", "translate(0,-2)")
    .attr("font-weight", "bold")
    .text(options.title);

  store.commit("SET_Loading", false);

  //LEGEND

  svg
    .append("g")
    .attr("class", "legend")
    .attr("transform", "translate(" + (width - 200) + ", 20)")
    .call(legend);
}

//recursively appends JSON object obj1 by key/values of obj2
var merge_objects = function (obj1, obj2) {
  //iterate over all the properties in the object which is being consumed
  var p;
  for (p in obj2) {
    // Property in destination object set; update its value.
    if (Object.prototype.hasOwnProperty.call(obj2, p) && typeof obj1[p] === "object") {
      merge_objects(obj1[p], obj2[p]);
    } else {
      //We don't have that level in the heirarchy so add it
      obj1[p] = obj2[p];
    }
  }
};

export function drawTuningCurvesD3(x, y, target, x_axis, y_axis, additional_options) {
  //plot options
  var options = {
    hAxis: {
      logScale: false,
      title: x_axis.title + (x_axis.units ? ", " + x_axis.units : ""),
      titleTextStyle: {
        italic: false,
      },
      tickOptions: {
        color: "#000",
      },
      format: "#",
    },
    vAxis: {
      logScale: false,
      title: y_axis.title + (y_axis.units ? ", " + y_axis.units : ""),
      titleTextStyle: {
        italic: false,
      },
      tickOptions: {
        color: "#000",
      },
      format: "#",
    },
    backgroundColor: "white",
    chartArea: { left: "15%", top: "10%", bottom: "20%", width: "85%", height: "75%" },
    height: 300,
    legend: { position: "in" },
    width: $("#" + target)
      .parent()
      .width(), /// CORRECT THIS
    explorer: {
      maxZoomIn: 0.0001,
      actions: ["dragToZoom", "rightClickToReset"],
    },
  };

  merge_objects(options, additional_options);

  var margin = { top: 30, right: 65, bottom: 70, left: 60 };
  var padding = { top: 5, right: 5, bottom: 5, left: 1 };

  var width = options.width - margin.left - margin.right;
  var height = options.height - margin.top - margin.bottom;

  $("#" + target).empty();

  var translate_format = function (format) {
    switch (format) {
      case "scientific":
        return "e";
      default:
        var myRegexp1 = /^(#*)\.(#*)$/g;
        var myRegexp2 = /^(#*)$/g;
        var myMatch = myRegexp1.exec(format) || myRegexp2.exec(format);

        switch (myMatch.length) {
          case 3: // example: ["#.###", "#", "###", index: 0, input: "#.###"]
            return myMatch[1].length + "." + myMatch[2].length + "f";
          case 2: // example: ["#", "#", index: 0, input: "#"]
            return "";
          default:
            return "";
        }
    }
  };

  // AXIS RANGES
  var xRange;
  var yRange;

  if (options.hAxis.logScale) {
    xRange = d3.scale.log().range([0, width]);
  } else {
    xRange = d3.scale.linear().range([0, width]);
  }

  if (options.vAxis.logScale || options.vAxes["0"].logScale) {
    yRange = d3.scale.log().range([height, 0]);
  } else {
    yRange = d3.scale.linear().range([height, 0]);
  }

  // PREPARE DATASET
  var xval, yval, tval;
  var dataSet = [];

  var i, j;

  for (j = 0; j < y.length; j++) {
    dataSet[j] = [];

    for (i = 0; i < x.length; i++) {
      xval = x[i];

      //data given as data points
      if (typeof y[j].val !== "undefined") {
        yval = y[j].val[i];
      }

      //data given as function reference
      if (typeof y[j].fun !== "undefined") {
        yval = y[j].fun(xval);
      }

      //exact tooltip given
      if (typeof y[j].tooltip !== "undefined") {
        tval = y[j].tooltip[i];
      }

      //tooltip function given
      if (typeof y[j].tooltipfun !== "undefined") {
        if (typeof xval !== "undefined" && typeof yval !== "undefined") {
          tval = y[j].tooltipfun(xval, yval);
        } else {
          tval = null;
        }
      }

      dataSet[j].push({ x: xval, y: yval, t: tval, series: j });
    }

    yRangeMinTemp = d3.min(dataSet[j], function (d) {
      return d.y;
    });
    yRangeMaxTemp = d3.max(dataSet[j], function (d) {
      return d.y;
    });

    yRangeMin = yRangeMin === null ? yRangeMinTemp : yRangeMinTemp < yRangeMin ? yRangeMinTemp : yRangeMin;
    yRangeMax = yRangeMax === null ? yRangeMaxTemp : yRangeMaxTemp > yRangeMax ? yRangeMaxTemp : yRangeMax;
  }

  for (j = 0; j < dataSet.length; j++) {
    if (y[j].title.indexOf("specification") !== -1) {
      for (i = 0; i < dataSet[j].length; i++) {
        dataSet[j][i].y = dataSet[j][i].y + dataSet[j - 1][i].y;
      }
    }

    if (y[j].title.indexOf("best effort") !== -1) {
      for (i = 0; i < dataSet[j].length; i++) {
        dataSet[j][i].y = dataSet[j][i].y + dataSet[j - 1][i].y;
      }
    }
  }

  if (Object.prototype.hasOwnProperty.call(options.hAxis, "ticks")) {
    xRange.domain([options.hAxis.ticks[0], options.hAxis.ticks[options.hAxis.ticks.length - 1]]);
  } else {
    xRange.domain(
      d3.extent(dataSet[0], function (d) {
        return d.x;
      })
    );
  }

  yRange.domain([yRangeMin, yRangeMax]);

  // AXIS FORMAT
  if (Object.prototype.hasOwnProperty.call(options.hAxis, "format")) {
    options.hAxis.format = translate_format(options.hAxis.format);
  }

  if (Object.prototype.hasOwnProperty.call(options.vAxis, "format")) {
    options.vAxis.format = translate_format(options.vAxis.format);
  }
  var svg = d3
    .select("#" + target)
    .append("svg")
    .attr("id", target + "_svg")
    .attr("width", options.width)
    .attr("height", options.height)
    .append("g")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

  d3.select("#" + target + " svg")
    .insert("rect", ":first-child")
    .attr("x", 0)
    .attr("y", 0)
    .attr("stroke", "none")
    .attr("stroke-width", 0)
    .attr("fill", options.backgroundColor)
    .attr("width", options.width)
    .attr("height", options.height);

  var xAxis = d3.svg
    .axis()
    .scale(xRange)
    .orient("bottom")
    .tickFormat(d3.format(options.hAxis.format))
    .innerTickSize(-height)
    .outerTickSize(0)
    .tickPadding(5);

  if (Object.prototype.hasOwnProperty.call(options.hAxis, "ticks")) {
    xAxis.tickValues(options.hAxis.ticks);
  }

  var yAxis = null;
  var yAxisSecondary = null;
  var yScale = [];
  var f = [];
  var v = [];

  var tip = d3Tip()
    .attr("class", "d3-tip")
    .offset([-12, 0])
    .html(function (d) {
      return d.t;
    });

  svg.call(tip);

  if (Object.prototype.hasOwnProperty.call(options, "vAxes") && Object.keys(options.vAxes).length > 0) {
    for (var item in options.vAxes) {
      f[item] = [];
      v[item] = [];

      for (i = 0; i < options.vAxes[item].ticks.length; i++) {
        f[item].push(options.vAxes[item].ticks[i].f);
        v[item].push(options.vAxes[item].ticks[i].v);
      }

      if (options.vAxes[item].logScale) {
        //yScale.push(d3.scale.log().domain([v[item][0], v[item][v[item].length-1]]).range([height, 1]));
        yScale.push(
          d3.scale
            .log()
            .domain([v[item][0], v[item][v[item].length - 1]])
            .range([height, 0])
        );
      } else {
        yScale.push(
          d3.scale
            .linear()
            .domain([v[item][0], v[item][v[item].length - 1]])
            .range([height, 0])
        );
      }
    }

    yAxis = d3.svg
      .axis()
      .scale(yScale[0])
      .orient("left")
      .tickValues(v[0])
      .tickFormat(d3.format("."))
      .innerTickSize(-width)
      .outerTickSize(0)
      .tickPadding(5);
if(v.length>1)
    yAxisSecondary = d3.svg
      .axis()
      .scale(yScale[1])
      .orient("right")
      .tickValues(v[1])
      .tickFormat(function (d, i) {
        return f[item][i];
      })
      .outerTickSize(0)
      .innerTickSize(0)
      .tickPadding(5);
  } else {
    yAxis = d3.svg
      .axis()
      .scale(yRange)
      .orient("left")
      .tickFormat(d3.format(options.vAxis.format))
      .innerTickSize(-width)
      .outerTickSize(0)
      .tickPadding(5);
  }

  //TITLE
  svg
    .append("text")
    .attr("text-anchor", "bottom")
    .attr("text-anchor", "start")
    .attr("alignment-baseline", "baseline")
    .attr("transform", "translate(0,-2)")
    .attr("font-weight", "bold")
    .text(options.title);

  //LEFT Y AXIS
  svg
    .append("g")
    .attr("class", "y axis")
    .call(yAxis)
    .selectAll("line")
    .style("opacity", 0.2)
    .style("fill", "none")
    .style("stroke", "grey")
    .style("stroke-width", 1)
    .style("shape-rendering", "crispEdges");

  //RIGHT Y AXIS
  if(yAxisSecondary!=null)
  svg
    .append("g")
    .attr("class", "y2 axis")
    .attr("transform", "translate(" + width + ", 0)")
    .call(yAxisSecondary);

  //BOTTOM X AXIS
  var xaxis = svg
    .append("g")
    .attr("class", "x axis")
    .attr("transform", "translate(0," + height + ")")
    .call(xAxis);

  xaxis.selectAll(".tick line").style("opacity", 0.2);

  xaxis
    .selectAll(".axis line")
    .style("fill", "none")
    .style("stroke", "grey")
    .style("stroke-width", 1)
    .style("shape-rendering", "crispEdges");

  xaxis
    .selectAll("text")
    .style("text-anchor", "end")
    .attr("dx", -10)
    .attr("dy", 0)
    .attr("transform", function () {
      return "rotate(-90)";
    });

  //TOP X AXIS
  svg
    .append("g")
    .attr("class", "x axis")
    .call(
      d3.svg
        .axis()
        .scale(xRange)
        .orient("bottom")
        .tickFormat(function () {
          return "";
        })
        .innerTickSize(0)
        .outerTickSize(0)
        .tickPadding(5)
    );

  //LEFT Y AXIS LABEL
  svg
    .append("text")
    .attr("text-anchor", "middle")
    .attr("alignment-baseline", "hanging")
    .attr("transform", "translate(" + (-margin.left + padding.left+10) + "," + height / 2 + ")rotate(-90)")
    .text(options.vAxes[0].title);

  //RIGHT Y AXIS LABEL
  if(yAxisSecondary!=null)
  svg
    .append("text")
    .attr("text-anchor", "middle")
    .attr("alignment-baseline", "baseline")
    .attr("transform", "translate(" + (width + margin.right - 5) + "," + height / 2 + ")rotate(-90)")
    .text(options.vAxes[1].title);

  //X AXIS LABEL
  svg
    .append("text")
    .attr("text-anchor", "middle")
    .attr("alignment-baseline", "baseline")
    .attr("transform", "translate(" + width / 2 + "," + (height + margin.bottom - padding.bottom) + ")")
    .text(options.hAxis.title);

  var yRangeMin = null,
    yRangeMax = null;
  var yRangeMinTemp, yRangeMaxTemp;

  var lineFunction = d3.svg
    .line()
    .defined(function (d) {
      return d.y;
    }) // Omit empty values.;
    .x(function (d) {
      return xRange(d.x);
    })
    .y(function (d) {
      return yScale[0](d.y);
    });

  var dataSetBE = [];
  var dataSetSpec = [];
  var dataSetOther = [];
  for (j = 0; j < dataSet.length; j++) {
    if (y[j].title.indexOf("best effort") !== -1) {
      dataSetBE.push(j);
      continue;
    }
    if (y[j].title.indexOf("specification") !== -1) {
      dataSetSpec.push(j);
      continue;
    }
    dataSetOther.push(j);
  }

  var dataSetOrder = dataSetSpec.concat(dataSetBE.concat(dataSetOther));

  for (var jj in dataSetOrder) {
    j = dataSetOrder[jj];

    if (y[j].title !== "FAKE DATASET") {
      var line = lineFunction(dataSet[j]);

      var path = svg
        .append("path")
        .attr("d", line)
        .attr("stroke", options.series[j].color)
        .attr("stroke-width", 2)
        .attr("fill", "none")
        .style('stroke-dasharray', options.series[j].lineDashStyle ? options.series[j].lineDashStyle : 'none');


      var pointSize = 1.0;
      var pointSizeHover = 5.0;

      if (
        !Object.prototype.hasOwnProperty.call(options.series[j], "visibleInLegend") ||
        options.series[j].visibleInLegend
      ) {
        path.attr("data-legend", y[j].title);
        path.attr("data-legend-circle", true);
        pointSize = 3.0;

        svg
          .selectAll(".dot" + j)
          .data(
            dataSet[j].filter(function (d) {
              return d.y;
            })
          )
          .enter()
          .append("circle")
          .attr("class", function (d, i) {
            return "dot" + j + " dot" + j + "p" + i;
          })
          .attr("data-series", j)
          .attr("cx", function (d) {
            return xRange(d.x);
          })
          .attr("cy", function (d) {
            return yScale[0](d.y);
          })
          .style("fill", options.series[j].color)
          .style("stroke", "none")
          .attr("r", pointSize);
      } else {
        svg
          .selectAll(".nodot" + j)
          .data(
            dataSet[j].filter(function (d) {
              return d.y;
            })
          )
          .enter()
          .append("circle")
          .attr("class", function (d, i) {
            return "nodot" + j + " nodot" + j + "p" + i;
          })
          .attr("data-series", j)
          .attr("cx", function (d) {
            return xRange(d.x);
          })
          .attr("cy", function (d) {
            return yScale[0](d.y);
          })
          .style("fill", options.series[j].color)
          .style("stroke", "none")
          .attr("r", pointSize);
      }

      var hoverRange = svg
        .selectAll(".rect" + j)
        .data(
          dataSet[j].filter(function (d) {
            return d.y;
          })
        )
        .enter()
        .append("rect")
        .attr("class", "rect" + j + " overlay")
        .attr("y", function (d) {
          return yScale[0](d.y) - 5;
        })
        .attr("height", 35)
        .style("fill", "none")
        .style("pointer-events", "all")
        .attr("data-series", j)
        .on("mouseover", function (d, i) {
          svg
            .selectAll("circle")
            .filter(".dot" + d.series + "p" + i)
            .attr("r", pointSizeHover);
          svg
            .selectAll("circle")
            .filter(".nodot" + d.series + "p" + i)
            .attr("r", pointSizeHover);
          tip.show(d, this);
        })
        .on("mouseout", function (d, i) {
          svg
            .selectAll("circle")
            .filter(".dot" + d.series + "p" + i)
            .attr("r", pointSize);
          svg
            .selectAll("circle")
            .filter(".nodot" + d.series + "p" + i)
            .attr("r", 1.0);
          tip.hide(d);
        });

      hoverRange
        .attr("x", function (d, i) {
          return i === 0 ? xRange(d.x) - 5 : xRange((hoverRange.data()[i - 1].x + hoverRange.data()[i].x) / 2.0);
          //return (i===hoverRange.data().length-1) ? xRange(d.x) : xRange((hoverRange.data()[i+1].x + hoverRange.data()[i].x)/2.0);
        })
        .attr("width", function (d, i) {
          var leftX = i === 0 ? xRange(d.x) - 10 : xRange((hoverRange.data()[i - 1].x + hoverRange.data()[i].x) / 2.0);
          var rightX =
            i === hoverRange.data().length - 1
              ? xRange(d.x) + 5
              : xRange((hoverRange.data()[i + 1].x + hoverRange.data()[i].x) / 2.0);

          return rightX - leftX;
        });

      if (y[j].title.indexOf("best effort") !== -1 || y[j].title.indexOf("specification") !== -1) {
        path.attr("stroke-width", 1);
      }

      if (y[j].title.indexOf("best effort") !== -1) {
        var data1 = dataSet[j];
        //var data2 = dataSet[j - 1];
        var indexer = d3.range(data1.length);

        var area = d3.svg
          .area()
          .defined(function (d) {
            return dataSet[j][d].y;
          })
          .x(function (d) {
            return xRange(dataSet[j][d].x);
          })
          .y0(function (d) {
            return yScale[0](dataSet[j][d].y);
          })
          .y1(function (d) {
            return yScale[0](dataSet[j - 1][d].y);
          });

        svg
          .append("path")
          .datum(indexer)
          .attr("class", "area")
          .attr("d", area)
          .style("fill", "none")
          .style("opacity", 0.5);
      }
    }
  }

  //LEGEND
  svg.append("g").attr("class", "legend")
   .attr("width", 60)
   .attr("height", 30).attr("transform", options.legend.translate ? "translate("+options.legend.translate+")" : "translate(10,20)").call(legend);
}

const legend = function (g) {
  g.each(function () {
    var g = d3.select(this),
      items = {},
      svg = d3.select(g.property("nearestViewportElement")),
      legendPadding = g.attr("data-style-padding") || 5,
      lb = g.selectAll(".legend-box").data([true]),
      li = g.selectAll(".legend-items").data([true]);

    lb.enter()
      .append("rect")
      .classed("legend-box", true)
      .style("fill", "white")
      .style("stroke", "none")
      .style("stroke-width", 0.5)
      .style("opacity", 0.6);

    li.enter().append("g").classed("legend-items", true);

    svg.selectAll("[data-legend]").each(function () {
      var self = d3.select(this);
      items[self.attr("data-legend")] = {
        pos: self.attr("data-legend-pos") || this.getBBox().y,
        circle: self.attr("data-legend-circle") != undefined ? true : false,
        color:
          self.attr("data-legend-color") != undefined
            ? self.attr("data-legend-color")
            : self.style("fill") != "none"
            ? self.style("fill")
            : self.style("stroke"),
        linedashstyle:
          self.attr("data-legend-linedashstyle") != undefined
            ? self.attr("data-legend-linedashstyle")
            : self.style("stroke-dasharray") != undefined
            ? self.style("stroke-dasharray")
            : "",
      };
    });

    items = d3.entries(items).sort(function (a, b) {
      return a.value.pos - b.value.pos;
    });

    li.selectAll("text")
      .data(items, function (d) {
        return d.key;
      })
      .call(function (d) {
        d.enter().append("text");
      })
      .call(function (d) {
        d.exit().remove();
      })
      .attr("y", function (d, i) {
        return i * 1.5 + "em";
      })
      .attr("x", "2.5em")
      .text(function (d) {
        return d.key;
      });
    
    li.selectAll("circle")
        .data(items,function(d) { return d.key})
        .call(function(d) { d.enter().append("circle")})
        .call(function(d) { d.exit().remove()})
        .attr("cy",function(d,i) { return i-0.25+"em"})
        .attr("cx",0)
        .attr("r","0.4em")
        .style("fill",function(d) { return d.value.color})  


    li.selectAll("line")
      .data(items, function (d) {
        return d.key;
      })
      .call(function (d) {
        d.enter().append("line");
      })
      .call(function (d) {
        d.exit().remove();
      })
      .attr("x1", 0)
      .attr("x2", "2em")
      .attr("y1", function (d, i) {
        return i * 1.5 - 0.25 + "em";
      })
      .attr("y2", function (d, i) {
        return i * 1.5 - 0.25 + "em";
      })
      .style("stroke", function (d) {
        return d.value.color;
      })
      .style("stroke-dasharray", function (d) {
        return d.value.linedashstyle;
      })
      .style("stroke-width", "2");

    li.selectAll("circle")
      .data(items, function (d) {
        return d.key;
      })
      .call(function (d) {
        d.enter().append("circle");
      })
      .call(function (d) {
        d.exit().remove();
      })
      .attr("cx", "1em")
      .attr("cy", function (d, i) {
        return i * 1.5 - 0.25 + "em";
      })
      .style("fill", function (d) {
        return d.value.color;
      })
      .style("r", function (d) {
        if (d.value.circle) {
          return 3.0;
        } else {
          return 0.0;
        }
      });

    // Reposition and resize the box
    var lbbox = li[0][0].getBBox();
    lb.attr("x", lbbox.x - legendPadding)
      .attr("y", lbbox.y - legendPadding)
      .attr("height", lbbox.height + 2 * legendPadding)
      .attr("width", lbbox.width + 2 * legendPadding);
  });
  return g;
};
