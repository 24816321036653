<template lang="">
  <div class="row">
    <!-- CONVERTERS -->
    <div class="col-lg-12 mobile-hide animate">
      <div class="panel panel-default mobile-hide">
        <div class="panel-body">
          <div class="row">
            <div class="col-sm-5 col-xl-5">
              <a href="http://www.lightcon.com"><img
                alt="Light Conversion"
                src="@/assets/static/media/svg/lc_banner.svg"
              ></a>
            </div>
            <div class="col-sm-4 col-xl-3">
              <a href="https://planner.lightcon.com/" target="_blank">
                <img alt="Dashboard" src="@/assets/static/media/svg/kitchen_banner.svg" >
              </a>
            </div>
            <div class="col-sm-3 col-xl-3">
              <router-link :to="{ name: 'tuningcurves' }">
                <img
                  alt="Tuning curves"
                  src="@/assets/static/media/svg/tuningcurves_banner.svg"
                >
              </router-link>
            </div>
          </div>
          <!-- <a href="{% url 'tools:dashboard' %}"
            ><img alt="Dashboard" src="@/assets/static/media/svg/dashboard_banner.svg"
          /></a> -->
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-4 col-md-6 col-xs-12">
      <div class="panel panel-default">
        <div class="panel-heading">
          <h4>Converters</h4>
        </div>
        <div class="panel-body">
          <table style="border-width: 0px">
            <tr>
              <td>
                <img
                  alt="Unit converters"
                  src="@/assets/static/media/svg/converters.svg"
                >
              </td>
              <td>
                <ul>
                  <li>
                    <router-link :to="{ name: 'unitconverter' }">
                      Unit converter
                    </router-link><br><small>Wavelength unit conversion</small>
                  </li>
                  <li>
                    <router-link :to="{ name: 'bandwidthconverter' }">
                      Bandwidth converter
                    </router-link><br><small>Bandwidth unit conversion</small>
                  </li>
                  <li>
                    <router-link :to="{ name: 'pulselengthbandwidthconverter' }">
                      Pulse length-bandwidth converter
                    </router-link><br><small>Transform-limited pulses</small>
                  </li>
                  <li>
                    <router-link :to="{ name: 'sfconverter' }">
                      OPA/SFG wavelengths
                    </router-link><br><small>Wavelenghts in nonlinear processes</small>
                  </li>
                </ul>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-6 col-xs-12">
      <div class="panel panel-default">
        <div class="panel-heading">
          <h4>Nonlinear optics</h4>
        </div>
        <div class="panel-body">
          <table style="border-width: 0px">
            <tr>
              <td>
                <img
                  alt="Nonlinear optics"
                  src="@/assets/static/media/svg/nonlinear.svg"
                >
              </td>
              <td>
                <ul>
                  <li>
                    <router-link :to="{ name: 'PMangles' }">
                      Nonlinear optical interacions
                    </router-link><br><small>Phase matching angles, angular walk-offs, group velocity mismatch (collinear geometry)</small>
                  </li>
                  <li>
                    <router-link :to="{ name: 'SFG' }">
                      Sum frequency generation
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="{ name: 'SFGinternalangles' }">
                      Noncolinear SFG internal angles
                    </router-link><br><small>Angular walk-offs</small>
                  </li>
                  <li>
                    <router-link :to="{ name: 'Bintegral' }">
                      B-integral
                    </router-link><br>
                  </li>
                </ul>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-6 col-xs-12">
      <div class="panel panel-default">
        <div class="panel-heading">
          <h4>Refraction</h4>
        </div>
        <div class="panel-body">
          <table style="border-width: 0px">
            <tr>
              <td>
                <img
                  alt="Refraction"
                  src="@/assets/static/media/svg/refraction.svg"
                >
              </td>
              <td>
                <ul>
                  <li>
                    <router-link :to="{ name: 'refractiveindex' }">
                      Refractive index
                    </router-link><br><small>Ordinary/extraordinary rays</small>
                  </li>
                  <li>
                    <router-link :to="{ name: 'comparematerials' }">
                      Comparison of materials
                    </router-link><br><small>Refractive index, GVD, TOD</small>
                  </li>
                  <li>
                    <router-link :to="{ name: 'prismrefraction' }">
                      Beam refraction in prism
                    </router-link><br><small>Refraction, deviation, Brewster's, minimum deviation apex angles</small>
                  </li>
                  <li>
                    <router-link :to="{ name: 'lensradius' }">
                      Lens radius
                    </router-link><br><small>Focal length vs. radius of plane convex/concave lens</small>
                  </li>
                </ul>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-6 col-xs-12">
      <div class="panel panel-default">
        <div class="panel-heading">
          <h4>Reflection and diffraction</h4>
        </div>
        <div class="panel-body">
          <table style="border-width: 0px">
            <tr>
              <td>
                <img
                  alt="Reflection and diffraction"
                  src="@/assets/static/media/svg/reflection_diffraction.svg"
                >
              </td>
              <td>
                <ul>
                  <li>
                    <router-link :to="{ name: 'fresnelreflection' }">
                      Fresnel reflection
                    </router-link>
                    <br><small>Reflectance of s/p polarized light, Brewster's angle</small>
                  </li>
                  <li>
                    <router-link :to="{ name: 'diffractionangles' }">
                      Diffraction angles
                    </router-link><br><small>Dependence on wavelength, angle of incidence; Littrow, blaze angle</small>
                  </li>
                  <li>
                    <router-link :to="{ name: 'gratingcalculator' }">
                      Grating calculator
                    </router-link><br><small>Angles of incidence at Littrow configuration</small>
                  </li>
                </ul>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-6 col-xs-12">
      <!-- DISPERSION CALCULATORS -->
      <div class="panel panel-default">
        <div class="panel-heading">
          <h4>Dispersion calculators</h4>
        </div>
        <div class="panel-body">
          <table style="border-width: 0px">
            <tr>
              <td>
                <img
                  alt="dispersion"
                  src="@/assets/static/media/svg/dispersion.svg"
                >
              </td>
              <td>
                <ul>
                  <li>
                    <router-link :to="{ name: 'prismpair' }">
                      Two prism compressor
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="{ name: 'dispersionparameters' }">
                      Material dispersion
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="{ name: 'gratingpair' }">
                      Grating pair compressor
                    </router-link><br><small>Optical path, GDD (GDV), GDV zero point, TOD, chirped pulse elongation</small>
                  </li>
                  <li>
                    <router-link :to="{ name: 'gaussianbeamelongation' }">
                      Pulse elongation through material
                    </router-link><br><small>Arbitrary material thickness</small>
                  </li>
                  <li>
                    <router-link :to="{ name: 'pulsebroadening' }">
                      Pulse elongation
                    </router-link><br><small>with known GDD</small>
                  </li>
                </ul>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-6 col-xs-12">
      <!-- BEAM PROPERTIES -->
      <div class="panel panel-default">
        <div class="panel-heading">
          <h4>Beam properties</h4>
        </div>
        <div class="panel-body">
          <table style="border-width: 0px">
            <tr>
              <td>
                <img
                  alt="Beam properties"
                  src="@/assets/static/media/svg/beam_properties.svg"
                >
              </td>
              <td>
                <ul>
                  <li>
                    <router-link :to="{ name: 'pulseintensity' }">
                      Peak intensity/fluence
                    </router-link><br><small>Of (super)Gaussian beam</small>
                  </li>
                  <li>
                    <router-link :to="{ name: 'pulsepower' }">
                      Pulse peak power
                    </router-link><br><small>Of Gaussian and sech<sup>2</sup> pulses</small>
                  </li>
                  <li>
                    <router-link :to="{ name: 'beamdisplacement' }">
                      Beam displacement
                    </router-link><br><small>By parallel-surfaced crystal</small>
                  </li>
                  <li>
                    <router-link :to="{ name: 'gaussianbeampropagation' }">
                      Gaussian beam propagation
                    </router-link><br><small>Diameter, curvature, phase, focusing etc.</small>
                  </li>
                  <li>
                    <router-link :to="{ name: 'beamdisplacementsandwich' }">
                      Optical path in heterostructures
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="{ name: 'beamdisplacementslabpair' }">
                      Beam displacement (slab pair)
                    </router-link>
                  </li>
                </ul>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <!-- OTHER CALCULATORS -->
    <div class="col-lg-4 col-md-6 col-xs-12">
     
      <div class="panel panel-default">
        <div class="panel-heading">
          <h4>Other tools</h4>
        </div>
        <div class="panel-body">
          <table style="border-width: 0px">
            <tr>
              <td>
                <img alt="Other tools" src="@/assets/static/media/svg/other.svg" />
              </td>
              <td>
                <ul>
                  <li>
                    <router-link :to="{ name: 'planner' }">
                      Planner
                    </router-link><br><small>Create optical table layout</small>
                  </li>
                  <li>
                    <router-link :to="{ name: 'tuningcurves' }">
                      Tuning curves
                    </router-link><br><small>Performance charts of wavelength-tunable sources</small>
                  </li>
                  <li>
                    <router-link :to="{ name: 'harmonicmoduleoptimization' }">
                      Laser harmonic generation
                    </router-link><br><small>Performance charts of laser harmonic generators</small>
                  </li>
                </ul>
              </td> 
            </tr>
          </table>
        </div>
      </div>
    </div>
    <!-- DASHBOARD -->
    <!-- <div class="col-lg-4 col-md-6 col-xs-12">
      
      <div class="panel panel-default mobile-hide">
        <div class="panel-body">
          <table style="border-width: 0px">
            <tr>
              <td>
                <a href="{% url 'tools:dashboard' %}"
                  ><img alt="Dashboard" src="@/assets/static/media/svg/logo_dashboard.svg" width="120px"
                /></a>
              </td>
              <td>
                <ul>
                  <li><a href="{% url 'tools:dashboard' %}">Empty dashboard</a></li>
                  <li><a href="{% url 'tools:dashboard' %}?layout=dispersion">Beam</a></li>
                </ul>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
export default {};
</script>
<style scoped></style>
