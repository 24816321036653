<template lang="">
  <div
    id="beamdisplacementsandwich"
    class="row"
    ng-controller="materialslistCtrl"
  >
    <!-- TITLE -->
    <div class="col-lg-12 col-md-12 col-xs-12 settings">
      <h3>
        Optical path in heterostructures
        <small><span
          data-toggle="collapse"
          data-target="#info_beamdisplacementsandwich"
          class="glyphicon glyphicon-info-sign info-glyph"
        /></small>
      </h3>
    </div>
    <!-- INPUT -->
    <div class="col-lg-6 col-lg-push-6 col-xs-12">
      <div class="row">
        <div class="col-lg-7 col-xs-7 no-right-padding">
          <h5>Material</h5>
        </div>
        <div class="col-lg-3 col-xs-3">
          <h5><math-jax latex="h" /><small> [mm]</small></h5>
        </div>
        <div class="col-lg-2 col-xs-2 no-left-padding">
          <button
            type="button"
            class="btn btn-info btn-add-remove"
            style="width: 100%"
            href="#"
            @click="addMaterial"
          >
            <span class="glyphicon glyphicon-plus" />
          </button>
        </div>
      </div>
      <div v-if="$store.state.dataReady">
        <MaterialSelect
          v-for="(material, index) in materialList"
          :key="material.id"
          :n="index"
          :thickness="material.thickness"
          :default-material-name="material.material.key"
          :with-thickness="true"
          @delete="onDelete(index)"
          @material="onSelect"
          @hChange="onhChange"
        />
      </div>

      <!-- WAVELENGTH SELECT -->
      <div class="row">
        <div class="col-md-7 col-xs-7 top-padding10">
          <Slider
            v-model="slider.value"
            v-bind="slider"
            @update="onSliderChange()"
          />
        </div>
        <div class="col-md-3 col-xs-5">
          <div class="input-group">
            <input
              v-model="inputValue"
              step="1"
              type="number"
              class="form-control indicator"
              tabindex="1000"
              @change="onInputChange()"
            >
            <span class="input-group-addon">nm</span>
          </div>
        </div>
      </div>
    </div>
    <!-- OUTPUT -->
    <div class="col-lg-6 col-lg-pull-6 col-xs-12">
      <div class="row">
        <div
          id="info_beamdisplacementsandwich"
          class="col-lg-12 col-md-12 col-xs-12 collapse"
        >
          <p>
            Optical path and carrier-envelope phase difference of beam, passing through sandwiched heterostructure of
            different materials
          </p>
          <img
            class="img-center"
            src="@/assets/static/media/svg/beamdisplacementsandwich.svg"
          >
        </div>
      </div>

      <div
        v-if="no_wl_overlap"
        class="alert alert-danger"
      >
        <strong>Material wavelength ranges do not overlap!</strong>
      </div>
      <!-- OUTPUT ( ORDINARY )-->
      <BeamDisplacementSandwichRayOutput
        ref="ordinary"
        Title="Ordinary"
        icon="glyphicon glyphicon-resize-horizontal"
        :material-list="materialList"
        :wl="inputValue"
      />
      <!-- OUTPUT ( EXTRAORDINARY )-->
      <BeamDisplacementSandwichRayOutput
        ref="extraordinary"
        Title="Extraordinary"
        icon="glyphicon glyphicon-resize-vertical"
        :material-list="materialList"
        :wl="inputValue"
        :extraordinay-ray="true"
      />
    </div>
  </div>
</template>
<script>
import BeamDisplacementSandwichRayOutput from "@/components/BeamDisplacementSandwichRayOutput.vue";
import MaterialSelect from "@/components/MaterialSelect.vue";
import Slider from "@vueform/slider";

export default {
  components: {
    BeamDisplacementSandwichRayOutput,
    MaterialSelect,
    Slider,
  },
  data() {
    return {
      materialList: [],
      inputValue: 1030,
      slider: {
        value: 1030,
        max: 2000,
        lazy: false,
        showTooltip: "drag",
        step: -1,
      },
      wlrangeMin: null,
      wlrangeMax: null,
      materialCount: 1,
      no_wl_overlap: false,
    };
  },
  computed: {
    materialListLength() {
      return this.materialList.length;
    },
    defaultmaterial(){
      return this.$store.state.defaultMaterial
    }
  },
  watch: {
    defaultmaterial(){
      this.addMaterial();
    }
  },
  mounted() {
    if(this.$store.state.defaultMaterial !== null) this.addMaterial();
  },
  methods: {
    onhChange(thickness, n) {
      this.materialList[n].thickness = thickness;
      this.$refs.ordinary.calculate(this.inputValue);
      this.$refs.extraordinary.calculate(this.inputValue);
    },
    onInputChange() {
      this.slider.value = this.inputValue;
    },
    onSliderChange() {
      this.inputValue = this.slider.value;
    },
    onSelect(material, n) {
      if (n <= this.materialList.length) {
        this.materialList[n].material = material;
        this.updateRange();
      }
    },
    onDelete(n) {
      this.materialList.splice(n, 1);
    },
    addMaterial() {
      //cia reikia tikrinti kazkoks bybis

      if (this.materialList.length > 0) {
        var newMaterialForList = {
          id: this.materialList[this.materialList.length - 1].id + 1,
          material: this.$store.state.defaultMaterial.value,
          thickness: 1,
        };
      } else {
        newMaterialForList = { id: 1, material:  this.$store.state.defaultMaterial.value, thickness: 1 };
      }
      this.materialList.push(newMaterialForList);
      this.updateRange();

      this.$refs.ordinary.calculate(this.inputValue);
      this.$refs.extraordinary.calculate(this.inputValue);
    },
    updateRange() {
      var wlMin = this.materialList[0].material.parameters[0].wl_n_range[0];
      var wlMax = this.materialList[0].material.parameters[0].wl_n_range[1];

      for (var i = 0; i < this.materialList.length; i += 1) {
        if (wlMin < this.materialList[i].material.parameters[0].wl_n_range[0]) {
          wlMin = this.materialList[i].material.parameters[0].wl_n_range[0];
        }

        if (wlMax > this.materialList[i].material.parameters[0].wl_n_range[1]) {
          wlMax = this.materialList[i].material.parameters[0].wl_n_range[1];
        }
      }

      wlMin *= 1.0e3;
      wlMax *= 1.0e3;

      this.wlrangeMin = wlMin;
      this.wlrangeMax = wlMax;

      this.slider.min = wlMin;
      this.slider.max = wlMax;

      //if wavelength ranges do not overlap
      if (this.wlrangeMin > this.wlrangeMax) {
        this.no_wl_overlap = true;
      } else {
        this.no_wl_overlap = false;
      }
    },
  }
};
</script>
