<template>
  <div
    id="unitconverter"
    class="row"
    ng-controller="unitconverterCtrl"
  >
    <!-- TITLE -->
    <div class="col-xs-12">
      <h3>Unit converter</h3>
    </div>

    <div class="col-lg-2 col-md-4 col-xs-6">
      <h5>
        Wavelength <math-jax
          latex="\lambda"
          tabindex="-1"
        />&nbsp;
        <span
          class="glyphicon glyphicon-info-sign info-glyph"
          data-toggle="modal"
          data-target="#description_convert_from_lambda"
        />
      </h5>
      <div class="input-group">
        <input
          ref="focusMe"
          v-model="lambda"
          type="number"
          step="1.0"
          class="form-control indicator"
        >
        <span class="input-group-addon">nm</span>
      </div>
    </div>

    <div class="col-lg-2 col-md-4 col-xs-6">
      <h5>
        Wavenumber <math-jax latex="k" />&nbsp;
        <span
          class="glyphicon glyphicon-info-sign info-glyph"
          data-toggle="modal"
          data-target="#description_convert_from_k"
        />
      </h5>
      <div class="input-group">
        <input
          v-model.lazy="k"
          class="form-control indicator"
          type="number"
        >
        <span class="input-group-addon">cm<sup>-1</sup></span>
      </div>
    </div>

    <div class="col-lg-2 col-md-4 col-xs-6">
      <h5>
        Optical period <math-jax latex="T" />&nbsp;
        <span
          class="glyphicon glyphicon-info-sign info-glyph"
          data-toggle="modal"
          data-target="#description_convert_from_T"
        />
      </h5>
      <div class="input-group">
        <input
          v-model.lazy="T"
          step="1"
          class="form-control indicator"
          type="number"
        >
        <span class="input-group-addon">fs</span>
      </div>
    </div>

    <div class="col-lg-2 col-md-4 col-xs-6">
      <h5>
        Ang. frequency <math-jax latex="\omega" />&nbsp;
        <span
          class="glyphicon glyphicon-info-sign info-glyph"
          data-toggle="modal"
          data-target="#description_convert_from_omega"
        />
      </h5>
      <div class="input-group">
        <input
          v-model.lazy="omega"
          step="0.01"
          class="form-control indicator"
          type="number"
        >
        <span class="input-group-addon">fs<sup>-1</sup></span>
      </div>
    </div>

    <div class="col-lg-2 col-md-4 col-xs-6">
      <h5>
        Energy <math-jax latex="E" />&nbsp;
        <span
          class="glyphicon glyphicon-info-sign info-glyph"
          data-toggle="modal"
          data-target="#description_convert_from_E"
        />
      </h5>
      <div class="input-group">
        <input
          v-model.lazy="E"
          step="0.01"
          class="form-control indicator"
          type="number"
        >
        <span class="input-group-addon">eV</span>
      </div>
    </div>

    <div class="col-lg-2 col-md-4 col-xs-6">
      <h5>
        Frequency <math-jax latex="f" />&nbsp;
        <span
          class="glyphicon glyphicon-info-sign info-glyph"
          data-toggle="modal"
          data-target="#description_convert_from_f"
        />
      </h5>
      <div class="input-group">
        <input
          v-model.lazy="f"
          step="1"
          class="form-control indicator"
          type="number"
        >
        <span class="input-group-addon">THz</span>
      </div>
    </div>
  </div>

  <ConvertFromLambdaDescriptionModal id="description_convert_from_lambda" />
  <ConvertFromEDescriptionModal id="description_convert_from_E" />
  <ConvertFromFDescriptionModal id="description_convert_from_f" />
  <ConvertFromKDescriptionModal id="description_convert_from_k" />
  <ConvertFromOmegaDescriptionModal id="description_convert_from_omega" />
  <ConvertFromTDescriptionModal id="description_convert_from_T" />
</template>
<script>
import ConvertFromLambdaDescriptionModal from "@/components/descriptions/converters/ConvertFromLambda.vue";
import ConvertFromEDescriptionModal from "@/components/descriptions/converters/ConvertFromE.vue";
import ConvertFromFDescriptionModal from "@/components/descriptions/converters/ConvertFromF.vue";
import ConvertFromKDescriptionModal from "@/components/descriptions/converters/ConvertFromK.vue";
import ConvertFromOmegaDescriptionModal from "@/components/descriptions/converters/ConvertFromOmega.vue";
import ConvertFromTDescriptionModal from "@/components/descriptions/converters/ConvertFromT.vue";

const c = 299792458;
const pi = 3.14159265359;

export default {
  components: {
    ConvertFromLambdaDescriptionModal,
    ConvertFromEDescriptionModal,
    ConvertFromFDescriptionModal,
    ConvertFromKDescriptionModal,
    ConvertFromOmegaDescriptionModal,
    ConvertFromTDescriptionModal,
  },
  data() {
    return {
      lambdaValue: 0,
    };
  },
  computed: {
    lambda: {
      get: function () {
        return this.lambdaValue.toFixed(0);
      },
      set: function (newValue) {
        if(this.validateNumber(newValue)) this.lambdaValue = newValue;
      },
    },
    T: {
      get: function () {
        return ((this.lambdaValue / c) * 1.0e6).toFixed(3);
      },
      set: function (newValue) {
        if(this.validateNumber(newValue)) this.lambdaValue = (newValue * c) / 1.0e6;
      },
    },
    E: {
      get: function () {
        return (1.0e7 / this.lambdaValue / 8065.54429).toFixed(3);
      },
      set: function (newValue) {
        if(this.validateNumber(newValue)) this.lambdaValue = 1.0e7 / newValue / 8065.54429;
      },
    },
    omega: {
      get: function () {
        return ((c * 2.0 * pi) / 1.0e6 / this.lambdaValue).toFixed(3);
      },
      set: function (newValue) {
        if(this.validateNumber(newValue)) this.lambdaValue = (c * 2.0 * pi) / 1.0e6 / newValue;
      },
    },
    f: {
      get: function () {
        return (c / this.lambdaValue / 1.0e3).toFixed(1);
      },
      set: function (newValue) {
        if(this.validateNumber(newValue)) this.lambdaValue = c / newValue / 1.0e3;
      },
    },
    k: {
      get: function () {
        return (1.0e7 / this.lambdaValue).toFixed(2);
      },
      set: function (newValue) {
        if(this.validateNumber(newValue)) this.lambdaValue = 1.0e7 / newValue;
      },
    },
  },
  mounted () {
    this.$refs.focusMe.focus()    
  },
  methods: {
    validateNumber(number){
      if (/^[A-Za-z]*$/.test(number)) {
        return false;
      } else return true;
    }
  }
};
</script>
<style lang=""></style>
